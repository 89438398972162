import React from "react";
import { withRouter } from 'react-router';

import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import swal from "@sweetalert/with-react";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import Button from "components/CustomButtons";
import Card from "components/Card";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import { processError } from 'components/ErrorBoundary';

import { cardTitle, tooltip } from "assets/jss/omni.jsx";

import { 
  getCardRequest,
  confirmCardRequest,
  associateGuieCardRequest,
  editAddressCardRequest
} from "providers/api-rest.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  tooltip,
  buttonDetail: {
    color: "#ff9800"
  },
  search: {
    position: "absolute",
    right: 0,
    top: "10px",
    padding: 0,
    margin: 0,
    width: "30%",
  }
};

class MonitorTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card_request: {},
      associate_guie: {
        'delivery_type': 'local'
      },
      edit_address: {},
    };
  }

  async getCardRequest(search) {
    const response = await getCardRequest(search);
    if (response.status === 200) {
      this.setState({ 
        card_request: response.data.card_request,
        response
      });
    } else {
      swal("Lo siento!", "Card Request no encontrado en gaita","error");
    }
  }

  setVarForAssociateGuie(key, value){
    let { associate_guie } = this.state;
    associate_guie[key] = value;
    this.setState({ 
      associate_guie: associate_guie
    });
  }

  setValuesForEditAddress(card_request){
    let { edit_address } = this.state;
    edit_address['street'] = card_request.street;
    edit_address['external_number'] = card_request.external_number;
    edit_address['internal_number'] = card_request.internal_number;
    edit_address['colonia'] = card_request.colonia;
    edit_address['postal_code'] = card_request.postal_code;
    edit_address['city'] = card_request.city;
    edit_address['comment'] = card_request.comment;
    this.setState({
      edit_address: edit_address
    });
  }

  setVarForEditAddress(key, value){
    let { edit_address } = this.state;
    edit_address[key] = value;
    this.setState({
      edit_address: edit_address
    });
  }

  async onKeyPressSearch(search) {
    this.getCardRequest(search)
  }

  async confirmCardRequest(card_request_id){
    const response = await confirmCardRequest(card_request_id);
    if (response.status === 200) {
      this.setState({ card_request: response.data });
    } else {
      swal("Lo siento!", "Card Request no encontrado en gaita","error");
    }
  }

  async associateGuieCardRequest(card_request_id){
    const swalConfirmed = await swal('', {
      title: 'Campos requeridos',
      content: (
        <div>
          <input
            class="swal-content__input"
            type='text'
            placeholder='Número De Guía'
            onChange={event => {
              this.setVarForAssociateGuie(
                'provider_id',
                event.target.value
              )
            }}
          />
          <input 
            class="swal-content__input"
            type='text'
            placeholder='Url de tracking'
            onChange={event => {
              this.setVarForAssociateGuie(
                'tracking_url',
                event.target.value
              )
            }}
          />
          <select
            class="swal-content__input"
            onChange={event => {
              this.setVarForAssociateGuie(
                'delivery_type',
                event.target.value
              )
            }}
          >
            <option checked value="local">Local</option>
            <option value="local_next_day">Next Day</option>
            <option value="domestic">Foranea (DHL)</option>
          </select>
        </div>
      ),
      buttons: ['Cancelar', 'Asociar']
    });
    if (swalConfirmed) {
        const associate_guie = this.state.associate_guie;
        if (Object.keys(associate_guie).length !== 0) {
         let data = {
            ...associate_guie,
            card_request_id: card_request_id
          }
          const response = await associateGuieCardRequest(data);
          if (response.status === 200) {
            this.setState({ card_request: response.data });
          } else {
            swal("Lo siento!", "Card Request no encontrado en gaita","error");
          }
        }
    }
  }

  async editAddressCardRequest(card_request_id){
    this.setValuesForEditAddress(this.state.card_request);
    const swalConfirmed = await swal('', {
      title: 'Campos requeridos',
      content: (
        <form>
          <label>Calle</label>
          <input
            class="swal-content__input"
            type='text'
            placeholder="Calle"
            name='street'
            defaultValue={this.state.edit_address.street}
            onChange={event => {
              this.setVarForEditAddress(
                'street',
                event.target.value
              )
            }}
          />
          <label>Exterior</label>
          <input
            class="swal-content__input"
            type='text'
            placeholder='Exterior'
            name='external_number'
            defaultValue={this.state.edit_address.external_number}
            onChange={event => {
              this.setVarForEditAddress(
                'external_number',
                event.target.value
              )
            }}
          />
          <label>Interior</label>
          <input
            class="swal-content__input"
            type='text'
            placeholder='Interior'
            name='internal_number'
            defaultValue={this.state.edit_address.internal_number}
            onChange={event => {
              this.setVarForEditAddress(
                'internal_number',
                event.target.value
              )
            }}
          />
          <label>Colonia</label>
          <input
            class="swal-content__input"
            type='text'
            placeholder='Colonia'
            name='colonia'
            defaultValue={this.state.edit_address.colonia}
            onChange={event => {
              this.setVarForEditAddress(
                'colonia',
                event.target.value
              )
            }}
          />
          <label>CP</label>
          <input
            class="swal-content__input"
            type='text'
            placeholder='CP'
            name='postal_code'
            defaultValue={this.state.edit_address.postal_code}
            onChange={event => {
              this.setVarForEditAddress(
                'postal_code',
                event.target.value
              )
            }}
          />
          <label>Ciudad</label>
          <input
            class="swal-content__input"
            type='text'
            placeholder='Exterior'
            name='city'
            defaultValue={this.state.edit_address.city}
            onChange={event => {
              this.setVarForEditAddress(
                'city',
                event.target.value
              )
            }}
          />
          <label>Comentario</label>
          <textarea
            class="swal-content__input"
            placeholder='Comentario'
            name='comment'
            defaultValue={this.state.edit_address.comment}
            onChange={event => {
              this.setVarForEditAddress(
                'comment',
                event.target.value
              )
            }}
          />
        </form>
      ),
      showCloseButton: true,
      buttons: ['Cancelar', 'Guardar']
    });
    if (swalConfirmed) {
        const edit_address = this.state.edit_address;
        if (Object.keys(edit_address).length !== 0) {
         let data = {
            ...edit_address,
            card_request_id: card_request_id
          }
          const response = await editAddressCardRequest(data);
          if (response.status === 200) {
            this.setState({ card_request: response.data });
          } else {
            swal("Lo siento!", "Card Request no encontrado en gaita","error");
          }
        }
     }
  }

  render() {
    const {
      response,
      card_request,
    } = this.state;
    const marginRight = {
      marginRight: "10px"
    }
    const { classes, location } = this.props;    
    if (response && response.status !== 200 && response.status !== 201) {
      processError(response.status, location.pathname, false, '', true);
    }
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Buscar envío en Gaita
                <CustomInput
                  className={classes.search}
                  formControlProps={{
                    className: classes.top + " " + classes.search
                  }}
                  inputProps={{
                    placeholder:
                      "Buscar (user_id) (telephone) (email) Etc...",
                    inputProps: {
                      "aria-label": "Buscar",
                      className: classes.searchInput
                    },
                    onKeyPress: event => {
                      if (event.key === "Enter") {
                        this.onKeyPressSearch(event.target.value);
                      }
                    }
                  }}
                />
              </h4>
            </CardHeader>
            <CardBody>
                {Object.keys(card_request).length !== 0
                  ? <div> 
                      <hr/>
                      {card_request.status !== 'confirmed'
                        ? <div>
                            <Button
                              size="sm"
                              color="info"
                              style={marginRight}
                              round
                              onClick={() =>
                                this.confirmCardRequest(card_request.id)
                              }
                            >
                              Confirmar orden
                            </Button>
                            <Button
                              size="sm"
                              color="primary"
                              style={marginRight}
                              round
                              onClick={() =>
                                this.associateGuieCardRequest(card_request.id)
                              }
                            >
                              Asociar guía
                            </Button>
                            <Button
                              size="sm"
                              color="primary"
                              round
                              onClick={() =>
                                this.editAddressCardRequest(card_request.id)
                              }
                            >
                              Editar dirección
                            </Button>
                            <hr/>
                          </div>
                        : null
                      }
                      <pre>
                        {Object.entries(card_request).map(([key, subject], i) => {
                            if(typeof subject !== 'object'){
                              return (
                                <li className="travelcompany-input" key={i}>
                                  <span className="input-label">{key}: <strong>{`${subject}`}</strong></span>
                                </li>
                              )
                            }
                          }
                        )}
                        {card_request.messenger_info
                          ? <div>
                              <li className="travelcompany-input">
                                <span className="input-label">
                                  Nombre Mensajero: <strong>{card_request.messenger_info.name}</strong>
                                </span>
                              </li>
                              <li className="travelcompany-input">
                                <span className="input-label">
                                  Telefono Mensajero: <strong>{card_request.messenger_info.phone}</strong>
                                </span>
                              </li>
                            </div>
                          : null
                        }
                      </pre>
                    </div>
                  : null
                }
               
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

MonitorTransactions.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(MonitorTransactions));