const accordionStyle = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '20px'
  },
  expansionPanel: {
    background: 'rgb(163, 169, 194, 0.17)',
    marginBottom: '5px',
    boxShadow: 'none',
    '&:before': {
      display: 'none !important'
    }
  },
  expansionPanelExpanded: {
    margin: '0',
    '& $expansionPanelSummary': {
      minHeight: '48px',
      background: 'rgba(0, 0, 0, 0.10)',
    }
  },
  expansionPanelSummary: {
    backgroundColor: 'transparent',
    borderBottom: '1px solid rgb(163, 169, 194, 0.17)',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    color: '#8D8D8D',
    fontWeight: '700',
  },
  expansionPanelSummaryExpaned: {
    color: '#8D8D8D',
    fontWeight: '700',
    '& $expansionPanelSummaryExpandIcon': {
      [theme.breakpoints.up('md')]: {
        top: 'auto !important'
      },
      transform: 'rotate(180deg)',
      [theme.breakpoints.down('sm')]: {
        top: '10px !important'
      }
    }
  },
  expansionPanelSummaryContent: {
    margin: '0 !important'
  },
  expansionPanelSummaryExpandIconExpanded: {},
  title: {
    fontSize: '15px',
    fontWeight: 'bolder',
    marginTop: '0',
    marginBottom: '0',
    color: 'inherit'
  },
});

export default accordionStyle;
