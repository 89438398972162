import React from 'react';

import PropTypes from 'prop-types';

import swal from '@sweetalert/with-react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Badge from 'components/Badge';
import Button from 'components/CustomButtons';
import Card from 'components/Card';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CustomInput from 'components/CustomInput';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem.jsx';


import regularFormsStyle from 'assets/jss/omni/views/regularFormsStyle';

import { cardTitle, tooltip } from 'assets/jss/omni.jsx';

import { 
  getNotificadorTemplates,
  postUrgentNotification
} from 'providers/api-rest.js';

const styles = {
  tooltip,
  regularFormsStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  blockCenter: {
    margin: '0 auto',
    padding: '0'
  },
  userIdsInput: {
    margin: '0',
    padding: '0'
  },
  marginMsgTemp: {
    marginTop: '11px'
  }
};

class CreateExecution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentSelect: 'user_ids',
      user_ids: null,
      timeout_for_req: null,
      templates: [],
      notification: {},
    };
    this.handleChangeTypeData = this.handleChangeTypeData.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.onChangeHandlerFile = this.onChangeHandlerFile.bind(this);
  }

  componentWillReceiveProps = props => {
    if (props.newtemplate) {
      this.getTemplates();
    }
  };

  async componentDidMount() {
    this.getTemplates();
  }

  async getTemplates(){
    const response = await getNotificadorTemplates();
    this.setState({
      templates: response.data ? response.data : [],
      loading: false
    });
  }

  handleChangeTypeData(type) {
    if (!this.state.user_ids){
      this.setState({
        documentSelect: type
      });
    }
  }

  onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
  }

  handleFile(event) {
    const content = event.target.result;
    let lista = content.split('\n');
      let i = lista.length;
      while(i--)
      {
          if(lista[i] !== '')
              lista[i] = lista[i].split(',');
          else
              lista.splice(i,1);
      }
      if (!lista[0].includes('user_id')){
        this.setState({user_ids: null});
        swal(
          'Lo siento!',
          'El archivo CSV debe contener un campo llamado user_id',
          'error'
        );
        const randomString = Math.random().toString(36);
        this.setState({theInputKey: randomString});
        return;
      }
      let user_ids = lista.slice(1).map((data) => {
        return data[0]
      });
      user_ids = user_ids.filter( this.onlyUnique );
      this.setState({user_ids: user_ids});
  }

  onChangeHandlerFile(event) {
    const reader = new FileReader();
    reader.onload = this.handleFile
    reader.readAsBinaryString(event.target.files[0]);
  };

  handleChangeTemplateId(template_id) {
    this.setState({ notification: { template_id } });
  }

  handleChangeManualMessage(field, value) {
    let notification = this.state.notification;
    if (value === ''){
      delete notification[field];
    } else {
      notification[field] = value;
    }
    this.setState({ notification });
  }

  changeFormCustomer(event) {
    const fromCustomer = this.state.formCustomer;
    fromCustomer[event.target.id] = event.target.value;
    this.setState({ message: '', fromCustomer });
  }

  async handleExecution(){
    const notification = this.state.notification;
    if (Object.keys(notification).length === 0){
      swal('Lo siento!', 'Seleciona un template o ingresa el mensaje', 'error');
      return;
    }
    const response = await postUrgentNotification(Object.assign(
      {user_ids: this.state.user_ids}, this.state.notification)
    );
    if (response.status === 201) {
      swal('Excelente!', 'Acción realizada exitosamente.', 'success').then(() => {
        window.location.reload()
      });
    } else if (response.status === 401) {
      swal(
        'Lo siento!',
        'No tiene el rol necesario para realizar esta accion.',
        'error'
      );
    } else {
      swal('Lo siento!', 'Error inesperado, intente de nuevo o contacta a Ingeniería', 'error');
    }
  }

  loadListUserIds(event){
      const value = String(event.target.value).replace(/'/g,'"');
      if(this.timeout_for_req) {
        clearTimeout(this.timeout_for_req);
        this.timeout_for_req = null;
      }
      this.timeout_for_req = setTimeout(() => {
        try{
            let user_ids = JSON.parse(value);
            user_ids = user_ids.filter( this.onlyUnique );
            this.setState({user_ids: user_ids});
        }catch{
          this.setState({user_ids: null});
          swal(
            'Lo siento!',
            'El formato de la informacion es incorrecto',
            'error'
          );
        }
      }, 1000);
  }

  render() {
    const { documentSelect, templates, theInputKey } = this.state;
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color='primary' icon>
          <h4 className={classes.cardIconTitle}>
            Enviar Notificaciones Push
          </h4>
         
        </CardHeader>
        <CardBody>
          <br />
          <GridContainer>
              <GridItem xs={6} sm={6} md={6} className={classes.alignCenter}>
                <div
                  onClick={() => this.handleChangeTypeData('user_ids')}
                  className={classes.changeTypeDocument}
                >
                  <Badge
                    color={
                      documentSelect === 'user_ids'
                        ? 'info'
                        : 'gray'
                    }
                  >
                    Usar lista con user_ids
                  </Badge>
                </div>
              </GridItem>
              <GridItem xs={6} sm={6} md={6} className={classes.alignCenter}>
                <div
                  onClick={() => this.handleChangeTypeData('csv')}
                  className={classes.changeTypeDocument}
                >
                  <Badge
                    color={
                      documentSelect === 'csv' ? 'info' : 'gray'
                    }
                  >
                    Cargar CSV de metabase
                  </Badge>
                </div>
              </GridItem>
            </GridContainer>
            {documentSelect === 'csv' ? (
              <div>
                <hr />
                <strong>Cargar CSV: </strong>
                <br /><br />
                <input
                  type='file'
                  key={theInputKey || '' }
                  name='csv_users'
                  onChange={event => this.onChangeHandlerFile(event)}
                />
                <br /><br /><br />
              </div>
            ) : (
              <div>
                <hr />
                <strong>Cargar Lista: </strong>
                <br />
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    className: classes.userIdsInput
                  }}
                  inputProps={{
                    placeholder:
                      '["USXXXXXX", "USXXXXXXXX", .....]',
                    multiline: true,
                    rows: 4,
                    onChange: event => this.loadListUserIds(event)
                  }}
                />
              </div>
            )}
            <br /><hr />
            <GridContainer>
              <GridItem xs={6} sm={6} md={6} className={classes.alignCenter}>
                <strong>Elegir plantilla: </strong>
                <br /><br />
                <select
                  onChange={event =>
                    this.handleChangeTemplateId(event.target.value)
                  }
                >
                  <option disabled selected>Ninguna</option>
                  {templates.map((template, index) => (
                    <option key={template.id} value={template.id}> {template.id} </option>
                  ))}
                </select>
              </GridItem>
              <GridItem xs={6} sm={6} md={6} className={classes.alignCenter}>
                <strong>Mensaje manual: </strong>
                <br />
                <div className={classes.marginMsgTemp}>
                  <input
                    type='text'
                    placeholder='Titulo'
                    onChange={event =>
                      this.handleChangeManualMessage('title', event.target.value)
                    }
                  />
                  <input
                    type='text'
                    placeholder='Cuerpo'
                    onChange={event =>
                      this.handleChangeManualMessage('body', event.target.value)
                    }
                  />
                </div>
              </GridItem>
            </GridContainer>
        </CardBody>
        <CardFooter className={classes.blockCenter}>
          {!this.state.user_ids
            ? <Button color='gray'>
                ENVIAR PUSH
              </Button>
            : <Button color='info' onClick={() => this.handleExecution()}>
                ENVIAR PUSH A {this.state.user_ids ? this.state.user_ids.length : 0} CLIENTES
              </Button>
          }
        </CardFooter>
        <br />
      </Card>
    );
  }
}

CreateExecution.propTypes = {
  classes: PropTypes.object.isRequired,
  newtemplate: PropTypes.bool
};

export default withStyles(styles)(CreateExecution);
