import React from "react";

import ReactTable from "react-table";

import PropTypes from "prop-types";

import swal from "@sweetalert/with-react";

import Moment from "react-moment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// @material-ui/icons
import blue from "@material-ui/core/colors/blue";

// core components
import Badge from "components/Badge";
import Button from "components/CustomButtons";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import { processError } from 'components/ErrorBoundary';

import { cardTitle, tooltip } from "assets/jss/omni.jsx";

import {
  getGlobalTransfersHeld,
  getLocalTransfersHeld,
  patchCuencaSendBlockSpeitTransaction
} from "providers/api-rest.js";

import Utils from "common/utils.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  tooltip,
  colorSwitchBaseBlue: {
    height: "auto",
    "&$colorChecked": {
      color: blue[500],
      "& + $colorBar": {
        backgroundColor: blue[500]
      }
    }
  },
  colorBar: {},
  colorChecked: {},
  icon: {
    marginLeft: "10%",
    fontSize: "30px",
    color: "#0ca621"
  },
  form: {
    fontFamily: 'Roboto',
    fontSize: '15px',
    color: '#616C93',
  },
  switch: {
    color: '#B3B8CC',
    '&$checked': {
      color: '#616C93',
    },
    '&$checked + $track': {
      backgroundColor: '#616C93',
    },
  },
  switchChecked: {
    color: '#616C93',
  },
  switchTrack: {
    backgroundColor: '#616C93',
  },
};

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showFraud: false,
    };

    this.onFetchData = this.onFetchData.bind(this);
    this.handleExecuteActions = this.handleExecuteActions.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }

  async onFetchData(state, local = false) {
    this.setState({ loading: true, lastState: state });

    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      showFraud: this.state.showFraud,
    };

    let response;
    if (local) {
        response = await getLocalTransfersHeld(params);
    } else {
        response = await getGlobalTransfersHeld(params);
    }

    this.setState({
      data: response.data && response.data.result ? response.data.result : [],
      loading: false,
      pages: response.data ? response.data.pages : 1,
      response: response
    });
  }

  async sendBlockSpeitTransaction(transaction_id) {
    const message = "Aprobar la transferencia saliente del cliente.";
    const button = "Aprobar";

    const reason = await swal(message, {
      title: "¿Estás seguro?",
      content: {
        element: "input",
        attributes: {
          placeholder: "Ingrese el motivo de acción",
          type: "text"
        }
      },
      buttons: ["Cancelar", button]
    });

    if (reason) {
      this.handleExecuteActions(transaction_id, reason);
    } else if (reason === "") {
      swal("Lo siento!", "Motivo de acción requerido!", "error");
    }
  }

  async handleExecuteActions(transaction_id, reason) {
    const response = await patchCuencaSendBlockSpeitTransaction({
      transaction_id: transaction_id,
      reason: reason
    });

    if (response.status === 201) {
      swal("Excelente!", "Acción realizada exitosamente.", "success");
      this.onFetchData(this.state.lastState);
    } else {
      this.showModalError(response);
    }
  }

  handleSwitchChange() {
    this.setState(
      prevState => ({ showFraud: !prevState.showFraud }),
      () => this.onFetchData(this.state.lastState)
    );
  }

  render() {
    const {
      response,
      data,
      pages,
      loading,
      showFraud,
    } = this.state;

    const {
      classes,
      location,
      local,
    } = this.props;

    const columns = [
      {
        Header: "Cliente",
        accessor: "customer_name",
        minWidth: 150,
        Cell: row => {
          const link = `/admin/customer/${row.original.customer_id}`;
          return (
            <strong>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {row.original.customer_name}
              </a>
            </strong>
          );
        }
      },
      {
        Header: "Saldo de Cliente",
        accessor: "balance",
        minWidth: 80,
        Cell: row => {
          if (!row.original.balance) return <strong>$0</strong>;
          return <strong>{Utils.formatPrice(row.original.balance)}</strong>;
        }
      },
      {
        Header: "Rastreo",
        accessor: "clave_rastreo"
      },
      {
        Header: "Fecha",
        accessor: "created_at",
        Cell: row => <Moment utc>{row.original.created_at}</Moment>
      },
      {
        Header: "Monto",
        accessor: "amount",
        minWidth: 70,
        Cell: row => <strong>{Utils.formatPrice(row.original.amount)}</strong>
      },
      {
        Header: "Concepto",
        accessor: "concepto",
        minWidth: 100
      },
      {
        Header: "Acción",
        accessor: "transaction_id",
        minWidth: 70,
        Cell: row => {
          const status = row.original.status;
          return (
            <div className="actions-right">
              {status && status === "fraud" ? null : (
                <Button
                  size="sm"
                  color="info"
                  round
                  onClick={() =>
                    this.sendBlockSpeitTransaction(row.original.transaction_id)
                  }
                >
                  Aprobar
                </Button>
              )}
            </div>
          );
        }
      },
      {
        Header: "Estado",
        accessor: "status",
        minWidth: 70,
        Cell: row => {
          const status = row.original.status;
          return (
            <div className="actions-right">
              {status && status === "fraud" ? (
                <Badge size="sm" color="danger" round>
                  Bloqueada
                </Badge>
              ) : null}
            </div>
          );
        }
      }
    ];
    
    if (response && response.status !== 200 && response.status !== 201) {
      processError(response.status, location.pathname);
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
            <FormControlLabel
                classes={{ label: classes.form }}
                control={
                    <Switch
                        classes={{
                            switchBase: classes.switch,
                            checked: classes.switchChecked,
                        }}
                        checked={showFraud}
                        disabled={loading}
                        onChange={this.handleSwitchChange}
                    />
                }
                label={
                    `${showFraud ? 'Ocultar' : 'Mostrar'} Transferencias a Cuentas Bloqueadas`
                }
            />
        </GridItem>
        <GridItem xs={12}>
            <ReactTable
            manual
            data={data}
            columns={columns}
            pages={pages}
            defaultPageSize={20}
            loading={loading}
            filterable={false}
            sortable={false}
            onFetchData={state =>this.onFetchData(state, local)}
            className="-highlight"
            noDataText="No se han encontrado resultados"
            />
        </GridItem>
      </GridContainer>
    );
  }
}

Transactions.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  local: PropTypes.bool,
};

export default withStyles(styles)(Transactions);
