import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopy from '@material-ui/icons/FileCopy';

// core components
import Button from 'components/CustomButtons';

import { tooltip } from 'assets/jss/omni.jsx';

const styles = {
	tooltip,
	wrapper: {
		margin: 'auto',
		width: '60%',
		position: 'relative',
	},
	button: {
		color: '#616C93',
		'&:hover': {
			color: '#B3B8CC',
		},
		'&:active': {
			color: '#525a7a',
		},
	},
	icon: {
		color: "#616C93",
		'&:hover': {
			color: '#B3B8CC',
		},
		'&:active': {
			color: '#525a7a',
		},
	},
};

const ClipboardCopyWrapper = React.forwardRef((props, ref) => (
	<div ref={ref}>
		{props.children}
	</div>
));

function ClipboardCopy({
	children,
	classes,
	extraData,
	linkInfo,
}) {
	const [copied, setCopied] = useState(false);
	const copyDOMRef = useRef(null);

	const copyToClipboard = () => {
		const selection = window.getSelection();
		const elementToCopy = copyDOMRef.current;
		const range = document.createRange();

		range.selectNodeContents(elementToCopy);

		let linkInfoNode = null;
		if (linkInfo) {
			linkInfoNode = document.createElement('a');
			linkInfoNode.appendChild(document.createTextNode(linkInfo));
			linkInfoNode.href = linkInfo;

			const br = document.createElement('br');
			linkInfoNode.appendChild(br);
			range.insertNode(linkInfoNode);
		}

		let extraDataNodes = [];
		if (extraData) {
			// ExtraData can either be a plain text or an object,
			// If it is an object we asume is of type = { title: str, data: str }
			extraDataNodes = extraData.map(data => {
				const node = document.createElement('span');
				node.style.display = 'inline';
				let dataText = '';
				const dataNode = document.createElement('p');

				if (typeof data === 'object' && data) {
					const titleNode = document.createElement('strong');

					titleNode.appendChild(document.createTextNode(data.title));
					dataText = data.data;

					node.appendChild(titleNode);
				} else {
					dataText = data;
				}

				dataNode.appendChild(document.createTextNode(dataText));
				node.appendChild(dataNode);
				const br = document.createElement('br');
				node.appendChild(br);

				range.insertNode(node);
				return node;
			});
		}

		selection.removeAllRanges();
		selection.addRange(range);
		
		if (document.execCommand('copy')) {
			setCopied(true);
			
			if (linkInfoNode) {
				linkInfoNode.parentNode.removeChild(linkInfoNode);
			}

			if (extraDataNodes.length) {
				extraDataNodes.forEach(node => node.parentNode.removeChild(node));
			}

			setTimeout(() => {
				setCopied(false);
				selection.removeAllRanges();
			}, 1000);
		}
	};

	return (
		<div>
			<div className={classes.wrapper}>
				<Tooltip
					title={copied ? '¡Copiado! 😉' : 'Copiar'}
					className={{ tooltip: classes.tooltip }}
				>
					<Button
						simple
						onClick={copyToClipboard}
						className={classes.button}
					>
						<FileCopy className={classes.icon} />
						Copiado para Ticket
					</Button>
				</Tooltip>
			</div>
			<ClipboardCopyWrapper ref={copyDOMRef}>
				{children}
			</ClipboardCopyWrapper>
		</div>
	);
}

ClipboardCopy.propTypes = {
	classes: PropTypes.object.isRequired,
	linkInfo: PropTypes.string,
	extraData: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.object,
		]),
	),
};

export default withStyles(styles)(ClipboardCopy);
