import React from "react";
import { withRouter } from 'react-router';
import Moment from 'react-moment';

import Button from 'components/CustomButtons';
import Card from "components/Card";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField  from "@material-ui/core/TextField";

import { withStyles } from "@material-ui/core"
import { cardTitle } from "assets/jss/omni.jsx";
import swal from "@sweetalert/with-react";
import { 
    paises,
    entidadesFederativas,
    instrumentosMonentarios,
    tiposOperacion,
    prioridadesDeReporte,
    tiposPersonas,
    regimenes,
} from './PldCatalogs.jsx';

import {
    getPldAlertList, 
    getUserDetail, 
    getUserReportDetail, 
    updateUserReport
} from 'providers/api-rest';

const styles = {
    alignCenter: {
        textAlign: 'center',
        cursor: 'pointer',
        color: '#af0000'
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
  };


class UserReport extends React.Component {
    constructor(props) {
        super(props);
        this.userReportId = props.match.params.userReportId;
        this.validateData = false;
        this.state = {
            user: {name: 'loading...', clabe: 'loading...'},
            relatedAlerts: [],
            mainAlertId: '',
            isEditable: false,
            // Sección <client><domicilio_cliente>
            entidadFederativaDomicilio: '',
            colonia: '',
            ciudadPoblacion: '',
            
            // Sección <cliente>
            actividadEconomica: '',

            // Sección <operacion>
            tipoOperacionItf: '',
            instrumentoMonetario: '',

            // Sección <analisis>
            descripcionOperacion: '',
            razonInusual: '',
            tipoOperacionPerfil: '',
            prioridadReporte: '',

            // Sección <contraparte_if_pago_electronico>
            tipoPersonaContraparte: '',
            razonDenominacionContraparte: '',
            nombreContraparte: '',
            apellidoPaternoContraparte: '',
            apellidoMaternoContraparte: '',
            nacionalidadCuentaContraparte: '',
            institucionFinancieraContraparte: '',
            cuentaClabeContraparte: '',

            userReport: {}
        };      
    }
    
    componentDidMount(){
        this.fetchUserReport(this.userReportId);
    }

    async fetchUserReport(userReportId) {
        let resp = await getUserReportDetail(userReportId);
        const userReport = resp.data;
        this.setState({
            isEditable: userReport.is_editable,
            userReport: userReport,
            // Sección <cuenta>
            paisNacionalidad: userReport.pais_nacionalidad || '',
            nivelCuenta: userReportId.nivel_cuenta || '',
            // Sección <client><domicilio_cliente>
            entidadFederativaDomicilio: userReport.entidad_federativa_domicilio || '',
            colonia: userReport.colonia || '',
            ciudadPoblacion: userReport.ciudad_poblacion || '',
            
            // Sección <cliente>
            actividadEconomica: userReport.actividad_economica || '',

            // Sección <operacion>
            tipoOperacionItf: userReport.tipo_operacion_itf || '',
            instrumentoMonetario: userReport.instrumento_monetario || '',

            // Sección <analisis>
            descripcionOperacion: userReport.descripcion_operacion || '',
            razonInusual: userReport.razon_inusual || '',
            tipoOperacionPerfil: userReport.tipo_operacion_perfil || '',
            prioridadReporte: userReport.prioridad_reporte || '',

            // Sección <contraparte_if_pago_electronico>
            tipoPersonaContraparte: userReport.tipo_persona_contraparte || '',
            razonDenominacionContraparte: userReport.razon_denominacion_contraparte || '',
            nombreContraparte: userReport.nombre_contraparte || '',
            apellidoPaternoContraparte: userReport.apellido_paterno_contraparte || '',
            apellidoMaternoContraparte: userReport.apellido_materno_contraparte || '',
            nacionalidadCuentaContraparte: userReport.nacionalidad_cuenta_contraparte || '',
            institucionFinancieraContraparte: userReport.institucion_financiera_contraparte || '',
            cuentaClabeContraparte: userReport.cuenta_clabe_contraparte || '',

            mainAlertIdError: '',
            paisNacionalidadError: '',
            nivelCuentaError: '',
            entidadFederativaDomicilioError: '',
            coloniaError: '',
            ciudadPoblacionError: '',
            actividadEconomicaError: '',
            tipoOperacionItfError: '',
            instrumentoMonetarioError: '',
            descripcionOperacionError: '',
            razonInusualError: '',
            tipoOperacionPerfilError: '',
            prioridadReporteError: '',
            tipoPersonaContraparteError: '',
            razonDenominacionContraparteError: '',
            nombreContraparteError: '',
            apellidoPaternoContraparteError: '',
            apellidoMaternoContraparteError: '',
            nacionalidadCuentaContraparteError: '',
            institucionFinancieraContraparteError: '',
            cuentaClabeContraparteError: '',
        });

        resp = await getUserDetail(userReport.user_id);
        const user = resp.data;

        resp = await getPldAlertList({
            user_id: userReport.user_id, 
            committee_meeting_date: userReport.committee_meeting_date.split('T')[0],
            status: 'unusual',
        });

        const relatedAlerts = resp.data.items;
        const mainAlertId = userReport.main_alert_uri ? userReport.main_alert_uri.replace('/alerts/', '') : null;
        this.setState({
            user,
            relatedAlerts,
            mainAlertId
        });
    }

    onChangeMainAlertId({ target: { value }}) {
        this.validateMainAlertId(value);
        this.setState({mainAlertId: value});
    }

    onChangeNivelCuenta({ target: { value }}) {
        this.setState({nivelCuenta: value});
    }

    onChangePaisNacionalidad({ target: { value }}) {
        this.validatePaisNacionalidad(value);
        this.setState({paisNacionalidad: value});
    }

    onChangeEntidadFederativaDomicilio({ target: { value }}) {
        this.validateEntidadFederativaDomicilio(value);
        this.setState({entidadFederativaDomicilio: value});
    }

    onChangeColonia({ target: { value }}){
        this.validateColonia(value);
        this.setState({colonia: value});
    }

    onChangeCiudadPoblacion({ target: { value }}) {
        this.validateCiudadPoblacion(value);
        this.setState({ciudadPoblacion: value});
    }

    onChangeActividadEconomica({ target: { value }}) {
        this.validateActividadEconomica(value);
        this.setState({actividadEconomica: value});
    }

    onChangeTipoOperacionItf({ target: { value }}) {
        this.validateTipoOperacionItf(value);
        this.setState({tipoOperacionItf: value});
    }

    onChangeInstrumentoMonentario({ target: { value }}) {
        this.validateTipoOperacionItf(value);
        this.setState({instrumentoMonetario: value});
    }

    onChangeDescripcionOperacion({ target: { value }}) {
        this.validateDescripcionOperacion(value);
        this.setState({descripcionOperacion: value});
    }

    onChangeRazonInusual({ target: { value }}) {
        this.validateRazonInusual(value);
        this.setState({razonInusual: value});
    }

    onChangeTipoOperacionPerfil({ target: { value }}) {
        this.validateTipoOperacionPerfil(value);
        this.setState({tipoOperacionPerfil: value});
    }

    onChangePrioridadReporte({ target: { value }}) {
        this.validatePrioridadReporte(value);
        this.setState({prioridadReporte : value});
    }

    onChangeTipoPersonaContraparte({ target: { value }}) {
        this.setState({tipoPersonaContraparte: value});
    }

    onChangeRazonDenominacionContraparte({ target: { value }}) {
        this.setState({razonDenominacionContraparte: value});
    }

    onChangeNombreContraparte({ target: { value }}) {
        this.setState({nombreContraparte: value});
    }

    onChangeApellidoPaternoContraparte({ target: { value }}) {
        this.setState({apellidoPaternoContraparte: value});
    }

    onChangeApellidoMaternoContraparte({ target: { value }}) {
        this.setState({apellidoMaternoContraparte: value});
    }

    onChangeNacionalidadCuentaContraparte({ target: { value }}) {
        this.validateNacionalidadCuentaContraparte(value);
        this.setState({nacionalidadCuentaContraparte: value});
    }

    onChangeInstitucionFinancieraContraparte({ target: { value }}) {
        this.validateInstitucionFinancieraContraparte(value);
        this.setState({institucionFinancieraContraparte: value});
    }

    onChangeCuentaClabeContraparte({ target: { value }}) {
        this.validateCuentaClabeContraparte(value);
        this.setState({cuentaClabeContraparte: value});
    }
    
    validateMainAlertId = (value = null) => this.validateField('mainAlertId', value, null, null);
    validatePaisNacionalidad = (value = null) => this.validateField('paisNacionalidad', value, null, null);
    validateEntidadFederativaDomicilio = (value = null) => this.validateField('entidadFederativaDomicilio', value, null, null);
    validateColonia = (value = null) => this.validateField('colonia', value, /^\d{4}$/, 'código de 4 dígitos');
    validateCiudadPoblacion = (value = null) => this.validateField('ciudadPoblacion', value, /^\d{3}$/, 'código de 3 dígitos');
    validateActividadEconomica = (value = null) => this.validateField('actividadEconomica', value, /^\d{7}$/, 'código de 7 dígitos');
    validateTipoOperacionItf = (value = null) => this.validateField('tipoOperacionItf', value, /^\d{2}$/, 'código de 3 dígitos');
    validateInstrumentoMonetario = (value = null) => this.validateField('instrumentoMonetario', value, null, null);
    validateDescripcionOperacion = (value = null) => this.validateField('descripcionOperacion', value, /^[A-ZÑ0-9 ,.:/'$\-_]{4,4000}$/, 'Contiene caracteres no válidos');
    validateRazonInusual = (value = null) => this.validateField('razonInusual', value, /^[A-ZÑ0-9 ,.:/'$\-_]{4,4000}$/, 'Contiene caracteres no válidos');
    validateTipoOperacionPerfil = (value = null) => this.validateField('tipoOperacionPerfil', value, null, null);
    validatePrioridadReporte = (value = null) => this.validateField('prioridadReporte', value, null, null);
    validateTipoPersonaContraparte = (value = null) => this.validateField('tipoPersonaContraparte', value, null, null);
    validateRazonDenominacionContraparte = (value = null) => this.validateField('razonDenominacionContraparte', value, /^[A-ZÑ0-9. ]{2,60}$/, 'Contiene caracteres no válidos');
    validateNombreContraparte = (value = null) => this.validateField('nombreContraparte', value, /^[A-ZÑ. ]{3,60}$/, 'Contiene caracteres no válidos');
    validateApellidoPaternoContraparte = (value = null) => this.validateField('apellidoPaternoContraparte', value, /^[A-ZÑ. ]{3,60}$/, 'Contiene caracteres no válidos');
    validateApellidoMaternoContraparte = (value = null) => this.validateField('apellidoMaternoContraparte', value, /^[A-ZÑ. ]{3,60}$/, 'Contiene caracteres no válidos');
    validateNacionalidadCuentaContraparte = (value = null) => this.validateField('nacionalidadCuentaContraparte', value, /^\d{3}$/, 'código de 3 dígitos');
    validateInstitucionFinancieraContraparte = (value = null) => this.validateField('institucionFinancieraContraparte', value, /^[A-ZÑ0-9. #\-,_@']{6,40}$/, 'Contiene caracteres no válidos');
    validateCuentaClabeContraparte = (value = null) => this.validateField('cuentaClabeContraparte', value, /^\d{18}$/, 'clabe de 18 dígitos');

    validateField(fieldName, value, fieldRegex, invalidFieldMessage) {
        if(!this.validateData)
            return null;

        const errorState = {};
        errorState[fieldName + 'Error'] = '';

        if (value === null) {
            value = this.state[fieldName];
        }

        if (!value) {
            errorState[fieldName + 'Error'] = `Ingresa ${fieldName}`;
            this.setState(errorState);
            return false;
        }

        if (fieldRegex !== null && !fieldRegex.test(value)){
            errorState[fieldName + 'Error'] = `Ingresa ${invalidFieldMessage}`;
            this.setState(errorState);
            return false;
        }

        this.setState(errorState);
        return true;
    }

    validate() {
        this.validateData = true;
        const validations = [
            this.validateMainAlertId(),
            this.validateEntidadFederativaDomicilio(),
            this.validateColonia(),
            this.validateCiudadPoblacion(),
            this.validateActividadEconomica(),
            this.validateTipoOperacionItf(),
            this.validateInstrumentoMonetario(),
            this.validateDescripcionOperacion(),
            this.validateRazonInusual(),
            this.validateTipoOperacionPerfil(),
            this.validatePrioridadReporte(),
            this.validateNacionalidadCuentaContraparte(),
            this.validateInstitucionFinancieraContraparte(),
            this.validateCuentaClabeContraparte()
        ];
        return validations.every(val => val === true);
    }

    async onSubmit() {
        if (!this.validate()) {
            return;
        }

        const reportUpdateRequest = {
            main_alert_id: this.state.mainAlertId,
            // Sección <client><domicilio_cliente></domicilio_cliente>
            entidad_federativa_domicilio: this.state.entidadFederativaDomicilio,
            colonia: this.state.colonia,
            ciudad_poblacion: this.state.ciudadPoblacion,
            
            // Sección <cliente>
            pais_nacionalidad: this.state.paisNacionalidad,
            actividad_economica: this.state.actividadEconomica,

            // Sección <operacion>
            tipo_operacion_itf: this.state.tipoOperacionItf,
            instrumento_monetario: this.state.instrumentoMonetario,

            // Sección <analisis>
            descripcion_operacion: this.state.descripcionOperacion,
            razon_inusual: this.state.razonInusual,
            tipo_operacion_perfil: this.state.tipoOperacionPerfil,
            prioridad_reporte: this.state.prioridadReporte,

            // Sección <contraparte_if_pago_electronico>
            tipo_persona_contraparte: this.state.tipoPersonaContraparte,
            razon_denominacion_contraparte: this.state.razonDenominacionContraparte,
            nombre_contraparte: this.state.nombreContraparte,
            apellido_paterno_contraparte: this.state.apellidoPaternoContraparte,
            apellido_materno_contraparte: this.state.apellidoMaternoContraparte,
            nacionalidad_cuenta_contraparte: this.state.nacionalidadCuentaContraparte,
            institucion_financiera_contraparte: this.state.institucionFinancieraContraparte,
            cuenta_clabe_contraparte: this.state.cuentaClabeContraparte,
        };
        if (this.state.nivelCuenta) {
            reportUpdateRequest.nivel_cuenta = this.state.nivelCuenta;
        }
        const resp = await updateUserReport(this.userReportId, reportUpdateRequest);
        if (resp.status === 200) {
            swal(
                'Excelente 🎉',
                'Se cambió el status existosamente',
                'success',
            );
        } else {
            swal(
                'Lo siento 😓',
                `Ocurrió un error: ${
                    resp.data.msg || resp.data.error
                }`,
                'error',
            );
        }
    }


    render() {
        return (
            <Card>
                <CardBody>
                    <div>
                        <hr />
                            <h3>
                            {this.state.user.name} {this.state.user.clabe}
                            </h3>
                        <hr />
                        <FormControl error={this.state.mainAlertIdError !== ''} fullWidth>
                            <InputLabel >Alerta principal</InputLabel>
                            <Select
                                value={this.state.mainAlertId || ''}
                                onChange={this.onChangeMainAlertId.bind(this)}
                                label="Alerta principal"
                                fullWidth
                            >
                                {this.state.relatedAlerts.map((alert) => 
                                    <MenuItem key={alert.id} value={alert.id}>
                                        Detección: <Moment date={Date.parse(alert.created_at)} format="YYYY-MM"/>, 
                                        Fecha de Transacción:  <Moment date={Date.parse(alert.transaction_date)} format="YYYY-MM"/>,
                                        Descripción: {alert.description}
                                    </MenuItem>)
                                }
                            </Select>
                            {
                                this.state.mainAlertIdError && <FormHelperText>{this.state.mainAlertIdError}</FormHelperText>
                            }
                        </FormControl>
                        <br/>
                        <br/>
                        <br/>
                        <FormControl fullWidth>
                            <InputLabel>Tipo de reporte</InputLabel>
                            <Select
                                    id="report-type-select"
                                    label="Tipo de reporte"
                                    value={2}
                                    fullWidth
                                    disabled
                                >
                                    <MenuItem value={2}>Operaciones Inusuales</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl disabled fullWidth>
                            <InputLabel >Régimen</InputLabel>
                            <Select
                                value={this.state.userReport.regimen || ''}
                                label="Régimen"
                                fullWidth
                            >
                                {
                                    regimenes.map((regimen) => <MenuItem key={regimen.code} value={regimen.code}>{regimen.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        <FormControl error={this.state.nivelCuentaError !== ''} disabled={this.state.userReport.regimen === '0'} fullWidth>
                            <InputLabel >Nivel de Cuenta</InputLabel>
                            <Select
                                value={this.state.nivelCuenta || ''}
                                onChange={this.onChangeNivelCuenta.bind(this)}
                                label="Nivel de Cuenta"
                                fullWidth
                            >
                                <MenuItem key={'1'} value={'1'}>NIVEL 1</MenuItem>
                                <MenuItem key={'2'} value={'2'}>NIVEL 2</MenuItem>
                            </Select>
                            {
                                this.state.nivelCuentaError && <FormHelperText>{this.state.nivelCuentaError}</FormHelperText>
                            }
                        </FormControl>
                        <TextField value={this.state.userReport.numero_cuenta_proyecto || ''} label="Número de cuenta proyecto" disabled fullWidth/>
                        <TextField value={this.state.userReport.nacionalidad_cuenta_asociada || ''} label="Nacionalidad Cuenta Asociada" disabled fullWidth/>
                        <TextField value={this.state.userReport.institucion_financiera || ''} label="Institución Financiera" disabled fullWidth/>
                        <FormControl disabled fullWidth>
                            <InputLabel >Tipo Persona</InputLabel>
                            <Select
                                value={this.state.userReport.tipo_persona || ''}
                                label="Tipo Persona"
                                fullWidth
                            >
                                {
                                    tiposPersonas.map((tipo) => <MenuItem key={tipo.code} value={tipo.code}>{tipo.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        <FormControl error={this.state.paisNacionalidadError !== ''} fullWidth>
                            <InputLabel >Pais Nacionalidad</InputLabel>
                            <Select
                                value={this.state.paisNacionalidad || ''}
                                onChange={this.onChangePaisNacionalidad.bind(this)}
                                label="Pais Nacionalidad"
                                fullWidth
                            >
                                {
                                    paises.map((tipo) => <MenuItem key={tipo.code} value={tipo.code}>{tipo.name}</MenuItem>)
                                }
                            </Select>
                            {
                                this.state.paisNacionalidadError && <FormHelperText>{this.state.paisNacionalidadError}</FormHelperText>
                            }
                        </FormControl>
                        <TextField value={this.state.userReport.nombre || ''} label="Nombre" disabled fullWidth/>
                        <TextField value={this.state.userReport.apellido_paterno || ''} label="Apellido paterno" disabled fullWidth/>
                        <TextField value={this.state.userReport.apellido_materno || ''} label="Apellido materno" disabled fullWidth/>
                        <FormControl disabled fullWidth>
                            <InputLabel >Género</InputLabel>
                            <Select
                                value={this.state.userReport.genero || ''}
                                label="Género"
                                fullWidth
                            >
                                <MenuItem key={'1'} value={'1'}>FEMENINO</MenuItem>
                                <MenuItem key={'2'} value={'2'}>MASCULINO</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField value={this.state.userReport.rfc || ''} label="RFC" disabled fullWidth/>
                        <TextField value={this.state.userReport.curp || ''} label="CURP" disabled fullWidth/>
                        <TextField value={this.state.userReport.fecha_nacimiento_constitucion || ''} label="Fecha de nacimiento" disabled fullWidth/>
                        <TextField value={this.state.userReport.telefono || ''} label="Teléfono" disabled fullWidth/>
                        <TextField value={this.state.userReport.correo_electronico || ''} label="Correo Electrónico" disabled fullWidth/>
                        <TextField
                            error={this.state.actividadEconomicaError !== ''}
                            value={this.state.actividadEconomica}
                            onChange={this.onChangeActividadEconomica.bind(this)} 
                            label="Actividad Económica"
                            helperText={this.state.actividadEconomicaError}
                            fullWidth
                        />
                        <FormControl error={this.state.entidadFederativaDomicilioError !== ''} fullWidth>
                            <InputLabel>Entidad Federativa Domicilio</InputLabel>
                            <Select
                                
                                value={this.state.entidadFederativaDomicilio}
                                onChange={this.onChangeEntidadFederativaDomicilio.bind(this)}
                                label="Entidad Federativa Domicilio"
                                fullWidth
                            >
                                {entidadesFederativas.map((item) => 
                                    <MenuItem key={item.code} value={item.code}>
                                        {item.name}
                                    </MenuItem>)
                                }
                            </Select>
                            {
                                this.state.entidadFederativaDomicilioError && <FormHelperText>{this.state.entidadFederativaDomicilioError}</FormHelperText>
                            }
                        </FormControl>
                        <TextField value={this.state.userReport.calle_domicilio || ''} label="Calle Domicilio" disabled fullWidth/>
                        <TextField
                            error={this.state.coloniaError !== ''}
                            value={this.state.colonia} 
                            onChange={this.onChangeColonia.bind(this)}
                            label="Colonia"
                            helperText={this.state.coloniaError}
                            fullWidth
                        />
                        <TextField 
                            error={this.state.ciudadPoblacionError !== ''}
                            value={this.state.ciudadPoblacion} 
                            onChange={this.onChangeCiudadPoblacion.bind(this)} 
                            label="Ciudad Población"
                            helperText={this.state.ciudadPoblacionError}
                            fullWidth
                        />
                        <TextField value={this.state.userReport.codigo_postal || ''} label="Código Postal" disabled fullWidth/>
                        <FormControl error={this.state.tipoOperacionItfError !== ''} fullWidth>
                            <InputLabel>Tipo Operación ITF</InputLabel>
                            <Select
                                value={this.state.tipoOperacionItf}
                                onChange={this.onChangeTipoOperacionItf.bind(this)}
                                label="Tipo Operación ITF"
                                fullWidth
                            >
                                {tiposOperacion.map((item) => 
                                    <MenuItem key={item.code} value={item.code}>
                                        {item.name}
                                    </MenuItem>)
                                }
                            </Select>
                            {
                                this.state.tipoOperacionItfError && <FormHelperText>{this.state.tipoOperacionItfError}</FormHelperText>
                            }
                        </FormControl>
                        <FormControl error={this.state.instrumentoMonetarioError !== ''} fullWidth>
                            <InputLabel>Instrumento Monetario</InputLabel>
                            <Select
                                value={this.state.instrumentoMonetario}
                                onChange={this.onChangeInstrumentoMonentario.bind(this)}
                                label="Instrumento Monetario"
                                fullWidth
                            >
                                {instrumentosMonentarios.map((item) => 
                                    <MenuItem key={item.code} value={item.code}>
                                        {item.name}
                                    </MenuItem>)
                                }
                            </Select>
                            {
                                this.state.instrumentoMonetarioError && <FormHelperText>{this.state.instrumentoMonetarioError}</FormHelperText>
                            }
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                error={this.state.descripcionOperacionError !== ''}
                                value={this.state.descripcionOperacion}
                                onChange={this.onChangeDescripcionOperacion.bind(this)}
                                label="Descripción Operación"
                                helperText={this.state.descripcionOperacionError}
                                multiline
                                rows={8}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                error={this.state.razonInusualError !== ''}
                                value={this.state.razonInusual}
                                onChange={this.onChangeRazonInusual.bind(this)}
                                label="Razón Inusual"
                                helperText={this.state.razonInusualError}
                                multiline
                                rows={8}
                            />
                        </FormControl>
                        <FormControl error={this.state.tipoOperacionPerfilError !== ''} fullWidth>
                            <InputLabel>Tipo Operación Perfil</InputLabel>
                            <Select
                                value={this.state.tipoOperacionPerfil}
                                onChange={this.onChangeTipoOperacionPerfil.bind(this)}
                                label="Tipo Operación Perfil"
                                fullWidth
                            >
                                {tiposOperacion.map((item) => 
                                    <MenuItem key={item.code} value={item.code}>
                                        {item.name}
                                    </MenuItem>)
                                }
                            </Select>
                            {
                                this.state.tipoOperacionPerfilError && <FormHelperText>{this.state.tipoOperacionPerfilError}</FormHelperText>
                            }
                        </FormControl>
                        <FormControl error={this.state.prioridadReporteError !== ''} fullWidth>
                            <InputLabel>Prioridad Reporte</InputLabel>
                            <Select
                                value={this.state.prioridadReporte}
                                onChange={this.onChangePrioridadReporte.bind(this)}
                                label="Prioridad Reporte"
                                fullWidth
                            >
                                {prioridadesDeReporte.map((item) => 
                                    <MenuItem key={item.code} value={item.code}>
                                        {item.name}
                                    </MenuItem>)
                                }
                            </Select>
                            {
                                this.state.prioridadReporteError && <FormHelperText>{this.state.prioridadReporteError}</FormHelperText>
                            }
                        </FormControl>
                        <div> Contraparte</div>
                        <FormControl error={this.state.tipoPersonaContraparteError !== ''} fullWidth>
                            <InputLabel>Tipo Persona</InputLabel>
                            <Select
                                value={this.state.tipoPersonaContraparte}
                                onChange={this.onChangeTipoPersonaContraparte.bind(this)}
                                label="Tipo Persona"
                                fullWidth
                            >
                                {tiposPersonas.map((item) => 
                                    <MenuItem key={item.code} value={item.code}>
                                        {item.name}
                                    </MenuItem>)
                                }
                            </Select>
                            {
                                this.state.tipoPersonaContraparteError && <FormHelperText>{this.state.tipoPersonaContraparteError}</FormHelperText>
                            }
                        </FormControl>
                        <br/>
                        <TextField 
                            error={this.state.razonDenominacionContraparteError !== ''}
                            value={this.state.razonDenominacionContraparte} 
                            onChange={this.onChangeRazonDenominacionContraparte.bind(this)} 
                            label="Razón Denominación"
                            helperText={this.state.razonDenominacionContraparteError}
                            fullWidth
                        />
                        <br/>
                        <TextField
                            error={this.state.nombreContraparteError !== ''}
                            value={this.state.nombreContraparte} 
                            onChange={this.onChangeNombreContraparte.bind(this)}
                            helperText={this.state.nombreContraparteError}
                            label="Nombre" 
                            fullWidth
                        />
                        <TextField
                            error={this.state.apellidoPaternoContraparteError !== ''}
                            value={this.state.apellidoPaternoContraparte} 
                            onChange={this.onChangeApellidoPaternoContraparte.bind(this)} 
                            label="Apellido Paterno" 
                            helperText={this.state.apellidoPaternoContraparteError}
                            fullWidth
                        />
                        <TextField
                            error={this.state.apellidoMaternoContraparteError !== ''}
                            value={this.state.apellidoMaternoContraparte} 
                            onChange={this.onChangeApellidoMaternoContraparte.bind(this)} 
                            label="Apellido Materno" 
                            helperText={this.state.apellidoMaternoContraparteError}
                            fullWidth
                        />
                        <FormControl error={this.state.nacionalidadCuentaContraparteError !== ''} fullWidth>
                            <InputLabel>Nacionalidad</InputLabel>
                            <Select
                                value={this.state.nacionalidadCuentaContraparte}
                                onChange={this.onChangeNacionalidadCuentaContraparte.bind(this)}
                                label="Nacionalidad"
                                fullWidth
                            >
                                {paises.map((item) => 
                                    <MenuItem key={item.code} value={item.code}>
                                        {item.name}
                                    </MenuItem>)
                                }
                            </Select>
                            {
                                this.state.nacionalidadCuentaContraparteError && <FormHelperText>{this.state.nacionalidadCuentaContraparteError}</FormHelperText>
                            }
                        </FormControl>
                        <TextField
                            error={this.state.institucionFinancieraContraparteError !== ''}
                            value={this.state.institucionFinancieraContraparte} 
                            onChange={this.onChangeInstitucionFinancieraContraparte.bind(this)}
                            label="Institución Financiera"
                            helperText={this.state.nacionalidadCuentaContraparteError}
                            fullWidth
                        />
                        <TextField
                            error={this.state.cuentaClabeContraparteError !== ''}
                            value={this.state.cuentaClabeContraparte} 
                            onChange={this.onChangeCuentaClabeContraparte.bind(this)}
                            label="Cuenta" 
                            helperText={this.state.cuentaClabeContraparteError}
                            fullWidth
                        />
                    </div>
                    {this.state.isEditable &&
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <Button round color="success" onClick={this.onSubmit.bind(this)}>Guardar</Button>
                            </GridItem> 
                        </GridContainer>
                    }
                </CardBody>
            </Card>
        );
    }
}


export default withRouter(withStyles(styles)(UserReport));
