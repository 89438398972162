import React from 'react';
import { withRouter } from 'react-router';

import ReactTable from 'react-table';

import PropTypes from 'prop-types';

import swal from '@sweetalert/with-react';
import Swal from 'sweetalert2';
import JsZip from 'jszip';
import FileSaver from 'file-saver';

import Moment from 'react-moment';

import ClipLoader from "react-spinners/ClipLoader";

import Chat from '@material-ui/icons/Chat';
import FileCopy from '@material-ui/icons/FileCopy';
import Info from '@material-ui/icons/Info';
import ListAlt from '@material-ui/icons/ListAlt';
import OpenInNew from '@material-ui/icons/OpenInNew';
import PanTool from '@material-ui/icons/PanTool';

// @material-ui/core components
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Accordion from 'components/Accordion';
import Badge from "components/Badge";
import Button from "components/CustomButtons";
import Card from "components/Card";
import CardBody from "components/Card/CardBody.jsx";
import ClipboardCopy from 'components/ClipboardCopy';
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import ModalOfDocument from "components/ModalOfDocument";
import ModalUploadDocument from "components/ModalUploadDocument";
import NavPills from "components/NavPills";
import ErrorBoundary from 'components/ErrorBoundary';
import { processError } from 'components/ErrorBoundary';

import BalanceEntries from 'views/BalanceEntries';
import BotmakerMessages from 'views/Messages/BotmakerMessages';
import HoldTransfers from 'views/HoldTransfers';
import LogOfCustomers from 'views/LogOfCustomers';
import Zendesk from 'views/Zendesk';

import { descriptionFiscalRegimeCode } from '../../utils.js';
import BirthInput from './BirthInput';
import AssignCardInput from './AssignCardInput'

import {
  getArcusUserDeposits,
  getCustomer,
  getCustomers,
  getDocuments,
  patchCuencaBlockFraudUser,
  patchCuencaUnBlockFraudUser,
  postCuencaWhilelistAddUser,
  patchCuencaDeactivateBlockAccount,
  patchCuencaResetPassword,
  deleteCuencaDeactivateCard,
  patchCuencaReactivateCardTrustedAction,
  getVerifiedStatus,
  postVerifiedChangeStatus,
  postMatiRequestVerification,
  getCards,
  deactivateAccount,
  reactivateAccount,
  changeHumaData,
  changeName,
  changeRfc,
  changePhoneNumber,
  returnTransaction,
  postGaitaCardReposition,
  chargeCardReposition,
  getGaitaCardRequestCount,
  changePldLevelRisk,
  changeDebitBalance,
  changeCashWithdrawLimit,
  changeNegativeLevel,
  generateNewClabe,
  downloadStatement,
  getCardReplacement,
  inactivateCardReplacement,
  createCardReplacement,
  getStoreReview,
  sendStoreReview,
  getFraudFundsData,
  sendFraudFundsToStp,
} from "providers/api-rest.js";

import { tooltip } from 'assets/jss/omni.jsx';

const styles = theme => ({
  cardCenter: {
    textAlign: 'center'
  },
  tooltip,
  progress: {
    margin: theme.spacing.unit * 2
  },
  badgeStatusVerified: {
    position: 'absolute',
    right: '10px',
    borderRadius: '0',
    cursor: 'pointer',
    textAlign: 'right'
  },
  changeTypeDocument: {
    cursor: 'pointer'
  },
  badgeLevelLeft: {
    position: 'absolute',
    left: '10px',
    borderRadius: '0',
    cursor: 'pointer',
    fontSize: '20px'
  },
  iconLinkToMati: {
    fontSize: '11px',
    position: 'relative',
    bottom: '4px'
  },
  iconLinkToMatiIcon: {
    fontSize: '15px'
  },
  selectVerifiedChangeStatus: {
    fontSize: '15px',
    height: '30px',
    width: '200px',
    background: 'white'
  },
  cardRequestsNumber: {
    color: '#ea4c89',
    padding: '7px'
  },
  badgeCustomerStatus: {
    marginTop: '5px'
  },
  renapoStyle: {
    fontSize: '15px',
    top: '3px',
    position: 'relative'
  },
  topBadges: {
    width: '60%',
    margin: 'auto',
  },
  AccordionTable: {
    width: '100%',
    textAlign: 'left'
  },
  crWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '20px',
    marginTop: '15px',
  },
  crInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
  },
});

const CardReplacementStatus = {
  NOT_VIEWED: 1,
  VIEWED: 2,
  PHYSICAL_REQUESTED: 3,
  PHYSICAL_DELIVERED: 4,
  VIRTUAL_REQUESTED: 5,
  REMINDER_ACTIVE: 6,
};
Object.freeze(CardReplacementStatus);

class CustomerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalUploadDocumentOpen: false,
      customer: {},
      tabs: [
        {
          tabButton: 'Transacciones',
          tabIcon: ListAlt,
        },
        {
          tabButton: 'Mensajes Botmaker',
          tabIcon: Chat,
        },
        {
          tabButton: 'Tickets Zendesk',
          tabIcon: ListAlt,
        },
        {
          tabButton: 'Histórico de Acciones',
          tabIcon: ListAlt,
        },
        {
          tabButton: 'Depósitos Retenidos',
          tabIcon: PanTool,
        }
      ],
      statusSelect: {},
      cards: null,
      verified: null,
      copied: false,
      cardRequestsNumber: 0,
      cardPayment: null,
      loadingSimilarCustomer: true,
      cardReplacement: null,
      fiscalRegimeCode: null,
      storeReview: null
    };
  }

  handleChangeTab(active) {
    const { match, location, history } = this.props;
    const { customer } = this.state;

    const componentAttrs = {
      match: match,
      location: location,
      history: history,
    };

    const tabComponents = [
      <BalanceEntries
        userId={customer.user_id}
        key={0}
      />,
      <BotmakerMessages
        {...componentAttrs}
        phone_number={customer.phone_number}
        key={1}
      />,
      (
        <ErrorBoundary boudaryType='zendesk'>
          <Zendesk
            {...componentAttrs}
            user_id={customer.user_id}
            key={2}
          />
        </ErrorBoundary>
      ),
      <LogOfCustomers
        searchConcat={`${customer.id} ${customer.user_id}`}
        key={3}
      />,
      <HoldTransfers
        userId={customer.user_id}
        key={4}
      />,
    ];

    const { tabs } = this.state;
    tabs[active]['tabContent'] = tabComponents[active];
    this.setState({ tabs });
  }

  showModalError(response, type = '') {
    if (response.status === 401) {
      if (response['data']['msg'] === 'invalid role') {
        swal(
          'Lo siento!',
          'No tiene el rol necesario para realizar esta accion.',
          'error'
        );
      } else {
        swal(
          'Lo siento!',
          'Esta acción requiere verificación con yubikey',
          'error'
        );
      }
    } else {
      if (type === 'reset') {
        swal('Lo siento!', 'La contraseña ya se encuentra reseteada', 'error');
      } else if (type === 'block') {
        swal('Lo siento!', 'Esta cuenta no se encuentra bloqueada', 'error');
      } else if (type === 'rfc') {
        swal(
          'Lo siento 😔',
          'El RFC es incorrecto o el usuario ya tiene ese RFC asignado.',
          'error'
        );
      } else if (response['data'] && response['data']['error']) {
        swal('Lo siento!', response['data']['error'], 'error');
      } else {
        swal('Lo siento!', 'Error inesperado, contacta a Ingeniería', 'error');
      }
    }
  }

  async getCustomer() {
    const { match } = this.props;
    const customer_id = match.params.customer_id;

    const response = await getCustomer(customer_id);
    this.setState({
      response: response,
      customer: response.data
    });
  }

  async getVerifiedStatus() {
    const { match } = this.props;
    const customer_id = match.params.customer_id;
    const response = await getVerifiedStatus({
      human_id: customer_id
    });
    if (response.status === 200) {
      this.setState({
        verified: response.data
      });
    }
  }

  async getCards() {
    const { match } = this.props;
    const customer_id = match.params.customer_id;
    const response = await getCards({
      human_id: customer_id
    });
    if (response.status === 200) {
      this.setState({
        cards: response.data
      });
    }
  }

  async getGaitaCardRequestCount() {
    const {
      match: {
        params: { customer_id }
      }
    } = this.props;
    const response = await getGaitaCardRequestCount(customer_id);

    if (response.status === 200) {
      const { card_requests, card_payment } = response.data;
      this.setState({
        cardRequestsNumber: card_requests ? card_requests.length : 0,
        cardPayment: card_payment ? card_payment : null
      });
    }
  }

  getStoreReview = async () => {
    const { match } = this.props;
    const { customer_id } = match.params;
    const response = await getStoreReview(customer_id);
    if (response.status === 200) {
      const { store_review } = response.data;

      if (!store_review) return;
      this.setState({
        storeReview: {
          sent: !!store_review['created_at'],
          readOrDone: !!store_review['notification_viewed_at'],
        },
      });
    }
  };

  async getRegimeFiscalCode() {
    const { customer } = this.state;
    this.setState({
      fiscalRegimeCode: customer.fiscal_regime_code,
    });
  }

  componentDidMount() {
    this.getCustomer();
    this.getVerifiedStatus();
    this.getCards();
    this.getGaitaCardRequestCount();
    this.getStoreReview();
    this.getRegimeFiscalCode();
  }

  async handleClickOpen(document_type) {
    this.setState({ modalOpen: true });
    const date = new Date();
    const user_id = this.state.customer.user_id;
    const response = await getDocuments({
      document_type: document_type,
      user_id: user_id,
      version: date.getTime()
    });
    if (response.status === 200) {
      this.setState({
        response: response,
        modalDocument: {
          type: document_type,
          blob: response.data,
          mimetype: response.type
        }
      });
    } else if (response.status >= 400 && response.status < 452) {
      this.handleClose();
      let modalTitle = '';
      switch (document_type) {
        case 'proofs_of_address':
          modalTitle = 'Comprobante de Domicilio';
          break;
        case 'proofs_of_life':
          modalTitle = 'Prueba de Vida';
          break;
        default:
          modalTitle = 'Documento de identidad';
          break;
      }
      const error = response.status === 400
        ? `No se pudo crear el video para la/el ${modalTitle}, contacta a Ingeniería.`
        : `No se encontró el archivo para la/el ${modalTitle}, ¡hay que subirlo!`;
      await swal(
        `Woops! 😵‍💫`,
        error,
        'warning',
      );
    } else {
      this.handleClose();
      this.showModalError(response);
    }
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
      modalDocument: null
    });
  };

  handleCloseUploadDocument = () => {
    this.setState({
      modalUploadDocumentOpen: false
    });
  };

  async handlePasswordReset(user_id) {
    const message = 'Se reseteara la contraseña del usuario.';
    const button = 'Resetear';
    const reason = await this.handleClickActions(message, button);
    if (reason) {
      const response = await patchCuencaResetPassword({
        user_id: user_id,
        reason: reason
      });
      if (response.status === 201) {
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
      } else {
        this.showModalError(response, 'reset');
      }
    }
  }

  async handleUnblockTrustedContact(user_id) {
    const message = `Se desbloqueará la cuenta del cliente, blockeada
        anteriormente por una persona de confianza.`;
    const button = 'Desbloquear';
    const reason = await this.handleClickActions(message, button);
    if (reason) {
      const response = await patchCuencaDeactivateBlockAccount({
        user_id: user_id,
        reason: reason
      });
      if (response.status === 201) {
        this.getCustomer();
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
      } else {
        this.showModalError(response, 'unblock');
      }
    }
  }

  async handleBlockFraud(user_id, status) {
    const message =
      'Se bloqueará la cuenta del cliente por actividad sospechosa.';
    const button = 'Bloquear';
    const reason = await this.handleClickActions(message, button);
    if (reason) {
      const response = await patchCuencaBlockFraudUser({
        user_id: user_id,
        reason: reason,
        status: status,
      });
      if (response.status === 201) {
        this.rejectProofOfAddress();
        this.getCustomer();
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
      } else {
        this.showModalError(response, 'block_fraud');
      }
    }
  }

  async handleUnBlockFraud(user_id) {
    const message =
      'Se desbloqueará la cuenta del cliente bloqueada por fraude.';
    const button = 'Desbloquear';
    const reason = await this.handleClickActions(message, button);
    if (reason) {
      const response = await patchCuencaUnBlockFraudUser({
        user_id: user_id,
        reason: reason
      });
      if (response.status === 201) {
        this.getCustomer();
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
      } else {
        this.showModalError(response, 'un_block_fraud');
      }
    }
  }

  async handleAddToWhiteList(user_id) {
    const message =
      'Permite que el cliente realice transacciones sin límite y envías todas sus transacciones sospeshosas.';
    const button = 'Añadir';
    const reason = await this.handleClickActions(message, button);
    if (reason) {
      const response = await postCuencaWhilelistAddUser({
        user_id: user_id,
        reason: reason
      });
      if (response.status === 201) {
        this.getCustomer();
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
      } else {
        this.showModalError(response, 'whilelist_add');
      }
    }
  }

  async handleClickActions(message, button = 'Confirmar') {
    const reason = await swal(message, {
      title: '¿Estás seguro?',
      content: {
        element: 'input',
        attributes: {
          placeholder: 'Ingrese el motivo de acción',
          type: 'text'
        }
      },
      buttons: ['Cancelar', button]
    });
    if (reason) {
      return reason;
    } else if (reason === '') {
      swal('Lo siento!', 'Motivo de acción requerido!', 'error');
    }
    return false;
  }

  changeFieldCardToValue(card_original, field, value) {
    const cardsType = this.state.cards[card_original.type];
    for (let card in cardsType) {
      if (cardsType[card]['id'] === card_original['id']) {
        cardsType[card][field] = value;
        break;
      }
    }
    const customer = this.state.customer;
    customer['cards'] = this.state.cards;
    this.setState({ customer: customer });
  }

  async swalConfirmActionCard(card) {
    if (card.status === 'deactivated' || card.is_blocked) {
        return false;
    }

    let message = `Al aceptar se desactivara la tarjeta para que el cliente puede asociar otra tarjeta a su cuenta.

    No puede revertirse la acción, ¿quieres realizar de todos modos?`;
    let button = 'Desactivar';

    const reason = await this.handleClickActions(message, button);
    if (reason) {
          this.handleDeactivatedCard(card, reason);
    }
  }

  async handleDeactivatedCard(card, reason) {
    // Create a card reposition if they have no card_requests and the physical card is being bloqued
    const {
      cardRequestsNumber,
      customer: { user_id }
    } = this.state;

    const response = await deleteCuencaDeactivateCard({
      user_id: user_id,
      last_4_digits: card['last_4_digits'],
      reason: reason
    });
    if (response.status === 201) {
      if (card.type === 'physical' && cardRequestsNumber === 0) {
        await this.handleCardReposition(user_id, 'free', true);
      }

      this.changeFieldCardToValue(card, 'status', 'deactivated');
    } else {
      this.showModalError(response);
    }
  }

  async handleConfirmReactivateCardTrustedAction(user_id) {
    const message =
      'Únicamente se puede desbloquear una tarjeta bloqueada por un usuario de confianza.';
    const button = 'Reactivar';
    const reason = await this.handleClickActions(message, button);

    if (reason) {
      const response = await patchCuencaReactivateCardTrustedAction({
        user_id,
        reason
      });
      if (response.status === 201) {
        this.getCards();
        this.getCustomer();
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
      } else {
        this.showModalError(response);
      }
    }

    return;
  }

  async handleBirthChange(type) {
    const { customer } = this.state;
    const msg = `Es posible que esta acción realice un cambio
    de CURP recalculando con los nuevos datos.`;

    await swal(msg, {
      title: 'Actualización de datos',
      content: (
        <BirthInput
          currentData={{
            birth_place: customer.birth_place_key,
            birth_country: customer.birth_country,
            birth_date: customer.birth_date,
          }}
          humanId={customer.id}
          type={type}
        />
      ),
      buttons: ['Cancelar'],
    });
  }

  async swalChangueCurp(customer) {
    if (!customer.birth_date || !customer.birth_place) {
      swal(
        'Alto 🛑',
        `No puedes completar esta acción porque el usuario no tiene
        fecha de nacimiento y/o lugar de nacimiento.`,
        'error'
      );
      return;
    }

    const message = customer.curp
      ? `El CURP actual es ${customer.curp}. `
      : 'El usuario todavía no tiene CURP';
    const curp = await swal(message, {
      title: 'Actualización de datos',
      content: {
        element: 'input',
        attributes: {
          placeholder: 'Ingrese el nuevo CURP del usuario',
          type: 'text'
        }
      },
      buttons: ['Cancelar', 'Confirmar']
    });
    if (curp) {
      let response = await changeHumaData({
        human_id: customer.id,
        curp: curp
      });
      if (response.status === 201) {
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
      } else if (response['data']['error'] === 'curp invalid'){
          swal(
            '¡Lo siento!', 'El curp no se encuentra en CURPER, favor verificar',
            'error'
          );
      } else {
        this.showModalError(response, 'change-human-data');
      }
    } else if (curp === '') {
      swal('¡Lo siento!', 'El curp ingresado es invalido', 'error');
    }
  }

  async swalChangePldRiskLevel(customer) {
    const htmlBody = `
      <p for="input_risk_level"> Ingrese el nivel de riesgo (Entre 0 y 1)</p> 
      <input
        id="input_risk_level"
        type="number"
        class="swal2-input"
        placeholder= "Nivel de Riesgo"
        min="0"
        max="1"
        step="0.1"
      >
      <p for="input_risk_level"> Ingrese el monto mensual a operar (En pesos)</p>
      <input
        id="input_risk_amount"
        type="number"
        class="swal2-input"
        placeholder= "Monto operacion"
        min="0"
      >
    `;
  const { value: data } = await Swal.fire({
    title: 'Actualización de Riesgo',
    html: htmlBody,
    focusConfirm: false,
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    preConfirm: () => {
      return {
        risk_level: parseFloat(document.getElementById('input_risk_level').value),
        risk_amount: parseInt(document.getElementById('input_risk_amount').value)*100,
      };
    }
    });
    if (data) {
      const {risk_amount, risk_level} = data
      if (risk_level> 0 && risk_level < 1 && risk_amount>0) {
        const response = await changePldLevelRisk({
          user_id: customer.user_id,
          level: risk_level,
          amount: risk_amount,
        });
        if (response.status === 201) {
          swal('Excelente!', 'Acción realizada exitosamente.', 'success');
          this.getCustomer()
        } else {
          this.showModalError(response, 'change-human-data');
        }
      } else {
        swal('¡Lo siento!', 'Los datos ingresados son invalidos', 'error');
      }
    }
  }

  async swalChangeDebitBalance(customer) {
    const message = 'Se realizará un cambio en el saldo deudor del cliente';
    const debit_balance = await swal(message, {
      title: 'Actualización de saldo deudor',
      content: {
        element: 'input',
        attributes: {
          placeholder: 'Ingrese el saldo deudor',
          type: 'number'
        }
      },
      buttons: ['Cancelar', 'Confirmar']
    });
    const debit_balance_amount = parseInt(debit_balance);
    if (debit_balance_amount >= 0 ) {
      const response = await changeDebitBalance({
        user_id: customer.user_id,
        debit_balance: debit_balance_amount
      });
      if (response.status === 201) {
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
        this.getCustomer()
      } else {
        this.showModalError(response, 'change-human-data');
      }
    } else {
      swal('¡Lo siento!', 'El monto ingresado es invalido', 'error');
    }
  }

  async swalchangeCashWithdrawLimit(customer) {
    const message = 'Se realizará un cambio en el límite de retiro de tarjeta';
    const limit = await swal(message, {
      title: 'Actualización límite de tarjeta',
      content: {
        element: 'input',
        attributes: {
          placeholder: 'Ingrese el nuevo límite',
          type: 'number'
        }
      },
      buttons: ['Cancelar', 'Confirmar']
    });
    const limit_amount = parseInt(limit);
    if (limit_amount >= 0 ) {
      const response = await changeCashWithdrawLimit({
        user_id: customer.user_id,
        limit: limit_amount
      });
      if (response.status === 201) {
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
        this.getCustomer()
      } else {
        this.showModalError(response, 'change-human-data');
      }
    } else {
      swal('¡Lo siento!', 'El monto ingresado es invalido', 'error');
    }
  }

  async swalChangeNegativeLevel(customer) {
    const message = `Se volverá a calcular el nivel de la cuenta del cliente`;
    const reason = await this.handleClickActions(message);
    if (reason) {
      const response = await changeNegativeLevel({
        reason,
        user_id: customer.user_id
      });
      if (response.status === 201) {
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
        this.getCustomer()
      } else {
        this.showModalError(response, 'change-human-data');
      }
    }
  }

  async swalTransactions(customer) {
    const message =
      'Los fondos retenidos serán depositado en la cuenta del cliente';
    const reason = await this.handleClickActions(message);
    if (reason) {
      const response = await returnTransaction({
        reason: reason,
        human_id: customer.id
      });
      if (response.status === 201) {
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
      } else {
        this.showModalError(response, 'change-human-data');
      }
    }
  }

  async swalChangueName(customer) {
    if (!customer.birth_date || !customer.birth_place) {
      swal(
        'Alto 🛑',
        `No puedes completar esta acción porque el usuario no tiene
        fecha de nacimiento y/o lugar de nacimiento.`,
        'error'
      );
      return;
    }

    const htmlBody = `
      <input id="name" class="swal2-input" placeholder= "Nombre">
      <input id="ap_paterno" class="swal2-input" placeholder= "Apellido Paterno">
      <input id="ap_materno" class="swal2-input" placeholder= "Apellido Materno">
    `;
    //Note: Html content is available only in Swal2
    const { value: data } = await Swal.fire({
      title: 'Actualización de datos',
      html: htmlBody,
      focusConfirm: false,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      preConfirm: () => {
        return {
          human_id: customer.id,
          nombres: document.getElementById('name').value,
          primer_apellido: document.getElementById('ap_paterno').value,
          segundo_apellido: document.getElementById('ap_materno').value
        };
      }
    });
    if (data) {
      if (data['nombres'] === '' || data['primer_apellido'] === '') {
        Swal.fire('¡Lo siento!', 'Faltan algunos campos requeridos', 'error');
      } else {
        const response = await changeName(data);
        if (response.status === 201) {
          Swal.fire('Excelente!', 'Acción realizada exitosamente.', 'success');
        } else {
          this.showModalError(response, 'change-human-data');
        }
      }
    }
  }

  async swalChangeRfc(customer) {
    const message = customer.rfc
      ? `El RFC actual es ${customer.rfc}.`
      : 'El usuario todavía no tiene RFC.';

    const rfc = await swal(message, {
      title: 'Actualizar RFC',
      content: {
        element: 'input',
        attributes: {
          placeholder: 'Ingrese el nuevo RFC del usuario',
          type: 'text',
        },
      },
      buttons: ['Cancelar', 'Confirmar'],
    });

    if (rfc) {
      const response = await changeRfc({ rfc, humanId: customer.id });
      if (response.status === 201) {
        swal('Excelente 🎉', 'Acción realizada exitosamente.', 'success');
      } else {
        this.showModalError(response, 'rfc');
      }
    } else if (rfc === '') {
      swal('Lo siento 😔', 'El RFC es inválido.', 'error');
    }
  }

  async swalGenerateClabe(customer) {
    if (!customer.birth_date || !customer.birth_place) {
      swal(
        'Alto 🛑',
        `No puedes completar esta acción porque el usuario no tiene
        fecha de nacimiento y/o lugar de nacimiento.`,
        'error'
      );
      return;
    }

    const message = `Se consultará el curp a CURPER para validar que sea correcto.`;
    const reason = await this.handleClickActions(message);
    if (!reason) return;
    const response = await generateNewClabe({
      reason,
      user_id: customer.user_id,
      curp: customer.curp
    });
    if (response.status === 201) {
      swal('Excelente!', 'Acción realizada exitosamente.', 'success');
      this.getCustomer()
    } else if (response['data']['error'] === 'curp invalid'){
        swal(
          '¡Lo siento!', 'El curp no se encuentra en CURPER, favor verificar',
          'error'
        );
    } else {
      this.showModalError(response, 'change-human-data');
    }
  }

  async swalDownloadStatement(customer) {
    let date = new Date();
    var newDate = new Date(date.setMonth(date.getMonth()-1));
    const year = newDate.getFullYear();
    const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
    const message = 'Se descargará los archivos pdf y xml';
    const yearmonth = await swal(message, {
      title: 'Ingrese año y mes',
      content: {
        element: 'input',
        attributes: {
          type: 'month',
          min: '2019-08',
          max: `${year}-${month}`,
          value: `${year}-${month}`,
        }
      },
      buttons: ['Cancelar', 'Confirmar']
    }) || `${year}-${month}`;


    swal('Estamos procesando tu solicitud...', {
      content: (<ClipLoader loading={true} size={150} />),
      buttons: false
    });
    const [actualYear, actualMonth] = yearmonth.split('-');

    const response = await downloadStatement({
      'user_id': customer.user_id,
      'year': actualYear,
      'month': actualMonth,
    });
    swal.close()

    if (response.status === 200) {
      const { data } = response;
      const zip = JsZip();

      const byteStringToBlob = (bString, docType) => {
        const bChars = atob(bString);
        const bytes = new Array(bChars.length);

        for (let i = 0; i < bChars.length; i++) {
          bytes[i] = bChars.charCodeAt(i);
        }

        const bArray = new Uint8Array(bytes);
        return new Blob([bArray], { type: `application/${docType}` });
      }

      const pdfBlob = byteStringToBlob(data.pdf, 'pdf');
      const xmlBlob = byteStringToBlob(data.xml, 'xml');

      zip.file(
        `${customer.user_id}_statement_${actualMonth}${actualYear}.pdf`,
        pdfBlob
      );
      zip.file(
        `${customer.user_id}_statement_${actualMonth}${actualYear}.xml`,
        xmlBlob
      );

      zip.generateAsync({type: 'blob'}).then(zipFile => {
        return FileSaver.saveAs(
          zipFile,
          `${customer.user_id}_statement_${actualMonth}${actualYear}.zip`,
        );
      });

      swal('Excelente!', 'Acción realizada exitosamente.', 'success');
    } else {
      this.showModalError(response);
    }
  }

  handleChangeTypeVerifiedStatus(status_key, status_value) {
    this.setState({
      statusSelect: {
        status_key: status_key,
        status_value: status_value
      }
    });
  }

  async rejectProofOfAddress(){
    const response = await postVerifiedChangeStatus({
      human_id: this.state.customer.id,
      document: 'proof_of_address_status',
      document_status: 'rejected'
    });
    if (response.status === 201) {
      this.state.verified.proof_of_address_status = 'rejected';
    }
  }

  async handleVerifiedChangeStatus(data) {
    const { classes } = this.props;
    const response = await swal({
      content: (
        <div>
          <h4>{data['title']}</h4>
          <select
            defaultValue={data['status_value']}
            className={classes.selectVerifiedChangeStatus}
            onChange={event =>
              this.handleChangeTypeVerifiedStatus(
                data['status_key'],
                event.target.value
              )
            }
          >
            <option value='not_verified'> not_verified </option>
            <option value='submitted'> submitted </option>
            <option value='rejected'> rejected </option>
            <option value='succeeded'> succeeded </option>
            <option value='review_needed'> review_needed </option>
            <option value='upload_again'> upload_again </option>
          </select>
        </div>
      ),
      buttons: ['Cancelar', 'Actualizar']
    });

    if (response && Object.keys(this.state.statusSelect).length !== 0) {
      const statusSelect = this.state.statusSelect;
      let data = {
        human_id: this.state.customer.id,
        document: statusSelect['status_key'],
        document_status: statusSelect['status_value']
      };
      const response = await postVerifiedChangeStatus(data);
      if (response.status === 201) {
        const verified = this.state.verified;
        verified[statusSelect['status_key']] = statusSelect['status_value'];
        this.setState({ verified: verified });
      } else {
        swal('Lo siento!', 'Error inesperado, contacta a Ingeniería', 'error');
      }
    }
    return false;
  }

  async handleFiscalRegimeCode(data) {
    const { classes } = this.props;
    const response = await swal({
      content: (
        <div>
          <h4>Actualiza el Régimen fiscal</h4>
          <select
            defaultValue={data.fiscal_regime_code}
            className={classes.selectVerifiedChangeStatus}
            onChange={event =>
              this.setState({
                fiscalRegimeCode: event.target.value
              })
            }
          >
            <option value="601">{descriptionFiscalRegimeCode("601")}</option>
            <option value='603'>{descriptionFiscalRegimeCode('603')}</option>
            <option value='605'>{descriptionFiscalRegimeCode('605')}</option>
            <option value='606'>{descriptionFiscalRegimeCode('606')}</option>
            <option value='608'>{descriptionFiscalRegimeCode('608')}</option>
            <option value='609'>{descriptionFiscalRegimeCode('609')}</option>
            <option value='610'>{descriptionFiscalRegimeCode('610')}</option>
            <option value='611'>{descriptionFiscalRegimeCode('611')}</option>
            <option value='612'>{descriptionFiscalRegimeCode('612')}</option>
            <option value='614'>{descriptionFiscalRegimeCode('614')}</option>
            <option value='616'>{descriptionFiscalRegimeCode('616')}</option>
            <option value='620'>{descriptionFiscalRegimeCode('620')}</option>
            <option value='621'>{descriptionFiscalRegimeCode('621')}</option>
            <option value='622'>{descriptionFiscalRegimeCode('622')}</option>
            <option value='623'>{descriptionFiscalRegimeCode('623')}</option>
            <option value='624'>{descriptionFiscalRegimeCode('624')}</option>
            <option value='628'>{descriptionFiscalRegimeCode('628')}</option>
            <option value='607'>{descriptionFiscalRegimeCode('607')}</option>
            <option value='629'>{descriptionFiscalRegimeCode('629')}</option>
            <option value='630'>{descriptionFiscalRegimeCode('630')}</option>
            <option value='615'>{descriptionFiscalRegimeCode('615')}</option>
            <option value='625'>{descriptionFiscalRegimeCode('625')}</option>
            <option value='626'>{descriptionFiscalRegimeCode('626')}</option>
          </select>
        </div>
      ),
      buttons: ['Cancelar', 'Actualizar']
    });

    if (response && this.state.fiscalRegimeCode !== null) {
      const regimeSelect = this.state.fiscalRegimeCode;
      let data = {
        human_id: this.state.customer.id,
        regime_code: regimeSelect
      };
      const response = await await changeHumaData(data);
      if (response.status === 201) {
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
        this.getCustomer();
      } else if (response.status === 401) {
        await swal(
          '¡Lo siento! No tienes permisos para realizar esta acción .',
          { title: 'Error' },
          'error'
        );
      } else {
        swal('Lo siento!', 'Error inesperado, contacta a Ingeniería', 'error');
      }
    }
    return false;
  }

  getColorIdentityStatus(status) {
    let color = null;
    if (status === 'succeeded') color = 'success';
    else if (status === 'review_needed') color = 'warning';
    else if (status === 'rejected') color = 'danger';
    else if (status === 'not_verified') color = 'gray';
    else color = 'info';
    return color;
  }

  getColorMatiIdentityStatus(status) {
    let color = null;
    if (status === 'verified') color = 'success';
    else if (status === 'reviewNeeded') color = 'warning';
    else color = 'danger';
    return color;
  }

  async requestVerificationMati() {
    let { verified } = this.state;
    const human_id = this.state.customer.id;
    const message = 'Se solicitara a mati la verificacion del cliente.';
    const button = 'Solicitar';
    const reason = await this.handleClickActions(message, button);
    if (reason) {
      let data = {
        human_id: human_id,
        reason: reason
      };
      const response = await postMatiRequestVerification(data);
      if (response.status === 201) {
        verified['gov_id_status'] = 'submitted';
        verified['selfie_video_status'] = 'submitted';
        this.setState({ verified: verified });
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
      } else {
        this.showModalError(response);
      }
    }
  }

  handleCreateTicket(user_id) {
    const { history } = this.props;
    history.push('/admin/create_ticket/' + user_id);
  }

  async swalConfirmDeactivateAccount(customer) {
    let message = 'Al desactivar una cuenta el cliente perderá su acceso.';
    const reason = await this.handleClickActions(message);
    if (reason) {
      let response = await deactivateAccount({
        user_id: customer.user_id,
        reason: reason
      });
      if (response.status === 201) {
        this.rejectProofOfAddress();
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
      } else {
        this.showModalError(response, 'deactivate-account');
      }
    }
  }

  async swalConfirmReactivateAccount(customer) {
    let message = `Al reactivar una cuenta el cliente volverá a tener su acceso y
    sus  tarjetas quedarán  activadas`;
    const reason = await this.handleClickActions(message);
    if (reason) {
      let response = await reactivateAccount({
        user_id: customer.user_id,
        reason: reason
      });
      if (response.status === 201) {
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
      } else {
        this.showModalError(response, 'reactivate-account');
      }
    }
  }

  async swalChanguePhoneNumber(customer) {
    let message = 'Ingrese el nuevo número asociado a esta cuenta';
    const number = await swal(message, {
      title: 'Nuevo Número de teléfono',
      content: {
        element: 'input',
        attributes: {
          placeholder: '+525511223344',
          type: 'text'
        }
      },
      buttons: ['Cancelar', 'Confirmar']
    });
    if (number) {
      let response = await changePhoneNumber({
        user_id: customer.user_id,
        phone_number: number
      });
      if (response.status === 201) {
        swal('Excelente!', 'Acción realizada exitosamente.', 'success');
      } else {
        this.showModalError(response, 'change-phone-number');
      }
    } else if (number === '') {
      swal('¡Lo siento!', 'Número de telefono invalido', 'error');
    }
  }

  async swalAssignVirtualCard(customer) {
    const msg = `La tarjeta actual quedará deshabilitada y se asignará una nueva tarjeta al cliente.`;

    await swal(msg, {
      title: 'Asignar Tarjeta Virtual',
      content: (
        <AssignCardInput
          userId={customer.user_id}
          createdAt={customer.created_at}
        />
      ),
      buttons: ['Cancelar'],
    });
  }

  async handleCardReposition(user_id, type, fromZero = false) {
    let confirmation = null;
    if (!fromZero) {
      const costMessage =
        type === 'charge' ? 'Se realizará un cobro de $250.00 al cliente.' : '';
      const message = `La reposición dará la oportunidad al cliente de pedir su tarjeta.
      ${costMessage}`;
      const button = 'Aceptar';
      confirmation = await this.handleClickActions(message, button);
    }

    if (confirmation || fromZero) {
      let chargeResponse = null;
      if (type === 'purchase') {
        chargeResponse = await chargeCardReposition({
          user_id,
          type: 'card_request',
          reason: confirmation
        });

        switch (chargeResponse.status) {
          case 401:
            await swal(
              '¡Lo siento! No tienes permisos para realizar esta acción .',
              { title: 'Error' },
              'error'
            );
            return;

          case 201:
            break;

          default: {
            let errorMessage = chargeResponse.data
              ? chargeResponse.data.message
              : '';
            await swal(
              `¡Lo siento! Hubo un problema con el cobro de la reposición. Intenta de nuevo.

                Error: ${errorMessage}
              `,
              { title: 'Error' },
              'error'
            );
            return;
          }
        }
      }

      const chargeData = chargeResponse ? chargeResponse.data : null;
      const transaction_id = chargeData ? chargeData.transaction_id : null;
      const response = await postGaitaCardReposition({
        user_id,
        type,
        transaction_id,
        reason: confirmation
      });

      const chargeMessage = chargeData
        ? `Cargo: ${chargeData.message} – ${transaction_id}.`
        : '';
      const gaitaMessage = response.data ? response.data.status : '';

      if (response.status >= 200 && response.status < 300) {
        swal(
          `Éxito en la reposición.
          ${gaitaMessage}.
          ${chargeMessage}`,
          { title: 'Éxito' },
          'success'
        );
      } else {
        const gaitaError = response.data ? response.data.error : '';
        swal(
          `¡Lo siento! Hubo un problema con la reposición en gaita:
            Gaita: ${gaitaError}.
            ${chargeMessage}

            Contacta a ingeniería.
          `,
          { title: 'Error' },
          'error'
        );
      }
    }

    return;
  }

  async getCustomersForCurp(customer) {
    const { dataSimilarCustomer } = this.state;
    if(dataSimilarCustomer !== undefined) return;
    const response = await getCustomers({
      filtered: [{id: "curp", value: customer.curp}]
    });
    let results = [];
    if (response.data && response.data.result){
      for (const index in response.data.result){
        const human = response.data.result[index];
        if (human.id !== customer.id){
          results.push(human)
        }
      }
    }
    this.setState({
      dataSimilarCustomer: results,
      loadingSimilarCustomer: false
    });
  }

  getUserCardReplacement = async userId => {
    if (this.state.cardReplacement !== null) return;
    const response = await getCardReplacement(userId);
    const { card_replacement } = response.data;
    if (card_replacement) {
      let status = [];
      if (!card_replacement.viewed_at) {
        status.push(CardReplacementStatus.NOT_VIEWED);
      } else if (!card_replacement.physical_requested_at) {
        status.push(CardReplacementStatus.VIEWED);
        if (card_replacement.remind_user_at) {
          status.push(CardReplacementStatus.REMINDER_ACTIVE);
        }
      } else if (!card_replacement.physical_delivered_at) {
        status.push(CardReplacementStatus.PHYSICAL_REQUESTED);
      } else {
        status.push(CardReplacementStatus.PHYSICAL_DELIVERED);
      }

      if (card_replacement.virtual_requested_at) {
        status.push(CardReplacementStatus.VIRTUAL_REQUESTED);
      }

      this.setState({
        cardReplacement: {
          status,
          phaseStart: card_replacement.phase_start,
          phaseEnd: card_replacement.phase_end,
          license: card_replacement.license_type,
          type: card_replacement.replacement_type,
        },
      });
    }
  };

  inactivateUserCardReplacement = async customer => {
    const { cardReplacement } = this.state;
    const { user_id } = customer;

    let message = `¿Segure que deseas realizar esta acción?`;
    if (cardReplacement.status.includes(CardReplacementStatus.PHYSICAL_REQUESTED)) {
      message = `${message} – El cliente tiene una tarjeta física MC en proceso de envío 😰`;
    }

    const reason = await this.handleClickActions(message);
    if (reason) {
      const response = await inactivateCardReplacement({user_id, reason});
      if (response.status === 201) {
        this.setState({ cardReplacement: null });
        swal('Excelente 😋', 'Acción realizada exitosamente.', 'success');
      } else {
        this.showModalError(response);
      }
    }
  };

  handleSendReview = async customer => {
    const { user_id } = customer;
    const reason = await this.handleClickActions(`¿Segure que deseas realizar esta acción?`);
    if (reason) {
      const response = await sendStoreReview({ userId: user_id, reason });
      if (response.status === 201) {
        swal('Excelente 😋', 'Acción realizada exitosamente.', 'success');
      } else {
        this.showModalError(response);
      }
    }
  };

  handleFraudFunds = async userId => {
    const swalObject = await Swal.fire({
      title: '¿Estás seguro?',
      text: `¿Seguro que deseas realizar esta acción? 🤔`,
      input: 'text',
      inputAttributes: {
        placeholder: 'Ingrese el motivo de acción',
      },
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: 'Confirmar',
    });

    if (swalObject.isDismissed) return;
    if (!swalObject.value) {
      Swal.fire({
        title: 'Lo siento 😥',
        text: 'Motivo requerido',
        icon: 'error',
        confirmButtonText: 'Upsi',
      });
      return;
    }

    const reason = swalObject.value;


    const swalClabeObject = await Swal.fire({
      title: 'Ingresa CLABE destino',
      text: `Ingresa la cuenta destino que recibirá los fondos 
      o deja vacio para mandar a la cuenta de STP`,
      input: 'text',
      inputAttributes: {
        placeholder: 'Ingrese la clabe destino',
      },
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: 'Continuar',
    });

    if (swalClabeObject.isDismissed) return;
    const clabe = swalClabeObject.value;

    const firstResponse = await getFraudFundsData(userId);
    if (firstResponse.status !== 200) {
      this.showModalError(firstResponse);
      return;
    }

    const {
      current_balance,
      suspect_spei_transactions_balance,
      suspect_ledger_transactions_balance,
      secondary_ledger_accounts_balance,
      total_balance,
    } = firstResponse.data;

    const formatN = n => (n / 100).toFixed(2);

    const ledgerWithAmount = Object.keys(secondary_ledger_accounts_balance).map(
      k => `${k} – <strong>$${formatN(secondary_ledger_accounts_balance[k])}</strong>`,
    );

    const secondSwalObject = await Swal.fire({
      title: 'Ingresa monto a mandar en centavos',
      html: `
        <p>Esta acción mandará los fondos de la cuenta a la CLABE ${clabe ? clabe: 'de fraudes de STP'}.</p>
        <br />
        <p>
          * Saldo Actual (Ledger Principal): <strong>$${formatN(current_balance)}</strong>.
        </p>
        <p>
          * Fondos Retenidos Ledgers Secundarios: (no sospechosos):<br />
          ${ledgerWithAmount.map(l => `\t${l}<br/>`)}
        </p>
        <p>* SPEI sospechosas: <strong>$${formatN(suspect_spei_transactions_balance)}</strong>.</p>
        <p>* Internas sospechosas: <strong>$${formatN(suspect_ledger_transactions_balance)}</strong>.</p>
        <p>* GRAN TOTAL: <strong>$${formatN(total_balance)}</strong>.</p>
        <br />
        <p>
          - Todos los fondos retenidos de la cuenta que provengan de transacciones internas sospechosas,
          NO serán mandados. El resto de fondos retenidos, sí.
        </p>
        <p>
          - Si un monto es especificado, se mandará esa cantidad específica, de lo contrario,
          se mandarán todos los fondos.
        </p>
        <p>
          - Los fondos se mandarán primero a la cuenta de fraudes de Cuenca y posteriormente a la cuenta ${clabe ? clabe: 'de STP'}.
        </p>
      `,
      input: 'number',
      inputAttributes: {
        placeholder: '000000',
      },
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: 'Confirmar',
    });

    if (secondSwalObject.isDismissed) return;

    const amount = parseInt(secondSwalObject.value) || 0;

    const thirdSwalObject = await Swal.fire({
      title: 'Para finalizar 😴',
      text: 'Agrega un concepto para la transferencia',
      input: 'text',
      inputAttributes: {
        placeholder: 'Concepto',
      },
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: 'Confirmar',
    });
    if (thirdSwalObject.isDismissed) return;
    if (!thirdSwalObject.value) {
      Swal.fire({
        title: 'Lo siento 😥',
        text: 'Concepto requerido',
        icon: 'error',
        confirmButtonText: 'Upsi',
      });
      return;
    }

    const concepto = thirdSwalObject.value;
    const response = await sendFraudFundsToStp({
      userId,
      reason,
      amount,
      concepto,
      clabe,
    });

    if (response.status === 201) {
      const { transaction_id, amount, clave_rastreo } = response.data;
      await swal({
        content: (
          <div>
            <h3>Éxito 🥳</h3>
            <ClipboardCopy
              linkInfo={`https://omni.cuenca.io/admin/transfer/${transaction_id}`}
            >
              <h4>Transferencia: </h4>
              <p>
                <strong>Monto:</strong> {` $${formatN(amount)}`}
              </p>
              <p>
                <strong>Clave de rastreo:</strong> {` ${clave_rastreo}`}
              </p>
              <p>
                <strong>Concepto: </strong> {` ${concepto}`}
              </p>
            </ClipboardCopy>
          </div>
        ),
        buttons: 'Listo',
      });
    } else if (response.status === 202) {
      await swal({
        content: (
          <div>
            <h4><b>Acción requiere doble autorización 🤔 </b></h4>
            <p>Otro usuario de cumplimiento debe replicar la acción con los siguientes datos: </p>
            <ClipboardCopy>
              <p>
                <strong>Motivo:</strong> {` ${reason}`}
              </p>
              <p>
                <strong>Clabe destino:</strong> {` ${clabe}`}
              </p>
              <p>
                <strong>Monto (en centavos):</strong> {` ${amount?amount:'Totalidad de fondos'}`}
              </p>
              <p>
                <strong>Concepto: </strong> {` ${concepto}`}
              </p>
            </ClipboardCopy>
          </div>
        ),
        buttons: 'Lo tengo',
      });
    } else {
      const message = `
        No se pudo completar esta acción.
        ${(response.data && response.data.status) || ''}
      `;
      await swal('Lo siento 😥', message, 'error');
    }
  };

  createUserCardReplacement = async userId => {
    let swalObject = await Swal.fire({
      title: '¿Estás segure? 🤔',
      text: `
        Esta acción mandará una notificación al cliente y le permitirá pedir una tarjeta MC
      `,
      input: 'text',
      inputAttributes: {
        placeholder: 'Ingresa el motivo de acción',
      },
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: 'Continuar',
    });

    if (swalObject.isDismissed) return;
    if (!swalObject.value) {
      Swal.fire({
        title: 'Lo siento 😥',
        text: 'Motivo requerido',
        icon: 'error',
        confirmButtonText: 'Upsi',
      });
      return;
    }

    const reason = swalObject.value;

    const { cards: { physical, virtual } } = this.state;

    if (!physical && !virtual) {
      Swal.fire(
        'Nope 😳',
        'El cliente no tiene tarjetas, puede pedir Mastercard sin problemas',
        'error',
      );
      return;
    }

    const hasMcPhysical = physical.some((card) => card.issuer === 'cuenca');
    const hasMcVirtual = virtual.some((card) => card.issuer === 'cuenca');
    const values = [
      { text: 'físico', value: 'physical' },
      { text: 'virtual', value: 'virtual' },
      { text: 'de ambas', value: 'both' },
      {
        text: 'Ya tiene las 2 tarjetas Mastercard, ¿porqué quieres hacer el reemplazo? 🤔',
        value: null,
      },
    ];

    let recommendation = values[0];
    if (hasMcPhysical && hasMcVirtual) {
      // No need to make a replacement
      recommendation = values[3];
    } else if (hasMcPhysical) {
      // Needs to replace virtual
      recommendation = values[1];
    } else if (!hasMcVirtual) {
      // Needs to replace both
      recommendation = values[2];
    }

    swalObject = await Swal.fire({
      title: 'Confirma el tipo de reemplazo',
      text: `
        Basados en sus tarjetas, el tipo de reemplazo que se haré es:
        ${recommendation.text}
      `,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: 'Confirmar',
    });

    if (swalObject.isDismissed) return;

    const response = await createCardReplacement({ userId, reason, type: recommendation.value });
    if (response.status === 201) {
      Swal.fire('Éxito 🥳', 'Se ha creado el reemplazo', 'success');
      this.getUserCardReplacement(userId);
    } else {
      this.showModalError(response);
    }
  };

  fetchUserArcusDeposits = async (clabe) => {
    const swalObject = await Swal.fire({
      title: '¿Estás segurx? 🤔',
      text: 'Esta acción buscará los depósitos de Arcus que no han sido reflejados en la cuenta del cliente',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: 'Confirmar',
    });

    if (swalObject.isDismissed) return;

    const response = await getArcusUserDeposits(clabe);
    if (response.status === 200) {
      Swal.fire('Éxito 🥳', 'Se han buscado los depósitos del usuario en Arcus correctamente', 'success');
    } else {
      const { data: { msg } } = response;
      Swal.fire(
        'Woops 😓',
        `No se han podido actualizar los depósitos, xfa intenta más tarde
        
        Error: ${msg}`,
        'error',
      );
    }
  };

  render() {
    const { classes, location } = this.props;
    const {
      copied,
      customer,
      verified,
      cards,
      cardRequestsNumber,
      cardPayment,
      response,
      dataSimilarCustomer,
      loadingSimilarCustomer,
      cardReplacement,
      storeReview,
    } = this.state;

    let mati = verified ? verified.mati_identity : null;
    const physical_cards = cards ? cards.physical : [];
    const virtual_cards = cards ? cards.virtual : [];

    if (response && response.status !== 200 && response.status !== 201) {
      processError(response.status, location.pathname);
    }

    let autoGrid =
      customer.proof_of_address && customer.proof_of_life
        ? 3
        : customer.proof_of_address
        ? 4
        : 12;
    let autoGridAction = 4;
    let columnsCards = [
      {
        Header: 'Tarjeta',
        accessor: 'last_4_digits',
        Cell: row => <strong>************{row.original.last_4_digits}</strong>
      },
      {
        Header: 'Estado',
        accessor: 'status'
      },
      {
        Header: 'Creada',
        accessor: 'created_at',
        Cell: row => <Moment utc>{row.original.created_at}</Moment>
      },
      {
        Header: 'Actualizada',
        accessor: 'updated_at',
        Cell: row => <Moment utc>{row.original.updated_at}</Moment>
      },
      {
        Header: 'Entidad',
        accessor: 'issuer'
      },
      {
        Header: 'Tipo',
        accessor: 'funding_type'
      },
      {
        Header: 'Intentos de pin',
        accessor: 'pin_attempts_failed'
      },
      {
        Header: 'Desactivar Tarjeta',
        Cell: row => {
          return (
            <Tooltip
              id='tooltip-top'
              title='Desactivar tarjeta para poder asociar otra a la cuenta'
              placement='top'
              classes={{ tooltip: classes.tooltip }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={row.original.status === 'deactivated'}
                    onChange={() =>
                      this.swalConfirmActionCard(row.original)
                    }
                    value='block'
                  />
                }
              />
            </Tooltip>
          );
        }
      }
    ];

    const maps =
      'https://www.google.com.mx/maps/place/' +
      customer.location +
      '/@' +
      customer.location +
      ',17z/';

    const data_status = {
      curp_status: 'Validación Curp',
      black_lists_status: 'Revisión Quién es Quién',
      gov_id_status: 'Documento de Identidad',
      selfie_video_status: 'Prueba de Vida',
      proof_of_address_status: 'Comprobante de Domicilio'
    };
    const modalDocument = this.state.modalDocument;
    let modalTitle = '';
    if (modalDocument) {
      modalTitle = 'Documento de identidad';
      if (modalDocument.type === 'proofs_of_address') {
        modalTitle = 'Comprobante de Domicilio';
      } else if (modalDocument.type === 'proofs_of_life') {
        modalTitle = 'Prueba de Vida';
      }
    }

    const deactivatedByUser = customer.status === 'deactivated';
    const deactivatedByDocs = customer.status === 'missing_documentation';
    const deactivatedByTrustedContact =
      customer.status === 'blocked_by_trusted_contact';
    const deactivatedByPassword = customer.status === 'loggin_attempts';
    const deactivatedByFraud = [
      'fraud',
      'pld_blocked',
      'inactive'
    ].includes(customer.status);

    const trustedActionsTypes = customer.trusted_actions
      ? customer.trusted_actions.map(action => {
          switch (action.type) {
            case 'password_reset':
              return 'Reset Password';

            case 'block_physical_card':
              return 'Bloqueo Tarjeta Física';

            case 'stolen_phone':
              return 'Celular Robado';

            case 'stolen_phone_with_atm':
              return 'Celular Robado con cajero';

            default:
              return '';
          }
        })
      : null;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <ModalOfDocument
              id='get_document'
              open={this.state.modalOpen}
              title={modalTitle}
              document={this.state.modalDocument}
              onClose={this.handleClose}
              maxWidth='sm'
            />
            <ModalUploadDocument
              open={this.state.modalUploadDocumentOpen}
              customer={customer}
              verified={verified}
              onClose={this.handleCloseUploadDocument}
              maxWidth='sm'
            />
            <Card>
              <CardBody>
                <div className={classes.badgeLevelLeft}>
                  {customer.user_level !== null ? (
                    <p>
                      <strong>Nivel de Cuenta:</strong> {customer.user_level}
                    </p>
                  ) : null}
                  {customer.fraud_level !== null ? (
                    <span>
                      <strong>Fraude PLD:</strong> {customer.fraud_level}
                    </span>
                  ) : null}
                  {customer.new_pld_level !== null ? (
                    <span>
                      &nbsp;|&nbsp;
                      <strong><a href={`https://metabase.cuenca.io/question/1607?user_id=${customer.user_id}`}>Fraude Nuevo PLD:</a></strong> {customer.new_pld_level}%
                    </span>
                  ) : null}
                  {customer.debit_balance? (
                    <p>
                      <strong>Deuda:</strong> {customer.debit_balance/100}
                    </p>
                  ) : null}
                   {customer.limit_card? (
                    <p>
                      <strong>Límite Retiro:</strong> {customer.limit_card/100}
                    </p>
                  ) : null}
                  <hr />
                  <p
                    onClick={() => {
                      this.setState({ modalUploadDocumentOpen: true });
                    }}
                  >
                    <Badge color='info'>Actualizar Documentos</Badge>
                  </p>
                   <p
                    onClick={() => {
                      this.swalChangePldRiskLevel(customer);
                    }}
                  >
                    <Badge color="info">Modificar nivel de fraude</Badge>
                  </p>

                  <p
                    onClick={() => {
                      this.swalChangueCurp(customer);
                    }}
                  >
                    <Badge color='info'>Modificar CURP</Badge>
                  </p>
                  <p
                    onClick={() => {
                      this.swalChanguePhoneNumber(customer);
                    }}
                  >
                    <Badge color='info'>Modificar Número</Badge>
                  </p>
                  <p
                    onClick={() => {
                      this.swalChangueName(customer);
                    }}
                  >
                    <Badge color='info'>Modificar Nombre</Badge>
                  </p>
                  <p
                    onClick={() => {
                      this.swalChangeRfc(customer);
                    }}
                  >
                    <Badge color='info'>Modificar RFC</Badge>
                  </p>
                  <p
                    onClick={() => {
                      this.swalchangeCashWithdrawLimit(customer);
                    }}
                  >
                    <Badge color="info">Modificar límite tarjeta</Badge>
                  </p>
                   <p
                    onClick={() => {
                      this.swalChangeDebitBalance(customer);
                    }}
                  >
                    <Badge color="info">Modificar deuda</Badge>
                  </p>
                  {customer.user_level === -1 ? (
                     <p onClick={() => {
                       this.swalChangeNegativeLevel(customer);
                      }}
                    >
                     <Badge color="info">Recalcular nivel</Badge>
                    </p>
                  ) : null}

                  <p
                    onClick={() => {
                      this.swalGenerateClabe(customer);
                    }}
                  >
                    <Badge color='info'>Generar nueva CLABE</Badge>
                  </p>
                  {customer.rfc && customer.fiscal_regime_code ? (
                    <p
                      onClick={() => {
                        this.swalDownloadStatement(customer);
                      }}
                    >
                      <Badge color='info'>
                        Descargar estado de cuenta
                      </Badge>
                    </p>
                  ) : null}
                  <p
                    onClick={() => {
                      this.handleFiscalRegimeCode(customer);
                    }}
                  >
                    <Badge color='info'>
                      Modificar el régimen fiscal
                    </Badge>
                  </p>
                </div>
                <div className={classes.badgeStatusVerified}>
                  <p
                    onClick={() => {
                      this.handleBirthChange('date');
                    }}
                  >
                    <Badge color='primary'>
                      Modificar Fecha de nacimiento
                    </Badge>
                  </p>
                  <p
                    onClick={() => {
                      this.handleBirthChange('place');
                    }}
                  >
                    <Badge color='primary'>
                      Modificar Lugar de nacimiento
                    </Badge>
                  </p>
                  <p
                    onClick={() => this.handleSendReview(customer)}
                  >
                    <Badge color='primary'>
                      Solicitar calificación de App Store
                    </Badge>
                  </p>
                  {['fraud','pld_blocked'].includes(customer.status) ?
                    <p onClick={() => this.handleFraudFunds(customer.user_id)}>
                      <Badge color='danger'>
                        Transferir fondos fraudalentos
                      </Badge>
                    </p>
                  : null}
                  {verified ? (
                    <div>
                      {Object.entries(data_status).map(([status, title]) => {
                        return (
                          <p
                            key={status}
                            onClick={() =>
                              this.handleVerifiedChangeStatus({
                                title: title,
                                status_key: status,
                                status_value: verified[status]
                              })
                            }
                          >
                            <Badge
                              color={this.getColorIdentityStatus(
                                verified[status]
                              )}
                            >
                              {title} ({verified[status]})
                            </Badge>
                          </p>
                        );
                      })}
                      <hr />
                      {verified && customer.proof_of_life ? (
                        <p onClick={() => this.requestVerificationMati()}>
                          <Badge color='info'>
                            Soliciar Verificacion a Mati
                          </Badge>
                        </p>
                      ) : null}
                      {mati ? (
                        <div>
                          <p>
                            <a
                              href={
                                'https://dashboard.getmati.com/identities/' +
                                mati.mati_identity_id
                              }
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <Badge
                                color={this.getColorMatiIdentityStatus(
                                  mati.annotated_status || mati.status
                                )}
                              >
                                <span className={classes.iconLinkToMati}>
                                  Mati ({mati.annotated_status || mati.status})
                                </span>
                                <OpenInNew
                                  className={classes.iconLinkToMatiIcon}
                                />
                              </Badge>
                            </a>
                          </p>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>

                <div className={classes.cardCenter}>
                  <GridContainer className={classes.topBadges} direction='column'>
                    {deactivatedByUser ? (
                      <GridItem className={classes.badgeCustomerStatus}>
                        <Badge color='danger'>
                          CUENTA CANCELADA POR USUARIO
                        </Badge>
                      </GridItem>
                    ) : null}
                    {deactivatedByDocs ? (
                      <GridItem className={classes.badgeCustomerStatus}>
                        <Badge color='danger'>
                          CUENTA BLOQUEADA POR FALTA DE DOCUMENTOS
                        </Badge>
                      </GridItem>
                    ) : null}
                    {deactivatedByTrustedContact ? (
                      <GridItem className={classes.badgeCustomerStatus}>
                        <Badge color='danger'>
                          CUENTA BLOQUEADA POR USUARIO DE CONFIANZA
                        </Badge>
                      </GridItem>
                    ) : null}
                    {deactivatedByPassword ? (
                      <GridItem className={classes.badgeCustomerStatus}>
                        <Badge color='danger'>
                          BLOQUEADA POR CONTRASEÑA INCORRECTA
                        </Badge>
                      </GridItem>
                    ) : null}
                    {deactivatedByFraud ? (
                      <GridItem className={classes.badgeCustomerStatus}>
                      <Badge color='danger'>
                       CUENTA BLOQUEADA POR {(() => {
                          switch (customer.status) {
                            case 'fraud':
                              return 'FRAUDE';
                            case 'pld_blocked':
                              return 'PLD';
                            case 'inactive':
                              return 'INACTIVIDAD';
                          }
                        })()}
                      </Badge>
                      </GridItem>
                    ) : null}
                    {trustedActionsTypes
                      ? trustedActionsTypes.map((action, index) => (
                          <GridItem
                            className={classes.badgeCustomerStatus}
                            key={index}
                          >
                            <Badge key={`${action}${index}`} color='rose'>
                              {`ACCIÓN POR USUARIO DE CONFIANZA – ${action}`}
                            </Badge>
                          </GridItem>
                        ))
                      : null}
                  </GridContainer>
                  <ClipboardCopy
                    linkInfo={`https://omni.cuenca.io/admin/customer/${customer.id}`}
                    extraData={[{
                      title: 'Email del Cliente: ',
                      data: customer.email_address,
                    }]}
                  >
                    <h3 className={classes.cardTitleBlack}>
                      <strong>CLABE:</strong> {customer.clabe}
                    </h3>
                    <p>
                      <strong>User ID:</strong> {customer.user_id}
                    </p>
                    <p>
                      <strong>Human ID:</strong> {customer.id}
                    </p>
                    <p>
                      <strong>Ledger Account ID:</strong>{' '}
                      {customer.ledger_account_id}
                    </p>
                    <p>
                      <strong>Identity ID:</strong> {customer.identity_id}
                    </p>
                    <p>
                      <strong>CURP:</strong> {customer.curp}{' '}
                      <a
                        href="https://www.gob.mx/curp/"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            customer.curp
                          );
                        }}
                        target='_blank'
                        rel="noopener noreferrer"
                      >
                        (Ir a Renapo <OpenInNew className={classes.renapoStyle} />)
                      </a>
                    </p>
                    <p>
                      <strong>RFC:</strong> {customer.rfc || 'Sin RFC'}
                    </p>
                    <p>
                      <strong>Réginem fiscal:</strong> {descriptionFiscalRegimeCode(customer.fiscal_regime_code) || 'Sin régimen'}
                    </p>
                    {customer.botmaker_id ? (
                      <p>
                          <a
                            href={`https://go.botmaker.com/#/chats/${customer.botmaker_id}`}
                            target='_blank'
                            rel="noopener noreferrer"
                          >
                            Ir al chat Botmaker <OpenInNew className={classes.renapoStyle} />
                          </a>
                        </p>)
                      : null
                    }
                    <h2 className={classes.cardTitleBlack}>{customer.name}</h2>
                    <p>
                      <strong>{`Pronombres: `}</strong> <em>{customer.pronouns || 'Sin pronombres'}</em>
                    </p>
                  </ClipboardCopy>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <div>
                        <small>Cliente Desde:</small>
                        <br />
                        <small>
                          <Moment utc>{customer.created_at}</Moment>
                        </small>
                      </div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <div>
                        <small>Cuenta Creada:</small>
                        <br />
                        <small>
                          <Moment utc>{customer.ledger_created_at}</Moment>
                        </small>
                      </div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <div>
                        <small>Cuenta Actualizada:</small>
                        <br />
                        <small>
                          <Moment utc>{customer.updated_at}</Moment>
                        </small>
                      </div>
                    </GridItem>
                  </GridContainer>
                  {customer.curp
                    ?
                      <div onClick={() => this.getCustomersForCurp(customer)}>
                        <hr />
                        <Accordion
                          collapses={[{
                            title: "Perfiles Relacionados por CURP",
                            content: (
                              <ReactTable
                                manual
                                data={dataSimilarCustomer}
                                columns={[
                                  {
                                    Header: "Nombre",
                                    accessor: "name",
                                    minWidth: 150,
                                    Cell: row => {
                                      const link = "/admin/customer/" + row.original.id;
                                      return (
                                        <strong>
                                          <a href={link} target="_blank" rel="noopener noreferrer">
                                            {row.original.name}
                                          </a>
                                        </strong>
                                      );
                                    }
                                  },
                                  {
                                    Header: "Clabe",
                                    accessor: "clabe",
                                    minWidth: 150,
                                  },
                                  {
                                    Header: "Curp",
                                    accessor: "curp",
                                    minWidth: 150,
                                  },
                                  {
                                    Header: "Creado",
                                    accessor: "created_at",
                                    minWidth: 150,
                                    Cell: row => (
                                      <div className="actions-right">
                                        <Moment utc>{row.original.created_at}</Moment>
                                      </div>
                                    )
                                  },
                                ]}
                                showPagination={false}
                                showPageSizeOptions={false}
                                minRows={5}
                                loading={loadingSimilarCustomer}
                                filterable={false}
                                sortable={false}
                                className={`-highligh ${classes.AccordionTable}`}
                                noDataText="No se han encontrado resultados"
                              />
                            )
                          }]}
                        />
                      </div>
                    : null
                  }
                  <div onClick={() => this.getUserCardReplacement(customer.user_id)}>
                    <hr />
                    <Accordion
                      collapses={[{
                        title: "Status de Reemplazo Tarjeta Física a MC",
                        content: cardReplacement ? (
                          <div className={classes.crWrapper}>

                            <div className={classes.crInfo}>
                              <strong>STATUS:</strong>
                              <span>
                                {cardReplacement.status.map(s => {
                                  switch (s) {
                                    case CardReplacementStatus.NOT_VIEWED:
                                      return <span>La notificación no se ha leído.</span>;
                                    case CardReplacementStatus.VIEWED:
                                      return <span>La notificación fue vista, pero no se ha solicitado tarjeta física.</span>;
                                    case CardReplacementStatus.PHYSICAL_REQUESTED:
                                      return <span>Tarjeta física ha sido solicitada.</span>;
                                    case CardReplacementStatus.PHYSICAL_DELIVERED:
                                      return <span>Tarjeta física ya fue entregada.</span>;
                                    case CardReplacementStatus.VIRTUAL_REQUESTED:
                                      return <span>Tarjeta virtual solicitada.</span>;
                                    case CardReplacementStatus.REMINDER_ACTIVE:
                                      return <span>Recordatorio agendado.</span>;
                                  }
                                })}
                              </span>
                            </div>

                            <div className={classes.crInfo}>
                              <Tooltip
                                title={`
                                  Apartir de esta fecha, empiezan los 7 días para
                                  pedir la tarjeta edición Travesía.
                                `}
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <p>
                                  <strong>FECHA DE INICIO:</strong>
                                  <Info fontSize='small' style={{ color: 'black' }} />
                                </p>
                              </Tooltip>
                              <span>{cardReplacement.phaseStart}</span>
                            </div>

                            <div className={classes.crInfo}>
                              <Tooltip
                                title={`
                                  Esta fecha sólo afecta a los reemplazos con licencia "full".
                                  Es la fecha en la que se inactivarán las VISAS por completo.
                                `}
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <p>
                                  <strong>FECHA DE FIN:</strong>
                                  <Info fontSize='small' style={{ color: 'black' }} />
                                </p>
                              </Tooltip>
                              <span>{cardReplacement.phaseEnd}</span>
                            </div>

                            <div className={classes.crInfo}>
                              <Tooltip
                                title={`
                                  El tipo de tarjeta que se reemplaza: física, virtual o ambas.
                                `}
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <p>
                                  <span>{`TIPO: ${cardReplacement.type}`}</span>
                                  <Info fontSize='small' style={{ color: 'black' }} />
                                </p>
                              </Tooltip>
                              <span>{`LICENCIA: ${cardReplacement.license}`}</span>
                            </div>

                            <div className={classes.crInfo}>
                              <Button
                                round
                                color='danger'
                                onClick={() => this.inactivateUserCardReplacement(customer)}
                              >
                                Inactivar
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div className={classes.crWrapper}>
                            <strong>No hay reemplazo (o está cargando)</strong>

                            <div className={classes.crInfo}>
                              <Button
                                round
                                color='info'
                                onClick={() => this.createUserCardReplacement(customer.user_id)}
                              >
                                Crear Reemplazo
                              </Button>
                            </div>
                          </div>
                        )
                      }]}
                    />
                  </div>
                  <hr />
                  <small>
                    <strong>Teléfono:</strong>
                    {customer.phone_number ? customer.phone_number : ' Número no asociado por algún cambio en la cuenta'}
                    &nbsp;/&nbsp;
                    <strong>Sexo:</strong> {customer.gender}&nbsp;/&nbsp;
                    <strong>Nacimiento:</strong> {customer.birth_date}
                    &nbsp;/&nbsp;
                    <strong>Lugar:</strong> {customer.birth_place}
                    &nbsp;/&nbsp;
                    <strong>País:</strong> {customer.birth_country}
                    <br />
                    <p>
                      <strong>Dirección:</strong> {customer.address}
                    </p>
                    <p>
                      <strong>Email:</strong> {customer.email_address}
                      <Tooltip
                        title={copied ? '¡Copiado!' : 'Copiar'}
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          justIcon
                          simple
                          onClick={() => {
                            this.setState({ copied: true });
                            navigator.clipboard.writeText(
                              customer.email_address
                            );
                          }}
                        >
                          <FileCopy style={{ color: 'black' }} />
                        </Button>
                      </Tooltip>
                    </p>
                    {storeReview && storeReview.sent && (
                      <p>
                        {`Solicitud de calificación en App Store enviada${storeReview.readOrDone && ' y realizada por cliente'}.`}
                      </p>
                    )}
                    <h3>
                      <a href={maps} target='_blank' rel='noopener noreferrer'>
                        Dirección Google Maps
                      </a>
                    </h3>
                    {customer.arcus_clabe && (
                      <>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={autoGridAction}>
                            <Button
                              round
                              color='github'
                              onClick={() => this.fetchUserArcusDeposits(customer.arcus_clabe)}
                            >
                              Reconciliar depósitos de Arcus
                            </Button>
                          </GridItem>
                        </GridContainer>
                        <hr />
                      </>
                    )}
                    {customer.user_id ? (
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={autoGridAction}>
                          <Button
                            color='success'
                            round
                            onClick={() =>
                              this.handlePasswordReset(customer.user_id)
                            }
                          >
                            Resetear Contraseña
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={autoGridAction}>
                          <Button
                            color='success'
                            round
                            onClick={() =>
                              this.handleUnblockTrustedContact(customer.user_id)
                            }
                          >
                            Desbloqueo - (Persona de Confianza)
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={autoGridAction}>
                          <Button
                            color='success'
                            round
                            onClick={() =>
                              this.handleConfirmReactivateCardTrustedAction(
                                customer.user_id
                              )
                            }
                          >
                            Desbloqueo de tarjeta - (Persona de Confianza)
                          </Button>
                        </GridItem>
                        {!deactivatedByFraud ? (
                          <GridItem xs={12} sm={12} md={autoGridAction}>
                            <Button
                              color='success'
                              round
                              onClick={() =>
                                this.handleBlockFraud(customer.user_id, 'fraud')
                              }
                            >
                              Bloquear cuenta por fraude
                            </Button>
                            <Button
                              color='success'
                              round
                              onClick={() =>
                                this.handleBlockFraud(customer.user_id, 'pld_blocked')
                              }
                            >
                              Bloquear cuenta por PLD
                            </Button>
                          </GridItem>
                        ) : (
                          <GridItem xs={12} sm={12} md={autoGridAction}>
                            <Button
                              color='danger'
                              round
                              onClick={() =>
                                this.handleUnBlockFraud(customer.user_id)
                              }
                            >
                              Desbloquear cuenta por fraude
                            </Button>
                          </GridItem>
                        )}
                        {customer.fraud_level !== 0 && !deactivatedByFraud ? (
                          <GridItem xs={12} sm={12} md={autoGridAction}>
                            <Button
                              color='success'
                              round
                              onClick={() =>
                                this.handleAddToWhiteList(customer.user_id)
                              }
                            >
                              Agregar a whitelist
                            </Button>
                          </GridItem>
                        ) : null}
                      </GridContainer>
                    ) : null}
                    <hr />
                    {customer.user_id ? (
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={autoGrid}>
                          <Button
                            color='info'
                            round
                            onClick={() => this.handleClickOpen('govt_ids')}
                          >
                            Documento de Identidad
                          </Button>
                        </GridItem>
                        {customer.proof_of_address ? (
                          <GridItem xs={12} sm={6} md={autoGrid}>
                            <Button
                              color='info'
                              round
                              onClick={() =>
                                this.handleClickOpen('proofs_of_address')
                              }
                            >
                              Comprobante de Domicilio
                            </Button>
                          </GridItem>
                        ) : null}
                        {customer.proof_of_life ? (
                          <GridItem xs={12} sm={6} md={autoGrid}>
                            <Button
                              color='info'
                              round
                              onClick={() =>
                                this.handleClickOpen('proofs_of_life')
                              }
                            >
                              Prueba de Vida
                            </Button>
                          </GridItem>
                        ) : null}
                      </GridContainer>
                    ) : null}
                    <hr />
                    {customer.user_id ? (
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={autoGridAction}>
                          <Button
                            color='primary'
                            round
                            onClick={() =>
                              this.handleCreateTicket(customer.user_id)
                            }
                          >
                            Crear Ticket Zendesk
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={autoGridAction}>
                          <Button
                            color='primary'
                            round
                            onClick={() =>
                              deactivatedByUser
                                ? this.swalConfirmReactivateAccount(customer)
                                : this.swalConfirmDeactivateAccount(customer)
                            }
                          >
                            {deactivatedByUser
                              ? 'Reactivar Cuenta'
                              : 'Cancelar cuenta'}
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={autoGridAction}>
                          <Button
                            color='primary'
                            round
                            onClick={() => this.swalTransactions(customer)}
                          >
                            Liberar depósitos retenidos
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={autoGridAction}>
                          <Button
                            color='primary'
                            round
                            onClick={() => this.swalAssignVirtualCard(customer)}
                          >
                            Nueva Tarjeta Virtual
                          </Button>
                        </GridItem>
                      </GridContainer>
                    ) : null}
                    <hr />
                    {customer.user_id ? (
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={4}>
                          <Button
                            color='dribbble'
                            round
                            onClick={() =>
                              this.handleCardReposition(
                                customer.user_id,
                                'free'
                              )
                            }
                          >
                            Reposición de tarjeta gratis
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                          <Button
                            color='dribbble'
                            round
                            onClick={() =>
                              this.handleCardReposition(
                                customer.user_id,
                                'purchase'
                              )
                            }
                          >
                            Reposición de tarjeta con costo $250
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                          <h4 className={classes.cardRequestsNumber}>
                            <strong>Número de tarjetas pedidas:</strong>{' '}
                            {cardRequestsNumber ? cardRequestsNumber : '0'}
                          </h4>
                          {cardPayment && (
                            <p className={classes.cardRequestsNumber}>
                              {`Cliente tiene un pago activo: ${
                                cardPayment.transaction_id
                              } – ${cardPayment.type}`}
                            </p>
                          )}
                        </GridItem>
                      </GridContainer>
                    ) : null}
                  </small>
                </div>
                {cards ? (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {physical_cards ? (
                        <div>
                          <hr />
                          <h3 className={classes.cardCenter}>
                            Tarjetas Físicas
                          </h3>
                          <hr />
                          <ReactTable
                            data={physical_cards}
                            columns={columnsCards}
                            showPageSizeOptions={false}
                            showPagination={false}
                            minRows={0}
                            filterable={false}
                            sortable={false}
                            className='-cards -striped -highlight'
                          />
                        </div>
                      ) : null}
                      {virtual_cards ? (
                        <div>
                          <hr />
                          <h3 className={classes.cardCenter}>
                            Tarjetas Virtuales
                          </h3>
                          <hr />
                          <ReactTable
                            data={virtual_cards}
                            columns={columnsCards}
                            showPageSizeOptions={false}
                            showPagination={false}
                            minRows={0}
                            filterable={false}
                            sortable={false}
                            className='-cards -striped -highlight'
                          />
                        </div>
                      ) : null}
                    </GridItem>
                  </GridContainer>
                ) : null}
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <NavPills
                  onChange={active => this.handleChangeTab(active)}
                  color='info'
                  alignCenter
                  tabs={this.state.tabs}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

CustomerDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(CustomerDetail));
