import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import swal from '@sweetalert/with-react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

// core components
import { withStyles } from '@material-ui/core';
import { detailMiniSubtitle } from 'assets/jss/omni.jsx';
import Badge from 'components/Badge';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem.jsx';
import MovementDetail from 'components/MovementDetail';

import Utils from 'common/utils';

import { 
    getWhatsappTransferDetail,
    reverseSwap
} from 'providers/api-rest';

const styles = {
    detailMiniSubtitle: { ...detailMiniSubtitle },
    lighterText: {
        opacity: '0.25',
        color: '#3A3A3A',
    },
};

function WhatsappTransferDetail({ classes, match }) {
    const [transfer, setTransfer] = useState(null);
    
    const getTransferInfo = async transferId => {
        const response = await getWhatsappTransferDetail(transferId);

        if (response.status === 200)
            setTransfer(response.data.result);
    };

    useEffect(() => {
        const { transferId } = match.params;
        getTransferInfo(transferId);
    }, []);

    if (!transfer) return null;

    // Format data to display
    const human = {
        name: transfer.source_name,
        id: transfer.source_id,
    };

    const topData = [
        transfer.recipient_name,
        Utils.formatPhoneNumber(transfer.phone_number),
        { status: transfer.status },
    ];
    const status = transfer.transfer_status;
    const mainData = [
        <Moment utc>{transfer.created_at}</Moment>,
        Utils.formatPrice(transfer.amount),
        transfer.descriptor,
    ];

    // Render specific details based on local or spei transfer
    if (transfer.tracking_key) {
        if (transfer.network === 'spei') {
            mainData.push(
                { status },
                (
                    <>
                        <span className={classes.detailMiniSubtitle}>REFERENCIA</span>
                        <span>{transfer.reference_number || ''}</span>
                    </>
                ),
                {
                    link: `
                        /admin/transfer/${encodeURIComponent(transfer.transfer_id)}
                    `,
                    data: (
                        <span className={classes.lighterText}>{transfer.tracking_key}</span>
                    ),
                },
            );
        } else {
            mainData.push(
                {
                    link: `
                        /admin/transfer/${encodeURIComponent(transfer.transfer_id)}
                    `,
                    status,
                },
            );
        }
    }

    const swapReverseSwap = async transferId => {
        const message = 'Se reembolsará la transferencia nuevamente al cliente'
        const reason = await swal(message, {
            title: '¿Estás seguro?',
            content: {
            element: 'input',
                attributes: {
                    placeholder: 'Ingrese el numero de ticket',
                     type: 'text'
                }
            },
            buttons: ['Cancelar', 'Reembolsar']
        });
        if (reason) {
            const response = await reverseSwap({"swap_id": transferId});
            if (response.status === 201) {
                swal('Excelente!', 'Acción realizada exitosamente.', 'success');
            } else {
                if (response.status === 401) {
                    if (response['data']['msg'] === 'invalid role') {
                        swal(
                            'Lo siento!',
                            'No tiene el rol necesario para realizar esta accion.',
                            'error'
                        );
                    } else {
                        swal(
                            'Lo siento!',
                            'Esta acción requiere verificación con yubikey',
                            'error'
                        );
                      }
                }
            }
        } else if (reason === '') {
          swal('Lo siento!', 'Motivo de acción requerido!', 'error');
        }
    };

    if (transfer.status === 'submitted'){
        mainData.push((
            <p
                onClick={() => {
                    swapReverseSwap(transfer.id);
                }}
            >
                <Badge color='info'>Cancelar Transferencia</Badge>
            </p>
        ))
    }

    let accordionCollapses = [];
    if (transfer.claim_url) {
        accordionCollapses.push({
            title: 'ENLACE DE COBRO',
            content: (
                <div>
                    {`https://cuenca.com/recibirdinero/${transfer.claim_url}`}
                </div>
            ),
        });
    }

    return (
        <GridContainer className="container">
            <GridItem xs={12}>
                <MovementDetail
                    human={human}
                    topData={topData}
                    mainData={mainData}
                    status={status}
                    accordionData={accordionCollapses}
                />
            </GridItem>
        </GridContainer>
    );
}

WhatsappTransferDetail.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default withRouter(
    withStyles(styles)(WhatsappTransferDetail)
);
