import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Compose.css";

export default class Compose extends Component {
  constructor({ postMessagesList }) {
    super();
    this.state = {
      message: "",
      postMessagesList: postMessagesList
    };
  }

  handleChange(message) {
    this.state.postMessagesList(this.props.chatId, message);
    this.setState({ message: "" });
  }

  render() {
    return (
      <div className="compose">
        <input
          type="text"
          className="compose-input"
          placeholder="Escribe un mensaje y preciona ENTER"
          value={this.state.message}
          onChange={evt => {
            this.setState({
              message: evt.target.value
            });
          }}
          onKeyPress={evt => {
            if (evt.key === "Enter") {
              this.handleChange(evt.target.value);
            }
          }}
        />
      </div>
    );
  }
}

Compose.propTypes = {
  chatId: PropTypes.string.isRequired,
  postMessagesList: PropTypes.func.isRequired
};
