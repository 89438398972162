import React, { Component } from "react";
import PropTypes from "prop-types";
import ScrollToBottom from "react-scroll-to-bottom";

import ConversationList from "../ConversationList/index.jsx";
import MessageList from "../MessageList/index.jsx";
import "./Messenger.css";

import { getMessagesList } from "providers/api-rest.js";

export default class Messenger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      messages: []
    };
    this.chatId = "";
  }

  componentDidMount() {
    if (this.props.search) {
      this.getMessagelist({
        chatId: this.props.search
      });
    }
  }

  addMessagelist = message => {
    if (message["contactId"] === this.chatId) {
      const messages = this.state.messages;
      let author = "apple";
      if (message.fromCustomer) {
        author = "orange";
      }
      messages.push({
        id: message.contactId,
        author: author,
        message: message.message,
        status: message.message_status,
        image: message.image,
        audio: message.audio,
        video: message.video,
        timestamp: message.date,
        fromName: message.fromName
      });
      this.setState({ messages: messages });
    }
  };

  getMessagelist = async chat => {
    this.chatId = chat["chatId"];
    const response = await getMessagesList(chat["chatId"]);
    let messages = response.data.map(result => {
      let author = "apple";
      if (result.fromCustomer) {
        author = "orange";
      }
      return {
        id: result.contactId,
        author: author,
        message: result.message,
        status: result.message_status,
        image: result.image,
        audio: result.audio,
        video: result.video,
        timestamp: result.date,
        fromName: result.fromName
      };
    });
    this.setState(prevState => {
      return {
        ...prevState,
        clientName: chat["clientName"],
        clientId: chat["clientId"],
        botmakerId: chat["botmakerId"],
        messages: messages
      };
    });
  };

  render() {
    return (
      <div className="messenger">
        <div className="scrollable sidebar">
          <ConversationList
            getMessagelist={this.getMessagelist}
            search={this.props.search}
          />
        </div>

        <ScrollToBottom className="scrollable content">
          <MessageList
            botmakerId={this.state.botmakerId}
            clientId={this.state.clientId}
            clientName={this.state.clientName}
            messages={this.state.messages}
          />
        </ScrollToBottom>
      </div>
    );
  }
}

Messenger.propTypes = {
  search: PropTypes.string
};
