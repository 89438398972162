import React from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';
import ReactTable from "react-table";
import Moment from "react-moment";
import swal from '@sweetalert/with-react';

import withStyles from '@material-ui/core/styles/withStyles';
import Utils from "common/utils.jsx";

const styles = {
  alignCenter: {
    textAlign: 'center',
    cursor: 'pointer',
    color: '#af0000'
  },
  'amount-debit': {
    color: '#cc0000',
  },
  'amount-credit': {
    color: '#00cc00',
  },
};

const modelToName = {
  'bill_payments': 'Pago de servicio',
  'card_transactions': 'Transacción de tarjeta',
  'commissions': 'Comisión',
  'deposits': 'Depósito',
  'wallet_transactions': 'Transacción Ahorros',
  'transfers': 'Transferencia',
  'whatsapp_transfers': 'Transferencia de Whatsapp',
};

class TableShowMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
       items: [],
       loading: false
    };
    this.getFormattedField = this.getFormattedField.bind(this);
    this.last_search = null;
    this.last_created_after = null;
    this.last_created_before = null;
    this.last_created_at = null;
  }

  componentDidMount() {
    const { search } = this.props;
    if (search){
        this.getRequestData(search)
        this.last_search = search;
    }
  }

  componentDidUpdate(prevProps) {
    const { search, created_after, created_before, restModel } = this.props;
    if (
      this.last_search !== search ||
      this.last_created_after !== created_after ||
      this.last_created_before !== created_before ||
      prevProps.restModel !== restModel
    ){
        this.last_created_at = null
        this.getRequestData(search);
        this.last_search = search;
        this.last_created_after = created_after;
        this.last_created_before = created_before;
    }
  };

  async getRequestData(search, items=[]) {
    this.setState({ loading: true });
    const { created_after, created_before, restModel } = this.props;

    let params = {search};
    if (this.last_created_at || created_before){
      params['created_before'] = this.last_created_at || created_before;
    }
    if (!this.last_created_at && created_after){
      params['created_after'] = created_after
    }

    const response = await restModel(params);

    if (response.status === 401) {
      swal(
        'Lo siento 😔',
        'No tienes el rol requerido',
        'error'
      );
      this.setState({loading: false});
      return;
    }

    if (!response.data || !response.data.result){
      this.setState({loading: false});
      return
    }

    const new_items = response.data.result;
    if (new_items.length === 0){
      this.setState({loading: false})
      return;
    }

    const last_item = new_items[new_items.length - 1];
    this.last_created_at = last_item['created_at'];
    this.setState({ 
        items: items.concat(new_items), loading: false
    });
  }

  getFormattedField = (field, type, row) => {
    const { classes } = this.props;

    if (type === 'datetime') {
      field = <Moment utc>{field}</Moment>
    } else if (type === 'amount') {
      field = Utils.formatPrice(field);
    } else if (type === 'amountType') {
      if (row) {
        field = (
          <span className={classes[`amount-${row.type}`]}>
            {
              `${
                row.type === 'debit' ? '-' : ''
              }${Utils.formatPrice(field)}`
            }
          </span>
        );
      }
    } else if (type === 'card') {
      field = (
        <span>
          ************<strong>{field}</strong>
        </span>
      );
    } else if (type === 'model') {
      field = modelToName[field];
    }
  
    return field;
  };


  pushDetail(rowInfo, column) {
    const { detailUri, activeClick = true } = this.props;
  
    if (!rowInfo || !activeClick) return {};

    let uri;
    // detailUri is a Function when it comes from BalanceEntries
    // This is to properly render each detail based on the model.
    if (detailUri instanceof Function) {
      const firstPart = detailUri(
        rowInfo.original.transaction_model || rowInfo.original.user_id
      );

      // This is for Commission, it does not have a detail view
      // so no need to do an onClick.
      if (!firstPart) return {};

      uri = `${firstPart}${rowInfo.original.transaction_id || rowInfo.original.id}`;
    } else {
      uri = `${detailUri}${rowInfo.original.id}`;
    }
    return {
      style: {
        cursor: "pointer"
      },
      onClick: () => {
        window.open(uri)
      }
    };
  }

  render() {
    const { loading, items } = this.state;
    const { classes, columns, search } = this.props;
    let new_columns = [];
    columns.forEach(column => {
        let new_column = {
            Header: column['name'],
            accessor: column['field']
        }
        if ('type' in column){
            new_column['Cell'] = row => this.getFormattedField(
                row.original[column['field']],
                column['type'],
                column['type'] === 'amountType' ? row.original : null,
            )
        }
        if ('width' in column) new_column['minWidth'] = column['width']
        if ('Cell' in column) new_column['Cell'] = column['Cell']
        new_columns.push(new_column)
    });

    return (
        <div>
            <ReactTable
                manual
                data={items}
                columns={new_columns}
                loading={loading}
                sortable={false}
                showPageSizeOptions={false}
                showPageJump={false}
                className="-cards -highlight"
                noDataText="No se han encontrado resultados"
                getTdProps={(state, rowInfo, column) =>
                    this.pushDetail(rowInfo, column)
                }
                showPagination={false}
            />
            { items.length !== 0
                ? <h4 className={classes.alignCenter}>
                        <span 
                            onClick={() => this.getRequestData(
                                search, items
                            )}
                        >
                            Ver más resultados
                        </span>
                    </h4>
                : null
            }
      </div>
    );
  }
}

TableShowMore.propTypes = {
  search: PropTypes.string.isRequired,
  created_after: PropTypes.instanceOf(Date),
  created_before: PropTypes.instanceOf(Date),
  columns: PropTypes.array.isRequired,
  restModel: PropTypes.func,
  detailUri: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]).isRequired,
  activeClick: PropTypes.bool,
};

export default withRouter(withStyles(styles)(TableShowMore));
