import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { DateRangePicker } from 'rsuite';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// core components
import Card from 'components/Card';
import CardBody from 'components/Card/CardBody';
import CardHeaderSearch from 'components/CardHeaderSearch';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem';
import TableShowMore from 'components/TableShowMore';

import 'rsuite/dist/styles/rsuite-default.css'; 

import {
    getBalanceEntries,
    getBalanceEntriesWithRole,
} from 'providers/api-rest';

const styles = {
    form: {
        fontFamily: 'Roboto',
        fontSize: '15px',
        color: '#616C93',
    },
    switch: {
        color: '#B3B8CC',
        '&$checked': {
            color: '#616C93',
        },
        '&$checked + $track': {
            backgroundColor: '#616C93',
        },
    },
    switchChecked: {
        color: '#616C93',
    },
    margin: {
        textAlign: 'right',
        marginRight: '1em',
        marginTop: '0.3em'
    }
};

const modelToRoute = {
    'bill_payments': '/admin/service/transaction/',
    'card_transactions': '/admin/card/transaction/',
    'commissions': '/admin/commissions/',
    'deposits': '/admin/deposit/',
    'transfers': '/admin/transfer/',
    'whatsapp_transfers': '/admin/whatsapp_transfers/',
    'wallet_transactions': '/admin/wallet_transactions/',
};

function BalanceEntries({ classes, userId }) {
    const [search, setSearch] = useState(null);
    const [showBalance, setShowBalance] = useState(false);
    const [dateRange, setDateRange] = useState([]);

    useEffect(() => {
        if (userId && search !== userId) setSearch(userId);
    }, []);

    const getUriFromModel = model => {
        return modelToRoute[model];
    };

    const newSearchHandle = search => setSearch(search);

    const columns = [
        {
            name: 'Tipo',
            field: 'transaction_model',
            width: 90,
            type: 'model',
        },
        {
            name: 'Nombre',
            field: 'name',
            width: 150,
        },
        {
            name: 'Fecha',
            field: 'created_at',
            width: 90,
            type: 'datetime',
        },
        {
            name: 'Monto',
            field: 'amount',
            width: 80,
            type: 'amountType',
        },
    ];

    if (showBalance) {
        columns.push({
            name: 'Saldo',
            field: 'rolling_balance',
            width: 100,
            type: 'amount',
        });
    }

    columns.push(
        {
            name: 'Descriptor',
            field: 'descriptor',
            width: 85,
        },
    );

    const created_after = (
        dateRange.length !== 0 ? dateRange[0].toISOString() : null
    )
    const created_before = (
        dateRange.length !== 0 ? dateRange[1].toISOString() : null
    )

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>

                    <CardHeaderSearch
                        type="BalanceEntry"
                        title="Transacciones"
                        placeholder="Buscar (Transaction ID)"
                        newSearchHandle={newSearchHandle}
                    />

                    <div className={classes.margin}>
                        <DateRangePicker
                            placement='bottomEnd'
                            onChange={value => setDateRange(value)}
                        />
                    </div>

                    <CardBody>
                        <span>
                            <FormControlLabel
                                classes={{ label: classes.form }}
                                control={
                                    <Switch
                                        classes={{
                                            switchBase: classes.switch,
                                            checked: classes.switchChecked,
                                        }}
                                        checked={showBalance}
                                        onChange={() => setShowBalance(!showBalance)}
                                    />
                                }
                                label={
                                    `${showBalance ? 'Ocultar' : 'Mostrar'} Balance`
                                }
                            />
                        </span>
                        <span style={styles.margin}>
                            <a
                                href={`https://metabase.cuenca.io/question/1131?user_id=${userId}`}
                                target='_blank'
                                rel="noopener noreferrer"
                            >
                                Ver detalle autorizaciones
                            </a>
                        </span>
                        <span style={styles.margin}>
                            <a
                                href={`https://metabase.cuenca.io/question/1132?user_id=${userId}`}
                                target='_blank'
                                rel="noopener noreferrer"
                            >
                                Ver detalle contracargos
                            </a>
                        </span>


                        {search && (
                            <TableShowMore
                                search={search}
                                created_after={created_after}
                                created_before={created_before}
                                columns={columns}
                                restModel={
                                    showBalance
                                    ? getBalanceEntriesWithRole
                                    : getBalanceEntries
                                }
                                detailUri={getUriFromModel}
                            />
                        )}
                    </CardBody>

                </Card>
            </GridItem>
        </GridContainer>
    );
}

BalanceEntries.propTypes = {
    userId: PropTypes.string,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BalanceEntries);
