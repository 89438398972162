import React from 'react';
import { withRouter } from 'react-router';
import ReactTable from "react-table";
import PropTypes from 'prop-types';

import Moment from "react-moment";

// core components
import Card from 'components/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem';

// @material-ui/icons
import HomeIcon from '@material-ui/icons/Home';
import CheckIcon from '@material-ui/icons/Check';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { cardTitle } from "assets/jss/omni";
import { getProofsOfAddressList } from 'providers/api-rest';

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: 0,
    },
};

class ProofsOfAddressList extends React.Component {
    constructor(props) {
      super(props);
      this.folios = {};
      this.state = {
        loading: true,
        folios: {},
        lastState: null,
      };
    }

    async onFetchData(state, referrer = null) {
      this.setState({
        loading: true,
        lastState: state,
      });
      const params = {
        page: state.page + 1,
        page_size: state.pageSize
      };
      const response = await getProofsOfAddressList(params);
      this.setState({
        data:
          response.data && response.data.identities
            ? response.data.identities
            : [],
        loading: false,
        pages: response.data ? response.data.pages : 1,
        response: response
      });
    }

    render () {
      const { classes } = this.props;
      const columns = [
          {
              Header: 'Nombre',
              accessor: 'full_name',
              width: 180,
              Cell: row => {
                const link = "/admin/proofs_of_address/" + row.original.user_id;
                return (
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {row.original.identity_data.nombre}{" "}
                    {row.original.identity_data.apellido_materno}{" "}
                    {row.original.identity_data.apellido_paterno}
                  </a>
                );
              }
          },
          {
              Header: 'Fecha',
              accessor: 'created_at',
              width: 180,
              Cell: row => {
                const created_at = row.original.created_at.split(".")[0];
                return <Moment utc>{created_at}</Moment>;
              }
          },
          {
              Header: 'Balance Retenido',
              accessor: 'has_hold_balance',
              width: 180,
              Cell: row => (
                row.original.has_hold_balance ?
                <center>
                  <CardIcon>
                    <CheckIcon />
                  </CardIcon>
                </center> : ''
              )
          },
      ];

      return (
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                        <HomeIcon />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                        Verificación de Comprobantes de Domicilio
                    </h4>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    manual
                    data={this.state.data}
                    columns={columns}
                    pages={this.state.pages}
                    defaultPageSize={20}
                    loading={this.state.loading}
                    filterable={false}
                    sortable={false}
                    onFetchData={state => this.onFetchData(state)}
                    getTrProps={this.getTrProps}
                    className="-highlight"
                    noDataText="No se han encontrado resultados"
                    showPageSizeOptions={false}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
      );
    }
}

ProofsOfAddressList.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  newOrderPlaced: PropTypes.func,
  newquote: PropTypes.bool
};

export default withRouter(withStyles(styles)(ProofsOfAddressList));
