import React from 'react';

// core components
import Card from 'components/Card';
import CardBody from 'components/Card/CardBody.jsx';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem.jsx';

import CreateTemplate from 'views/Notifications/CreateTemplate.jsx';
import CreateExecution from 'views/Notifications/CreateExecution.jsx';
import LastExecutions from 'views/Notifications/LastExecutions.jsx';
import ListTemplates from 'views/Notifications/ListTemplates.jsx';


class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newtemplate: false
    };
  }

  newTemplate = () => {
    this.setState({
      newtemplate: true
    });
  };

  render() {
    return [
      <Card key={0}>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <CreateExecution newtemplate={this.state.newtemplate}/>
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <LastExecutions />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>,
      <Card key={0}>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <CreateTemplate newTemplate={this.newTemplate} />
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <ListTemplates newtemplate={this.state.newtemplate}/>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>,
    ];
  }
}

export default Notifications;
