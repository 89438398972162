import React from 'react';

import PropTypes from 'prop-types';

import swal from '@sweetalert/with-react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import Send from '@material-ui/icons/Send';

// core components
import Button from 'components/CustomButtons';
import Card from 'components/Card';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CustomInput from 'components/CustomInput';
import SnackbarContent from 'components/Snackbar/SnackbarContent.jsx';

import regularFormsStyle from 'assets/jss/omni/views/regularFormsStyle';

import { cardTitle, tooltip } from 'assets/jss/omni.jsx';

import { postNotificadorTemplates } from 'providers/api-rest.js';

const styles = {
  tooltip,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  regularFormsStyle,
};

class CreateTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formTemplate: {}
    };
  }

  changeFormTemplate(event) {
    const { formTemplate } = this.state;
    formTemplate[event.target.id] = event.target.value;
    this.setState({ formTemplate });
  }

  validate() {
    return (
      this.validateName() && this.validateSubject() && this.validateMessage()
    );
  }

  validateName() {
    const { formTemplate } = this.state;
    let validated = true;
    if (!formTemplate.template_id) {
      swal(
        'Lo siento!',
        'Identificador de mensaje es requerido.',
        'error'
      );
      validated = false;
    }
    return validated;
  }

  validateSubject() {
    const { formTemplate } = this.state;
    let validated = true;
    if (!formTemplate.subject) {
      swal(
        'Lo siento!',
        'Titulo de mensaje es requerido.',
        'error'
      );
      validated = false;
    }
    return validated;
  }

  validateMessage() {
    const { formTemplate } = this.state;
    let validated = true;
    if (!formTemplate.message) {
      swal(
        'Lo siento!',
        'Cuerpo de mensaje es requerido.',
        'error'
      );
      validated = false;
    }
    return validated;
  }

  generateNewTemplate = async () => {
    const { newTemplate } = this.props;
    if (!this.validate()) return;
    const response = await postNotificadorTemplates(
      this.state.formTemplate
    );
    if (response.status === 201) {
      swal('Excelente!', 'Excelente, template creado correctamente.', 'success')
      newTemplate();
      this.setState({formCustomer: {}});
    } else if (response.status === 400 || response.status === 500) {
      swal(
        'Lo siento!',
        'Error inesperado, intente de nuevo o contacta a Ingeniería',
        'error'
      );
    } else if (response.status === 401) {
      swal(
        'Lo siento!',
        'No tiene el rol necesario para realizar esta accion.',
        'error'
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color='primary' icon>
          <CardIcon color='primary'>
            <Send />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Crear Plantilla</h4>
        </CardHeader>
        <CardBody>
          <form>
            <CustomInput
              labelText='ID'
              id='template_id'
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => this.changeFormTemplate(event),
                value: !this.state.formTemplate.template_id
                  ? ''
                  : this.state.formTemplate.template_id
              }}
            />
            <CustomInput
              labelText='Titulo'
              id='subject'
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => this.changeFormTemplate(event),
                value: !this.state.formTemplate.subject
                  ? ''
                  : this.state.formTemplate.subject
              }}
            />
            <CustomInput
              labelText='Cuerpo'
              id='message'
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => this.changeFormTemplate(event),
                value: !this.state.formTemplate.message
                  ? ''
                  : this.state.formTemplate.message
              }}
            />
            <div>
              <Button
                color='primary'
                size='sm'
                onClick={this.generateNewTemplate}
              >
                <span>Crear</span>
              </Button>
              {this.state.message ? (
                <small>
                  <hr />
                  <SnackbarContent
                    message={this.state.message}
                    color={this.state.messageColor}
                  />
                </small>
              ) : null}
            </div>
          </form>
        </CardBody>
      </Card>
    );
  }
}

CreateTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
  newTemplate: PropTypes.func
};

export default withStyles(styles)(CreateTemplate);
