import React, { useCallback, useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';
import Moment from 'react-moment';

import Swal from 'sweetalert2';

// @material-ui/core components
import { withStyles } from '@material-ui/core';

// core components
import { detailMiniSubtitle } from 'assets/jss/omni.jsx';
import Accordion from 'components/Accordion';
import Button from "components/CustomButtons";
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem.jsx';
import Table from 'components/Table';
import MovementDetail from 'components/MovementDetail';

import Utils from 'common/utils';

import {
  getArcusTxnStatus,
  getDeposit,
  getCepMexDocument,
  getTransfer,
  getTransferStpSpeidInfo
} from "providers/api-rest.js";

const styles = {
	liberarButton: {
	  marginLeft: '750px',
	},
	logData: { 
		fontSize: '12px',
		fontWeight: 900,
		color: '#666'
	},
	logMetadata: {
		maxWidth: '450px',
		display: 'block'
	},
	accorWidth: {
		width: '100%'
  },
  detailMiniSubtitle: { ...detailMiniSubtitle },
};

function TransferDepositDetail({
  classes, match, type
}) {
  const [transaction, setTransaction] = useState(null);
  const [banxicoCep, setBanxicoCep] = useState(null);
  const [info_stp, setStp] = useState(null);
  const [info_speid, setSpeid] = useState(null);

  const cepMexDocument = useCallback(async (transaction_id) => {
    const response = await getCepMexDocument(
      transaction_id,
      type
    );
    
    if (response.status !== 200) {
      setBanxicoCep('Not found');
      return;
    }
    
    setBanxicoCep(response.data);
  }, []);

  const stpSpeidInfo = useCallback(async (tracking_key) => {
    const response = await getTransferStpSpeidInfo(tracking_key);

    if (response.status === 200) {
      if (response.data.info_stp)
        setStp(response.data.info_stp);

      if (response.data.info_speid)
        setSpeid(response.data.info_speid);
    }
  }, []);

  const getTransferInfo = useCallback(async (transaction_id) => {
    let response = null;
    if (type === 'transfer')
      response = await getTransfer(transaction_id);
    else
      response = await getDeposit(transaction_id);
    
    if (!response) return;
    
    const transaction = response.data.result;
    setTransaction(transaction);

    if (transaction.network === 'spei') {
      stpSpeidInfo(transaction.tracking_key);

      if (transaction.institution !== 'STP')
        cepMexDocument(transaction_id);
    }
  }, []);

  const checkArcusStatus = useCallback(async () => {
    const swalObject = await Swal.fire({
      title: '¿Segurx? 🧐',
      text: 'Esta acción buscará actualizar el estado de la transacción con Arcus',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: 'Confirmar',
    });

    if (swalObject.isDismissed) return;

    const { transaction_id } = match.params;
    const response = await getArcusTxnStatus(transaction_id);
    if (response.status === 200) {
      const { data } = response;
      Swal.fire(
        'Listones ✅',
        `Se ha actualizado correctamente el estado de la trannsacción a: ${data.status}`,
        'success',
      );
    } else if (response.status === 404) {
      Swal.fire(
        'Awante ahí 🙏🏼',
        `Aún no encontramos la transacción, por favor espera de 10 a 20 minutos para intentar de nuez.
        
        Asegúrate de que esta transacción se haya realizado a través de Arcus.
        `,
        'warning',
      );
    } else {
      Swal.fire(
        'Lo siento 😓',
        'Ocurrió un error al obtener el estado de la transacción, xfa intenta de nuevo en unos minutos',
        'error',
      );
    }
  }, [match]);

  useEffect(() => {
    const { transaction_id } = match.params;
    getTransferInfo(transaction_id);
  }, [match]);

  if (!transaction) return null;

  let accordionCollapses = [];

  // Add SPEID accordion
  if (info_speid) {
    let logData = [[
      <span className={classes.logData}>ESTADO</span>,
      <span className={classes.logData}>CREADA</span>,
      <span className={classes.logData}>METADATA</span>,
    ]];

    if (info_speid.events) {
      logData = logData.concat(info_speid.events.map(
        l => [
          <span>{l.type}</span>,
          <Moment utc>{l.created_at}</Moment>,
          <span className={classes.logMetadata}>
            {l.metadata}
          </span>
        ]
      ));
    }

    const transactionLog = [{
      title: 'Bitácora de Transacción',
      content: (
        <div className={classes.accorWidth}>
          <Table tableData={logData} />
        </div>
      )
    }];

    accordionCollapses.push({
      title: 'SPEID',
      content: (
        <div className={classes.accorWidth}>
          <Table
            tableData={[
              [info_speid.created_at],
              [info_speid.status],
              [<Accordion collapses={transactionLog} />],
            ]}
          />
        </div>
      )
    })
  }

  // Add STP accordion
  if (info_stp) {
    accordionCollapses.push({
      title: 'STP',
      content: (
        <div className={classes.accorWidth}>
          <Table
            tableData={[
              [info_stp.date_capture || 'N/A'],
              [info_stp.date_liquidation || 'N/A'],
              [info_stp.date_operation || 'N/A'],
              [info_stp.estado_orden || 'N/A'],
            ]}
          />
        </div>
      )
    });
  }

  // Add Banxico accordion
  let banxico = {
    title: 'BANXICO',
    content: (
      <div>
        La información de Banxico no está disponible. Consulta más tarde.
      </div>
    )
  };

  if (banxicoCep) {
    if (banxicoCep !== 'Not found') { 
      banxico.content = (
        <div className={classes.accorWidth}>
          <embed src={banxicoCep} width="800px" height="1100px" />
        </div>
      );
    }

    accordionCollapses.push(banxico);
  }

  if (type !== 'transfer') {
    accordionCollapses.reverse();
  } else {
    accordionCollapses.unshift({
      title: 'ARCUS',
      content: (
        <div className={classes.accorWidth}>
          <Button
            round
            color="info"
            onClick={checkArcusStatus}
          >
            Actualizar Estado
          </Button>
        </div>
      ),
    });
  }

  // Format data to display:
  const human = {
    name: `
      ${transaction.human_name} ${transaction.from_whatsapp ? '(Whatsapp Transfer)' : ''}
    `,
    id: transaction.human_id,
  };

  const topData = transaction.account_number ? [
    transaction.recipient_name,
    transaction.account_number,
    transaction.institution,
  ] : null;

  const status = transaction.status;
  const mainData = [
    <Moment utc>{transaction.created_at}</Moment>,
    Utils.formatPrice(transaction.amount),
    { status: transaction.status },
    (
      <>
        <span className={classes.detailMiniSubtitle}>
          CONCEPTO
        </span>
        <span>{transaction.descriptor}</span>
      </>
    ),
    (
      <>
        <span className={classes.detailMiniSubtitle}>
          REFERENCIA
        </span>
        <span>{transaction.reference_number || 'N/A'}</span>
      </>
    ),
    (transaction.tracking_key || transaction.id),
  ];

  return (
    <GridContainer className="container">
      <GridItem xs={12}>
        <MovementDetail
          human={human}
          topData={topData}
          mainData={mainData}
          status={status}
          accordionData={accordionCollapses}
        />
      </GridItem>
    </GridContainer>
  );
}

TransferDepositDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['transfer', 'deposit']).isRequired,
};

export default withRouter(withStyles(styles)(TransferDepositDetail));
