import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import swal from '@sweetalert/with-react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

// core components
import Badge from 'components/Badge';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem';
import MovementDetail from 'components/MovementDetail';

import Utils from 'common/utils';

import { 
  getCommissionDetail,
  refundComission,
} from 'providers/api-rest';

function CommissionDetail({ match }) {
  const [commission, setCommission] = useState(null);

  const fetchCommissionDetail = async commissionId => {
    const response = await getCommissionDetail(commissionId);

    if (response.status === 200) setCommission(response.data.result);
  };

  useEffect(() => {
    const { commissionId } = match.params;
    fetchCommissionDetail(commissionId);
  }, [match.params]);

  const refundCommission = async () => {
    const reason = await swal('Se reembolsará la comisión', {
      title: '¿Estás segurx?',
      content: {
        element: 'input',
        attributes: {
          placeholder: 'Ingresa el motivo de la acción',
          type: 'text',
        },
      },
      buttons: ['Cancelar', 'Reembolsar'],
    });

    if (reason) {
      const response = await refundComission(match.params.commissionId, { reason });

      if (response.status === 201) {
        swal(
          'Excelente 🎉',
          'Acción realizada exitosamente.',
          'success',
        );
      } else {
        swal(
          'Woops 😵‍💫',
          'La comisión no pudo ser devuelta, revisa que no haya sido reembolsada anteriormente',
          'error',
        );
      }
    }
  };

  if (!commission) return null;

  const human = {
    id: commission.source_id,
    name: commission.source_name,
  };

  const topData = [
    { status: commission.status },
  ];

  const mainData = [
    <Moment utc>{commission.created_at}</Moment>,
    Utils.formatPrice(commission.amount),
    commission.descriptor,
  ];

  if (commission.status === 'succeeded') {
    mainData.push(
      <p cursor="pointer" onClick={refundCommission}>
        <Badge color="info">Reembolsar Comisión</Badge>
      </p>
    );
  }

  return (
    <GridContainer className="container">
      <GridItem xs={12}>
        <MovementDetail
          human={human}
          topData={topData}
          mainData={mainData}
          status={commission.status}
        />
      </GridItem>
    </GridContainer>
  );
}

CommissionDetail.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(CommissionDetail);
