import React from "react";
import { withRouter } from 'react-router';

import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Public from '@material-ui/icons/Public';
import Home from '@material-ui/icons/Home';

// core components
import Card from 'components/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem';
import NavPills from 'components/NavPills';
import HeldTransfersList from './HeldTransfersList';

import { cardTitle } from "assets/jss/omni.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
};

const TABS = [
    {
        tabButton: 'Transferencias Globales',
        tabIcon: Public,
    },
    {
        tabButton: 'Transferencias Locales',
        tabIcon: Home,
    },
];

const TAB_ACTIVE = [
    attrs => <HeldTransfersList {...attrs} />,
    attrs => <HeldTransfersList local {...attrs} />,
];

class Transactions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tabs: TABS,
        };
    }

    onTabChange = active => {
        const { match, location, history } = this.props;
        const attrs = { match, location, history };
        const { tabs } = this.state;
        tabs[active].tabContent = TAB_ACTIVE[active](attrs);
        this.setState(tabs);
    };

    render() {
        const { tabs } = this.state;
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Transferencias Salientes para Aprobar
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <NavPills
                                onChange={active => this.onTabChange(active)}
                                color="primary"
                                alignCenter
                                tabs={tabs}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

Transactions.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  customer_id: PropTypes.string
};

export default withRouter(withStyles(styles)(Transactions));
