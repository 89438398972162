import React from "react";
import { withRouter } from 'react-router';
// react component for creating dynamic tables
import ReactTable from "react-table";

import PropTypes from "prop-types";

import Moment from "react-moment";

import { NavLink } from "react-router-dom";

// @material-ui/core components
import blue from "@material-ui/core/colors/blue";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import CreditCard from "@material-ui/icons/CreditCard";
import ListAlt from "@material-ui/icons/ListAlt";
import Opacity from "@material-ui/icons/Opacity";
import SwapHorizontalCircle from "@material-ui/icons/SwapHorizontalCircle";

// core components
import Badge from "components/Badge";
import Card from "components/Card";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import { processError } from 'components/ErrorBoundary';

import { cardTitle, tooltip } from "assets/jss/omni.jsx";

import { getCustomers } from "providers/api-rest.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  tooltip,
  colorSwitchBaseBlue: {
    height: "auto",
    "&$colorChecked": {
      color: blue[500],
      "& + $colorBar": {
        backgroundColor: blue[500]
      }
    }
  },
  colorBar: {},
  colorChecked: {},
  icon: {
    marginLeft: "10%",
    fontSize: "20px"
  }
};

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  async onFetchData(state) {
    this.setState({ loading: true });
    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      sorted: state.sorted,
      filtered: state.filtered
    };
    if (state.filtered.length !== 0) {
      const response = await getCustomers(params);
      this.setState({
        data: response.data && response.data.result ? response.data.result : [],
        loading: false,
        pages: response.data ? response.data.pages : 1,
        response: response
      });
    } else {
      this.setState({
        data: [],
        loading: false,
        pages: 1
      });
    }
  }

  tooltipSwitch(card_id) {
    let tooltip = "Sin tarjeta Fisica";
    if (card_id) {
      tooltip = "Con Tarjeta Física";
    }
    return tooltip;
  }

  pushCustomerDetail(rowInfo, column) {
    const { history } = this.props;
    return {
      style: {
        cursor: "pointer"
      },
      onClick: () => {
        const block_click = ["name", "actions"];
        if (!block_click.includes(column.id)) {
          history.push("/admin/customer/" + rowInfo.original.id);
        }
      }
    };
  }

  getDeactivatedFont(rowInfo, column) {
    if (rowInfo) {
      const { deactivated } = rowInfo.original[column];
      if (deactivated) return { style: { color: '#b3b3b3' } };
    }
    return { style: { color: '#000000' } };
  }

  render() {
    const {
      response,
      data,
      pages,
      loading
    } = this.state;
    const { classes, location } = this.props;
    const columns = [
      {
        Header: "Nombre",
        accessor: "name",
        minWidth: 150,
        Cell: row => {
          const link = "/admin/customer/" + row.original.id;
          return (
            <strong>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {row.original.name}
              </a>
            </strong>
          );
        }
      },
      {
        Header: "Clabe",
        accessor: "clabe",
        minWidth: 130
      },
      {
        Header: "Teléfono",
        accessor: "phone_number",
        minWidth: 100,
        Cell: row => {
          const { phone } = row.original.phone_number;
          return phone;
        },
        getProps: (state, rowInfo) => {
          return this.getDeactivatedFont(rowInfo, 'phone_number');
        }
      },
      {
        Header: "Email",
        accessor: "email_address",
        minWidth: 140,
        Cell: row => {
          const { email } = row.original.email_address;
          return email;
        },
        getProps: (state, rowInfo) => {
          return this.getDeactivatedFont(rowInfo, 'email_address');
        }
      },
      {
        Header: "Creado",
        accessor: "created_at",
        minWidth: 150,
        Cell: row => <Moment utc>{row.original.created_at}</Moment>
      },
      {
        Header: "Curp",
        accessor: "curp",
        minWidth: 130
      },
      {
        Header: "Acciones",
        accessor: "actions",
        minWidth: 150,
        sortable: false,
        filterable: false,
        Cell: row => (
          <div className="actions-right">
            <Tooltip
              id="tooltip-top"
              title="Transferencias Globales"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <NavLink
                to={`/admin/global/transfers/${row.original.ledger_account_id}`}
              >
                <ListAlt className={classes.icon} />
              </NavLink>
            </Tooltip>
            <Tooltip
              id="tooltip-top"
              title="Transferencias Internas"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <NavLink to={`/admin/local/transfers/${row.original.clabe}`}>
                <SwapHorizontalCircle className={classes.icon} />
              </NavLink>
            </Tooltip>
            <Tooltip
              id="tooltip-top"
              title="Transacciones de Tarjeta"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <NavLink
                to={`/admin/card/transactions/${
                  row.original.user_id
                }`}
              >
                <CreditCard className={classes.icon} />
              </NavLink>
            </Tooltip>
            <Tooltip
              id="tooltip-top"
              title="Pagos de Servicios"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <NavLink
                to={`/admin/service/transactions/${
                  row.original.ledger_account_id
                }`}
              >
                <Opacity className={classes.icon} />
              </NavLink>
            </Tooltip>{" "}
          </div>
        )
      }
    ];

    if (response && response.status !== 200 && response.status !== 201) {
      processError(response.status, location.pathname, true);
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Lista de Clientes</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                manual
                data={data}
                columns={columns}
                pages={pages}
                defaultPageSize={20}
                loading={loading}
                filterable={true}
                defaultSorted={[{ id: "name", asc: true }]}
                onFetchData={state => this.onFetchData(state)}
                className="-highlight"
                noDataText="No se han encontrado resultados"
                getTdProps={(state, rowInfo, column) =>
                  this.pushCustomerDetail(rowInfo, column)
                }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Customers.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(Customers));
