const base64js = require("base64-js");

const transformCredentialCreateOptions = credentialCreateOptionsFromServer => {
  let { challenge, user } = credentialCreateOptionsFromServer;
  user.id = Uint8Array.from(
    atob(credentialCreateOptionsFromServer.user.id),
    c => c.charCodeAt(0)
  );

  challenge = Uint8Array.from(
    atob(credentialCreateOptionsFromServer.challenge),
    c => c.charCodeAt(0)
  );

  const transformedCredentialCreateOptions = Object.assign(
    {},
    credentialCreateOptionsFromServer,
    { challenge, user }
  );

  return transformedCredentialCreateOptions;
};

const transformNewAssertionForServer = newAssertion => {
  const attObj = new Uint8Array(newAssertion.response.attestationObject);
  const clientDataJSON = new Uint8Array(newAssertion.response.clientDataJSON);
  const rawId = new Uint8Array(newAssertion.rawId);

  const registrationClientExtensions = newAssertion.getClientExtensionResults();

  return {
    id: newAssertion.id,
    rawId: b64enc(rawId),
    type: newAssertion.type,
    attObj: b64enc(attObj),
    clientData: b64enc(clientDataJSON),
    registrationClientExtensions: JSON.stringify(registrationClientExtensions)
  };
};

const transformAssertionForServer = newAssertion => {
  const authData = new Uint8Array(newAssertion.response.authenticatorData);
  const clientDataJSON = new Uint8Array(newAssertion.response.clientDataJSON);
  const rawId = new Uint8Array(newAssertion.rawId);
  const sig = new Uint8Array(newAssertion.response.signature);
  const assertionClientExtensions = newAssertion.getClientExtensionResults();

  return {
    id: newAssertion.id,
    rawId: b64enc(rawId),
    type: newAssertion.type,
    authData: b64RawEnc(authData),
    clientData: b64RawEnc(clientDataJSON),
    signature: hexEncode(sig),
    assertionClientExtensions: JSON.stringify(assertionClientExtensions)
  };
};

const transformCredentialRequestOptions = credentialRequestOptionsFromServer => {
  let { challenge, allowCredentials } = credentialRequestOptionsFromServer;

  challenge = Uint8Array.from(atob(challenge), c => c.charCodeAt(0));

  allowCredentials = allowCredentials.map(credentialDescriptor => {
    let { id } = credentialDescriptor;
    id = id.replace(/\u005F/g, "/").replace(/\u002D/g, "+");
    id = Uint8Array.from(atob(id), c => c.charCodeAt(0));
    return Object.assign({}, credentialDescriptor, { id });
  });

  const transformedCredentialRequestOptions = Object.assign(
    {},
    credentialRequestOptionsFromServer,
    { challenge, allowCredentials }
  );

  return transformedCredentialRequestOptions;
};

function b64enc(buf) {
  return base64js
    .fromByteArray(buf)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}

function b64RawEnc(buf) {
  return base64js
    .fromByteArray(buf)
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
}

function hexEncode(buf) {
  return Array.from(buf)
    .map(function(x) {
      return ("0" + x.toString(16)).substr(-2);
    })
    .join("");
}

export async function credentialsCreate(credentialCreateOptionsFromServer) {
  if (credentialCreateOptionsFromServer) {
    const publicKeyCredentialCreateOptions = transformCredentialCreateOptions(
      credentialCreateOptionsFromServer
    );

    let credential;
    try {
      credential = await navigator.credentials.create({
        publicKey: publicKeyCredentialCreateOptions
      });
    } catch (err) {
      return { msg: "Error creating credential", err: err };
    }

    const newAssertionForServer = transformNewAssertionForServer(credential);

    return newAssertionForServer;
  }
}

export async function credentialsGet(credentialRequestOptionsFromServer) {
  const transformedCredentialRequestOptions = transformCredentialRequestOptions(
    credentialRequestOptionsFromServer
  );
  let assertion;
  try {
    assertion = await navigator.credentials.get({
      publicKey: transformedCredentialRequestOptions
    });
  } catch (err) {
    return { msg: "Error when creating credential", err: err };
  }

  const transformedAssertionForServer = transformAssertionForServer(assertion);

  return transformedAssertionForServer;
}
