export const paises = [
    {code: '101', name: 'ANGOLA' },
    {code: '102', name: 'ARGELIA' },
    {code: '104', name: 'BENIN' },
    {code: '105', name: 'BOTSWANA' },
    {code: '106', name: 'BURKINA FASO' },
    {code: '107', name: 'BURUNDI' },
    {code: '108', name: 'CABO VERDE' },
    {code: '109', name: 'CAMERUN' },
    {code: '112', name: 'RUANDA' },
    {code: '113', name: 'REPUBLICA PUPULAR DEL CONGO' },
    {code: '114', name: 'COSTA DE MARFIL' },
    {code: '115', name: 'CHAD' },
    {code: '116', name: 'DJIBOUTI' },
    {code: '117', name: 'EGIPTO' },
    {code: '118', name: 'ERITREA' },
    {code: '119', name: 'ETIOPIA' },
    {code: '120', name: 'GABON' },
    {code: '121', name: 'GAMBIA' },
    {code: '122', name: 'GHANA' },
    {code: '123', name: 'GUINEA' },
    {code: '124', name: 'GUINEA BISSAU' },
    {code: '125', name: 'GUINEA ECUATORIAL' },
    {code: '126', name: 'KENIA' },
    {code: '127', name: 'LESOTHO' },
    {code: '128', name: 'LIBERIA' },
    {code: '129', name: 'LIBIA' },
    {code: '130', name: 'MADAGASCAR' },
    {code: '133', name: 'MALAWI' },
    {code: '134', name: 'MALI' },
    {code: '135', name: 'MARRUECOS' },
    {code: '136', name: 'MAURICIO' },
    {code: '137', name: 'MAURITANIA' },
    {code: '138', name: 'ISLAS ANGLONORMANDAS' },
    {code: '139', name: 'MOZAMBIQUE' },
    {code: '140', name: 'NAMIBIA' },
    {code: '141', name: 'NIGER' },
    {code: '142', name: 'NIGERIA' },
    {code: '143', name: 'REPUBLICA CENTROAFRICANA' },
    {code: '145', name: 'SAN PEDRO Y MIQUELON' },
    {code: '147', name: 'ISLA SANTA HELENA' },
    {code: '148', name: 'SANTO TOME Y PRINCIPE' },
    {code: '149', name: 'SENEGAL' },
    {code: '150', name: 'SEYCHELLES' },
    {code: '151', name: 'SIERRA LEONA' },
    {code: '152', name: 'COMORAS' },
    {code: '153', name: 'SOMALIA' },
    {code: '154', name: 'SUDAFRICA' },
    {code: '160', name: 'SUAZILANDIA' },
    {code: '161', name: 'TANZANIA' },
    {code: '162', name: 'TOGO' },
    {code: '164', name: 'TUNEZ' },
    {code: '165', name: 'UGANDA' },
    {code: '167', name: 'ZAMBIA' },
    {code: '168', name: 'ZIMBABWE' },
    {code: '169', name: 'REPUBLICA DEMOCRATICA DEL CONGO' },
    {code: '170', name: 'REPUBLICA ARABE SAHARAUI DEMOCRATICA' },
    {code: '171', name: 'SUDAN' },
    {code: '172', name: 'SUDAN DEL SUR' },
    {code: '201', name: 'ANGUILA' },
    {code: '202', name: 'ANTIGUA Y BARBUDA' },
    {code: '204', name: 'ARGENTINA' },
    {code: '205', name: 'ARUBA' },
    {code: '206', name: 'BAHAMAS' },
    {code: '207', name: 'BARBADOS' },
    {code: '208', name: 'BELICE' },
    {code: '209', name: 'BERMUDAS' },
    {code: '210', name: 'BOLIVIA' },
    {code: '211', name: 'BRASIL' },
    {code: '212', name: 'ISLAS CAIMAN' },
    {code: '213', name: 'CANADA' },
    {code: '214', name: 'COLOMBIA' },
    {code: '215', name: 'COSTA RICA' },
    {code: '216', name: 'CUBA' },
    {code: '217', name: 'CHILE' },
    {code: '218', name: 'DOMINICA' },
    {code: '219', name: 'ECUADOR' },
    {code: '220', name: 'EL SALVADOR' },
    {code: '221', name: 'ESTADOS UNIDOS DE AMERICA' },
    {code: '222', name: 'GRANADA' },
    {code: '223', name: 'GROENLANDIA' },
    {code: '224', name: 'GUADALUPE' },
    {code: '225', name: 'GUATEMALA' },
    {code: '226', name: 'GUYANA' },
    {code: '227', name: 'GUAYANA FRANCESA' },
    {code: '228', name: 'HAITI' },
    {code: '229', name: 'HONDURAS' },
    {code: '230', name: 'JAMAICA' },
    {code: '231', name: 'MALVINAS' },
    {code: '233', name: 'ISLA DE MONTSERRAT' },
    {code: '234', name: 'NICARAGUA' },
    {code: '235', name: 'PANAMA' },
    {code: '236', name: 'PARAGUAY' },
    {code: '237', name: 'PERU' },
    {code: '238', name: 'PUERTO RICO' },
    {code: '239', name: 'REPUBLICA DOMINICANA' },
    {code: '240', name: 'SAN CRISTOBAL Y NIEVES' },
    {code: '241', name: 'ISLA DE MAN' },
    {code: '242', name: 'SAN VICENTE Y LAS GRANADINAS' },
    {code: '243', name: 'SANTA LUCIA' },
    {code: '244', name: 'SURINAM' },
    {code: '245', name: 'TRINIDAD Y TOBAGO' },
    {code: '246', name: 'ISLAS TURCAS Y CAICOS' },
    {code: '247', name: 'URUGUAY' },
    {code: '248', name: 'ISLAS VIRGENES DE LOS ESTADOS UNIDOS' },
    {code: '249', name: 'ISLAS VIRGENES BRITANICAS' },
    {code: '250', name: 'VENEZUELA' },
    {code: '251', name: 'CURAZAO' },
    {code: '252', name: 'ISLA DE SAINT MARTEEN' },
    {code: '253', name: 'BONAIRE, SAN EUSTAQUIO, SABA' },
    {code: '260', name: 'MEXICO' },
    {code: '301', name: 'AFGANISTAN' },
    {code: '302', name: 'ARABIA SAUDITA' },
    {code: '303', name: 'ARMENIA' },
    {code: '304', name: 'AZERBAIYAN' },
    {code: '305', name: 'BAHREIN' },
    {code: '306', name: 'BANGLADESH' },
    {code: '307', name: 'BHUTAN' },
    {code: '308', name: 'BRUNEI ' },
    {code: '309', name: 'CAMBOYA' },
    {code: '312', name: 'COREA DEL NORTE' },
    {code: '313', name: 'COREA DEL SUR' },
    {code: '315', name: 'CHINA' },
    {code: '316', name: 'TAIWAN' },
    {code: '318', name: 'CHIPRE' },
    {code: '321', name: 'EMIRATOS ARABES UNIDOS' },
    {code: '322', name: 'FILIPINAS' },
    {code: '323', name: 'GEORGIA' },
    {code: '325', name: 'INDIA' },
    {code: '326', name: 'INDONESIA' },
    {code: '327', name: 'IRAN' },
    {code: '328', name: 'IRAQ' },
    {code: '329', name: 'ISRAEL' },
    {code: '330', name: 'JAPON' },
    {code: '331', name: 'JORDANIA' },
    {code: '332', name: 'KAZAJSTAN' },
    {code: '333', name: 'KIRGUISTAN' },
    {code: '334', name: 'KUWAIT' },
    {code: '335', name: 'LIBANO' },
    {code: '337', name: 'MALASIA' },
    {code: '338', name: 'MALDIVAS' },
    {code: '339', name: 'MONGOLIA' },
    {code: '340', name: 'MYANMAR' },
    {code: '341', name: 'NEPAL' },
    {code: '342', name: 'OMAN' },
    {code: '343', name: 'PAKISTAN' },
    {code: '344', name: 'QATAR' },
    {code: '345', name: 'LAOS' },
    {code: '346', name: 'SINGAPUR' },
    {code: '347', name: 'SIRIA' },
    {code: '348', name: 'SRI LANKA' },
    {code: '349', name: 'TAILANDIA' },
    {code: '350', name: 'TAYIKISTAN' },
    {code: '351', name: 'TURKMENISTAN' },
    {code: '352', name: 'TURQUIA' },
    {code: '353', name: 'UZBEKISTAN' },
    {code: '354', name: 'VIETNAM' },
    {code: '355', name: 'YEMEN' },
    {code: '356', name: 'PALAOS' },
    {code: '357', name: 'TIMOR ORIENTAL' },
    {code: '358', name: 'PALESTINA' },
    {code: '401', name: 'ALBANIA' },
    {code: '402', name: 'ALEMANIA' },
    {code: '403', name: 'ANDORRA' },
    {code: '405', name: 'AUSTRIA' },
    {code: '407', name: 'BIELORRUSIA' },
    {code: '408', name: 'BELGICA' },
    {code: '409', name: 'BOSNIA Y HERZEGOVINA' },
    {code: '410', name: 'BULGARIA' },
    {code: '411', name: 'CROACIA' },
    {code: '412', name: 'DINAMARCA' },
    {code: '413', name: 'ESLOVAQUIA' },
    {code: '414', name: 'ESLOVENIA' },
    {code: '415', name: 'ESPAÑA' },
    {code: '416', name: 'ESTONIA' },
    {code: '417', name: 'ISLAS FEROE' },
    {code: '418', name: 'FINLANDIA' },
    {code: '419', name: 'FRANCIA' },
    {code: '420', name: 'GIBRALTAR' },
    {code: '421', name: 'GRECIA' },
    {code: '422', name: 'HUNGRIA' },
    {code: '423', name: 'IRLANDA' },
    {code: '424', name: 'ISLANDIA' },
    {code: '425', name: 'ITALIANA' },
    {code: '426', name: 'LETONIA' },
    {code: '427', name: 'LIECHTENSTEIN' },
    {code: '428', name: 'LITUANIA' },
    {code: '429', name: 'LUXEMBURGO' },
    {code: '430', name: 'MACEDONIA' },
    {code: '431', name: 'MALTA' },
    {code: '433', name: 'MOLDAVIA' },
    {code: '434', name: 'MONACO' },
    {code: '435', name: 'NORUEGA' },
    {code: '436', name: 'PAISES BAJOS' },
    {code: '437', name: 'POLONIA' },
    {code: '438', name: 'PORTUGAL' },
    {code: '439', name: 'REINO UNIDO DE GRAN BRETAÑA E IRLANDA' },
    {code: '440', name: 'CHEQUIA' },
    {code: '441', name: 'RUMANIA' },
    {code: '442', name: 'RUSIA' },
    {code: '443', name: 'SAN MARINO' },
    {code: '444', name: 'ESTADO DE LA CIUDAD DEL VATICANO' },
    {code: '445', name: 'SUECIA' },
    {code: '446', name: 'SUIZA' },
    {code: '447', name: 'UCRANIA' },
    {code: '452', name: 'SERBIA' },
    {code: '453', name: 'MONTENEGRO' },
    {code: '501', name: 'AUSTRALIA' },
    {code: '503', name: 'ISLAS COOK' },
    {code: '505', name: 'GUAM' },
    {code: '507', name: 'KIRIBATI' },
    {code: '512', name: 'ISLAS MARIANAS DEL NORTE' },
    {code: '513', name: 'LAS ISLAS MARSHALL' },
    {code: '514', name: 'MICRONESIA' },
    {code: '516', name: 'NAURU' },
    {code: '517', name: 'NIUE' },
    {code: '520', name: 'NUEVA ZELANDA' },
    {code: '522', name: 'ISLA WAKE' },
    {code: '523', name: 'PAPUA NUEVA GUINEA' },
    {code: '524', name: 'ISLAS PITCAIRN' },
    {code: '526', name: 'ISLAS SALOMON' },
    {code: '527', name: 'SAMOA' },
    {code: '528', name: 'SAMOA AMERICANA' },
    {code: '530', name: 'TOKELAU' },
    {code: '531', name: 'TONGA' },
    {code: '532', name: 'TUVALU' },
    {code: '533', name: 'VANUATU' },
    {code: '534', name: 'WALLIS Y FUTUNA' },
    {code: '535', name: 'LAS ISLAS FIJI' },
];
  
  
export const entidadesFederativas = [
    {code: '01', name: 'AGUASCALIENTES'},
    {code: '02', name: 'BAJA CALIFORNIA'},
    {code: '03', name: 'BAJA CALIFORNIA SUR'},
    {code: '04', name: 'CAMPECHE'},
    {code: '05', name: 'COAHUILA DE ZARAGOZA'},
    {code: '06', name: 'COLIMA'},
    {code: '07', name: 'CHIAPAS'},
    {code: '08', name: 'CHIHUAHUA'},
    {code: '09', name: 'CIUDAD DE MÉXICO'},
    {code: '10', name: 'DURANGO'},
    {code: '11', name: 'GUANAJUATO'},
    {code: '12', name: 'GUERRERO'},
    {code: '13', name: 'HIDALGO'},
    {code: '14', name: 'JALISCO'},
    {code: '15', name: 'MÉXICO'},
    {code: '16', name: 'MICHOACÁN DE OCAMPO'},
    {code: '17', name: 'MORELOS'},
    {code: '18', name: 'NAYARIT'},
    {code: '19', name: 'NUEVO LEÓN'},
    {code: '20', name: 'OAXACA'},
    {code: '21', name: 'PUEBLA'},
    {code: '22', name: 'QUERÉTARO'},
    {code: '23', name: 'QUINTANA ROO'},
    {code: '24', name: 'SAN LUIS POTOSÍ'},
    {code: '25', name: 'SINALOA'},
    {code: '26', name: 'SONORA'},
    {code: '27', name: 'TABASCO'},
    {code: '28', name: 'TAMAULIPAS'},
    {code: '29', name: 'TLAXCALA'},
    {code: '30', name: 'VERACRUZ DE IGNACIO DE LA LLAVE'},
    {code: '31', name: 'YUCATÁN'},
    {code: '32', name: 'ZACATECAS'},
];

export const instrumentosMonentarios = [
    {code: '01', name: 'EFECTIVO'},
    {code: '02', name: 'TRANSFERENCIA INTERBANCARIA RECIBIDA'},
    {code: '03', name: 'TRANSFERENCIA INTERBANCARIA ENVIADA'},
    {code: '04', name: 'TRANSFERENCIA INTERNACIONAL RECIBIDA'},
    {code: '05', name: 'TRANSFERENCIA INTERNACIONAL ENVIADA'},
    {code: '06', name: 'FONDOS DE PAGO ELECTRÓNICO'},
    {code: '00', name: 'SE DESCONOCE (TENTATIVA)'},
    {code: '50', name: 'SE REPORTA ACTO O CONDUCTA DEL CLIENTE, NO EXISTE OPERACION RELACIONADA'},
];

export const tiposOperacion = [
    {code: '01', name: 'ADMINISTRACIÓN DE CUENTAS'},
    {code: '02', name: 'RECEPCIÓN DE RECURSOS'},
    {code: '03', name: 'ENVÍO DE RECURSOS'},
    {code: '04', name: 'TRANSFERENCIAS ENVIADAS (OPERACIONES ENTRE CLIENTES)'},
    {code: '05', name: 'TRANSFERENCIAS RECIBIDAS (OPERACIONES ENTRE CLIENTES)'},
];

export const prioridadesDeReporte = [
    {code: '01', name: 'ALTA - REPORTE DE OPERATIVA'},
    {code: '02', name: 'SIN PRIORIDAD - REPORTE DE OPERATIVA'},
    {code: '03', name: 'EXCEPCIÓN AL REPORTE DE OPERATIVA'},
    {code: '04', name: 'TENTATIVA'},
    {code: '05', name: '24 HORAS'},
    {code: '06', name: '24 HORAS - LPB (SUJETO DESIGNADO)'},
    {code: '07', name: '24 HORAS - LPB (SUJETO RELACIONADO)'},
    {code: '08', name: '24 HORAS - SUSPENSIÓN DEL PROCESO DE RATIFICACIÓN'},
];

export const tiposPersonas = [
    {code: '1', name: 'FÍSICA'},
    {code: '2', name: 'MORAL'},
];


export const regimenes = [
    {code: '0', name: 'NORMAL'},
    {code: '1', name: 'SIMPLIFICADO'}
];