import React from "react";
import { withRouter } from 'react-router';

import ReactTable from "react-table";

import PropTypes from "prop-types";

import swal from "@sweetalert/with-react";

import Moment from "react-moment";

// @material-ui/core components
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";

import AutoRenew from "@material-ui/icons/Autorenew";
import Cancel from "@material-ui/icons/Cancel";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Send from "@material-ui/icons/Send";

// core components
import Button from "components/CustomButtons";
import Card from "components/Card";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import { processError } from 'components/ErrorBoundary';

import { PaymentTypeIcon } from 'common/utils.jsx';

import regularFormsStyle from "assets/jss/omni/views/regularFormsStyle";

import { cardTitle, tooltip } from "assets/jss/omni.jsx";

import {
  getGaitaOrdersOrdered,
  patchGaitaOrdersFinalized,
  patchGaitaOrdersOrdered,
  getOrderGuide
} from "providers/api-rest.js";

import { getReferredColor } from "common/utils.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  regularFormsStyle,
  tooltip,
  icon: {
    marginLeft: "10%",
    fontSize: "25px"
  },
  search: {
    position: "absolute",
    right: 0,
    top: "10px",
    padding: 0,
    margin: 0,
    width: "30%"
  },
  updateTable: {
    position: "absolute",
    top: "10px",
    padding: 0,
    margin: 0,
    width: "30%"
  },
  updatedCursor: {
    cursor: "pointer"
  }
};

class GaitaOrderForeignFinished extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      interval: null,
      search: null,
      lastState: null
    };
    this.provider = props.provider;
  }

  componentWillReceiveProps = props => {
    if (props.neworder) {
      this.onFetchDataReload();
    }
  };

  async onFetchData(state) {
    this.setState({
      loading: true,
      lastState: state
    });
    var order_type;
    if (this.provider === "ivoy_nextday") {
      order_type = "local_next_day";
    } else {
      order_type = "domestic";
    }
    const params = {
      page: state.page + 1,
      page_size: state.pageSize,
      search: state.search,
      order_type: order_type,
      order_status: "ordered,in_process"
    };
    const response = await getGaitaOrdersOrdered(params);
    this.setState({
      data:
        response.data && response.data.orders_groups
          ? response.data.orders_groups
          : [],
      loading: false,
      pages: response.data ? response.data.pages : 1,
      response: response
    });
    clearInterval(this.interval);
  }

  onFetchDataReload() {
    this.setState({ loading: true });
    this.onFetchData(this.state.lastState);
  }

  onKeyPressSearch(search) {
    let lastState = this.state.lastState;
    lastState["search"] = search;
    this.setState({ lastState: lastState });
    this.onFetchDataReload();
  }

  async cancelGaitaOrdersOrdered(order_id) {
    this.setState({ loading: true });
    const response = await patchGaitaOrdersOrdered({ order_id });
    if (response.status === 200) {
      this.onFetchDataReload();
    } else {
      alert("Ocurrio un error inesperado, intente de nuevo.");
    }
  }

  async finalizeOrder(order_id) {
    const response = await patchGaitaOrdersFinalized(order_id);
    if (response.status === 200) {
      this.onFetchDataReload();
    } else {
      alert(
        `Ocurrio un error inesperado, contacta a ingeniería con ID de orden: ${order_id}`
      );
    }
  }

  getQuoteLocal(group) {
    return group["options"][0];
  }

  async handleClickDownloadGuide(printed, agent, s3_url, provider_id) {
    let download_it = false;
    if (printed === true) {
      let message;
      if (agent) {
        message = "Esta guia fue descargada por ".concat(
          agent["name"],
          ". Deseas descargarla de nuevo?"
        );
      } else {
        message = "Esta guia fue descargada. Deseas descargarla de nuevo?";
      }
      await swal(message, {
        title: "Accion requerida",
        buttons: ["No", "Si"]
      }).then(willGroup => {
        if (willGroup) {
          download_it = true;
        } else {
          return;
        }
      });
    }
    if (printed === false || download_it === true) {
      var file_split = s3_url.split("/");
      let filename = file_split.pop();
      let bucket = file_split.pop();
      const timestamp = Date.now();

      const params = {
        filename: filename,
        bucket: bucket,
        provider_id: provider_id,
        timestamp: timestamp
      };

      const response = await getOrderGuide(params);
      if (response.status === 200) {
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.setAttribute("download", filename);
        link.href = response.data;
        link.click();
      } else {
        alert("Guia no encontrada, contacte a ingeniería");
      }
    }
  }

  getTrProps = (state, rowInfo) => {
    if (!rowInfo) return {};
    const { data } = this.state;
    const { original } = rowInfo;
    const orderData = data.find(order => order.group_id === original.group_id);
    const isReplacement = orderData.orders.reduce(
      (replacement, order) => replacement || order.is_replacement,
      false,
    );

    const color = getReferredColor(original.referred);

    return {
      style: {
        backgroundColor: isReplacement ? '#b3dcff' : color,
      }
    };
  }

  render() {
    const { classes, location } = this.props;
    const { response } = this.state;
    const columns = [
      {
        Header: "Guia ID",
        accessor: "order_id",
        minWidth: 70,
        filterable: false,
        Cell: row => {
          const { order_detail, payment_types } = row.original;
          return (
            <>
              <span><b>{order_detail.order_id}</b></span>
              {payment_types && payment_types.map(type => 
                <PaymentTypeIcon
                  id={`goff-${type}`}
                  type={type}
                  classes={classes}
                  size='small'
                />
              )}
            </>
          )
        }
      },
      {
        Header: "Entregas",
        accessor: "customer_orders",
        minWidth: 40,
        filterable: false,
        Cell: row => <span>{row.original.orders_count} Entregas</span>
      },
      {
        Header: "Estado",
        accessor: "statusText",
        minWidth: 80,
        filterable: false,
        Cell: row => <span>{row.original["order_detail"]["status"]}</span>
      },
      {
        Header: "Enlace de rastreo",
        accessor: "tracking_url",
        minWidth: 250,
        filterable: false,
        Cell: row => {
          if (row.original["order_detail"]) {
            return (
              <a
                href={row.original["order_detail"]["tracking_link"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                {row.original["order_detail"]["tracking_link"]}
              </a>
            );
          } else {
            return <p>Enlace de rastreo no disponible en este momento</p>;
          }
        }
      },
      {
        Header: "Enlace",
        accessor: "donwload_guie",
        minWidth: 100,
        filterable: false,
        Cell: row => (
          <Button
            size="sm"
            color={row.original["printed"] === true ? "default" : "primary"}
            round
            onClick={() =>
              this.handleClickDownloadGuide(
                row.original["printed"],
                row.original["last_agent"],
                row.original["order_detail"]["guide_link"],
                row.original["order_detail"]["order_id"]
              )
            }
          >
            Descargar Guia&nbsp;&nbsp; <CloudDownload />
          </Button>
        )
      },
      {
        Header: "Agente",
        accessor: "agent_name",
        minWidth: 70,
        filterable: false,
        Cell: row => <span>{row.original.agent["name"]}</span>
      },
      {
        Header: "Fecha de Creacion",
        accessor: "created_at",
        minWidth: 130,
        filterable: false,
        Cell: row => {
          const created_at = row.original.created_at.split(".")[0];
          return (
            <div className="actions-right">
              <Moment utc>{created_at}</Moment>
            </div>
          );
        }
      }
    ];
    if (this.provider === "ivoy_nextday") {
      columns.push({
        Header: "Acciones",
        minWidth: 50,
        filterable: false,
        Cell: row => {
          return (
            <div className="actions-right">
              {row.original.status === "ordered" &&
              this.provider === "ivoy_nextday" ? (
                <Tooltip
                  id="tooltip-top"
                  title="Eliminar Paquete"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <span
                    onClick={() =>
                      this.cancelGaitaOrdersOrdered(row.original.id)
                    }
                  >
                    <Cancel color="secondary" className={classes.icon} />
                  </span>
                </Tooltip>
              ) : null}
            </div>
          );
        }
      });
    }
    const subColumns = [
      {
        accessor: 'payment_type',
        minWidth: 30,
        Cell: row => (
          <PaymentTypeIcon
            type={row.original.payment_type}
            classes={classes}
          />
        )
      },
      {
        Header: "Cliente",
        accessor: "name"
      },
      {
        Header: "Telefono",
        accessor: "telephone"
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Fecha de Pedido",
        accessor: "created_at",
        minWidth: 130,
        Cell: row => {
          const created_at = row.original.created_at.split(".")[0];
          return <Moment utc>{created_at}</Moment>;
        }
      },
      {
        Header: "Direccion",
        accessor: "full_address",
        minWidth: 300,
        Cell: row => (
          <span>
            {row.original.full_address} INTERIOR: {row.original.int_number}{" "}
            CODIGO POSTAL: {row.original.zip_code}
          </span>
        )
      },
      {
        Header: "Comentario",
        accessor: "comment",
        minWidth: 100
      },
      {
        Header: "Estado",
        accessor: "status",
        minWidth: 80
      }
    ];

    if (response && response.status !== 200 && response.status !== 201) {
      processError(
        response.status,
        location.pathname,
        false,
        (
          (response.data && (response.data.error || response.data.message))
          || `Listado órdenes foráneas ${this.provider} finalizadas de Gaita, contacta a ingeniería`
        )
      );
    }

    const provider = this.provider;
    let title;
    if (provider === "ivoy_nextday") {
      title = "Ordenes NextDay Realizadas";
    } else {
      title = "Ordenes Foraneas Realizadas";
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Send />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {title}
                <span
                  className={classes.updatedCursor}
                  onClick={() => this.onFetchDataReload()}
                >
                  <strong> |</strong> Actualizar
                  <span className={classes.updateTable}>
                    <AutoRenew />
                  </span>
                </span>
                <CustomInput
                  className={classes.search}
                  formControlProps={{
                    className: classes.top + " " + classes.search
                  }}
                  inputProps={{
                    placeholder: "Buscar (User Id) (Teléfono) (Email)",
                    inputProps: {
                      "aria-label": "Buscar",
                      className: classes.searchInput
                    },
                    onKeyPress: event => {
                      if (event.key === "Enter") {
                        this.onKeyPressSearch(event.target.value);
                      }
                    }
                  }}
                />
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                manual
                data={this.state.data}
                columns={columns}
                pages={this.state.pages}
                defaultPageSize={7}
                loading={this.state.loading}
                filterable={true}
                sortable={false}
                onFetchData={state => this.onFetchData(state)}
                getTrProps={this.getTrProps}
                className="-highlight"
                noDataText="No se han encontrado resultados"
                showPageSizeOptions={false}
                SubComponent={row => {
                  return (
                    <div
                      style={{
                        paddingLeft: "50px"
                      }}
                    >
                      <hr
                        style={{
                          marginBottom: "0px",
                          marginTop: "5px"
                        }}
                      />
                      <ReactTable
                        data={row.original.orders}
                        columns={subColumns}
                        defaultPageSize={row.original.orders.length}
                        showPagination={false}
                        sortable={false}
                      />
                    </div>
                  );
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

GaitaOrderForeignFinished.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  provider: PropTypes.string.isRequired,
  neworder: PropTypes.bool
};

export default withRouter(withStyles(styles)(GaitaOrderForeignFinished));
