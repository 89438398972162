import { codigoRegimenFiscal } from '../src/types';

export function descriptionFiscalRegimeCode(codigo) {
    const descripcion = codigoRegimenFiscal[codigo];
    return descripcion || 'Sin Regimen Fiscal';
}

export function codeToDescription(descripcion) {
    const codigo = Object.keys(codigoRegimenFiscal).find(key => codigoRegimenFiscal[key] === descripcion);
    return codigo || 'Sin Regimen Fiscal';
}
