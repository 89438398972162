import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';
import Moment from 'react-moment';

// @material-ui/core components
import { withStyles } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';

// core components
import swal from '@sweetalert/with-react';
import Swal from 'sweetalert2';
import Button from 'components/CustomButtons';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem.jsx';
import Table from 'components/Table';
import MovementDetail from 'components/MovementDetail';

import Utils from 'common/utils';

import {
  expireCardTransaction,
  getCardTransaction,
  getCardTransactionRelated,
  applyRefund,
  cashbackAtmFeeTransaction,
  updateChargebackTransaction,
  chargebackTransaction
} from 'providers/api-rest';

const styles = {
  accorWidth: {
    width: '100%',
  },
  related: {
    '&:hover': { cursor: 'pointer', }
  },
  logData: { 
    fontSize: '12px',
    fontWeight: 900,
    color: '#666'
  },
  merchantName: {
    fontSize: '18px',
  },
  selectStatus: {
    fontSize: '15px',
    height: '30px',
    width: '200px',
    background: 'white'
  },
};

function CardTransactionDetail({ classes, match }) {
  const [transaction, setTransaction] = useState(null);
  const [relatedData, setRelated] = useState(null);

  const getTransactionInfo = async transactionId => {
    const response = await getCardTransaction(transactionId);

    if (response.status === 200)
      setTransaction(response.data.result);
  }

  const getTransactionRelated = async transactionId => {
    const response = await getCardTransactionRelated(transactionId);

    if (response.status === 200)
      setRelated(response.data.result);
  }

  const checkIfExpire = () => {
    if (!relatedData || !relatedData.length)
      return true;
    
    const avoidStatus = ['capture', 'void'];
    for (const related of relatedData) {
      if (avoidStatus.includes(related.related_type))
        return false;
    }
  }

  const handleExpire = async transaction => {
    const message = `Al expirar la transacción se reembolsará el monto de la transacción al cliente.
    No puede revertirse la acción, ¿quieres realizar de todos modos?`;

    const confirm = await swal(message, {
      title: 'Confirmar',
      buttons: ['Cancelar', 'Confirmar'],
    });

    if (confirm) {
      const response = await expireCardTransaction({
        card_transaction_id: transaction.id,
      });

      if (response.status === 201) {
        swal('Excelente!', 'Acción realizada exitosamente', 'success');
      } else {
        const errorMessage = response['data'] && response['data']['error'] 
          ? response['data']['error']
          : 'Error inesperado, contacta a ingeniería';

        swal('Lo siento 😓', errorMessage, 'error');
      }
    }
  }
  const handleCashbackAtmFee = async transaction => {
    const message = `Se reembolsará la comisión cobrada por el cajero.
    No puede revertirse la acción, ¿quieres realizar de todos modos?`;

    const confirm = await swal(message, {
      title: 'Confirmar',
      buttons: ['Cancelar', 'Confirmar'],
    });

    if (confirm) {
      const response = await cashbackAtmFeeTransaction({
        cardTransactionId: transaction.id,
      });

      if (response.status === 201) {
        swal('Excelente!', 'Acción realizada exitosamente', 'success');
      } else {
        const errorMessage = response.data && response.data.status
          ? response.data.status
          : 'Error inesperado, contacta a ingeniería';
        swal('Lo siento 😓', errorMessage, 'error');
      }
    }
  }
   const handleApplyChargeback = async transaction => {
    const charge = transaction
    const htmlBody = `
    <p for="input_reference"> Ingrese la referencia de transaccion presente en el archivo 510. 
    Valor sugerido: ${charge['transaction_reference']}</p>
    <input
      id="input_reference"
      type="text"
      class="swal2-input"
      placeholder= "Transaction reference"
    >
    <p for="input_amount"> Ingrese el monto a rembolsar. 
    Debe ser menor o igual a ${charge['amount']/100}</p>
    <input
      id="input_amount"
      type="number"
      class="swal2-input"
      placeholder= "Monto"
      min="0"
    >
  `;
  const { value: data } = await Swal.fire({
    title: 'Aplicar contracargo',
    html: htmlBody,
    focusConfirm: false,
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    preConfirm: () => {
      return {
        transactionReference: document.getElementById('input_reference').value,
        amount: parseFloat(document.getElementById('input_amount').value)*100,
      };
    }
    });
    if (data) {
      const {transactionReference, amount} = data
      const response = await chargebackTransaction({
        cardTransactionId: transaction.id,
        transaction_reference: transactionReference || null,
        amount: amount || null,
      });
      if (response.status === 201) {
        swal('Excelente!', 'El contracargo se vera reflejado en unos momentos', 'success');
      } else {
        const errorMessage = response.data && response.data.status
          ? response.data.status
          : 'Error inesperado, contacta a ingeniería';
        swal('Lo siento 😓', errorMessage, 'error');
      }
    }
  }
  const handleUpdateChargeback = async (transaction,status) => {
    let message = status === 'completed'?
    `Un contracargo ganado abona recursos a CUENCA desde el merchant account.`
    :`Se le cobrará de nuevo el monto del cargo al cliente.`
    message += `\nNo puede revertirse la acción, ¿quieres realizar de todos modos?`
    const confirm = await swal(message, {
      title: 'Actualizar estado del contracargo',
      buttons: ['Cancelar', 'Confirmar'],
    });

    if (confirm) {
      const response = await updateChargebackTransaction({
        cardTransactionId: transaction.id,
        transaction_status: status
      });

      if (response.status === 201) {
        swal('Excelente!', 'Acción realizada exitosamente', 'success');
      } else {
        const errorMessage = response.data && response.data.status
          ? response.data.status
          : 'Error inesperado, contacta a ingeniería';
        swal('Lo siento 😓', errorMessage, 'error');
      }
    }
  }

  const handleApplyRefund = async transaction => {
    const auth = relatedData.find(t=>t.related_type==='auth')
    const msgParent= auth ? ` El parent_id sugerido es ${auth['related_id']}`:''
    const message = `Ingrese el parent_id del reembolso (si existe) y presione continuar.
    ${msgParent}`;

    const parentId = await swal(message, {
      title: 'Confirmar Reembolso',
      content: {
        element: "input",
        attributes: {
          placeholder: "Ingrese el parent_id (si existe)",
          type: "text",
        }
      },
      buttons: ['Cancelar', 'Confirmar'],
    });
    if (parentId!= null) {
      const response = await applyRefund({
        card_transaction_id: transaction.id,
        parent_id: parentId
      });

      if (response.status === 201) {
        swal('Excelente!', 'El reembolso se vera reflejado en unos momentos', 'success');
      } else {
        const msg = response['data']['error'] || 'Error inesperado, contacta a ingeniería';
        swal('Lo siento 😓', msg, 'error');
      }
    }
  }
  const pushCardTransaction = cardTransaction =>
    window.open(`/admin/card/transaction/${encodeURIComponent(cardTransaction)}`);

  useEffect(() => {
    const { transactionId } = match.params;
    getTransactionInfo(transactionId);
    getTransactionRelated(transactionId);
  }, []);

  if (!transaction) return null;

  // Format data to display
  const human = {
    name: transaction.name,
    id: transaction.human_id,
  };

  const topData = [
    (
      <h4 className={classes.merchantName}>
        <strong>{transaction.merchant_name}</strong>
      </h4>
    ),
    (
      <h3>
        <strong>{`${transaction.type.toUpperCase()}: `}</strong>
        {Utils.formatPrice(transaction.amount)}
      </h3>
    ),
    (
      <GridContainer>

        <GridItem xs={12} sm={12} md={4}>
          <small><strong>Fecha de Creación</strong></small>
          <br />
          <small>
            <Moment utc>{transaction.created_at}</Moment>
          </small>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <small><strong>Tipo</strong></small>
          <br />
          <small>{transaction.type.toUpperCase()}</small>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <small><strong>Estado</strong></small>
          <br />
          <small>{transaction.status.toUpperCase()}</small>
        </GridItem>

      </GridContainer>
    ),
  ];

  if (transaction.prosa_transaction_id) {
    topData.push((
      <small>
        <strong>PROSA ID: </strong>
        {transaction.prosa_transaction_id}
      </small>
    ));
  }

  if (transaction.authorizer_number) {
    topData.push((
      <small>
        <strong>AUTORIZACIÓN ID: </strong>
        {transaction.authorizer_number}
      </small>
    ));
  }

  topData.push((
    <small>
      <strong>TARJETA USADA: </strong>
      {`************${transaction.card_last4}`}
    </small>
  ));

  topData.push((
    <small>
      <strong>CARD ID: </strong>
      {transaction.card_id}
    </small>
  ));

  const status = transaction.status;
  const mainData = [
    (
      <>
        <strong>TARJETA: </strong>
        {transaction.card_type.toUpperCase()}
      </>
    ),
    (
      <>
        <strong>CARD ISSUER: </strong>
        {transaction.card.issuer.toUpperCase()}
      </>
    ),
    (
      <>
        <strong>CARD FUNDING TYPE: </strong>
        {transaction.card.funding_type.toUpperCase()}
      </>
    ),
  ];

  if (transaction.track_data_method) {
    mainData.push((
      <>
        <strong>MÉTODO: </strong>
        {transaction.track_data_method}
      </>
    ));
  }

  if (transaction.pos_capability) {
    mainData.push((
      <>
        <strong>CAPACIDAD TERMINAL: </strong>
        {transaction.pos_capability}
      </>
    ));
  }

  if (transaction.card_acceptor) {
    mainData.push((
      <>
        <strong>CARD ACCEPTOR: </strong>
        {transaction.card_acceptor}
      </>
    ));
  }

  if (transaction.commission) {
    mainData.push((
      <>
        <strong>COMMISSION: </strong>
        {transaction.commission}
      </>
    ));
  }

  if (transaction.currency_code) {
    mainData.push((
      <>
        <strong>CURRENCY: </strong>
        {transaction.currency_code}
      </>
    ));
  }
  
  if (transaction.retrieval_reference) {
    mainData.push((
      <>
        <strong>RETRIEVAL REFERENCE: </strong>
        {transaction.retrieval_reference}
      </>
    ));
  }
  if (transaction.cardholder_verification_method) {
    mainData.push((
      <>
        <strong>MÉTODO DE IDENTIFICACIÓN: </strong>
        {transaction.cardholder_verification_method}
      </>
    ));
  }
  if (transaction.transaction_reference) {
    mainData.push((
      <>
        <strong>TRANSACTION REFERENCE: </strong>
        {transaction.transaction_reference}
      </>
    ));
  }
  
  if (transaction.is_cvv) {
    mainData.push((
      <strong>IS CVV</strong>
    ));
  }
  
  if (transaction.error_type) {
    mainData.push((
      <h4>
        <strong>
          {`TIPO DE ERROR: (${transaction.error_type})`}
        </strong>
      </h4>
    ));
  }

  if (transaction.atm_fee) {
    mainData.push((
      <>
        <strong>COMISIÓN ATM: </strong>
        {Utils.formatPrice(transaction.atm_fee)}
      </>
    ));
    if (transaction.type === 'auth' && status === 'succeeded') {
      mainData.push(
        <div>
          <Button
            round
            color="reddit"
            onClick={() => handleCashbackAtmFee(transaction)}
          >
            Reembolsar Comisión ATM
          </Button>
        </div>
      );
    }
  }

  if (
    transaction.type === 'auth'
    && status === 'succeeded'
    && checkIfExpire()
  ) {
    mainData.push(
      <div>
        <Button
          round
          color="reddit"
          onClick={() => handleExpire(transaction)}
        >
          Expirar Transacción
        </Button>
      </div>
    );
  }
  if (
    transaction.type === 'refund'
    && status === 'failed'
  ) {
    mainData.push(
      <div>
        <Button
          round
          color="reddit"
          onClick={() => handleApplyRefund(transaction)}
        >
          Aplicar reembolso
        </Button>
      </div>
    );
  }
   if (
    transaction.type === 'capture'
    && status === 'succeeded'
  ) {
    mainData.push(
      <div>
        <Button
          round
          color="reddit"
          onClick={() => handleApplyChargeback(transaction)}
        >
          Aplicar contracargo
        </Button>
      </div>
    );
  }
  if (
    transaction.type === 'chargeback'
    && status === 'succeeded'
  ) {
    mainData.push(
      <div>
        <Button
          round
          color="success"
          onClick={() => handleUpdateChargeback(transaction,'completed')}
        >
          Marcar como ganado
        </Button>
        <Button
          round
          color="reddit"
          onClick={() => handleUpdateChargeback(transaction,'failed')}
        >
            Cobrar de nuevo
        </Button>
      </div>
    );
  }


  const accordionCollapses = [];
  if (relatedData && relatedData.length) {
    let related_table= [[
      <span className={classes.logData}>FECHA</span>,
      <span className={classes.logData}>MONTO</span>,
      <span className={classes.logData}>TIPO</span>,
      <span className={classes.logData}>ESTATUS</span>,
    ]];

    related_table = related_table.concat(
      relatedData.map(r => [
        <Moment utc>{r.related_created_at}</Moment>,
        Utils.formatPrice(r.related_amount),
        r.related_type.toUpperCase(),
        r.related_status.toUpperCase(),
        (
          <span
            className={classes.related}
            onClick={() => pushCardTransaction(r.related_id)}
          >
            <LaunchIcon />
          </span>
        ),
      ])
    );

    accordionCollapses.push({
      title: 'TRANSACCIONES RELACIONADAS',
      content: (
        <div className={classes.accorWidth}>
          <Table tableData={related_table} />
        </div>
      )
    });
  }

  return (
    <GridContainer className="container">
      <GridItem xs={12}>
        <MovementDetail
          human={human}
          topData={topData}
          mainData={mainData}
          status={status}
          accordionData={accordionCollapses}
          enableCopyData={true}
          extraCopyData={{
            linkInfo: `https://omni.cuenca.io/admin/card/transaction/${transaction.id}`,
          }}
        />
      </GridItem>
    </GridContainer>
  );
}

CardTransactionDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(
  withStyles(styles)(CardTransactionDetail)
);
