import React from "react";

import PropTypes from "prop-types";

import moment from "moment";
import swal from "@sweetalert/with-react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/icons
import Receipt from "@material-ui/icons/Receipt";

// core components
import Card from 'components/Card';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CustomButton from 'components/CustomButtons';
import CustomInput from 'components/CustomInput';
import DateInput from 'components/DateInput';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem.jsx';

import { cardTitle, tooltip } from "assets/jss/omni.jsx";

import { postReconMissingTransfer } from "providers/api-rest.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  resultContainer: {
    marginTop: "45px"
  },
  messageHeader: {
    fontSize: "14px",
    color: "black"
  },
  select: {
    width: "250px",
    height: "45px"
  },
  label: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "12px",
    transition: "0.3s ease all",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingLeft: "0"
  },
  tooltip
};

const TRANSFER_STATUS = {
  NOT_ON_STP: 0,
  RECONCILIATION_MADE: 1,
  RECONCILIATION_NOT_NEEDED: 2,
  STP_DOWN: 3
};

const BANK_NAMES = {
  "ABC Capital": "40138",
  "Accendo Banco": "40102",
  Accival: "90614",
  Actinver: "40133",
  Afirme: "40062",
  Akala: "90638",
  "American Express": "40103",
  Asea: "90652",
  "Asp Integra Opc": "90659",
  Autofin: "40128",
  Axa: "90674",
  Azteca: "40127",
  Bajio: "40030",
  Banamex: "40002",
  "Banco Famsa": "40131",
  "Banco Finterra": "40154",
  Bancomext: "37006",
  Bancoppel: "40137",
  "Banco S3": "40160",
  Bancrea: "40152",
  Banjercito: "37019",
  Bankaool: "40147",
  "Bank Of America": "40106",
  Banobras: "37009",
  "Banorte/Ixe": "40072",
  Banregio: "40058",
  Bansefi: "37166",
  Bansi: "40060",
  Banxico: "2001",
  Barclays: "40129",
  Bbase: "40145",
  "BBVA Bancomer": "40012",
  Bmonex: "40112",
  "Caja Pop Mexica": "90677",
  "Caja Telefonist": "90683",
  "CB Intercam": "90630",
  CIbanco: "40143",
  "CI Bolsa": "90631",
  Cls: "90901",
  "CoDi Valida": "90903",
  Compartamos: "40130",
  Consubanco: "40140",
  "Credit Suisse": "40126",
  "Cristobal Colon": "90680",
  Deutsche: "40124",
  Donde: "40151",
  Estructuradores: "90606",
  Evercore: "90648",
  Finamex: "90616",
  Fincomun: "90634",
  Fnd: "90679",
  Fomped: "90689",
  "Fondo (Fira)": "90685",
  Gbm: "90601",
  "Hdi Seguros": "90636",
  "Hipotecaria Fed": "37168",
  HSBC: "40021",
  Icbc: "40155",
  Inbursa: "40036",
  Indeval: "90902",
  Inmobiliario: "40150",
  "Intercam Banco": "40136",
  Invercap: "90686",
  Invex: "40059",
  "JP Morgan": "40110",
  Kuspit: "90653",
  Libertad: "90670",
  Masari: "90602",
  Mifel: "40042",
  "Mizuho Bank": "40158",
  Monexcb: "90600",
  Mufg: "40108",
  "Multiva Banco": "40132",
  "Multiva Cbolsa": "90613",
  Nafin: "37135",
  Order: "90637",
  Oskndia: "90649",
  Pagatodo: "40148",
  Profuturo: "90620",
  Reforma: "90642",
  Sabadell: "40156",
  Santander: "40014",
  Scotiabank: "40044",
  Skandia: "90623",
  Sofiexpress: "90655",
  STP: "90646",
  Transfer: "90684",
  Unagra: "90656",
  Valmex: "90617",
  Value: "90605",
  Vector: "90608",
  "Ve Por Mas": "40113",
  Volkswagen: "40141"
};

class MissingTransfers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rastreo: undefined,
      fechaOperacion: undefined,
      institucionOperante: undefined,
      loading: false,
      message: null,
      status: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.renderStatusMessage = this.renderStatusMessage.bind(this);
    this.getBankNames = this.getBankNames.bind(this);
  }

  handleChange(event) {
    if (moment.isMoment(event)) {
      this.setState({
        fechaOperacion: event.format("YYYY-MM-DD HH:mm:ss.SSSSSS")
      });
      return;
    } else if (typeof event === "string") {
      this.setState({ fechaOperacion: event });
      return;
    }

    const {
      target: { name, value }
    } = event;
    this.setState({ [name]: value });
  }

  async handleSearch(event) {
    event.preventDefault();

    const confirmation = await swal(
      `¿Está segura?
            En caso de que se encuentre la transferencia en STP y no internamente, se creará la transferencia.`,
      {
        title: "Confirmación",
        buttons: ["Cancelar", "Confirmar"]
      }
    );

    if (!confirmation) return;

    this.setState({ loading: true });

    // Call recon to search for transfer
    const { rastreo, fechaOperacion, institucionOperante } = this.state;
    const payload = {
      rastreo,
      institucionOperante: BANK_NAMES[institucionOperante]
    };

    if (fechaOperacion) payload.fechaOperacion = fechaOperacion;

    const response = await postReconMissingTransfer(payload);

    if (response.status === 200) {
      const data = response.data;
      this.setState({
        message: data.message,
        status: data.status,
        loading: false
      });
    } else {
      this.setState({ loading: false });
      const message =
        response.status === 401
          ? "No tiene rol necesario"
          : "Intenta de nuevo en unos momentos";
      await swal("Error", `${message}.`, "error");
    }
  }

  renderStatusMessage(status) {
    switch (status) {
      case TRANSFER_STATUS.NOT_ON_STP:
        return "La transferencia no fue encontrada en STP, no se realizó reconciliación";
      case TRANSFER_STATUS.RECONCILIATION_MADE:
        return "La transferencia fue encontrada en STP y se realizó la reconciliación";
      case TRANSFER_STATUS.RECONCILIATION_NOT_NEEDED:
        return "La transferencia y la reconciliación ya habían sido realizadas";
      case TRANSFER_STATUS.STP_DOWN:
        return "STP no está disponible o los datos ingresados no son válidos";
      default:
        return "Oops, algo salió mal";
    }
  }

  getBankNames() {
    const values = Object.keys(BANK_NAMES);
    return values;
  }

  render() {
    const { classes } = this.props;
    const {
      rastreo,
      institucionOperante,
      loading,
      message,
      status
    } = this.state;

    const bankNames = this.getBankNames();

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Receipt />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Transferencias No Reflejadas
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <form onSubmit={this.handleSearch}>
                    <GridContainer
                      alignItems="center"
                      direction="row"
                      justify="space-evenly"
                    >
                      <GridItem xs md={3}>
                        <CustomInput
                          labelText="Clave de rastreo"
                          id="rastreo"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            required: true,
                            onChange: this.handleChange,
                            name: "rastreo"
                          }}
                        />
                      </GridItem>
                      <GridItem xs md={3}>
                        <DateInput
                          label="Fecha de operación"
                          id="fechaOperacion"
                          name="fechaOperacion"
                          onChange={this.handleChange}
                        />
                      </GridItem>
                      <GridItem xs md={3}>
                        <InputLabel className={classes.label}>
                          Institución operante
                        </InputLabel>
                        <select
                          className={classes.select}
                          id="institucionOperante"
                          name="institucionOperante"
                          onChange={this.handleChange}
                        >
                          {bankNames.map(bank => (
                            <option key={bank} value={bank}>
                              {bank}
                            </option>
                          ))}
                        </select>
                      </GridItem>
                      <GridItem xs md={3}>
                        <CustomButton
                          color="primary"
                          type="submit"
                          disabled={!rastreo || !institucionOperante}
                        >
                          Buscar
                        </CustomButton>
                      </GridItem>
                    </GridContainer>
                  </form>
                </GridItem>
              </GridContainer>
              <GridContainer alignItems="center" justify="center">
                {loading ? (
                  <Card className={classes.resultContainer}>
                    <CardHeader className={classes.messageHeader}>
                      <strong>Cargando...</strong>
                    </CardHeader>
                  </Card>
                ) : (
                  message && (
                    <Card className={classes.resultContainer}>
                      <CardHeader className={classes.messageHeader}>
                        <strong>{`Mensaje: ${message}`}</strong>
                      </CardHeader>
                      <CardBody>
                        <p>{`Explicación: ${this.renderStatusMessage(
                          status
                        )}`}</p>
                      </CardBody>
                    </Card>
                  )
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

MissingTransfers.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MissingTransfers);
