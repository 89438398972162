import React from "react";

import PropTypes from "prop-types";

// core components
import ErrorBoundary from 'components/ErrorBoundary';

import GaitaOrdersQuote from "views/GaitaOrders/GaitaOrdersQuote.jsx";
import GaitaOrdersQuoteReferred from "views/GaitaOrders/GaitaOrdersQuoteReferred.jsx";
import GaitaOrdersOrder from "views/GaitaOrders/GaitaOrdersOrder.jsx";

class GaitaOrdersIvoyExpress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  newOrderPlaced = () => {
    this.setState({
      neworder: true
    });
  };

  cancelCustomer = () => {
    this.setState({
      newquote: true
    });
  };

  render() {
    const { location } = this.props;
    return [
      (
        <ErrorBoundary key='geb-01' boundaryType='gaita'>
          <GaitaOrdersQuote
            key={1}
            location={location}
            newOrderPlaced={this.newOrderPlaced}
            newquote={this.state.newquote}
          />
        </ErrorBoundary>
      ),
      (
        <ErrorBoundary key='geb-02' boundaryType='gaita'>
          <GaitaOrdersQuoteReferred
            key={2}
            location={location}
            newOrderPlaced={this.newOrderPlaced}
            newquote={this.state.newquote}
          />
        </ErrorBoundary>
      ),
      (
        <ErrorBoundary key='geb-03' boundaryType='gaita'>
          <GaitaOrdersOrder
            key={3}
            location={location}
            neworder={this.state.neworder}
          />
        </ErrorBoundary>
      )
    ];
  }
}

GaitaOrdersIvoyExpress.propTypes = {
  location: PropTypes.object.isRequired
};

export default GaitaOrdersIvoyExpress;
