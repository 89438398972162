import React from 'react';

import { CardTransactions, CardTransactionDetail } from 'views/CardTransactions';

import ErrorBoundary from 'components/ErrorBoundary';

import { BillPayments, BillPaymentDetail } from 'views/BillPayments';
import Customers from 'views/Customers/Customers';
import CustomerDetail from 'views/Customers/CustomerDetail';
import Deposits from 'views/Deposits';
import ErrorPage from 'views/ErrorPage/ErrorPage';
import {
  GaitaActions,
  GaitaCsv,
  GaitaInventory,
  GaitaOrdersIvoyExpress,
  GaitaOrdersDHL,
  GaitaOrdersIvoyNextDay,
} from 'views/GaitaOrders';
import TransfersHeld from 'views/HeldTransfers';
import LoginGoogle from 'views/Auth/LoginGoogle';
import MonitorTransactions from 'views/MonitorTransactions';
import MissingTransfers from 'views/Transfers/MissingTransfers';
import Notifications from 'views/Notifications/Notifications'
import PasswordResetList from 'views/PasswordResets';
import PasswordResetDetail from 'views/PasswordResets/PasswordResetDetail';
import PldAlerts from 'views/PldAlerts';
import ProofsOfAddressList from 'views/ProofsOfAddress';
import ProofOfAddressDetail from 'views/ProofsOfAddress/ProofOfAddressDetail';
import Messages from 'views/Messages/BotmakerMessages';
import Transfers from 'views/Transfers';
import TransferDepositDetail from 'views/Transfers/TransferDepositDetail';
import UserReport from 'views/PldAlerts/UserReport';
import Validate from 'views/Validate/Validate';
import WalletTransactionDetail from 'views/WalletTransactions/WalletTransactionDetail';
import { WhatsappTransfers, WhatsappTransferDetail } from 'views/WhatsappTransfers';
import ZendeskDetail from 'views/Zendesk/ZendeskDetail';
import CommissionDetail from 'views/Commissions';

import Chat from '@material-ui/icons/Chat';
import CreditCard from '@material-ui/icons/CreditCard';
import HomeIcon from '@material-ui/icons/Home';
import ListAlt from '@material-ui/icons/ListAlt';
import Opacity from '@material-ui/icons/Opacity';
import Receipt from '@material-ui/icons/Receipt';
import Review from '@material-ui/icons/RateReview';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import SwapHorizontalCircle from '@material-ui/icons/SwapHorizontalCircle';
import Send from '@material-ui/icons/Send';
import Check from '@material-ui/icons/Check';
import NotificationsActive from '@material-ui/icons/NotificationsActive';
import ViewHeadline from '@material-ui/icons/ViewHeadline';

var dashRoutes = [
  {
    name: 'Envios Gaita',
    state: 'envios',
    icon: Send,
    collapse: true,
    views: [
      {
        path: '/gaita/actions',
        name: 'Buscar envío',
        component: GaitaActions,
        layout: '/admin'
      },
      {
        path: '/gaita/inventory',
        name: 'Inventario',
        component: GaitaInventory,
        layout: '/admin'
      },
      {
        path: '/gaita/ivoy_express',
        name: 'iVoy Express (120 min.)',
        component: GaitaOrdersIvoyExpress,
        layout: '/admin'
      },
      {
        path: '/gaita/ivoy_nextday',
        name: 'iVoy Next Day',
        component: GaitaOrdersIvoyNextDay,
        layout: '/admin'
      },
      {
        path: '/gaita/dhl',
        name: 'DHL',
        component: GaitaOrdersDHL,
        layout: '/admin'
      },
      {
        path: '/gaita/csv',
        name: 'Generar CSV',
        component: () => (
          <ErrorBoundary boundaryType='gaita'>
            <GaitaCsv />
          </ErrorBoundary>
        ),
        layout: '/admin',
      },
    ]
  },
  {
    name: 'PLD',
    state: 'pld',
    icon: Check,
    collapse: true,
    views: [
      {
        path: '/held',
        name: 'Transferencias Retenidas',
        icon: SwapHorizontalCircle,
        component: () => (
          <ErrorBoundary boundaryType='transfer'>
           <TransfersHeld />
          </ErrorBoundary>
        ),
        layout: '/admin'
      },
      {
        path: '/pld_alerts',
        name: 'Alertas PLD',
        icon: NotificationsActive,
        component: () => (
          <ErrorBoundary boundaryType='pldAlerts'>
           <PldAlerts />
          </ErrorBoundary>
        ),
        layout: '/admin'
      },
    ]
  },
  {
    path: '/notifications',
    name: 'Notificaciones Push',
    icon: SwapHorizontalCircle,
    component: Notifications,
    layout: '/admin'
  },
  {
    path: '/held',
    name: 'Transferencias Retenidas',
    icon: SwapHorizontalCircle,
    component: () => (
      <ErrorBoundary boundaryType='transfer'>
       <TransfersHeld />
      </ErrorBoundary>
    ),
    layout: '/admin'
  },
  {
    path: '/whatsapp_transfers',
    name: 'Transferencias Whatsapp',
    component: () => (
      <ErrorBoundary boundaryType='transfer'>
        <WhatsappTransfers />
      </ErrorBoundary>
    ),
    icon: SwapHorizontalCircle,
    layout: '/admin'
  },
  {
    path: '/customers',
    name: 'Lista de Clientes',
    icon: SupervisorAccount,
    component: () => (
      <ErrorBoundary boundaryType='customer'>
        <Customers />
      </ErrorBoundary>
    ),
    layout: '/admin'
  },
  {
    path: '/validate',
    name: 'Validar documentos',
    icon: SupervisorAccount,
    component: () => (
      <ErrorBoundary boundaryType='validate'>
        <Validate />
      </ErrorBoundary>
    ),
    layout: '/admin'
  },
  {
    path: '/password_resets',
    name: 'Reestablece Contraseña',
    icon: Review,
    component: () => (
      <ErrorBoundary boundaryType='passwordResets'>
        <PasswordResetList />
      </ErrorBoundary>
    ),
    layout: '/admin',
  },
  {
    path: '/proofs_of_address',
    name: 'Validar Comprobantes',
    icon: HomeIcon,
    component: () => (
      <ErrorBoundary boundaryType='proofsOfAddress'>
        <ProofsOfAddressList />
      </ErrorBoundary>
    ),
    layout: '/admin',
  },
  {
    path: '/reflected',
    name: 'Revisar Transferencia',
    icon: Receipt,
    component: MissingTransfers,
    layout: '/admin'
  },
  {
    path: '/transfers',
    name: 'Transferencias',
    component: () => (
      <ErrorBoundary boundaryType='transfer'>
        <Transfers />
      </ErrorBoundary>
    ),
    icon: SwapHorizontalCircle,
    layout: '/admin'
  },
  {
    path: '/deposits',
    name: 'Depósitos',
    icon: ListAlt,
    component: () => (
      <ErrorBoundary boundaryType='transfer'>
        <Deposits />
      </ErrorBoundary>
    ),
    layout: '/admin'
  },
  {
    path: '/card/transactions',
    name: 'Transacciones de Tarjeta',
    component: () => (
      <ErrorBoundary boundaryType='transfer'>
        <CardTransactions />
      </ErrorBoundary>
    ),
    icon: CreditCard,
    layout: '/admin'
  },
  {
    path: '/monitor/transactions',
    name: 'Monitor de Transacciones',
    icon: ViewHeadline,
    component: () => (
      <ErrorBoundary boundaryType='transfer'>
        <MonitorTransactions />
      </ErrorBoundary>
    ),
    layout: '/admin'
  },
  {
    path: '/service/transactions',
    name: 'Pagos de Servicios',
    component: () => (
      <ErrorBoundary boundaryType='transfer'>
        <BillPayments />
      </ErrorBoundary>
    ),
    icon: Opacity,
    layout: '/admin'
  },
  {
    path: '/messages',
    name: 'Mensajes Botmaker',
    icon: Chat,
    component: Messages,
    layout: '/chat'
  }
];

dashRoutes.unshift({
  collapse: true,
  name: 'Redirects',
  state: 'mapsCollapse',
  redirect: true,
  views: [
    {
      name: 'Crear un Ticket',
      path: '/create_ticket/:user_id',
      component: () => (
        <ErrorBoundary boundaryType='zendesk'>
          <ZendeskDetail />
        </ErrorBoundary>
      ),
      layout: '/admin'
    },
    {
      name: 'Detalle de un Ticket',
      path: '/ticket/:ticket_id',
      component: () => (
        <ErrorBoundary boundaryType='zendesk'>
          <ZendeskDetail />
        </ErrorBoundary>
      ),
      layout: '/admin'
    },
    {
      name: 'Detalle de Depósito',
      path: '/deposit/:transaction_id',
      component: () => (
        <ErrorBoundary boundaryType='deposit'>
          <TransferDepositDetail type='deposit' />
        </ErrorBoundary>
      ),
      layout: '/admin'
    },
    {
      name: 'Detalle de Transferencia',
      path: '/transfer/:transaction_id',
      component: () => (
        <ErrorBoundary boundaryType='transfer'>
          <TransferDepositDetail type='transfer' />
        </ErrorBoundary>
      ),
      layout: '/admin'
    },
    {
      name: 'Detalle de Transferencia Whatsapp',
      path: '/whatsapp_transfers/:transferId',
      component: () => (
        <ErrorBoundary boundaryType='transfer'>
          <WhatsappTransferDetail />
        </ErrorBoundary>
      ),
      layout: '/admin'
    },
    {
      name: 'Detalle de Transaccion de Wallets',
      path: '/wallet_transactions/:transactionId',
      component: () => (
        <ErrorBoundary boundaryType='transfer'>
          <WalletTransactionDetail />
        </ErrorBoundary>
      ),
      layout: '/admin'
    },
    {
      name: 'Detalle de Transacion de Tarjeta',
      path: '/card/transaction/:transactionId',
      component: () => (
        <ErrorBoundary boundaryType='transfer'>
          <CardTransactionDetail />
        </ErrorBoundary>
      ),
      layout: '/admin'
    },
    {
      name: 'Detalle de Pago de Servicio',
      path: '/service/transaction/:transaction_id',
      component: () => (
        <ErrorBoundary boundaryType='transfer'>
          <BillPaymentDetail />
        </ErrorBoundary>
      ),
      layout: '/admin'
    },
    {
      name: 'Comisión',
      path: '/commissions/:commissionId',
      component: () => (
        <ErrorBoundary boundaryType='transfer'>
          <CommissionDetail />
        </ErrorBoundary>
      ),
      layout: '/admin',
    },
    {
      name: 'Transacciones de Tarjeta de Cliente',
      path: '/card/transactions/:userId',
      component: () => (
        <ErrorBoundary boundaryType='transfer'>
          <CardTransactions />
        </ErrorBoundary>
      ),
      layout: '/admin'
    },
    {
      name: 'Pagos de Servicios de Cliente',
      path: '/service/transactions/:ledger_account_id',
      component: () => (
        <ErrorBoundary boundaryType='transfer'>
          <BillPayments />
        </ErrorBoundary>
      ),
      layout: '/admin'
    },
    {
      path: '/login',
      name: 'Inicio de Sesión',
      component: LoginGoogle,
      layout: '/auth'
    },
    {
      path: '/:status',
      name: 'Error',
      component: ErrorPage,
      layout: '/error'
    },
    {
      name: 'Detalle de Cliente',
      path: '/customer/:customer_id',
      component: () => (
        <ErrorBoundary boundaryType='customer'>
          <CustomerDetail />
        </ErrorBoundary>
      ),
      layout: '/admin'
    },
    {
      name: 'Detalle de Reestablecimiento',
      path: '/password_resets/:userId/:passwordResetId',
      component: PasswordResetDetail,
      layout: '/admin',
    },
    {
      path: '/pld_reports/:userReportId',
      name: 'Reporte de usuario PLD',
      icon: NotificationsActive,
      component: () => (
        <ErrorBoundary boundaryType='pldAlerts'>
         <UserReport />
        </ErrorBoundary>
      ),
      layout: '/admin'
    },
    {
      name: 'Detalle de Comprobante de Domicilio',
      path: '/proofs_of_address/:userId',
      component: ProofOfAddressDetail,
      layout: '/admin',
    },
  ]
});

export default dashRoutes;
