import React from "react";
import PropTypes from "prop-types";

import { Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons";
import Card from "components/Card";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/omni/views/loginPageStyle.jsx";

import GoogleLogin from "react-google-login";

import { postLoginGoogle } from "providers/api-rest.js";

class LoginGoogle extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      redirectToReferrer: false
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  async responseGoogle(e, data) {
    if ("code" in data) {
      const response = await postLoginGoogle(data);
      localStorage.setItem("access_token", response.data["access_token"]);
      localStorage.setItem("refresh_token", response.data["refresh_token"]);
      localStorage.setItem("user_name", response.data["user_name"]);
      e.setState({ redirectToReferrer: true });
    }
  }

  render() {
    const { classes, location } = this.props;
    let redirect_state = { pathname: "/admin/customers" };
    if (location.state) {
      redirect_state = location.state;
    }
    let { redirectToReferrer } = this.state;
    if (redirectToReferrer) return <Redirect to={redirect_state} />;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.cardTitle}>Log in</h4>
                  <div className={classes.socialLine}>
                    <GoogleLogin
                      clientId="614634999659-68bo086ngva92rpq8p249icookt7egqr.apps.googleusercontent.com"
                      responseType="code"
                      render={renderProps => (
                        <Button
                          onClick={renderProps.onClick}
                          color="transparent"
                          justIcon
                          key="0"
                          size="lg"
                          className={classes.customButtonClass}
                        >
                          <i className="fab fa-google-plus" />
                        </Button>
                      )}
                      buttonText="Login"
                      onSuccess={data => this.responseGoogle(this, data)}
                      onFailure={data => this.responseGoogle(this, data)}
                    />
                  </div>
                </CardHeader>
                <CardFooter className={classes.justifyContentCenter} />
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginGoogle.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginGoogle);
