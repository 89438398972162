import React from 'react';

import PropTypes from 'prop-types';

// @material-ui/core components
import { withStyles } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVeryDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentVeryDissatisfiedOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@material-ui/icons/SentimentVerySatisfiedOutlined';

// core components
import Accordion from 'components/Accordion';
import Card from 'components/Card';
import CardBody from 'components/Card/CardBody';
import ClipboardCopy from 'components/ClipboardCopy';
import Table from 'components/Table';

const styles = {
	cardStyle: {
		borderRadius: 0,
		marginTop: '10px',
		marginBottom: '5px',
		boxShadow: '0 0 0 0',
		'& td': {
			fontFamily: "Helvetica Neue",
			color: '#8D8D8D',
			borderTop: '1px solid rgba(141, 141, 141, 0.25)',
			fontWeight: '400',
		},
		'& tr:first-child': {
			'& td':{
				borderTop: 0
			}
		},
		'& > div':{
		  	padding: '0 20px'
		}
	},
	failedTransfer: {
		background: 'rgba(210, 97, 111, 0.16)',
	},
	headerName: {
		background: '#616C93',
		height: '50px',
		fontSize: '20px',
		padding: '14px',
		color: 'white',
		fontWeight: '600',
		'&:hover': {
			cursor: 'pointer',
			background: 'rgb(163, 169, 194, 0.15)',
			color: '#616C93',
		}
	},
	headerTransfer: {
		background: 'rgb(163, 169, 194, 0.17)',
	},
	highlight: {
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	highlighIcon: { float: 'right', },
	iconFailed: { color: '#D2616F' },
	iconSuccess: { color: '#00B7B0' },
	iconSubmited: { color: '#B98702' },
	statusMargin: {
		top: '-7px',
		position: 'relative'
	},
	succeededTransfer: {
		background: 'rgba(0, 183, 176, 0.16)',
	},
	submittedTransfer: {
		background: 'rgba(232, 223, 101, 0.16)',
	},
};

const statusIcon = {
	'succeeded': classes => 
		<SentimentVerySatisfiedOutlinedIcon className={classes.iconSuccess} />,
	'failed': classes => 
		<SentimentVeryDissatisfiedOutlinedIcon className={classes.iconFailed} />,
};

function MovementDetail({
	accordionData,
	classes,
	human,
	mainData,
	status,
	topData,
	enableCopyData = false,
	extraCopyData = {},
}) {
	// Returns a div containing an Icon and status message given a status.
	const renderIconStatus = status => {

		const icon = statusIcon[status] ?
			statusIcon[status](classes) :
			<SentimentSatisfiedIcon className={classes.iconSubmitted} />;
		return (
			<div>
				{icon}
				<span className={classes.statusMargin}>
					{`  ${status.toUpperCase()}`}
				</span>
			</div>
		);
	};

	// Returns a color class given a status.
	const getColorCard = status => {
		if (status === 'succeeded')
			return classes.succeededTransfer;
		else if (status === 'failed')
			return classes.failedTransfer;
		
		return classes.submittedTransfer;
	};

	// Opens a new window to a customer's profile.
	const pushCustomer = human =>
		window.open(`/admin/customer/${encodeURIComponent(human)}`);
	
	// Given an array of data, prep it for Table component needs.
	const prepData = dataArray => {
		return dataArray.map(d => {
			if (typeof d === 'object') {
				if (d && d.link) {
					const { link, ...rest } = d;
					return [
						<div className={classes.highlight} onClick={() => window.open(link)}>
							{rest.status ? renderIconStatus(rest.status) : rest.data}
							<span>
								<LaunchIcon />
							</span>
						</div>
					];
				}
				if (d && d.status)
					return [renderIconStatus(d.status)];
			}
			return [d];
		});
	}

	// save top data render
	let TopDataCard;
	if (topData) {
		TopDataCard = (
			<Card className={classes.headerTransfer}>
				<CardBody>
					<Table tableData={prepData(topData)} />
				</CardBody>
			</Card>
		);
	}
	
	return (
		<div>

			<div
				key={`title-${human}`}
				onClick={() => pushCustomer(human.id)}
				className={classes.headerName}
			>
				{human.name}
			</div>

			{topData && !enableCopyData ? TopDataCard : (
				<ClipboardCopy {...extraCopyData}>
					{TopDataCard}
				</ClipboardCopy>
			)}

			<Card
				className={
					`${classes.cardStyle} ${getColorCard(status)}`
				}
			>
				<CardBody>
					<Table tableData={prepData(mainData)} />
				</CardBody>
			</Card>

			<br />

			{accordionData && (
				<Accordion collapses={accordionData} />
			)}

		</div>
	);
}

MovementDetail.propTypes = {
	classes: PropTypes.object.isRequired,
	/*
		Human data: its name and ID
	*/
	human: PropTypes.exact({
		name: PropTypes.string,
		id: PropTypes.string,
	}).isRequired,
	/*
		Data on the top table.
		An element could be the plain data or
		an object with a status to render an Icon 
			or a link with data to display or
		an element to directly render a React element
	*/
	topData: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.object,
			PropTypes.element,
		]),
	),
	/*
		Data for the movement (colored table).
		Same explanation as above
	*/
	mainData: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.object,
			PropTypes.element,
		]),
	).isRequired,
	// String status of the transaction
	status: PropTypes.string.isRequired,
	/*
		Array containing data to be displayed as an Accordion.
		Each element will be rendered in its unique Accordion.
		See Accordion component for more details on formating.
	*/
	accordionData: PropTypes.array,
	// Add Copyclipboard component to TopData
	enableCopyData: PropTypes.bool,
	extraCopyData: PropTypes.object,
};

export default withStyles(styles)(MovementDetail);
