import React from "react";
import { withRouter } from 'react-router';

import ReactTable from "react-table";

import PropTypes from "prop-types";

import Moment from "react-moment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import Card from "components/Card";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import { processError } from 'components/ErrorBoundary';

import { cardTitle, tooltip } from "assets/jss/omni.jsx";

import { getMonitorTransactions } from "providers/api-rest.js";

import Utils from "common/utils.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  tooltip,
  buttonDetail: {
    color: "#ff9800"
  },
  search: {
    position: "absolute",
    right: 0,
    top: "10px",
    padding: 0,
    margin: 0,
    width: "30%"
  }
};

class MonitorTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      filtered: []
    };
  }

  async onFetchData(state) {
    this.setState({ loading: true });
    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filtered: state.filtered
    };
    const response = await getMonitorTransactions(params);
    this.setState({
      data: response.data && response.data.result ? response.data.result : [],
      loading: false,
      pages: response.data ? response.data.pages : 1,
      response: response
    });
  }

  onKeyPressSearch(findText) {
    this.setState({
      filtered: [
        {
          id: "findText",
          value: findText
        }
      ],
      loading: true
    });
  }

  render() {
    const {
      response,
      data,
      pages,
      loading,
      filtered
    } = this.state;
    const { classes, location } = this.props;

    const columns = [
      {
        Header: "Identificacion",
        accessor: "identifier",
        sortable: false,
        filterable: false,
        minWidth: 80
      },
      {
        Header: "Comercio",
        accessor: "merchant_name",
        sortable: false,
        filterable: false,
        minWidth: 220
      },
      {
        Header: "Fecha",
        accessor: "datetime",
        sortable: false,
        filterable: false,
        minWidth: 130,
        Cell: row => <Moment utc>{row.original.datetime}</Moment>
      },
      {
        Header: "Monto",
        accessor: "amount",
        sortable: false,
        filterable: false,
        minWidth: 70,
        Cell: row => Utils.formatPrice(row.original.amount)
      },
      {
        Header: "Tarjeta",
        accessor: "card_number",
        filterable: false,
        sortable: false,
        Cell: row => <strong>{row.original.card_number}</strong>
      },
      {
        Header: "Mensaje",
        accessor: "message",
        filterable: false,
        sortable: false,
        minWidth: 120,
        Cell: row => (
          <div>
            {row.original.type ? (
              <div
                style={{
                  color: "#00acc1"
                }}
              >
                <span>{row.original.message} &nbsp;</span>
              </div>
            ) : (
              <span>{row.original.message}</span>
            )}
          </div>
        )
      },
      {
        Header: "Accion Final",
        accessor: "action_end",
        filterable: false,
        sortable: false,
        minWidth: 120
      }
    ];
    const subColumns = [
      {
        Header: "Prosa ID",
        accessor: "prosa_id",
        sortable: false
      },
      {
        Header: "Fecha",
        accessor: "datetime",
        sortable: false,
        filterable: false,
        Cell: row => <Moment utc>{row.original.datetime}</Moment>
      },
      {
        Header: "Monto",
        accessor: "amount",
        sortable: false,
        filterable: false,
        minWidth: 70,
        Cell: row => Utils.formatPrice(row.original.amount)
      },
      {
        Header: "Mensaje",
        accessor: "message",
        filterable: false,
        sortable: false
      },
      {
        Header: "Acción Final",
        accessor: "action_end",
        filterable: false,
        sortable: false
      },
      {
        Header: "Descripcion",
        accessor: "description",
        filterable: false,
        sortable: false,
        minWidth: 150
      }
    ];

    if (response && response.status !== 200 && response.status !== 201) {
      processError(response.status, location.pathname, false, '', true);
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Transacciones de PROSA Authorizer
                <CustomInput
                  className={classes.search}
                  formControlProps={{
                    className: classes.top + " " + classes.search
                  }}
                  inputProps={{
                    placeholder:
                      "Buscar (Prosa ID) (Dígitos de Tarjeta) (Monto) Etc...",
                    inputProps: {
                      "aria-label": "Buscar",
                      className: classes.searchInput
                    },
                    onKeyPress: event => {
                      if (event.key === "Enter") {
                        this.onKeyPressSearch(event.target.value);
                      }
                    }
                  }}
                />
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                manual
                data={data}
                columns={columns}
                pages={pages}
                defaultPageSize={20}
                loading={loading}
                filterable={true}
                filtered={filtered}
                onFetchData={state => this.onFetchData(state)}
                noDataText="No se han encontrado resultados"
                SubComponent={row => {
                  return (
                    <div
                      style={{
                        paddingLeft: "170px"
                      }}
                    >
                      <hr
                        style={{
                          marginBottom: "0px",
                          marginTop: "5px"
                        }}
                      />
                      <ReactTable
                        data={row.original.events}
                        columns={subColumns}
                        defaultPageSize={row.original.events.length}
                        showPagination={false}
                      />
                    </div>
                  );
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

MonitorTransactions.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(MonitorTransactions));
