import React from "react";

import PropTypes from "prop-types";

// @material-ui/core components
import Close from "@material-ui/icons/Close";
import CloudUpload from "@material-ui/icons/CloudUpload";
import DoneOutline from "@material-ui/icons/DoneOutline";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "components/CustomButtons";

const styles = {
  textUploadDone: {
    marginRight: '10px'
  }
};

class ActionButtons extends React.Component {
  render() {
    const { classes, validating, enableSucceded, preOptionSelected, optionSelected  } = this.props;
    return (
      <div>
        <Button
          size="sm"
          color="danger"
          onClick={() => optionSelected("rejected", validating)}
        >
          <span className={classes.textUploadDone}>
            Rejected
          </span><Close />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          size="sm"
          color="info"
          onClick={() => optionSelected("upload_again", validating)}
        >
          <span className={classes.textUploadDone}>
            Upload Again
          </span><CloudUpload />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {enableSucceded
          ? <Button
            size="sm"
              color="success"
              onClick={() => {
                if (preOptionSelected){
                  preOptionSelected()
                }
                optionSelected("succeeded", validating)
              }}
            >
              <span className={classes.textUploadDone}>
                Succeeded
               </span><DoneOutline />
            </Button>
          : null
        }
      </div>
    );
  }
}

ActionButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  enableSucceded: PropTypes.bool,
  preOptionSelected: PropTypes.func,
  optionSelected: PropTypes.func,
};

export default withStyles(styles)(ActionButtons);
