import React from "react";

import PropTypes from "prop-types";

// core components
import ErrorBoundary from 'components/ErrorBoundary';

import GaitaOrdersForeign from "views/GaitaOrders/GaitaOrdersForeign.jsx";
import GaitaOrdersForeignReferred from "views/GaitaOrders/GaitaOrdersForeignReferred.jsx";
import GaitaOrderForeignFinished from "views/GaitaOrders/GaitaOrderForeignFinished.jsx";

class GaitaOrdersDHL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  newOrderPlaced = () => {
    this.setState({
      neworder: true
    });
  };

  cancelCustomer = () => {
    this.setState({
      newquote: true
    });
  };

  render() {
    const { location } = this.props;
    return [
      (
        <ErrorBoundary key='geb-01' boundaryType='gaita'>
          <GaitaOrdersForeign
            key={1}
            location={location}
            provider="dhl"
            newOrderPlaced={this.newOrderPlaced}
          />
        </ErrorBoundary>
      ),
      (
        <ErrorBoundary key='geb-02' boundaryType='gaita'>
          <GaitaOrdersForeignReferred
            key={2}
            location={location}
            provider='dhl'
          />
        </ErrorBoundary>
      ),
      (
        <ErrorBoundary key='geb-03' boundaryType='gaita'>
          <GaitaOrderForeignFinished
            key={3}
            location={location}
            provider="dhl"
            neworder={this.state.neworder}
          />
        </ErrorBoundary>
      )
    ];
  }
}

GaitaOrdersDHL.propTypes = {
  location: PropTypes.object.isRequired
};

export default GaitaOrdersDHL;
