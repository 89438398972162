import React from 'react';

import ReactTable from 'react-table';

import PropTypes from 'prop-types';

import Moment from 'react-moment';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import AutoRenew from '@material-ui/icons/Autorenew';

// core components
import Card from 'components/Card';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import regularFormsStyle from 'assets/jss/omni/views/regularFormsStyle';

import { cardTitle, tooltip } from 'assets/jss/omni.jsx';

import {
  getNotificadorExecutions,
} from 'providers/api-rest.js';

const styles = {
  tooltip,
  regularFormsStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  updateTable: {
    position: 'absolute',
    top: '10px',
    padding: 0,
    margin: 0,
    width: '30%'
  },
  updatedCursor: {
    cursor: 'pointer'
  }
};

class LastExecution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      interval: null,
    };
  }

  async onFetchData() {
    this.setState({ loading: true });
    const response = await getNotificadorExecutions();
    this.setState({
      data: response.data ? response.data : [],
      loading: false
    });
  }

  onFetchDataReload() {
    this.setState({ loading: true });
    this.onFetchData();
  }

  render() {
    const { classes } = this.props;
    const columns = [
      {
        Header: 'Mensaje',
        accessor: 'message_text',
        minWidth: 250,
        Cell: row => <strong>{row.original.message_text}</strong>
      },{
        Header: 'Inicio',
        accessor: 'created_at',
        minWidth: 150,
        Cell: row => <Moment utc>{row.original.created_at}</Moment>
      },{
        Header: 'Estado',
        accessor: 'status',
        minWidth: 70,
      },
      {
        Header: 'Totales',
        accessor: 'result.user_ids',
        minWidth: 70,
      },
      {
        Header: 'Exitosos',
        accessor: 'result.successful',
        minWidth: 70
      },
      {
        Header: 'Fallidos',
        accessor: 'result.failed',
        minWidth: 70
      }
    ];
    return (
      <Card>
        <CardHeader color='primary' icon>
          <h4 className={classes.cardIconTitle}>
            Ultimas Ejecuciones
            <span
              className={classes.updatedCursor}
              onClick={() => this.onFetchDataReload()}
            >
              <strong> |</strong> Actualizar
              <span className={classes.updateTable}>
                <AutoRenew />
              </span>
            </span>
          </h4>
        </CardHeader>
        <CardBody>
          <ReactTable
            manual
            data={this.state.data}
            columns={columns}
            defaultPageSize={10}
            loading={this.state.loading}
            filterable={false}
            sortable={false}
            showPagination={false}
            onFetchData={state => this.onFetchData(state)}
            className='-highlight'
            noDataText='No se han encontrado resultados'
            showPageSizeOptions={false}
          />
        </CardBody>
      </Card>
    );
  }
}

LastExecution.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LastExecution);
