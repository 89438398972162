import React from 'react';
import PropTypes from 'prop-types';

import Moment from "react-moment";

// core components
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { cardTitle } from "assets/jss/omni.jsx";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    search: {
        position: "absolute",
        right: 0,
        top: "10px",
        padding: 0,
        margin: 0,
        width: "30%"
    },
};

class CardHeaderSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      last_created: null
    };
    this.onKeyPressSearch = this.onKeyPressSearch.bind(this);
  }

  async lastCreatedAt() {
    const { lastCreatedAtModel, type } = this.props;

    if (!lastCreatedAtModel) return;

    const response = await lastCreatedAtModel(type);
    if (response.data && response.data.created_at){
      this.setState({last_created: response.data.created_at});
    }
  }

  componentDidMount() {
    this.lastCreatedAt();
  }

  onKeyPressSearch = event => {
    const { newSearchHandle } = this.props;
    if (event.key === "Enter") {
      newSearchHandle(event.target.value)
    }
  };

  render() {
    const { last_created } = this.state;
    const { classes, title, placeholder } = this.props;
    return (
        <CardHeader color="primary" icon>
            <CardIcon color="primary">
                <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
            {title}&nbsp;
            {last_created
                ? <small>
                    (Ultimo registro: <Moment utc>{last_created}</Moment>)
                </small>
                : null
            }
            <CustomInput
                className={classes.search}
                formControlProps={{
                    className: classes.top + " " + classes.search
                }}
                inputProps={{
                    placeholder: placeholder,
                    inputProps: {
                        "aria-label": "Buscar",
                        className: classes.searchInput
                    },
                    onKeyPress: this.onKeyPressSearch
                }}
            />
            </h4>
        </CardHeader>
    );
  }
}

CardHeaderSearch.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  lastCreatedAtModel: PropTypes.func,
  newSearchHandle: PropTypes.func
};

export default withStyles(styles)(CardHeaderSearch);
