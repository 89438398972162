import React from "react";
import { withRouter } from 'react-router';

import ReactTable from "react-table";

import PropTypes from "prop-types";

import Moment from "react-moment";

// @material-ui/core components
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";

import AutoRenew from "@material-ui/icons/Autorenew";
import Cancel from "@material-ui/icons/Cancel";
import Place from "@material-ui/icons/Place";
import Send from "@material-ui/icons/Send";

// core components
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput";
import { processError } from 'components/ErrorBoundary';

import { PaymentTypeIcon } from 'common/utils.jsx';

import regularFormsStyle from "assets/jss/omni/views/regularFormsStyle";

import { cardTitle, tooltip } from "assets/jss/omni.jsx";

import {
  getGaitaOrdersOrdered,
  patchGaitaOrdersFinalized,
  patchGaitaOrdersOrdered
} from "providers/api-rest.js";

import { getReferredColor } from "../../common/utils";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  regularFormsStyle,
  tooltip,
  icon: {
    marginLeft: "10%",
    fontSize: "25px"
  },
  search: {
    position: "absolute",
    right: 0,
    top: "10px",
    padding: 0,
    margin: 0,
    width: "30%"
  },
  updateTable: {
    position: "absolute",
    top: "10px",
    padding: 0,
    margin: 0,
    width: "30%"
  },
  updatedCursor: {
    cursor: "pointer"
  }
};

class GaitaOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      interval: null,
      search: null,
      lastState: null
    };
  }

  componentWillReceiveProps = props => {
    if (props.neworder) {
      this.onFetchDataReload();
    }
  };

  async onFetchData(state) {
    this.setState({
      loading: true,
      lastState: state
    });
    const params = {
      page: state.page + 1,
      page_size: state.pageSize,
      search: state.search,
      order_status: "ordered,in_process"
    };
    const response = await getGaitaOrdersOrdered(params);
    this.setState({
      data:
        response.data && response.data.orders_groups
          ? response.data.orders_groups
          : [],
      loading: false,
      pages: response.data ? response.data.pages : 1,
      response: response
    });
    clearInterval(this.interval);
  }

  onFetchDataReload() {
    this.setState({ loading: true });
    this.onFetchData(this.state.lastState);
  }

  onKeyPressSearch(search) {
    let lastState = this.state.lastState;
    lastState["search"] = search;
    this.setState({ lastState: lastState });
    this.onFetchDataReload();
  }

  async cancelGaitaOrdersOrdered(order_id) {
    this.setState({ loading: true });
    const response = await patchGaitaOrdersOrdered({ order_id });
    if (response.status === 200) {
      this.onFetchDataReload();
    } else {
      alert("Ocurrio un error inesperado, intente de nuevo.");
    }
  }

  async finalizeOrder(order_id) {
    const response = await patchGaitaOrdersFinalized(order_id);
    if (response.status === 200) {
      this.onFetchDataReload();
    } else {
      alert(
        `Ocurrio un error inesperado, contacta a ingeniería con ID de orden: ${order_id}`
      );
    }
  }

  getQuoteLocal(group) {
    return group["options"][0];
  }

  getTrProps(state, rowInfo) {
    if (rowInfo) {
      const color = getReferredColor(rowInfo.original.referred);

      return {
        style: {
          backgroundColor: color
        }
      };
    }
    return {};
  }

  render() {
    const { response } = this.state;
    const { classes, location } = this.props;
    const columns = [
      {
        Header: "Orden ID",
        accessor: "order_id",
        minWidth: 40,
        filterable: false,
        Cell: row => {
          const { order_detail, payment_types } = row.original;
          return (
            <>
              <span><b>{order_detail.order_id}</b></span>
              {payment_types && payment_types.map(type =>
                <PaymentTypeIcon
                  id={`goo-${type}`}
                  type={type}
                  classes={classes}
                  size='small'
                />
              )}
            </>
          )
        }
      },
      {
        Header: "Entregas",
        accessor: "customer_orders",
        minWidth: 40,
        filterable: false,
        Cell: row => <span>{row.original.orders_count} Entregas</span>
      },
      {
        Header: "Tiempo",
        accessor: "eta",
        minWidth: 35,
        filterable: false,
        Cell: row => <span>{this.getQuoteLocal(row.original)["eta"]} min</span>
      },
      {
        Header: "Distancia",
        accessor: "distance",
        minWidth: 40,
        filterable: false,
        Cell: row => (
          <span>{this.getQuoteLocal(row.original)["distance"]} km</span>
        )
      },
      {
        Header: "Costo",
        accessor: "price",
        minWidth: 30,
        filterable: false,
        Cell: row => <span>${this.getQuoteLocal(row.original)["price"]}</span>
      },
      {
        Header: "Estado",
        accessor: "statusText",
        minWidth: 80,
        filterable: false,
        Cell: row => <span>{row.original["order_detail"]["status"]}</span>
      },
      {
        Header: "Enlace de rastreo",
        accessor: "tracking_url",
        minWidth: 160,
        filterable: false,
        Cell: row => {
          if (row.original["order_detail"]) {
            return (
              <a
                href={row.original["order_detail"]["tracking_link"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                {row.original["order_detail"]["tracking_link"]}
              </a>
            );
          } else {
            return <p>Enlace de rastreo no disponible en este momento</p>;
          }
        }
      },
      {
        Header: "Agente",
        accessor: "agent_name",
        minWidth: 80,
        filterable: false,
        Cell: row => <span>{row.original.agent["name"]}</span>
      },
      {
        Header: "Mensajero",
        accessor: "messenger",
        minWidth: 110,
        filterable: false,
        Cell: row => {
          if (row.original.messenger !== null) {
            return (
              <p>
                <strong>{row.original.messenger.name}</strong> -{" "}
                {row.original.messenger.phone_number}
              </p>
            );
          } else {
            return <p>Mensajero sin asignar</p>;
          }
        }
      },
      {
        Header: "Acciones",
        minWidth: 50,
        filterable: false,
        Cell: row => {
          const {
            order_detail,
            messenger,
            id
          } = row.original;
          const link =
            `https://v2.ivoy.mx/client/app/my/order/track/${order_detail.order_id}`;
          return (
            <div className="actions-right">
              {messenger === null ? (
                <Tooltip
                  id="tooltip-top"
                  title="Cancelar Orden"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <span
                    onClick={() =>
                      this.cancelGaitaOrdersOrdered(id)
                    }
                  >
                    <Cancel color="secondary" className={classes.icon} />
                  </span>
                </Tooltip>
              ) : null}
              <Tooltip
                id="tooltip-top"
                title="Rastrear Pedido"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <a href={link} target="_blank" rel="noopener noreferrer">
                  <Place color="primary" className={classes.icon} />
                </a>
              </Tooltip>
            </div>
          );
        }
      }
    ];
    const subColumns = [
      {
        accessor: 'payment_type',
        minWidth: 30,
        Cell: row => (
          <PaymentTypeIcon
            type={row.original.payment_type}
            classes={classes}
          />
        )
      },
      {
        Header: "Cliente",
        accessor: "name"
      },
      {
        Header: "Telefono",
        accessor: "telephone",
        Cell: row => {
          const phone = row.original.telephone.replace("+", "");
          const link = "https://wa.me/" + phone;
          return (
            <a href={link} target="_blank" rel="noopener noreferrer">
              {row.original.telephone}
            </a>
          );
        }
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Fecha de Pedido",
        accessor: "created_at",
        minWidth: 130,
        Cell: row => {
          const created_at = row.original.created_at.split(".")[0];
          return <Moment utc>{created_at}</Moment>;
        }
      },
      {
        Header: "Direccion",
        accessor: "full_address",
        minWidth: 300,
        Cell: row => (
          <span>
            {row.original.full_address} INTERIOR: {row.original.int_number}{" "}
            CODIGO POSTAL: {row.original.zip_code}
          </span>
        )
      },
      {
        Header: "Estado",
        accessor: "status"
      },
      {
        Header: "Comentario",
        accessor: "comment",
        minWidth: 100
      }
    ];

    if (response && response.status !== 200 && response.status !== 201) {
      processError(
        response.status,
        location.pathname,
        false,
        (
          (response.data && (response.data.error || response.data.message))
          || 'Listado órdenes realizadas de Gaita, contacta a ingeniería'
        )
      );
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Send />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Ordenes realizadas
                <span
                  className={classes.updatedCursor}
                  onClick={() => this.onFetchDataReload()}
                >
                  <strong> |</strong> Actualizar
                  <span className={classes.updateTable}>
                    <AutoRenew />
                  </span>
                </span>
                <CustomInput
                  className={classes.search}
                  formControlProps={{
                    className: classes.top + " " + classes.search
                  }}
                  inputProps={{
                    placeholder: "Buscar (User Id) (Teléfono) (Email)",
                    inputProps: {
                      "aria-label": "Buscar",
                      className: classes.searchInput
                    },
                    onKeyPress: event => {
                      if (event.key === "Enter") {
                        this.onKeyPressSearch(event.target.value);
                      }
                    }
                  }}
                />
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                manual
                data={this.state.data}
                columns={columns}
                pages={this.state.pages}
                defaultPageSize={7}
                loading={this.state.loading}
                filterable={true}
                sortable={false}
                onFetchData={state => this.onFetchData(state)}
                getTrProps={this.getTrProps}
                className="-highlight"
                noDataText="No se han encontrado resultados"
                showPageSizeOptions={false}
                SubComponent={row => {
                  return (
                    <div
                      style={{
                        paddingLeft: "50px"
                      }}
                    >
                      <hr
                        style={{
                          marginBottom: "0px",
                          marginTop: "5px"
                        }}
                      />
                      <ReactTable
                        data={row.original.orders}
                        columns={subColumns}
                        defaultPageSize={row.original.orders.length}
                        showPagination={false}
                        sortable={false}
                      />
                    </div>
                  );
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

GaitaOrders.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  neworder: PropTypes.bool
};

export default withRouter(withStyles(styles)(GaitaOrders));
