import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// react library for Date inputs
import Datetime from 'react-datetime';

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = {
  label: {
    color: 'rgba(0, 0, 0, 0.26)',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '12px',
    transition: '0.3s ease all',
    lineHeight: '1.428571429',
    fontWeight: '400',
    paddingLeft: '0'
  },
  container: {
    margin: '0',
    padding: '10'
  }
};

function DateInput(props) {
  const {
    classes,
    label,
    id,
    name,
    timeFormat,
    formControlProps,
    onChange
  } = props;

  return (
    <div className={classes.container}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <FormControl fullWidth {...formControlProps}>
        <Datetime
          dateFormat="DD/MM/YY"
          timeFormat={!!timeFormat}
          inputProps={{
            placeholder: 'DD/MM/YY',
            id: id,
            name: name
          }}
          onChange={onChange}
        />
      </FormControl>
    </div>
  );
}

DateInput.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  timeFormat: PropTypes.bool,
  formControlProps: PropTypes.object,
  onChange: PropTypes.func
};

export default withStyles(styles)(DateInput);
