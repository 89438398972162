import React from "react";

import PropTypes from "prop-types";

import swal from '@sweetalert/with-react';
import ReactPanZoom from "react-image-pan-zoom-rotate";

// @material-ui/core components
import Close from "@material-ui/icons/Close";
import Done from "@material-ui/icons/Done";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";
import { green } from '@material-ui/core/colors';

// core components
import Card from "components/Card";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import Table from 'components/Table';

import ActionButtons from 'views/Validate/ActionButtons';

import { cardTitle, tooltip } from "assets/jss/omni.jsx";

import {  changeHumaData } from "providers/api-rest.js";

const styles = {
  tooltip,
  cardIconTitle: {
    ...cardTitle,
    top: "15px",
    position: 'relative',
    marginBottom: "0px",
    textAlign: "center"
  },
  verticalCenter: {
    margin: "0",
    top: "50%",
    transform: "translateY(-50%)",
    textAlign: "center"
  },
  reactPanZoom: {
    overflow: "hidden",
    position: "relative"
  },
  tableHeadColor: {
    fontSize: '12px',
    fontWeight: 900,
    color: '#666'
  },
  selectOptionOcr:{
    cursor: 'pointer',
    "&:hover": {
      color: green[500],
      
    },
  },
  textUploadDone: {
    marginRight: '10px'
  }
};

class ValidateGovId extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      human_id: props.human_id,
      user_info: props.user_info,
      ine_validator: props.ine_validator,
      data_update_human: {}
    };
  }

  showModalError(response) {
    if (response.status === 401) {
      if (response['data']['msg'] === 'invalid role') {
        swal(
          'Lo siento!',
          'No tiene el rol necesario para realizar esta accion.',
          'error'
        );
      } else {
        swal(
          'Lo siento!',
          'Esta acción requiere verificación con yubikey',
          'error'
        );
      }
    } else {
      if (response['data'] && response['data']['error']) {
        swal('Lo siento!', response['data']['error'], 'error');
      } else {
        swal('Lo siento!', 'Error inesperado, contacta a Ingeniería', 'error');
      }
    }
  }

  renderCheckIsValid(is_valid){
    if (is_valid){
      return <Done style={{ color: green[500] }} />
    } else {
      return <Close color="secondary"/>
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  formatDateForCuenca(string){
    return string.split('/').reverse().join('-');
  }

  async updateOrMarkedHumanData(key, type){
    const { ine_validator, data_update_human } = this.state;

    ine_validator[key].is_valid = true;
    ine_validator[key].selected = type;

    if (type === 'cuenca'){
      delete data_update_human[key];
      this.setState({ ine_validator, data_update_human });
      return
    }

    const value = ine_validator[key]   

    if (key === 'name'){
      const metadata = value['aiokyc_metadata'];
      data_update_human[key] = {
        nombres: this.capitalizeFirstLetter(
          metadata['first_name']
        ),
        primer_apellido: this.capitalizeFirstLetter(
          metadata['last_name']
        )
      }
      if (metadata['middle_name']){
        data_update_human[key]['segundo_apellido'] = this.capitalizeFirstLetter(
          metadata['middle_name']
        )
      }
    } else if (key === 'curp'){
      data_update_human[key] = ine_validator[key]['aiokyc']
    } else if (key === 'birth_date'){
      data_update_human[key] = this.formatDateForCuenca(
        ine_validator[key]['aiokyc']
      )
    }

    this.setState({ ine_validator, data_update_human });
  }

  preOptionSelected = async validation => {
    const { optionSelected } = this.props;
    const { data_update_human, human_id } = this.state;
    const data_for_update = {
      curp: data_update_human['curp'],
      birth_date: data_update_human['birth_date']
    }
    if ('name' in data_for_update) {
      const name = data_update_human['name'];
      data_for_update.nombres = name['nombres'];
      data_for_update.primer_apellido = name['primer_apellido'];
      if (name['segundo_apellido'])
        data_for_update.segundo_apellido = name['segundo_apellido'];
    }
    if (Object.keys(data_update_human).length !== 0){
      const response = await changeHumaData({
        human_id: human_id,
        ...data_for_update
      });
      if (response.status !== 201) {
        if (response.status === 400) {
          swal(
            '¡Lo siento!', 'El curp es incorrecto.',
            'error'
          );
        } else {
          this.showModalError(response)
        }
        return
      }
    }
    optionSelected(validation, 'gov_id_status')
  }

  renderWithTooltip(key, value, type){
    const { classes } = this.props;
    if (!('selected' in value) && (value.is_valid ||
        ['validity', 'gender'].includes(key))){
      return value[type]
    }
    let tooltip_text = 'Marcar como informacion correcta.';
    if (type === 'aiokyc'){
      tooltip_text = 'Marcar como informacion correcta y actualizar (Human).'
    }
    return (
      <Tooltip
        placement="top"
        title={tooltip_text}
        classes={{ tooltip: classes.tooltip }}
        TransitionProps={{ timeout: 100 }}
      >
        <span
          className={classes.selectOptionOcr}
          style={ (value.selected === type) ? { color: green[500] }: {}}
          onClick={() => {
            this.updateOrMarkedHumanData(key, type);
          }}
        >{value[type]}</span>
      </Tooltip>
    )
  }

  render() {
    const { classes } = this.props;
    const { user_info, ine_validator } = this.state;
    let tabledata = [['',
       <span className={classes.tableHeadColor}>Cuenca</span>,
       <span className={classes.tableHeadColor}>AIOKYC (OCR)</span>,
       <span className={classes.tableHeadColor}>Status</span>,
    ]]
    if (ine_validator){
      for (const [key, value] of Object.entries(ine_validator)) {
        tabledata.push(
          [
            <span className={classes.tableHeadColor}>
              {this.capitalizeFirstLetter(key)}
            </span>,
            this.renderWithTooltip(key, value, 'cuenca'),
            this.renderWithTooltip(key, value, 'aiokyc'),
            Object.keys(value).includes('cuenca') || value.is_valid
            ? this.renderCheckIsValid(value.is_valid) : null,
          ]
        )
      };
    }
    const enableSucceded = !ine_validator || (
      ine_validator.name.is_valid &&
      ine_validator.curp.is_valid &&
      ine_validator.birth_date.is_valid)
    return (
      <Card>
        <CardHeader color="primary" icon>
          <h4 className={classes.cardIconTitle}>
            Validacion de <strong>Documento de Identidad</strong>
          </h4>
        </CardHeader>
        <CardBody>
          <hr />
          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              <Card className={classes.verticalCenter}>
                <CardBody>
                  {ine_validator
                    ? <div>
                        <Card>
                          <CardBody>
                            <a
                              rel="noopener noreferrer"
                              href="https://listanominal.ine.mx/scpln/"
                              target="_blank"
                            >
                              {" "}
                              LISTA NOMINAL INEX.MX
                            </a>
                          </CardBody>
                        </Card>
                        <Table
                          tableData={tabledata}
                        />
                      </div>
                    : <div>
                      NOMBRE: <strong>{user_info.name}</strong>
                      <br />
                      NACIMIENTO: <strong>{user_info.birth_date}</strong>
                      <br />
                      CURP: <strong>{user_info.curp}</strong>
                      <br />
                    </div>
                  }
                  <ActionButtons
                    validating='gov_id_status'
                    enableSucceded={enableSucceded}
                    optionSelected={this.preOptionSelected}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={7}>
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      className={classes.reactPanZoom}
                    >
                      {user_info.document_front ? (
                        <ReactPanZoom
                          key={4}
                          image={user_info.document_front.blob}
                          alt="document image"
                        />
                      ) : null}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      className={classes.reactPanZoom}
                    >
                      {user_info.document_back ? (
                        <ReactPanZoom
                          key={4}
                          image={user_info.document_back.blob}
                          alt="document image"
                        />
                      ) : null}
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

ValidateGovId.propTypes = {
  classes: PropTypes.object.isRequired,
  user_info: PropTypes.object.isRequired,
  optionSelected: PropTypes.func,
};

export default withStyles(styles)(ValidateGovId);
