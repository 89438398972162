import React from "react";
import PropTypes from "prop-types";

import { Redirect, NavLink } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "components/CustomButtons";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";

import errorPageStyles from "assets/jss/omni/views/errorPageStyles.jsx";

class ErrorPage extends React.Component {
  render() {
    const { classes, location, match } = this.props;
    if (location.pathname === "/") {
      return <Redirect to={location.pathname} />;
    }

    let status = match.params.status;
    let message = "Error el conectarse al servidor, intente de nuevo.";
    if (status === "404") {
      message = "No encontramos la pagina solicitada.";
    } else if (status === "403") {
      message = "No podemos acceder a esta informacion en Staging";
    }

    return (
      <div className={classes.contentCenter}>
        <GridContainer>
          <GridItem md={12}>
            <h1 className={classes.title}>{status}</h1>
            <h2 className={classes.subTitle}>{message} :(</h2>
            <h4 className={classes.description}>
              ¡Ooooups! Parece que ocurrio un error, por favor contacta a
              ingeniería.
            </h4>
            <NavLink to={location.state.pathname}>
              <Button justIcon round simple color="warning" className="edit">
                Reintentar
              </Button>
            </NavLink>
            <br />
            <NavLink to="/">
              <Button justIcon round simple color="warning" className="edit">
                Volver a inicio
              </Button>
            </NavLink>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ErrorPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withStyles(errorPageStyles)(ErrorPage);
