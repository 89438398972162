import React from 'react';

import PropTypes from 'prop-types';

import swal from '@sweetalert/with-react';

// core components
import Button from 'components/CustomButtons';
import Card from 'components/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem';
import TableShowMore from 'components/TableShowMore';

import 'rsuite/dist/styles/rsuite-default.css';

import {
    getHoldTransactions,
    patchHoldTransaction,
} from 'providers/api-rest';

function HoldTransfers({ userId }) {
    const freeTransfer = async id => {
        const reason = await swal(`
            Esta acción regresará la transferencia a la cuenta de origen.

            Recuerda que esto se hace automáticamente después de 48hrs de retenida.
        `, {
            title: 'Cuidado 😳',
            content: {
                element: 'input',
                attributes: {
                    placeholder: 'Ingresa el motivo',
                    type: 'text',
                },
            },
            buttons: ['Cancelar', 'Confirmar'],
        });

        if (!reason) {
            await swal('Lo siento 😓', 'Motivo de acción requerido', 'error');
            return;
        }

        const response = await patchHoldTransaction({ id, reason });
        if (response.status === 201) {
            await swal('Éxito 🥳', 'Todo salió chidori', 'success');
        } else if (response.status === 401 || response.status === 422) {
            await swal('Lo siento 😓', 'No tienes el rol necesario', 'error');
        } else {
            await swal(
                'Lo siento 😓',
                `No se pudo completar esta acción:
                ${response.error || ''}`,
                'error',
            );
        }
    };

    const columns = [
        {
            name: 'Clave Rastreo',
            field: 'clave_rastreo',
            width: 120,
        },
        {
            name: 'Concepto',
            field: 'concepto',
            width: 100,
        },
        {
            name: 'Fecha',
            field: 'created_at',
            width: 90,
            type: 'datetime',
        },
        {
            name: 'Monto',
            field: 'amount',
            width: 90,
            type: 'amountType',
        }/*,
        {
            name: 'Acciones',
            field: 'transaction_id',
            width: 100,
            Cell: row => (
                <span>
                    <Button
                        round
                        color="danger"
                        onClick={() => freeTransfer(row.original.transaction_id)}
                    >
                        Retornar al Origen
                    </Button>
                </span>
            )
        }*/
    ];

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>

                    <CardBody>
                        {userId && (
                            <TableShowMore
                                search={userId}
                                columns={columns}
                                restModel={getHoldTransactions}
                                detailUri={'/admin/deposit/'}
                                activeClick={false}
                            />
                        )}
                    </CardBody>

                </Card>
            </GridItem>
        </GridContainer>
    );
}

HoldTransfers.propTypes = {
    userId: PropTypes.string,
};

export default HoldTransfers;
