import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import Swal from 'sweetalert2';
import Moment from "react-moment";

import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import Close from "@material-ui/icons/Close";
import Done from "@material-ui/icons/Done";
import Replay from "@material-ui/icons/Replay";

import {
    getProofOfAddressDetail,
    getDocuments,
    postVerifiedChangeStatus,
} from 'providers/api-rest';

const styles = {
    headerName: {
		background: '#616C93',
		height: '70px',
		fontSize: '20px',
		padding: '14px',
		color: 'white',
		fontWeight: '600',
		'&:hover': {
			cursor: 'pointer',
			background: 'rgb(163, 169, 194, 0.15)',
			color: '#616C93',
		}
	},
    cardStyle: {
		borderRadius: 0,
		marginTop: '10px',
		marginBottom: '5px',
		boxShadow: '0 0 0 0',
		'& td': {
			fontFamily: "Helvetica Neue",
			color: '#8D8D8D',
			borderTop: '1px solid rgba(141, 141, 141, 0.25)',
			fontWeight: '400',
		},
		'& tr:first-child': {
			'& td':{
				borderTop: 0
			}
		},
		'& > div':{
		  	padding: '0 20px'
		}
	},
    reactPanZoom: {
        marginTop: "10px",
        fontSize: '20px',
        overflow: "hidden",
        position: "relative"
    },
    verticalCenter: {
        margin: "0",
        top: "50%",
        transform: "translateY(-50%)",
        textAlign: "center",
        position: "relative"
    },
};

function ProofOfAddressDetail({ classes, match }) {
    const { userId } = match.params;
    const [[loadingPoa, poaBlob], setPoa] = useState([true, null]);
    const [mimetype, setMimetype] = useState(null);
    const [resetData, setData] = useState({
        humanId: '',
        name: '',
        address: '',
        createdAt: null,
    });

    const getPoa = async () => {
        const response = await getDocuments({
            document_type: 'proofs_of_address',
            user_id: userId,
        });
        if (response.status === 200) {
          setMimetype(response.type);
          setPoa([false, response.data]);
        }
    };

    const getDetail = async () => {
        const response = await getProofOfAddressDetail({userId});
        if (response.status === 200) {
            const { human_id, name, created_at, address } = response.data;
            setData({
                humanId: human_id,
                createdAt: created_at,
                address,
                name,
            });
        }
    };

    const fetchData = async () => {
        await getDetail();
    };

    const changePoaStatus = async status => {
        const { dismiss } = await Swal.fire({
            title: '¿Estás segure?',
            text: '¿Segure que deseas realizar esta acción? 🤔',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: 'Confirmar',
        });
        if (dismiss) return;
        const data = {
          human_id: resetData.humanId,
          document: 'proof_of_address_status',
          document_status: status
        };
        const response = await postVerifiedChangeStatus(data);

        if (response.status === 201) {
            setTimeout(() => window.close(), 5500);
            await Swal.fire({
                title: '¡Éxito! 🥳',
                text:
                    `Se ${status === 'failed' ? 'rechazó' : 'aprobó'} el comprobante correctamente.

                    Esta ventana se cerrará en 5 segundos
                    `,
                icon: 'success',
                confirmButtonText: 'Cerrar de una',
            });
            window.close();
        } else {
            Swal.fire({
                title: 'Lo siento 😥',
                text:
                    `El sistema no quizo actualizar el estatus del comprobante.

                    Intenta en un momento, si no jala, avísale a los inges. 🥲
                    `,
                icon: 'error',
                confirmButtonText: 'Upsi',
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        getPoa();
    }, [resetData]);

    return (
        <div>

                <div
                    className={classes.headerName}
                    onClick={() => window.open(`/admin/customer/${encodeURIComponent(resetData.humanId)}`)}
                >
                    {resetData.name}
                    <br/>
                    {resetData.createdAt && (
                        <Moment utc>{resetData.createdAt}</Moment>
                    )}
                </div>

                <Card className={classes.cardStyle}>
                    <CardBody>
                        <GridContainer spacing={8}>

                            <GridItem xs={12}>
                                <GridContainer spacing={16}>

                                    <GridItem
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        className={classes.reactPanZoom}
                                    >
                                        <div className={classes.verticalCenter}>
                                            <strong>Dirección: </strong> {resetData.address}
                                        </div>
                                    </GridItem>
                                    <GridItem
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        className={classes.reactPanZoom}
                                    >
                                        <div className={classes.verticalCenter}>
                                            {loadingPoa ? <CircularProgress /> : (
                                              poaBlob && (
                                                <embed
                                                  src={poaBlob}
                                                  width="500"
                                                  height="750"
                                                  type={mimetype}
                                                />
                                              )
                                            )}
                                        </div>
                                    </GridItem>

                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={2}>
                                        <Button
                                            round
                                            color="danger"
                                            onClick={() => changePoaStatus('rejected')}
                                        >
                                            <span>
                                                Rechazar
                                            </span>
                                            <Close />
                                        </Button>
                                    </GridItem>

                                    <GridItem xs={2}>
                                        <Button
                                            round
                                            color="info"
                                            onClick={() => changePoaStatus('upload_again')}
                                        >
                                            <span>
                                                Reintentar
                                            </span>
                                            <Replay />
                                        </Button>
                                    </GridItem>

                                    <GridItem xs={2}>
                                        <Button
                                            round
                                            color="success"
                                            onClick={() => changePoaStatus('succeeded')}
                                        >
                                            <span>
                                                Validar
                                            </span>
                                            <Done />
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                        </GridContainer>
                    </CardBody>
                </Card>

        </div>
    );
}

export default withRouter(withStyles(styles)(ProofOfAddressDetail));
