import React from "react";
import { withRouter } from 'react-router';

import PropTypes from "prop-types";

import swal from "@sweetalert/with-react";

import Moment from "react-moment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Badge from "components/Badge";
import Button from "components/CustomButtons";
import Card from "components/Card";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput";
import CustomTabs from "components/CustomTabs";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import Timeline from "components/Timeline";

import Public from "@material-ui/icons/Public";
import Send from "@material-ui/icons/Send";
import Security from "@material-ui/icons/Security";

import ZendeskFooter from "views/Zendesk/ZendeskFooter.jsx";

import {
  getTicketDetail,
  getTicketComments,
  postCreateTicket,
  putCreateTicket
} from "providers/api-rest.js";
import { processError } from "../../components/ErrorBoundary";

const styles = {
  cardCenter: {
    textAlign: "center"
  },
  cardIconTitle: {
    color: "#000",
    textAlign: "left"
  },
  cardIconBadge: {
    float: "right"
  },
  marginTitleTicket: {
    marginLeft: "20px"
  }
};

class ZendeskDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ticket: {},
      user_id: null,
      ticket_id: null
    };
  }

  async getTicketDetail(ticket_id) {
    const response = await getTicketDetail(ticket_id);
    this.setState({
      response: response,
      ticket: response.data
    });
  }

  async getTicketComments(ticket_id) {
    const response = await getTicketComments(ticket_id);
    let comments = [];
    for (const index in response.data) {
      const comment = response.data[index];
      comments.push({
        inverted: true,
        is_public: comment.public ? "Publico" : "Privado",
        photoUrl: comment.author_photo,
        title: comment.author_name,
        titleColor: "info",
        date: comment.created_at,
        dateColor: "primary",
        body: (
          <div key={index}>
            <div dangerouslySetInnerHTML={{ __html: comment.html_body }} />
            {comment.attachments.map((attach, index) => (
              <div key={index}>
                <hr />
                <a
                  href={attach.content_url}
                  alt={attach.file_name}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {attach.file_name}
                </a>
              </div>
            ))}
          </div>
        )
      });
    }
    this.setState({
      response: response,
      comments: comments
    });
  }

  componentWillMount() {
    const { match } = this.props;
    const ticket_id = match.params.ticket_id;
    const user_id = match.params.user_id;
    let data = {
      user_id: user_id,
      ticket_id: ticket_id,
      ticket: this.state.ticket
    };
    if (ticket_id !== undefined) {
      this.getTicketDetail(ticket_id);
      this.getTicketComments(ticket_id);
    } else if (user_id !== undefined) {
      data["ticket"]["user_id"] = user_id;
    }
    this.setState(data);
  }

  newTicket = ticket => {
    this.setState({ ticket: ticket });
  };

  changeTicketTitle(event) {
    const ticket = this.state.ticket;
    ticket["subject"] = event.target.value;
    this.setState({ ticket: ticket });
  }

  changeTicketComment(event, is_private) {
    const ticket = this.state.ticket;
    ticket["comment"] = {
      body: event.target.value,
      public: !is_private
    };
    this.setState({ ticket: ticket });
  }

  handleCreateTicket = async () => {
    const ticket = this.state.ticket;
    if (!ticket.id) {
      const response = await postCreateTicket(this.state.ticket);
      if (response.status === 201) {
        const ticket_id = response.data.ticket_id;
        const { history } = this.props;
        history.push("/admin/ticket/" + ticket_id);
      } else {
        swal("Lo siento!", "Error inesperado, contacta a Ingeniería", "error");
      }
    } else {
      const response = await putCreateTicket(this.state.ticket);
      if (response.status === 200) {
        this.getTicketDetail(ticket.id);
        this.getTicketComments(ticket.id);
      } else {
        swal("Lo siento!", "Error inesperado, contacta a Ingeniería", "error");
      }
    }
  };

  render() {
    const {
      ticket,
      comments,
      response,
      ticket_id,
    } = this.state;
    const { classes, location } = this.props;

    if (response && response.status !== 200 && response.status !== 201) {
      processError(
        response.status,
        location.pathname,
        false,
        (
          (response.data && response.data.message)
          || 'Error en obtener detalle de ticket'
        )
      );
    }

    if (Object.keys(ticket).length === 0) {
      return null;
    }
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card className={classes.cardCenter}>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <Send />
                </CardIcon>
                {ticket_id ? (
                  <div>
                    <h4 className={classes.cardIconTitle}>
                      <div className={classes.marginTitleTicket}>
                        <CustomInput
                          labelText="Titulo del Ticket..."
                          id="title_title"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => this.changeTicketTitle(event),
                            value: !ticket["subject"] ? "" : ticket["subject"]
                          }}
                        />
                        <div className={classes.cardIconBadge}>
                          <Badge color="primary">
                            <Moment utc>{ticket.updated_at}</Moment>
                          </Badge>
                        </div>
                        <br />
                        <small>
                          <strong>Cliente: </strong>
                          {ticket.requester_name} ({ticket.requester_email})
                          {" | "}
                          <strong>Agente: </strong>
                          {ticket.submitter_name}
                        </small>
                      </div>
                    </h4>
                    <hr />
                  </div>
                ) : (
                  <GridContainer>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={6}
                      className={classes.marginTitleTicket}
                    >
                      <CustomInput
                        labelText="Titulo del Ticket..."
                        id="title_title"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: event => this.changeTicketTitle(event),
                          value: !ticket["subject"] ? "" : ticket["subject"]
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                )}
              </CardHeader>
              <CardBody>
                <CustomTabs
                  headerColor="info"
                  tabs={[
                    {
                      tabName: "Nota Privada",
                      tabIcon: Security,
                      tabContent: (
                        <CustomInput
                          labelText="Escribir nota privado..."
                          id="note"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event =>
                              this.changeTicketComment(event, true),
                            multiline: true,
                            rows: 10,
                            value: !ticket["comment"]
                              ? ""
                              : ticket["comment"]["body"]
                          }}
                        />
                      )
                    },
                    {
                      tabName: "Nota Publica",
                      tabIcon: Public,
                      tabContent: (
                        <CustomInput
                          labelText="Escribir nota publico..."
                          id="note"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event =>
                              this.changeTicketComment(event, false),
                            multiline: true,
                            rows: 10,
                            value: !ticket["comment"]
                              ? ""
                              : ticket["comment"]["body"]
                          }}
                        />
                      )
                    }
                  ]}
                  footer={{
                    content: (
                      <ZendeskFooter
                        ticket={ticket}
                        newTicket={this.newTicket}
                      />
                    )
                  }}
                />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Button color="info" onClick={this.handleCreateTicket}>
                      {ticket_id ? "Guardar" : "Crear Ticket"}
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            {comments ? <Timeline simple stories={comments} /> : null}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ZendeskDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(ZendeskDetail));
