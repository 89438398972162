import React from 'react';
import PropTypes from 'prop-types';

// core components
import Card from 'components/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem';
import TableShowMore from 'components/TableShowMore';

// @material-ui/icons
import Review from '@material-ui/icons/RateReview';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { cardTitle } from "assets/jss/omni";
import { getPasswordResetList } from 'providers/api-rest';

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: 0,
    },
};

function PasswordResetList({ classes }) {
    const columns = [
        {
            name: 'Nombre',
            field: 'name',
            width: 180,
        },
        {
            name: 'Fecha de Video',
            field: 'created_at',
            width: 180,
            type: 'datetime',
        },
    ];

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>

                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <Review />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>
                            Verificación de Videos para Reestablecimientos de Contraseña
                        </h4>
                    </CardHeader>

                    <CardBody>
                        <TableShowMore
                            search='US'
                            columns={columns}
                            restModel={getPasswordResetList}
                            detailUri={userId => `/admin/password_resets/${userId}/`}
                        />
                    </CardBody>

                </Card>
            </GridItem>
        </GridContainer>
    );
}

PasswordResetList.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(PasswordResetList);
