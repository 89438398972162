import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from 'components/Snackbar';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            errorInfo: null,
            openSnackbar: true
        };

        this.isOmniError = this.isOmniError.bind(this);
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error,
            errorInfo,
            openSnackbar: true
        });
    }

    isOmniError(error) {
        return typeof error === 'object' && error !== null;
    } 

    render() {
        const { error, openSnackbar } = this.state;
        const { boundaryType, children } = this.props;

        const loginErrorStatus = [401, 422];
        if (error) {
            // If the error is caused by auth, throw an error so GlobalErrorBoundary can capture it
            // to be redirected to Login page.
            // Check if error.status exists because this case is only for request errors.
            if (error.status && loginErrorStatus.includes(error.status)) {
                const loginError = {
                    error: error.status,
                    pathname: error.pathname
                };
                throw loginError;
            }

            // Check which message to use depending on Error Boundary type
            // Error can be caused by request or React component. 
            // If error is an object, it was caused by the request.
            const omniError = this.isOmniError(error);

            let errorMessage = '';
            let message = '';
            if (!omniError) {
                errorMessage = `Error de Omni en vistas de ${boundaryType}: ${error.toString()}`;
            } else {
                switch (boundaryType) {
                    case 'gaita':
                        errorMessage = `Error con Gaita: ${error.status} - ${error.info}`;
                        break;
                    
                    case 'customer':
                        message = error.list ? 'Lista de Clientes' : 'Cliente';
                        errorMessage = `Error cargando datos de ${message} de réplica: ${error.status || 'Sin conexión'}`;
                        break;
                    
                    case 'transfer':
                        message = error.redshift
                            ? 'Redshift'
                            : error.oaxaca ? 'Oaxaca' : 'Réplica';
                        let notFound = '';
                        if (error.status === 404) notFound = 'La transferencia no fue encontrada';
    
                        errorMessage = `Error cargando datos de ${message}: ${error.status || 'Sin conexión'}
                                    ${error.info || ''}
                                    ${notFound}`;
                        break;

                    case 'zendesk':
                        errorMessage= `Error cargando tickets en Zendesk: ${error.status} – ${error.info}`;
                        break;

                    default:
                        errorMessage = `Error místico y desconocido, contacta a ingeniería`;
                        break;
                }
            }

            // Set a timeout for Snackbar to be closed after 10 seconds.
            setTimeout(() => this.setState({ openSnackbar: false }), 10000);

            return (
                <Snackbar
                    message={errorMessage}
                    color='warning'
                    place='bc'
                    open={openSnackbar}
                    closeNotification={() => this.setState({ openSnackbar: false })}
                    close
                />
            );
        }

        // If there was no error, render the component normally.
        return children;
    }
}

ErrorBoundary.propTypes = {
    boundaryType: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export default ErrorBoundary;

export const processError = (status, pathname, list = false, info = '', redshift = false, oaxaca = false) => {
    const error = {
        status,
        pathname,
        list,
        info,
        redshift,
        oaxaca
    };
    throw error;
};
