import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

// core components
import Card from 'components/Card';
import CardBody from 'components/Card/CardBody';
import CardHeaderSearch from 'components/CardHeaderSearch';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem';
import TableShowMore from 'components/TableShowMore';

import {
	getDateLastCuenca,
	getWhatsappTransfersList,
} from 'providers/api-rest';

function WhatsappTransfers({ user_id }) {
	const [search, setSearch] = useState(null);

	useEffect(() => {
	    if (user_id && search !== user_id) setSearch(user_id)
	 });

	const newSearchHandle = search =>
		setSearch(search);
	
	const columns = [
		{
			name: 'Cliente',
			field: 'source_human_name',
			width: 120,
		},
		{
			name: 'Destinatario',
			field: 'destination',
			width: 150,
		},
		{
			name: 'Fecha',
			field: 'created_at',
			width: 100,
			type: 'datetime',
		},
		{
			name: 'Monto',
			field: 'amount',
			width: 100,
			type: 'amount',
		},
		{
			name: 'Estado',
			field: 'status',
			width: 100,
		},
		{
			name: 'Tipo',
			field: 'network',
			width: 100,
		},
	];
	return (
		<GridContainer>
			<GridItem xs={12}>
				<Card>

					<CardHeaderSearch
						type='WhatsappTransfer'
						title='Transferencias Whatsapp'
						placeholder='Buscar (Transfer ID) (User ID)'
						lastCreatedAtModel={getDateLastCuenca}
						newSearchHandle={newSearchHandle}
					/>

					<CardBody>
						{search && (
							<TableShowMore
								search={search}
								columns={columns}
								restModel={getWhatsappTransfersList}
								detailUri='/admin/whatsapp_transfers/'
							/>
						)}
					</CardBody>

				</Card>
			</GridItem>
		</GridContainer>
	);
}

WhatsappTransfers.propTypes = {
  user_id: PropTypes.string,
};

export default WhatsappTransfers;
