import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import routes from "routes.js";

import pagesStyle from "assets/jss/omni/layouts/authStyle.jsx";

import login from "assets/img/login.jpeg";
import error from "assets/img/clint-mckoy.jpg";

class Pages extends React.Component {
  componentDidMount() {
    document.body.style.overflow = "unset";
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth" || prop.layout === "/error") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBgImage = () => {
    const props = this.props;
    const { location } = props;
    const status = location.pathname.split("/")[2];
    if (window.location.pathname.includes("/auth/login")) {
      return login;
    } else if (window.location.pathname.includes("/error/" + status)) {
      return error;
    }
  };
  render() {
    const props = this.props;
    const { classes } = props;
    return (
      <div>
        <div className={classes.wrapper} ref={ref => (this.wrapper = ref)}>
          <div
            className={classes.fullPage}
            style={{ backgroundImage: "url(" + this.getBgImage() + ")" }}
          >
            <Switch>{this.getRoutes(routes)}</Switch>
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(pagesStyle)(Pages);
