import React from "react";
import { withRouter } from 'react-router';

import ReactTable from 'react-table';
import Moment from 'react-moment';

import Button from 'components/CustomButtons';
import Badge from "components/Badge";
import Card from "components/Card";
import CardBody from "components/Card/CardBody.jsx";
import FormControl from '@material-ui/core/FormControl';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { OutlinedInput} from "@material-ui/core";
import Select from '@material-ui/core/Select';
import TextField  from "@material-ui/core/TextField";

import { withStyles } from "@material-ui/core"
import swal from "@sweetalert/with-react";


import {
    getPldAlertDetail, 
    getPldAlertList, 
    updatePldAlert, 
    getUserReportList, 
    createUserReport, 
    createReport
} from 'providers/api-rest';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        border: 'none',
        borderRadius: '3px',
        position: 'relative',
        width: '40%',
        padding: '12px',
        margin: '.3125rem 1px',
        fontSize: '12px',
        textTransform: 'uppercase',
        textAlign: 'center',
        backgroundColor: '#616C93',
        color: '#f2f2f2',
        cursor: 'pointer',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        '&:hover,&:focus': {
            backgroundColor: 'rgb(163, 169, 194, 0.15)',
            color: '#616C93',
        },
        '&:disabled': {
            backgroundColor: 'rgb(163, 169, 194, 0.15)',
            color: '#666666',
            cursor: 'default',
        }
    },
    AccordionTable: {
        width: '100%',
        textAlign: 'left'
    },
    input: {
        color: '#404040',
        fontSize: '14px',
        lineHeight: '1.42857',
        top: '10px',
        width: '60%',
        marginBottom: '20px',
        height: '30px',
        border: '0.5px solid #616C93',
        borderRadius: '3px',
    },
    margin: {
        textAlign: 'right',
        marginRight: '1em',
        marginTop: '0.3em'
    }
};

const alertsColors = {
    created: 'danger',
    ready_for_analysis: 'danger',
    analysis: 'info',
    dictum: 'info',
    justified: 'success',
    unusual: 'danger',
    reported: 'info',
};

const alertStatuses = [
    'created',
    'ready_for_analysis',
    'analysis',
    'dictum',
    'unusual',
    'justified',
    'reported',
]


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


class ChangeStatusAlert extends React.Component {

    constructor(props) {
        super(props);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onChangeReportDate = this.onChangeReportDate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.alertId = props.alert.id;

        let committeeMeetingDate = '';
        if (props.alert.committee_meeting_date !== null) {
            // Solo se toma YYYY-MM, por eso el substring
            committeeMeetingDate = props.alert.committee_meeting_date.substring(0, 7)
        }

        this.state = {
            status: props.alert.status,
            committeeMeetingDate: committeeMeetingDate,
        };
    }

    onChangeStatus({ target: { value }}) {
        this.setState({status: value});
    }

    onChangeReportDate({ target: { value }}) {
        this.setState({committeeMeetingDate: value});
    }

    async onSubmit(event) {
        event.preventDefault()
        const { status, committeeMeetingDate} = this.state;
        const alertUpdateRequest = {
            status: status,
        };

        if (status === 'dictum') {
            alertUpdateRequest['committee_meeting_date'] = `${committeeMeetingDate}-01`;
        }

        const response = await updatePldAlert(this.alertId, alertUpdateRequest);
        // Se aprovecha un "hack" para que el swal padre de este componente
        // pueda retornar el resultado de la actualización
        //
        // Como internamente el swal padre no tiene botones visibles
        // pero implicitamente siempre tiene un "cancel" es posible
        // modificar el valor de retorno del "cancel"
        swal.setActionValue({cancel: {response}});
        swal.close();
    }

    render(){
        return (
            <div >
                <form onSubmit={this.onSubmit}>
                    <select onChange={this.onChangeStatus} value={this.state.status}>
                        {alertStatuses.map((status) => 
                                <option key={status} value={status}>{status.toUpperCase()}</option>
                            )
                        }
                    </select>
                    <br/>
                    {this.state.status === 'dictum' && 
                        <div>
                            <input
                                type="month"
                                id="ccc_meeting_date"
                                placeholder="Presentación CCC"
                                value={this.state.committeeMeetingDate}
                                onChange={this.onChangeReportDate}
                            />
                        </div>
                    }
                    <button className={styles.button} type="submit">Actualizar</button>
                </form>
            </div>
        );
    }
}


class PldAlerts extends React.Component {

    constructor(props) {
        super(props);
        this.fetchAlert = this.fetchAlert.bind(this);
        this.fetchAlerts = this.fetchAlerts.bind(this);
        this.onChangeStatusFilter = this.onChangeStatusFilter.bind(this);
        this.onChangeComeetteMeetingDateFilter = this.onChangeComeetteMeetingDateFilter.bind(this);
        this.onChangeClabeFilter = this.onChangeClabeFilter.bind(this);
        this.onApplyFilters = this.onApplyFilters.bind(this);

        this.state = {
            alerts: [],
            filters: {
                status: '',
                comeette_meeting_date: '',
                created_before: '',
                clabe: '',
            },
            loading: false,
        };
    }

    componentDidMount() {
        this.fetchAlerts();
    }

    async fetchAlerts() {
        this.setState({loading: true});
        let filters = {};
        const {
            status,
            comeette_meeting_date,
            clabe,
            created_before
        } = this.state.filters;
        // Se crea un nuevo objeto filtro 
        // solo con keys que tengan un valor no "falsy"
        if (status) {
            filters.status = status;
        }

        if (comeette_meeting_date) {
            filters.committee_meeting_date = comeette_meeting_date;
        }

        if (clabe) {
            filters.clabe = clabe;
        } 

        if (created_before) {
            filters.created_before = created_before;
        }

        const resp = await getPldAlertList(filters);
        
        filters = this.state.filters;
        if (!resp.data.next_page_uri) {
            filters.created_before = '';
        } else {
            let params = new URLSearchParams(resp.data.next_page_uri.split('?')[1]);
            filters.created_before = decodeURIComponent(params.get('created_before'));
        }
        this.setState({
            loading: false,
            alerts: this.state.alerts.concat(resp.data.items),
            filters: filters,
        });
    }

    async fetchAlert(alertId) {
        const resp = await getPldAlertDetail(alertId);
        const { alerts } = this.state;
        const index = alerts.findIndex((alert) => alert.id === resp.data.id);
        alerts[index] = resp.data;
        this.setState({alerts});
    }

    onChangeStatusFilter({ target: { value }}) {
        const { filters } = this.state;
        filters.status = value;
        filters.created_before = '';
        this.setState({filters});
    }

    onChangeComeetteMeetingDateFilter({ target: { value }}) {
        const { filters } = this.state;
        if (value) {
            filters.comeette_meeting_date = `${value}-01`;
        }
        else {
            filters.comeette_meeting_date = '';
        }
            
        filters.created_before = '';
        this.setState({filters});
    }

    onChangeClabeFilter({ target: { value }}) {
        const { filters } = this.state;
        filters.clabe = value;
        filters.created_before = '';
        this.setState({filters});
    }

    onApplyFilters() {
        this.setState({alerts: []});
        this.fetchAlerts();
    }

    async createUserReport(userId, committeeMeetingDate, event) {
        event.preventDefault();

        const userReportRequest = {
            user_id: userId,
            committee_meeting_date: committeeMeetingDate.split('T')[0],
        };

        let resp = await getUserReportList(userReportRequest);
        let userReport = null;

        if (resp.status === 200 && resp.data.items.length === 0) {
            resp = await createUserReport(userReportRequest);
            userReport = resp.data;
        } else {
            // en el backend solo puede haber un UserReport
            // por user_id y por committee_meeting_date
            userReport = resp.data.items[0];
        }
        window.open(`/admin/pld_reports/${userReport.id}`);
    }

    render(){
        return (
            <Card>
                <CardBody>
                <GridItem>
                        <Button 
                            round 
                            color="info" 
                            onClick={() => swal({ 
                                title: 'Generar XML',
                                content: (
                                    <div>
                                        <input
                                            type="month"
                                            id="ccc_meeting_date"
                                            placeholder="Presentación CCC"
                                            onChange={(event) => {
                                                event.preventDefault()
                                                this.setState({xmlReportDate: `${event.target.value}-01`})
                                            }}
                                        />
                                        <button onClick={async () => {
                                            let link = document.createElement("a");
                                            const xmlFile = await createReport({committee_meeting_date: this.state.xmlReportDate});
                                            link.download = `Reporte-${this.state.xmlReportDate}.xml`;
                                            link.href = xmlFile.data;
                                            link.click();
                                            URL.revokeObjectURL(link.href);
                                        }}>Descargar</button>
                                    </div>
                                ),
                                className: 'sweet-overlay',
                                buttons: true,
                            })}
                        >
                            Generar XML
                        </Button>
                </GridItem>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel >Filtrar por Status</InputLabel>
                            <Select
                                value={this.state.filters.status}
                                onChange={this.onChangeStatusFilter}
                                input={<OutlinedInput labelWidth={20} />}
                                MenuProps={MenuProps}
                                fullWidth
                            >
                                {alertStatuses.map((status) => 
                                        <MenuItem key={status} value={status}>{status.toUpperCase()}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem>
                        <input
                            type="month"
                            placeholder="Filtrar por Fecha de Presentación"
                            value={this.state.filters.committee_meeting_date}
                            onChange={this.onChangeComeetteMeetingDateFilter}
                        />
                    </GridItem>
                    <GridItem>
                        <TextField 
                            value={this.state.filters.clabe} 
                            onChange={this.onChangeClabeFilter} 
                            label="Filtrar por CLABE"
                            fullWidth
                        />
                    </GridItem>
                    <GridItem>
                        <Button round color="info" onClick={this.onApplyFilters}>Aplicar Filtro</Button>
                    </GridItem>
                </GridContainer>

                <ReactTable
                    manual
                    data={this.state.alerts}
                    columns={
                        [
                            {
                                Header: 'Fecha de apertura',
                                accessor: 'user_created_at',
                                Cell: props =>  <div><Moment date={Date.parse(props.value)} local/> UTC</div>
                            },
                            {
                                Header: 'Nombre',
                                accessor: 'user_name',
                            },
                            {
                                Header: 'Clabe',
                                accessor: 'clabe',
                            },
                            {
                                Header: 'Detección',
                                accessor: 'created_at',
                                Cell: props => <Moment date={Date.parse(props.value)} format="YYYY-MM-DD"/>
                            },
                            {
                                Header: '# Dias',
                                accessor: 'created_at',
                                Cell: props => {
                                    const oneDay = 1000 * 60 * 60 * 24;
                                    const timeDiff = new Date() - Date.parse(props.value);
                                    return Math.round(timeDiff/oneDay);
                                }
                            },
                            {
                                Header: 'Fecha de transacción',
                                accessor: 'transaction_date',
                                Cell: props => <Moment date={Date.parse(props.value)} format="YYYY-MM-DD"/>
                            },
                            {
                                Header: 'Escenario',
                                accessor: 'description',
                            },
                            {
                                Header: 'Estatus',
                                accessor: 'status',
                                Cell: props => {
                                    return (
                                        <p onClick={
                                            async () => {
                                            const result = await swal(
                                            { 
                                                title: 'Cambiar status',
                                                content: <ChangeStatusAlert alert={props.original}/>,
                                                className: 'sweet-overlay',
                                                buttons: false,
                                            });
                                            if (result === null) {
                                                // Este caso es cuando hacen clic fuera del swal 
                                                // y se cierra automáticamente
                                                return;
                                            } 
                                            if (result.response.status === 200){
                                                swal(
                                                    'Excelente 🎉',
                                                    'Se cambió el status existosamente',
                                                    'success',
                                                );
                                                await this.fetchAlert(props.original.id);
                                            } else {
                                                swal(
                                                    'Lo siento 😓',
                                                    `Ocurrió un error: ${
                                                        result.response.data.msg || result.response.data.error
                                                    }`,
                                                    'error',
                                                );
                                            }
                                            
                                        }
                                        }>
                                            <Badge color={alertsColors[props.value]}>{props.value}</Badge>
                                        </p>
                                    );
                                }
                            },
                            {
                                Header: 'Fecha de presentación Comité',
                                accessor: 'committee_meeting_date',
                                Cell: props => props.value  !== null ? <Moment date={Date.parse(props.value)} format="YYYY-MM"/> : null
                            },
                            {
                                Header: '',
                                accessor: 'status',
                                Cell: props => props.value === 'unusual' ? (
                                    <p onClick={(e) => this.createUserReport(props.original.user_id, props.original.committee_meeting_date, e)}>
                                        <Badge color={'info'}>Crear Reporte</Badge>
                                    </p>
                                ) : null
                            },
                        ]
                    }
                    loading={this.state.loading}
                    showPageSizeOptions={false}
                    showPagination={false}
                    minRows={0}
                    filterable={false}
                    sortable={false}
                    className='-cards -striped -highlight'
                />
                {
                    this.state.filters.created_before &&
                        <h4 className={styles.alignCenter}>
                            <span onClick={this.fetchAlerts}>Ver más resultados</span>
                        </h4>
                }
                </CardBody>
            </Card>
        );
    }
}

export default withRouter(withStyles(styles)(PldAlerts));
