import React from "react";

import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Card from "components/Card";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";

import ActionButtons from 'views/Validate/ActionButtons';

import { cardTitle } from "assets/jss/omni.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    top: "15px",
    position: 'relative',
    marginBottom: "0px",
    textAlign: "center"
  },
  verticalCenter: {
    margin: "0",
    top: "50%",
    transform: "translateY(-50%)",
    textAlign: "center"
  }
};

class ValidateCurp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_info: props.user_info
    };
  }

  render() {
    const { classes, optionSelected } = this.props;
    const { user_info } = this.state;
    return (
      <Card>
        <CardHeader color="primary" icon>
          <h4 className={classes.cardIconTitle}>
            Validacion de <strong>Curp</strong>
          </h4>
        </CardHeader>
        <CardBody>
          <hr />
          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              <Card className={classes.verticalCenter}>
                <CardBody>
                  CURP: <strong>{user_info.curp}</strong>
                  <br />
                  <ActionButtons 
                    validating='curp_status'
                    enableSucceded={true}
                    optionSelected={optionSelected}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={7}>
              <Card>
                <CardBody>
                  <a
                    rel="noopener noreferrer"
                    href="https://www.gob.mx/curp/"
                    target="_blank"
                  >
                    {" "}
                    CONSULTAR CURP EN RENAPO
                  </a>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

ValidateCurp.propTypes = {
  classes: PropTypes.object.isRequired,
  user_info: PropTypes.object.isRequired,
  optionSelected: PropTypes.func
};

export default withStyles(styles)(ValidateCurp);
