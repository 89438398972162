import React, { Component } from "react";
import PropTypes from "prop-types";
import Compose from "../Compose/index.jsx";
import Toolbar from "../Toolbar/index.jsx";
import Message from "../Message/index.jsx";
import moment from "moment";

import swal from "@sweetalert/with-react";

import "./MessageList.css";

import { postMessagesList } from "providers/api-rest.js";

const MY_USER_ID = "apple";

export default class MessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderMessages() {
    if (this.props.messages.length > 0) {
      let i = 0;
      let messageCount = this.props.messages.length;
      let messages = [];

      while (i < messageCount) {
        let previous = this.props.messages[i - 1];
        let current = this.props.messages[i];
        let next = this.props.messages[i + 1];
        let isMine = current.author === MY_USER_ID;
        let currentMoment = moment(current.timestamp);
        let prevBySameAuthor = false;
        let nextBySameAuthor = false;
        let startsSequence = true;
        let endsSequence = true;
        let showTimestamp = true;

        if (previous) {
          let previousMoment = moment(previous.timestamp);
          let previousDuration = moment.duration(
            currentMoment.diff(previousMoment)
          );
          prevBySameAuthor = previous.author === current.author;

          if (prevBySameAuthor && previousDuration.as("hours") < 1) {
            startsSequence = false;
          }

          if (previousDuration.as("hours") < 1) {
            showTimestamp = false;
          }
        }

        if (next) {
          let nextMoment = moment(next.timestamp);
          let nextDuration = moment.duration(nextMoment.diff(currentMoment));
          nextBySameAuthor = next.author === current.author;

          if (nextBySameAuthor && nextDuration.as("hours") < 1) {
            endsSequence = false;
          }
        }

        messages.push(
          <Message
            key={i}
            isMine={isMine}
            startsSequence={startsSequence}
            endsSequence={endsSequence}
            showTimestamp={showTimestamp}
            data={current}
          />
        );
        i += 1;
      }

      return messages;
    }
  }

  postMessagesList = async (chat_id, message) => {
    const response = await postMessagesList(chat_id, { message: message });

    if (response.status === 200) {
      let messages = this.props.messages;
      messages.push({
        id: response.data.contactId,
        author: "apple",
        message: response.data.message,
        timestamp: response.data.date,
        fromName: response.data.fromName
      });
      this.setState({
        messages: messages
      });
    } else {
      swal("Lo siento!", "Error inesperado, contacta a Ingeniería", "error");
    }
  };

  render() {
    let chat_id = null;
    if (this.props.messages.length) {
      chat_id = this.props.messages[0]["id"];
    }
    let items = [];
    if (this.props.clientId) {
      const linkCuenca = "/admin/customer/" + this.props.clientId;
      const linkBotmaker = "https://go.botmaker.com/#/chats/" + this.props.botmakerId;
      items = [
        <a key="profile" target="_blank" href={linkBotmaker} rel="noopener noreferrer">
          Ir al Chat de Botmaker
        </a>,
        <span>&nbsp;|&nbsp;</span>,
        <a key="profile" target="_blank" href={linkCuenca} rel="noopener noreferrer">
          Ir al Perfil en Cuenca
        </a>
      ];
    } else if (this.props.clientName) {
      items = [<span key="not-profile">Sin perfil en Cuenca</span>];
    }

    return (
      <div className="message-list">
        <Toolbar title={this.props.clientName} rightItems={items} />
        <div className="message-list-container">{this.renderMessages()}</div>
        {this.props.clientName ? (
          <Compose chatId={chat_id} postMessagesList={this.postMessagesList} />
        ) : null}
      </div>
    );
  }
}

MessageList.propTypes = {
  messages: PropTypes.array.isRequired,
  clientName: PropTypes.string,
  clientId: PropTypes.string,
  botmakerId: PropTypes.string
};
