import React from "react";

import PropTypes from "prop-types";

import ReactPanZoom from "react-image-pan-zoom-rotate";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Card from "components/Card";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";

import ActionButtons from 'views/Validate/ActionButtons';

import { cardTitle } from "assets/jss/omni.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    top: "15px",
    position: 'relative',
    marginBottom: "0px",
    textAlign: "center"
  },
  verticalCenter: {
    margin: "0",
    top: "50%",
    transform: "translateY(-50%)",
    textAlign: "center"
  },
  textCenter: {
    textAlign: "center"
  },
  reactPanZoom: {
    overflow: "hidden",
    position: "relative",
    height: "557px"
  }
};

class ValidateProofOfLife extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_info: props.user_info
    };
  }

  render() {
    const { classes, optionSelected } = this.props;
    const { user_info } = this.state;
    return (
      <Card>
        <CardHeader color="primary" icon>
          <h4 className={classes.cardIconTitle}>
            Validacion de <strong>Prueba de Vida</strong>
          </h4>
        </CardHeader>
        <CardBody>
          <hr />
          <div className={classes.textCenter}>
            <ActionButtons
              validating='selfie_video_status'
              enableSucceded={true}
              optionSelected={optionSelected}
            />
          </div>

          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              <Card>
                <CardBody>
                  {user_info.document_front ? (
                    <div className={classes.reactPanZoom}>
                      <ReactPanZoom
                        image={user_info.document_front.blob}
                        alt="document image"
                      />
                      <br />
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={7} className={classes.textCenter}>
              <Card>
                <CardBody>
                  {user_info.proof_of_live ? (
                    <video controls height="550" autoPlay>
                      <source src={user_info.proof_of_live.blob} />
                    </video>
                  ) : null}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

ValidateProofOfLife.propTypes = {
  classes: PropTypes.object.isRequired,
  user_info: PropTypes.object.isRequired,
  optionSelected: PropTypes.func
};

export default withStyles(styles)(ValidateProofOfLife);
