import React from 'react';

import Tooltip from "@material-ui/core/Tooltip";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import FiberNewIcon from '@material-ui/icons/FiberNewOutlined';

class CommonController {
  formatPrice(price) {
    const amount = (price / 100).toLocaleString(undefined,
      {minimumFractionDigits: 2, maximumFractionDigits: 2}
    );
    return '$ ' + amount
  }

  formatPhoneNumber(numberString) {
    const onlyNumbers = numberString.replace(/\D/g, '');

    let formatter = '';
    let i = 0;
    if (onlyNumbers.startsWith('52')) {
      formatter = '+00 (00) 0000 0000';
      return formatter.replace(/0/g, _ => onlyNumbers[i++]);
    }

    return numberString;
  }
}

export default new CommonController();

const colors = {
  ss: '#e6f3ff',
  special_edition: "#fff0ff",
  hiking: '#c1f2e7'
};

const text = {
  ss: 'SomeoneSomewhere',
  special_edition: 'Edición Especial',
  hiking: 'Edición Travesia'
};

export function getReferredColor(referred) {
  return colors[referred] ? colors[referred] : 'none';
}

export function getReferredText(referred) {
  return text[referred] ? text[referred] : '';
}

export const PaymentTypeIcon = ({ type, classes, size }) => {
  switch (type) {
    case 'new':
      return (
        <Tooltip
          id="tooltip-top"
          title="Nuevo"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <FiberNewIcon
            fontSize={size || 'default'}
            style={{ color: '#ab8d36' }}
          />
        </Tooltip>
      );

    case 'purchase':
      return (
        <Tooltip
          id="tooltip-top"
          title="Pagado"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <AttachMoneyIcon
            fontSize={size || 'default'}
            style={{ color: '#36ab36' }}
          />
        </Tooltip>
      );

    case 'free':
      return (
        <Tooltip
          id="tooltip-top"
          title="Gratis"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <MoneyOffIcon
            fontSize={size || 'default'}
            style={{ color: '#77657b' }}
          />
        </Tooltip>
      );

    default:
      return null;
  }
};

export const States = {
  MX: [
    {key: 'AS', value: 'Aguascalientes'},
    {key: 'BC', value: 'Baja California'},
    {key: 'BS', value: 'Baja California Sur'},
    {key: 'CC', value: 'Campeche'},
    {key: 'CS', value: 'Chiapas'},
    {key: 'CH', value: 'Chihuahua'},
    {key: 'CL', value: 'Coahuila'},
    {key: 'CM', value: 'Colima'},
    {key: 'DF', value: 'Ciudad de México'},
    {key: 'DG', value: 'Durango'},
    {key: 'GT', value: 'Guanajuato'},
    {key: 'GR', value: 'Guerrero'},
    {key: 'HG', value: 'Hidalgo'},
    {key: 'JC', value: 'Jalisco'},
    {key: 'MC', value: 'México'},
    {key: 'MN', value: 'Michoacán'},
    {key: 'MS', value: 'Morelos'},
    {key: 'NT', value: 'Nayarit'},
    {key: 'NL', value: 'Nuevo León'},
    {key: 'OC', value: 'Oaxaca'},
    {key: 'PL', value: 'Puebla'},
    {key: 'QT', value: 'Querétaro'},
    {key: 'QR', value: 'Quintana Roo'},
    {key: 'SP', value: 'San Luis Potosí'},
    {key: 'SL', value: 'Sinaloa'},
    {key: 'SR', value: 'Sonora'},
    {key: 'TC', value: 'Tabasco'},
    {key: 'TL', value: 'Tlaxcala'},
    {key: 'TS', value: 'Tamaulipas'},
    {key: 'VZ', value: 'Veracruz'},
    {key: 'YN', value: 'Yucatán'},
    {key: 'ZS', value: 'Zacatecas'},
  ],
  World: [
    {key: 'NE', value: 'Nacido en el Extranjero'}
  ],
};

export const pueblaPostalCodes = new Set([
  '72000','72010','72013','72014','72015','72016','72017','72019','72020','72023','72029','72030','72040','72044','72045',
  '72050','72060','72062','72070','72072','72080','72089','72090','72094','72100','72103','72104','72105','72106','72108',
  '72110','72114','72115','72116','72120','72124','72127','72130','72135','72140','72150','72154','72160','72170','72176',
  '72180','72189','72190','72193','72194','72197','72200','72210','72219','72220','72223','72225','72227','72228','72229',
  '72230','72240','72243','72250','72260','72266','72267','72270','72280','72290','72300','72303','72304','72307','72308',
  '72309','72310','72314','72320','72330','72340','72350','72353','72359','72360','72364','72365','72370','72373','72377',
  '72380','72390','72400','72403','72410','72420','72423','72424','72425','72430','72440','72450','72453','72456','72459',
  '72460','72464','72468','72470','72474','72477','72478','72480','72482','72483','72484','72490','72492','72493','72494',
  '72495','72496','72497','72498','72499','72500','72501','72503','72504','72505','72510','72520','72523','72530','72532',
  '72533','72534','72538','72540','72543','72550','72560','72564','72565','72567','72570','72573','72574','72575','72580',
  '72582','72583','72584','72587','72589','72590','72592','72593','72594','72595','72596','72597','72599','72670','72680',
  '72683','72685','72700','72702','72703','72704','72705','72706','72707','72708','72710','72712','72713','72720','72723',
  '72724','72725','72730','72733','72735','72750','72753','72754','72755','72756','72757','72760','72762','72764','72765',
  '72766','72767','72768','72770','72772','72774','72775','72776','72777','72778','72788','72803','72805','72810','72813',
  '72814','72815','72817','72819','72820','72823','72824','72825','72826','72828','72830','72833','72834','72835','72836',
  '72837','72840','72843','72845','72846','72848','72900','72943','72960','72963','72967','72968','72970','72971','72973',
  '72975','72977','72978',
]);
