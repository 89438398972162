import React, { Component } from "react";
import PropTypes from "prop-types";
import "./ConversationSearch.css";

export default class ConversationSearch extends Component {
  constructor({ getConversationList }) {
    super();
    this.state = {
      getConversationList: getConversationList
    };
  }

  handleChange(search) {
    this.state.getConversationList(search);
  }

  render() {
    return (
      <div className="conversation-search">
        <input
          type="search"
          className="conversation-search-input"
          placeholder="Buscar..."
          input={this.state.input}
          onKeyPress={evt => {
            if (evt.key === "Enter") {
              this.handleChange(evt.target.value);
            }
          }}
        />
      </div>
    );
  }
}

ConversationSearch.propTypes = {
  getConversationList: PropTypes.func.isRequired
};
