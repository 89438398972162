import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

// core components
import Card from 'components/Card';
import CardBody from 'components/Card/CardBody';
import CardHeaderSearch from 'components/CardHeaderSearch';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem';
import TableShowMore from 'components/TableShowMore';

import {
  getDateLastCuenca,
  getServiceTransactions
} from 'providers/api-rest';

function BillPayments({ userId }) {
  const [search, setSearch] = useState(null);

  useEffect(() => {
    if (userId) setSearch(userId);
  }, []);

  const newSearchHandle = search =>
    setSearch(search);

  const columns = [
    {
      name: 'Servicio',
      field: 'provider',
      width: 150,
    },
    {
      name: '# Cuenta',
      field: 'account_number',
      width: 180,
    },
    {
      name: 'Fecha',
      field: 'created_at',
      width: 100,
      type: 'datetime',
    },
    {
      name: 'Monto',
      field: 'amount',
      width: 70,
      type: 'amount',
    },
    {
      name: 'Estado',
      field: 'status',
      width: 70,
    },
  ];
  
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>

          <CardHeaderSearch
            type='BillPayment'
            title='Pagos de Servicios'
            placeholder='Buscar (# Cuenta) (User ID) (Transaction ID) Etc...'
            lastCreatedAtModel={getDateLastCuenca}
            newSearchHandle={newSearchHandle}
          />

          <CardBody>
            {search && (
              <TableShowMore
                search={search}
                columns={columns}
                restModel={getServiceTransactions}
                detailUri='/admin/service/transaction/'
              />
            )}
          </CardBody>

        </Card>
      </GridItem>
    </GridContainer>
  );
}

BillPayments.propTypes = {
  userId: PropTypes.string,
};

export default BillPayments;
