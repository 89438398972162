import React from "react";

import PropTypes from "prop-types";

import swal from "@sweetalert/with-react";

// @material-ui/core components
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import Card from "components/Card";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import ValidateCurp from "views/Validate/ValidateCurp.jsx";
import ValidateGovId from "views/Validate/ValidateGovId.jsx";
import ValidateProofOfAddress from "views/Validate/ValidateProofOfAddress.jsx";
import ValidateProofOfLife from "views/Validate/ValidateProofOfLife.jsx";

import { cardTitle } from "assets/jss/omni.jsx";

import {
  getTakemeDocument,
  getVerifiedValidateIdentity,
  postVerifiedChangeStatus
} from "providers/api-rest.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  verticalCenter: {
    margin: "0",
    top: "50%",
    transform: "translateY(-50%)",
    textAlign: "center",
    position: "relative"
  },
  loadingBox: {
    height: "500px"
  },
  alignRight: {
    color: '#9c27b0 !important',
    cursor: 'pointer',
    position: "relative",
    float: "right",
    fontWeight: "500 !important",
    marginRight: '10px'
  }
};

const document_types = [
  'ine_front', 'ine_back', 'residence', 'residence_back',
  'passport', 'matricula_consular', 'matricula_back'
];

const mapper_documents = {
  curp_status: [],
  gov_id_status: document_types,
  proof_of_address_status: ['proof_of_address'],
  selfie_video_status: [...document_types, 'proof_of_live']
};

class Validate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      identity: null,
      is_loading: true
    };
  }

  componentDidMount() {
    this.getIdentity();
  }

  async getIdentity() {
    this.setState({ identity: null, is_loading: true });
    const response = await getVerifiedValidateIdentity();
    if (response.status === 201) {
      let identity = await this.getTakemeDocuments(response.data);
      this.setState({identity, is_loading: false});
    } else {
      if (response.status === 404) {
        this.setState({ is_loading: false });
      } else {
        swal("Lo siento!", "Error inesperado, contacta a Ingeniería", "error");
      }
    }
  }

  async getTakemeDocuments(identity) {
    let user_info = identity["user_info"];

    // Consult only the necessary documents
    const documents = []
    for (const validation in identity['required_validations']){
      const required = identity['required_validations'][validation];
      for (const y in user_info["documents"]){
        const document = user_info["documents"][y];
        if (mapper_documents[required].includes(document['document_type'])){
          if (required === 'gov_id_status'){
            if (document['document_type'].includes('back')){
              document['document_type'] = 'document_back';
            } else {
              document['document_type'] = 'document_front';
            }
          }
          documents.push(document)
        }
      }
    }

    for (let document_index in documents) {
      let document = documents[document_index];
      const response = await getTakemeDocument({
        feedme_uri: document["feedme_url"]
      });
      if (response.status === 200) {
        user_info[document["document_type"]] = {
          blob: response.data,
          mimetype: response.type,
          feedme_uri: document["feedme_url"]
        };
      }else{
        user_info[document["document_type"]] = {
          blob: 'assets/img/error-image.png',
          mimetype: response.type,
          feedme_uri: document["feedme_url"]
        };
      }
    }
    return identity;
  }

  optionSelected = async (status, validating)  => {
    const { identity } = this.state;
    let data = {
      human_id: identity["human_id"],
      document: validating,
      document_status: status
    };
    const response = await postVerifiedChangeStatus(data);
    if (response.status !== 201) {
      swal("Lo siento!", "Error inesperado, contacta a Ingeniería", "error");
      return;
    }
    let required_validations = identity["required_validations"];
    var index = required_validations.indexOf(validating);
    if (index > -1) {
        required_validations.splice(index, 1);
    }
    if (required_validations.length !== 0) {
      this.setState({ identity: identity });
    } else {
      this.getIdentity();
    }
  };

  formatDataUserInfo(user_info) {
    user_info["name"] = `
      ${user_info["nombre"] + " " || ""}
      ${user_info["apellido_paterno"] + " " || ""}
      ${user_info["apellido_materno"] + " " || ""}
    `;
    user_info["full_address"] = `
      ${user_info["address"]["calle"] + " " || ""}
      ${user_info["address"]["numero_ext"] + " " || ""}
      ${user_info["address"]["numero_int"] + " " || ""}
      ${user_info["address"]["colonia"] + " " || ""}
      ${user_info["address"]["ciudad"] + " " || ""}
      ${user_info["address"]["estado"] + " " || ""}
      ${user_info["address"]["codigo_postal"] + " " || ""}
    `;
    return user_info;
  }

  render() {
    const { classes } = this.props;
    const { identity, is_loading } = this.state;
    let user_info = (
      identity ? this.formatDataUserInfo(identity["user_info"]) : null
    );
    const link_human = 'https://omni.cuenca.io/admin/customer/';
    if (is_loading) {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Validacion de Informacion
                </h4>
              </CardHeader>
              <CardBody className={classes.loadingBox}>
                <hr />
                <div className={classes.verticalCenter}>
                  <CircularProgress />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    }
    let ine_validator = (
      identity && identity['ine_validator'] &&
      !('error' in identity['ine_validator']) ? (
        identity['ine_validator']
      ) : null
    );
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Validacion de Informacion
                {identity ? (
                  <a
                    href={`${link_human}${identity.human_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.alignRight}>
                    {user_info.name}
                  </a>
                ) : null}
              </h4>
            </CardHeader>
            <CardBody>
              {identity ? (
                <div>
                  {identity.required_validations.includes("curp_status") ? (
                    <ValidateCurp
                      key={0}
                      user_info={user_info}
                      optionSelected={this.optionSelected}
                    />
                  ) : null}

                  {identity.required_validations.includes("gov_id_status") ? (
                    <ValidateGovId
                      key={1}
                      human_id={identity["human_id"]}
                      user_info={user_info}
                      ine_validator={ine_validator}
                      optionSelected={this.optionSelected}
                    />
                  ) : null}

                  {identity.required_validations.includes("proof_of_address_status") ? (
                    <ValidateProofOfAddress
                      key={2}
                      user_info={user_info}
                      optionSelected={this.optionSelected}
                    />
                  ) : null}

                  {identity.required_validations.includes("selfie_video_status") ? (
                    <ValidateProofOfLife
                      key={3}
                      user_info={user_info}
                      optionSelected={this.optionSelected}
                    />
                  ) : null}
                </div>
              ) : (
                <small>
                  <hr />
                  <SnackbarContent
                    message="Excelente, ya no hay documentos para validar"
                    color="success"
                  />
                </small>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Validate.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Validate);
