import React from "react";
import { Redirect } from "react-router-dom";

import Snackbar from 'components/Snackbar';

class GlobalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
      openSnackbar: true
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { children } = this.props;
    const { error, openSnackbar } = this.state;

    const redirectLoginErrors = [401, 422];
    
    if (error && redirectLoginErrors.includes(error.error)) {
      return (
        <Redirect
          push
          to={{
            pathname: "/auth/login",
            state: { pathname: error.pathname }
          }}
        />
      );
    } else if (typeof error !== 'object') {
      setTimeout(() => this.setState({ openSnackbar: false }), 10000);

      return(
        <Snackbar
          message='Error en Omni, contacta a ingeniería'
          color='warning'
          place='bc'
          open={openSnackbar}
          closeNotification={() => this.setState({ openSnackbar: false })}
          close
        />
      );
    }

    return children;
  }
}

export default GlobalErrorBoundary;
