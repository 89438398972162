import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { DateRangePicker } from 'rsuite';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Card from 'components/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem';
import TableShowMore from 'components/TableShowMore';

import 'rsuite/dist/styles/rsuite-default.css'; 

import { getActionLogs } from 'providers/api-rest';

const styles = {
    dateRangePicker: {
        textAlign: 'right',
        marginRight: '1em',
        marginTop: '0.3em'
    }
};


function LogOfCustomers({ classes, searchConcat }) {
    const [search, setSearch] = useState(null);
    const [dateRange, setDateRange] = useState([]);

    useEffect(() => {
        if (searchConcat && search !== searchConcat) setSearch(searchConcat);
    }, []);

    const columns = [
        {
            name: 'Agente',
            field: 'agent',
            width: 90,
        },
        {
            name: 'Fecha',
            field: 'created_at',
            width: 90,
            type: 'datetime',
        },
        {
            name: 'Descripción',
            field: 'request_endpoint',
            width: 90,
            Cell: row => (
                <span>
                    {row.original.request_endpoint}{" "}
                    ({row.original.request_method})
                </span>
            )
        },
        {
            name: 'Extra Info',
            field: 'request_args_data',
            width: 200,
            Cell: row => (
                <span>
                    <pre>
                    	{Object.entries(row.original.request_args).map(([key, subject], i) => {
                            return (
                              <li className="travelcompany-input" key={i}>
                                <span className="input-label">{key}: <strong>{subject}</strong></span>
                              </li>
                            )
                          }
                        )}
                        {Object.entries(row.original.request_data).map(([key, subject], i) => {
                            return (
                              <li className="travelcompany-input" key={i}>
                                <span className="input-label">{key}: <strong>{subject}</strong></span>
                              </li>
                            )
                          }
                        )}
                    </pre>
                </span>
            )
        }

    ];

    const created_after = (
        dateRange.length !== 0 ? dateRange[0].toISOString() : null
    )
    const created_before = (
        dateRange.length !== 0 ? dateRange[1].toISOString() : null
    )

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <div className={classes.dateRangePicker}>
                        <DateRangePicker
                            placement='bottomEnd'
                            onChange={value => setDateRange(value)}
                        />
                    </div>
                    <CardBody>
                        {search && (
                            <TableShowMore
                                search={search}
                                created_after={created_after}
                                created_before={created_before}
                                columns={columns}
                                restModel={getActionLogs}
                                detailUri='#/'
                            />
                        )}
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

LogOfCustomers.propTypes = {
    searchConcat: PropTypes.string,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LogOfCustomers);
