import React from "react";

import PropTypes from "prop-types";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import CustomInput from "components/CustomInput";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";

import customSelectStyle from "assets/jss/omni/customSelectStyle.jsx";

import { getTicketGroups } from "providers/api-rest.js";

class ZendeskFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      ticket: props.ticket,
      newTicket: props.newTicket
    };
    this.postal_code_id = 360024687932;
  }

  async getTicketGroups() {
    const response = await getTicketGroups();
    this.setState({
      groups:
        response.data && response.data.results ? response.data.results : []
    });
    this.setState({
      ticket: this.props.ticket
    });
  }

  componentDidMount() {
    this.getTicketGroups();
  }

  handleChangeSelect(event, field) {
    const ticket = this.state.ticket;
    if (field === "assignee") {
      const value = event.target.value;
      const values = value.split(",");

      ticket["group_id"] = values[0];
      if (values.length > 1) {
        ticket["assignee_id"] = values[1];
      }
    } else {
      ticket[field] = event.target.value;
    }
    this.setState({ ticket: ticket });
    this.state.newTicket(ticket);
  }

  changeTicketPostalCode(event) {
    const ticket = this.state.ticket;
    ticket["custom_fields"] = [
      {
        id: this.postal_code_id,
        value: event.target.value
      }
    ];
    this.setState({ ticket: ticket });
    this.state.newTicket(ticket);
  }

  render() {
    const { classes } = this.props;
    const ticket = this.state.ticket;
    const groups = this.state.groups;
    let assigne = "";
    let type = "";
    let status = "";
    let priority = "";
    let postal_code = "";
    if (ticket) {
      if (ticket.group_id || ticket.assignee_id) {
        assigne = !ticket
          ? ""
          : ticket.group_id + "," + (ticket.assignee_id || "");
      }
      if (ticket.type) {
        type = ticket.type;
      }
      if (ticket.status) {
        status = ticket.status;
      }
      if (ticket.priority) {
        priority = ticket.priority;
      }
      if (ticket.custom_fields) {
        for (let index in ticket.custom_fields) {
          if (ticket.custom_fields[index]["id"] === this.postal_code_id) {
            postal_code = ticket.custom_fields[index]["value"];
          }
        }
      }
    }
    if (groups.length === 0) {
      return null;
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Asignar a
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={assigne}
              onChange={event => this.handleChangeSelect(event, "assignee")}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select"
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Seleccionar
              </MenuItem>
              {groups.map((group, index) => (
                <MenuItem
                  key={index}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={group.group_id + "," + (group.assignee_id || "")}
                >
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={2}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Tipo
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={type}
              onChange={event => this.handleChangeSelect(event, "type")}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select"
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Seleccionar
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="incident"
              >
                Incidencia
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="question"
              >
                Pregunta
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="problem"
              >
                Problema
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="task"
              >
                Tarea
              </MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={2}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Prioridad
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={priority}
              onChange={event => this.handleChangeSelect(event, "priority")}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select"
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Seleccionar
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="low"
              >
                Low
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="normal"
              >
                Normal
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="high"
              >
                High
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="urgent"
              >
                Urgent
              </MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={2}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Estado
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={status}
              onChange={event => this.handleChangeSelect(event, "status")}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select"
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Seleccionar
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="new"
              >
                New
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="open"
              >
                Open
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="pending"
              >
                Pending
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="solved"
              >
                Solved
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="closed"
              >
                Closed
              </MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={2}>
          <CustomInput
            labelText="Codigo Postal..."
            id="note"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.changeTicketPostalCode(event),
              value: postal_code
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

ZendeskFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  ticket: PropTypes.object.isRequired,
  newTicket: PropTypes.func.isRequired
};

export default withStyles(customSelectStyle)(ZendeskFooter);
