import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';
import Moment from 'react-moment';

// core components
import { withStyles } from '@material-ui/core';
import { detailMiniSubtitle } from 'assets/jss/omni.jsx';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem.jsx';
import MovementDetail from 'components/MovementDetail';

import Utils from 'common/utils';

import {
    getWalletTransactionDetail,
} from 'providers/api-rest';

const styles = {
    detailMiniSubtitle: { ...detailMiniSubtitle },
    lighterText: {
        opacity: '0.25',
        color: '#3A3A3A',
    },
};

function WalletTransactionDetail({classes, match }) {
    const [transaction, setTransaction] = useState(null);

    const getTransferInfo = async transactionId => {
        const response = await getWalletTransactionDetail(transactionId);

        if (response.status === 200)
            setTransaction(response.data.result);
    };

    useEffect(() => {
        const { transactionId } = match.params;
        getTransferInfo(transactionId);
    }, []);

    if (!transaction) return null;

    // Format data to display
    const human = {
        name: transaction.source_name,
        id: transaction.source_id,
    };

    const status = transaction.status;

    const mainData = [
        <Moment utc>{transaction.created_at}</Moment>,
        Utils.formatPrice(transaction.amount),
        { status: transaction.status },
        <span>
            CONCEPTO:
            {transaction.descriptor}
        </span>,
        <span>
            TIPO TRANSACCIÓN:
            {transaction.transaction_type}
        </span>,
        <span>
            <strong>WALLET ID: </strong>
            {transaction.wallet_id}
        </span>
    ];

    return (
        <GridContainer className="container">
            <GridItem xs={12}>
                <MovementDetail
                    human={human}
                    mainData={mainData}
                    status={status}
                />
            </GridItem>
        </GridContainer>
    );
}

WalletTransactionDetail.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default withRouter(
    withStyles(styles)(WalletTransactionDetail)
);
