import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';

// core components
import Card from 'components/Card';
import CardBody from 'components/Card/CardBody';
import CardHeaderSearch from 'components/CardHeaderSearch';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem';
import TableShowMore from 'components/TableShowMore';

import {
  getCardTransactions,
  getDateLastCuenca,
} from 'providers/api-rest.js';

function CardTransactions({ match: { params: { userId }}, user_id }) {
  const [search, setSearch] = useState(null);

  useEffect(() => {
    if (userId && search !== userId) setSearch(userId);
    else if (user_id) setSearch(user_id);
  });

  const newSearchHandle = search => setSearch(search);

  const columns = [
    {
      name: 'Comercio',
      field: 'merchant_name',
      width: 150,
    },
    {
      name: 'Fecha',
      field: 'created_at',
      width: 100,
      type: 'datetime',
    },
    {
      name: 'Monto',
      field: 'amount',
      width: 100,
      type: 'amount',
    },
    {
      name: 'Tipo',
      field: 'type',
      width: 100,
    },
    {
      name: 'Estado',
      field: 'status',
      width: 100,
    },
    {
      name: 'Tarjeta',
      field: 'card_last4',
      width: 100,
      type: 'card',
    },
    {
      name: 'Tipo de tarjeta',
      field: 'card_type',
      width: 100,
    }
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>

          <CardHeaderSearch
            type="CardTransaction"
            title="Transacciones de Tarjeta"
            placeholder="Buscar (Transaction ID) (User ID)"
            lastCreatedAtModel={getDateLastCuenca}
            newSearchHandle={newSearchHandle}
          />

          <CardBody>
            {search && (
              <TableShowMore
                search={search}
                columns={columns}
                restModel={getCardTransactions}
                detailUri="/admin/card/transaction/"
              />
            )}
          </CardBody>

        </Card>
      </GridItem>
    </GridContainer>
  );
}

CardTransactions.propTypes = {
  match: PropTypes.object,
  user_id: PropTypes.string,
};

export default withRouter(CardTransactions);
