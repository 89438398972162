import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';
import Moment from 'react-moment';

// core components
import { withStyles } from '@material-ui/core';
import { detailMiniSubtitle } from 'assets/jss/omni';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem';
import MovementDetail from 'components/MovementDetail';

import Utils from 'common/utils';

import {
  getArcusInfo,
  getServiceTransaction,
} from 'providers/api-rest';

const styles = {
  detailMiniSubtitle: { ...detailMiniSubtitle },
};

function BillPaymentDetail({ classes, match }) {
  const [transaction, setTransaction] = useState(null);
  const [arcusInfo, setArcusInfo] = useState({
    arcus_id: '',
    ticket_text: '',
  });

  const getArcus = async transactionId => {
    const response = await getArcusInfo(transactionId);

    if (response.status === 200)
      setArcusInfo(response.data.arcus_info);
  };

  const getTransactionInfo = async transactionId => {
    const response = await getServiceTransaction(transactionId);

    if (response.status === 200)
      setTransaction(response.data.result);
  };

  useEffect(() => {
    const { transaction_id } = match.params;
    getTransactionInfo(transaction_id);
    getArcus(transaction_id);
  }, []);

  if (!transaction) return null;

  // Format data to display
  const human = {
    name: transaction.human_name,
    id: transaction.human_id,
  };

  const topData = [
    `${transaction.provider_name} (${transaction.provider_key})`,
    Utils.formatPhoneNumber(transaction.account_number),
  ];

  const { status } = transaction;
  const mainData = [
    <Moment utc>{transaction.created_at}</Moment>,
    Utils.formatPrice(transaction.amount),
    { status: transaction.status },
    (
      <>
        <span className={classes.detailMiniSubtitle}>
          ARCUS TRANSACTION ID
        </span>
        <span>{arcusInfo.arcus_id || 'N/A'}</span>
      </>
    ),
    (
      <>
        <span className={classes.detailMiniSubtitle}>
          TICKET TEXT
        </span>
        <span>{arcusInfo.ticket_text || transaction.descriptor}</span>
      </>
    ),
    arcusInfo.ctid,
  ];

  return (
    <GridContainer className="container">
      <GridItem xs={12}>
        <MovementDetail
          human={human}
          topData={topData}
          mainData={mainData}
          status={status}
        />
      </GridItem>
    </GridContainer>
  );
}

BillPaymentDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(
  withStyles(styles)(BillPaymentDetail)
);
