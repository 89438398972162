import React from "react";
import { withRouter } from 'react-router';

import ReactTable from "react-table";

import PropTypes from "prop-types";

import Moment from "react-moment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import Card from "components/Card";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import { processError } from 'components/ErrorBoundary';

import { cardTitle, tooltip } from "assets/jss/omni.jsx";

import { getTicketList } from "providers/api-rest.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  tooltip
};

class Zendesk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  async onFetchData(state, user_id) {
    this.setState({ loading: true });
    const params = {
      page: state.page + 1,
      page_size: state.pageSize,
      sorted: state.sorted,
      filtered: state.filtered,
      user_id: user_id
    };
    const response = await getTicketList(params);
    this.setState({
      data: response.data && response.data.tickets ? response.data.tickets : [],
      loading: false,
      pages: response.data ? response.data.count : 1,
      response: response
    });
  }

  pushCardTransactionDetail(rowInfo, column) {
    const { history } = this.props;
    return {
      style: {
        cursor: "pointer"
      },
      onClick: () => {
        const block_click = ["human_name"];
        if (!block_click.includes(column.id)) {
          history.push("/admin/ticket/" + rowInfo.original.id);
        }
      }
    };
  }

  render() {
    const {
      response,
      data,
      pages,
      loading
    } = this.state;
    const {
      classes,
      match,
      location,
      user_id
    } = this.props;

    const columns = [
      {
        Header: "ID",
        accessor: "id",
        minWidth: 70,
        sortable: false,
        filterable: false,
        Cell: row => (
          <a
            href={`https://cuencasoporte.zendesk.com/agent/tickets/${row.original.id}`}
            target="_blank"
            style={{
              color: '#616C93',
              textDecoration: 'none',
              fontWeight: 600,
            }}
          >
            {row.original.id}
          </a>
        ),
      },
      {
        Header: "Titulo",
        accessor: "subject",
        minWidth: 200,
        sortable: false,
        filterable: false
      },
      {
        Header: "Creado",
        accessor: "created_at",
        minWidth: 150,
        filterable: false,
        sortable: false,
        Cell: row => <Moment utc>{row.original.created_at}</Moment>
      },
      {
        Header: "Actualizado",
        accessor: "updated_at",
        minWidth: 150,
        filterable: false,
        sortable: false,
        Cell: row => <Moment utc>{row.original.updated_at}</Moment>
      },
      {
        Header: "Estado",
        accessor: "status",
        minWidth: 70,
        sortable: false,
        filterable: false
      },
      {
        Header: "Tipo",
        accessor: "type",
        minWidth: 70,
        sortable: false,
        filterable: false
      },
      {
        Header: "Prioridad",
        accessor: "priority",
        minWidth: 70,
        sortable: false,
        filterable: false
      },
      {
        Header: "Solicitado por",
        accessor: "requester_name",
        filterable: false,
        sortable: false
      },
      {
        Header: "Grupo",
        accessor: "group_name",
        minWidth: 200,
        filterable: false,
        sortable: false,
        Cell: row => (
          <div>
            {row.original.group_name || row.original.assignee_name ? (
              <div>
                {row.original.group_name}
                {row.original.assignee_name ? (
                  <span> / {row.original.assignee_name}</span>
                ) : null}
              </div>
            ) : null}
          </div>
        )
      }
    ];
    
    if (response && response.status !== 200 && response.status !== 201) {
      processError(
        response.status,
        location.pathname,
        false,
        (
          (response.data && response.data.message)
          || 'Error con Zendesk'
        )
      );
    }

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Ticket de zendesk</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                manual
                data={data}
                columns={columns}
                pages={pages}
                defaultPageSize={20}
                loading={loading}
                filterable={true}
                defaultSorted={[{ id: "created_at", desc: true }]}
                onFetchData={state =>
                  this.onFetchData(
                    state,
                    user_id || match.params.user_id
                  )
                }
                className="-highlight"
                noDataText="No se han encontrado resultados"
                getTdProps={(state, rowInfo, column) =>
                  this.pushCardTransactionDetail(rowInfo, column)
                }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Zendesk.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user_id: PropTypes.string.isRequired
};

export default withRouter(withStyles(styles)(Zendesk));
