import React, { useState, useEffect } from 'react';

// components
import { withStyles } from '@material-ui/core';
import swal from '@sweetalert/with-react';

// libraries
import countries from 'i18n-iso-countries';

import { States } from 'common/utils';
import { changeHumanBirthData } from 'providers/api-rest';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        border: 'none',
        borderRadius: '3px',
        position: 'relative',
        width: '40%',
        padding: '12px',
        margin: '.3125rem 1px',
        fontSize: '12px',
        textTransform: 'uppercase',
        textAlign: 'center',
        backgroundColor: '#616C93',
        color: '#f2f2f2',
        cursor: 'pointer',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        '&:hover,&:focus': {
            backgroundColor: 'rgb(163, 169, 194, 0.15)',
            color: '#616C93',
        },
        '&:disabled': {
            backgroundColor: 'rgb(163, 169, 194, 0.15)',
            color: '#666666',
            cursor: 'default',
        }
    },
    input: {
        color: '#404040',
        fontSize: '14px',
        lineHeight: '1.42857',
        top: '10px',
        width: '60%',
        marginBottom: '20px',
        height: '30px',
        border: '0.5px solid #616C93',
        borderRadius: '3px',
    },
};

countries.registerLocale(require('i18n-iso-countries/langs/es.json'));

const BirthInput = ({
    currentData,
    classes,
    humanId,
    type
}) => {
    const [birthData, setData] = useState({});

    const countriesList = countries.getNames('es', {select: 'official'});

    useEffect(() => {
        if (type === 'date') {
            setData({ birth_date: currentData.birth_date });
        } else {
            setData({
                birth_place: currentData.birth_place,
                birth_country: currentData.birth_country,
            });
        } 
    }, []);

    const statesToShow = birthData.birth_country ? (
        States[birthData.birth_country] || States.World
    ) : States.MX;

    const getPastDate = () => {
        const today = new Date();
        today.setFullYear(
            today.getFullYear() - 18
        );

        return `${
            today.getFullYear()
        }-${
            today.getMonth() + 1
        }-${
            today.getDate()
        }`
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = { ...birthData };
        for (const k in data) {
            if (currentData[k] && currentData[k] === data[k]) {
                delete data[k];
            }
        }

        const response = await changeHumanBirthData({
            humanId,
            ...data,
        });

        if (response.status === 201) {
            swal(
                'Excelente 🎉',
                'Se cambiaron exitosamente los datos',
                'success',
            );
        } else {
            swal(
                'Lo siento 😓',
                `Ocurrió un error: ${
                    response.data.msg || response.data.error
                }`,
                'error',
            );
        }
    };

    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit}>
                {type === 'date' ? (
                    <input
                        className={classes.input}
                        type="date"
                        id="birth_date"
                        placeholder="Fecha de nacimiento"
                        min="1930-01-01"
                        max={getPastDate()}
                        value={birthData.birth_date}
                        onChange={e => setData({
                            birth_date: e.target.value
                        })}
                    />
                ) : (
                    <div className={classes.container}>
                        <select
                            required
                            className={classes.input}
                            value={birthData.birth_country || ''}
                            onChange={e => setData({
                                ...birthData,
                                birth_country: e.target.value,
                            })}
                        >
                            <option value="" disabled>
                                País
                            </option>
                            {Object.keys(countriesList).map((k, i) => (
                                <option
                                    key={`${k}${i}`}
                                    value={k}
                                >
                                    {countriesList[k]}
                                </option>
                            ))}
                        </select>
                        <select
                            required
                            className={classes.input}
                            value={birthData.birth_place || ''}
                            onChange={e => setData({
                                ...birthData,
                                birth_place: e.target.value,
                            })}
                        >
                            <option value="" disabled>
                                Estado
                            </option>
                            {statesToShow.map((s, i) => (
                                <option
                                    key={`${s.key}${i}`}
                                    value={s.key}
                                >
                                    {s.value}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <button
                    className={classes.button}
                    type="submit"
                    disabled={!Object.keys(birthData).length}
                >
                    Actualizar
                </button>
            </form>
        </div>
    );
};

export default withStyles(styles)(BirthInput);
