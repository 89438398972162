import React from "react";
import { withRouter } from 'react-router';

import ReactTable from "react-table";

import PropTypes from "prop-types";

import swal from "@sweetalert/with-react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from '@material-ui/core/Switch';

import AutoRenew from "@material-ui/icons/Autorenew";
import CreditCard from "@material-ui/icons/CreditCard";

// core components
import Button from "components/CustomButtons";
import Card from "components/Card";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import { processError } from 'components/ErrorBoundary';

import { cardTitle } from "assets/jss/omni.jsx";

import {
  getCardEditions,
  editCardEdition
} from "providers/api-rest.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  icon: {
    marginLeft: "10%",
    fontSize: "25px"
  },
  updateTable: {
    position: "absolute",
    top: "10px",
    padding: 0,
    margin: 0,
    width: "30%"
  },
  updatedCursor: {
    cursor: "pointer"
  }
};

class GaitaInventory extends React.Component {
  constructor(props) {
    super(props);
    this.folios = {};
    this.state = {
      editedValues:{},
      loading: true,
      folios: {},
      lastState: null
    };
  }

  componentWillReceiveProps = props => {
    if (props.newquote) {
      this.onFetchDataReload();
    }
  };

  async onFetchData(state, referrer = null) {
    this.setState({
      loading: true,
      lastState: state,
      order_status: 'ready_to_order,ordering'
    });
    const response = await getCardEditions();
    let editedValues = {};
    for (let edition of response.data.card_editions) {
      editedValues[edition.id] = {
        'is_active': edition.is_active,
        'inventory': edition.inventory,
      };
    }
    this.setState({
      data: response.data && response.data.card_editions
        ? response.data.card_editions
        : [],
      editedValues: editedValues,
      loading: false,
      pages: 1,
      response: response
    });
  }

  onFetchDataReload() {
    this.setState({ loading: true });
    this.onFetchData(this.state.lastState);
  }

  editValue(editionId, key, value) {
    let changes = this.state.editedValues;
    changes[editionId][key] = value;
    this.setState({ editedValues: changes });
  }

  async patchCardEdition(data){
    const response = await editCardEdition(data);
    if (response.status === 201) {
      swal('Excelente!', response.data.message, 'success');
      this.onFetchDataReload();
    } else {
      swal('Lo sentimos!', 'Ocurrio un error inesperado, contacta a ingeniería', 'error');
    }
  }

  async handleEditValue(edition) {
    let message = "¿Estás seguro que quieres cambiar la edición: " + edition.title + "?";
    await swal(message, {
      title: "Accion requerida",
      buttons: ["Cancelar", "Estoy seguro"]
    }).then(willGroup => {
      if (willGroup) {
        let data = this.state.editedValues[edition.id];
        data['card_edition_id'] = edition.id;
        this.patchCardEdition(data);
      } else {
        return;
      }
    });
  }

  render() {
    const { response } = this.state;
    const { classes, location } = this.props;
    const columns = [
      {
        Header: "Nombre",
        accessor: "title",
        minWidth: 40,
        Cell: row => <span>{row.original.title}</span>
      },
      {
        Header: "Inventario",
        accessor: "inventory",
        minWidth: 40,
        Cell: row =>{
          return (
            <CustomInput
              key='unique'
              id='name'
              inputProps={{
                id: 'unique',
                name: 'unique',
                value: this.state.editedValues[row.original.id].inventory,
                type: 'number',
                onChange: event => {
                  event.preventDefault();
                  this.editValue(row.original.id, 'inventory', parseInt(event.target.value));
                }
              }}
            />
          );
        }
      },
      {
        Header: "Activo",
        accessor: "is_active",
        minWidth: 40,
        Cell: row => (
          <Switch
          checked={this.state.editedValues[row.original.id].is_active}
          onChange={() => this.editValue(row.original.id, 'is_active', !this.state.editedValues[row.original.id].is_active)}
          />
        )
      },
      {
        Header: "Acciones",
        textAlign: 'right !important',
        minWidth: 30,
        Cell: row => (
          <div style={{textAlign: 'right'}}>
            <Button
              size="sm"
              color="primary"
              round
              onClick={() =>
                this.handleEditValue(row.original)
              }
            >
              Guardar
            </Button>
          </div>
        )
      }
    ];


    if (response && response.status !== 200 && response.status !== 201) {
      processError(
        response.status,
        location.pathname,
        false,
        (
          (response.data && (response.data.error || response.data.message))
          || 'Listado ediciones de tarjetas, contacta a ingeniería'
        )
      );
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <CreditCard />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Ediciones de tarjetas
                <span
                  className={classes.updatedCursor}
                  onClick={() => this.onFetchDataReload()}
                >
                  <strong> | </strong> Actualizar
                  <span className={classes.updateTable}>
                    <AutoRenew />
                  </span>
                </span>
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                manual
                data={this.state.data}
                columns={columns}
                pages={this.state.pages}
                defaultPageSize={7}
                loading={this.state.loading}
                filterable={false}
                sortable={false}
                onFetchData={state => this.onFetchData(state)}
                className="-highlight"
                noDataText="No se han encontrado resultados"
                showPageSizeOptions={false}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

GaitaInventory.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  newquote: PropTypes.bool
};

export default withRouter(withStyles(styles)(GaitaInventory));
