import React from "react";

import PropTypes from "prop-types";

// core components
import Card from "components/Card";
import CardBody from "components/Card/CardBody.jsx";
import CardHeaderSearch from "components/CardHeaderSearch";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem";
import TableShowMore from "components/TableShowMore";

import { 
  getDateLastCuenca,
  getDeposits
 } from "providers/api-rest.js";

class Deposits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: null,
    };
  }

  componentDidMount() {
    const { user_id } = this.props;
    if (user_id) this.setState({search: user_id})
  }

  newSearchHandle = (search) => {
    this.setState({search});
  };

  render() {
    const { search } = this.state;
    const columns = [
      {
        name: "ID",
        field: "id",
      },
      {
        name: "Fecha",
        field: "created_at",
        width: 100,
        type: 'datetime'
      },
      {
        name: "Monto",
        field: "amount",
        width: 70,
        type: 'amount'
      },
      {
        name: "Estado",
        field: "status",
        width: 70,
      },
      {
        name: "Tipo",
        field: "network",
        width: 70,
      },
      {
        name: "Rastreo",
        field: "tracking",
        Cell: row => (
          <div>
            {row.original.network === 'spei'
             ? <span>{row.original.tracking_key}</span>
             : <span>{row.original.id}</span>
            }
          </div>
        )
      },
    ];
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeaderSearch
              type='Deposit'
              title='Depósitos'
              placeholder='Buscar (Rastreo) (User ID) (Transfer ID) Etc...'
              lastCreatedAtModel={getDateLastCuenca}
              newSearchHandle={this.newSearchHandle}
            />
            <CardBody>
              {search
                ? <TableShowMore
                    search={search}
                    columns={columns}
                    restModel={getDeposits}
                    detailUri='/admin/deposit/'
                  />
                : null
              }        
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

Deposits.propTypes = {
  user_id: PropTypes.string
};

export default Deposits;