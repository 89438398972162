import { credentialsCreate, credentialsGet } from "./webauthn.js";

const querystring = require("querystring");

const domain =
  process.env.REACT_APP_ENV === "production"
    ? "https://app-14150.on-aptible.com"
    : process.env.REACT_APP_ENV === "staging"
    ? "https://app-14151.on-aptible.com"
    : "http://localhost:8000";

function formatParams(params) {
  if (params.sorted && params.sorted.length > 0) {
    var order = "ASC";
    if (params.sorted[0]["desc"]) {
      order = "DESC";
    }
    params.sorted = [params.sorted[0]["id"], order];
  }

  const filtered = params.filtered;
  params.filtered = [];
  if (filtered && filtered.length > 0) {
    for (let x in filtered) {
      params.filtered.push(filtered[x]["id"] + ":" + filtered[x]["value"]);
    }
  }
  params = querystring.stringify(params);
  return params;
}

async function refreshAccessToken() {
  const request = {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("refresh_token") + ""
    })
  };
  const refresh = await fetch(domain + "/auth/refresh", request);
  const json = await refresh.json();
  localStorage.setItem("access_token", json["access_token"]);
  return json["access_token"];
}

function request(
  url,
  method = "GET",
  data = null,
  headers = {},
  formData = false
) {
  headers["Authorization"] =
    "Bearer " + localStorage.getItem("access_token") + "";
  const request = {
    method: method,
    headers: new Headers(headers)
  };
  if (data) request["body"] = JSON.stringify(data);
  if (formData) {
    if (data) request["body"] = data;
  }
  return fetch(url, request);
}

async function requestAuthentication(headers, json) {
  if (json["msg"] === "Token has expired") {
    const access_token = await refreshAccessToken();
    headers["Authorization"] = "Bearer " + access_token + "";
  } else if (json["msg"] === "Webauthn register is required") {
    const result = await credentialsCreate(json["data"]);
    if ("err" in result) return;
    headers["Webauthn-VerifyCredentialInfo"] = JSON.stringify(result);
  } else if (json["msg"] === "Webauthn login is required") {
    const result = await credentialsGet(json["data"]);
    if ("err" in result) return;
    headers["Webauthn-VerifyAssertion"] = JSON.stringify(result);
  } else if (json["msg"] === "invalid role") {
    return;
  }
  return headers;
}

async function requestJson(url, method = "GET", data = null, headers = {}) {
  try {
    headers["Content-Type"] = "application/json";
    let response = await request(url, method, data, headers);
    const json = await response.json();
    if (response.status === 200) {
      return { data: json, status: response.status };
    } else {
      if (response.status === 401) {
        headers = await requestAuthentication(headers, json);
        if (headers) return requestJson(url, method, data, headers);
      }
      return { data: json, status: response.status };
    }
  } catch (error) {
    return { status: 500 };
  }
}

async function requestBlob(url, method = "GET", data = null, headers = {}) {
  try {
    let response = await request(url, method, data, headers);

    if (response.status === 200) {
      const blob = await response.blob();
      return {
        data: URL.createObjectURL(blob),
        type: blob.type,
        status: response.status
      };
    } else {
      const json = await response.json();
      if (response.status === 401) {
        headers = await requestAuthentication(headers, json);
        if (headers) return requestBlob(url, method, data, headers);
      }
      return { data: json, status: response.status };
    }
  } catch (error) {
    return { status: 500 };
  }
}

async function requestUploadFile(
  url,
  method = "POST",
  data = null,
  headers = {}
) {
  try {
    let response = await request(url, method, data, headers, true);
    const json = await response.json();
    if (response.status === 200) {
      return { data: json, status: response.status };
    } else {
      if (response.status === 401) {
        headers = await requestAuthentication(headers, json);
        if (headers) return requestUploadFile(url, method, data, headers);
      }
      return { data: json, status: response.status };
    }
  } catch (error) {
    return { status: 500 };
  }
}

export const postDocuments = data =>
  requestUploadFile(domain + "/documents", "POST", data);

export const getDocuments = (params = {}) =>
  requestBlob(domain + "/documents?" + formatParams(params), "GET");
export const getCepMexDocument = (tracking_id, _type) =>
  requestBlob(
    `${domain}/cepmex/document/${tracking_id}/${_type}`
  );
export const getTakemeDocument = (params = {}) =>
  requestBlob(domain + "/takeme_document?" + formatParams(params), "GET");
export const getOrderGuide = (params = {}) =>
  requestBlob(domain + "/gaita/download/guide?" + formatParams(params), "GET");
export const getMergedGuide = (params = {}, data) =>
  requestBlob(
    domain + "/gaita/merge/files?" + formatParams(params),
    "POST",
    data,
    { "Content-Type": "application/json" }
  );
export const downloadStatement = (params = {}) =>
  requestJson(`${domain}/statement/download?${formatParams(params)}`);

export const postLoginGoogle = data =>
  requestJson(domain + "/auth/google/", "POST", data);

export const getBalanceEntries = (params = {}) =>
  requestJson(`${domain}/balance_entries?${formatParams(params)}`);
export const getBalanceEntriesWithRole = (params = {}) =>
  requestJson(`${domain}/balance_entries?${formatParams(params)}`, 'PATCH');

export const getActionLogs = (params = {}) =>
  requestJson(`${domain}/action_logs?${formatParams(params)}`);

export const getCardShipmentsInvitations = (params = {}) =>
  requestJson(domain + "/card/shipments/invitations?" + formatParams(params));
export const getCardShipmentsQuoted = (params = {}) =>
  requestJson(domain + "/card/shipments/quoted?" + formatParams(params));
export const postCardShipmentsQuoted = data =>
  requestJson(domain + "/card/shipments/quoted", "POST", data);
export const getCardShipmentsOrdered = (params = {}) =>
  requestJson(domain + "/card/shipments/ordered?" + formatParams(params));
export const deleteCardShipmentsOrdered = order_id =>
  requestJson(domain + "/card/shipments/ordered/" + order_id, "DELETE");
export const postCardShipmentsOrderForeignConfirm = data =>
  requestJson(domain + "/card/shipments/ordered", "POST", data);
export const postCardShipmentsInvitations = data =>
  requestJson(domain + "/card/shipments/invitations", "POST", data);
export const deleteCardShipmentsInvitations = client_id =>
  requestJson(domain + "/card/shipments/invitations/" + client_id, "DELETE");

export const getCardRequest = (search = '') =>
  requestJson(`${domain}/gaita/card_request?search=${search}`);
export const confirmCardRequest = card_request_id =>
  requestJson(`${domain}/gaita/card_request/${card_request_id}`, "PATCH");
export const associateGuieCardRequest = data =>
  requestJson(`${domain}/gaita/associate_guie`, "PATCH", data);
export const editAddressCardRequest = data =>
  requestJson(`${domain}/gaita/edit_address`, "PATCH", data);
export const getGaitaOrdersQuoted = (params = {}) =>
  requestJson(`${domain}/gaita/quotes?${formatParams(params)}`);
export const getGaitaOrdersOrdered = (params = {}) =>
  requestJson(`${domain}/gaita/orders?${formatParams(params)}`);
export const postGaitaOrdersQuoted = data =>
  requestJson(`${domain}/gaita/order/`, "POST", data);
export const patchGaitaOrdersOrdered = data =>
  requestJson(`${domain}/gaita/order/`, "PATCH", data);
export const postGaitaOrderManual = data =>
  requestJson(`${domain}/gaita/order/manual`, 'POST', data);
export const patchGaitaOrdersQuoted = request_id =>
  requestJson(`${domain}/gaita/quote/card_request/${request_id}`, "PATCH");
export const patchGaitaOrdersFinalized = order_id =>
  requestJson(`${domain}/gaita/orders/finalized/${order_id}`, "PATCH");
export const postGaitaCardReposition = data =>
  requestJson(`${domain}/gaita/reposition`, "POST", data);
export const getGaitaCardRequestCount = human_id =>
  requestJson(`${domain}/gaita/card_request_size/${human_id}`);
export const createGaitaCsvOrders = orders => 
  requestJson(`${domain}/gaita/csv`, 'POST', { orders });

export const getConversationList = (params = {}) =>
  requestJson(domain + "/botmaker/conversations?" + formatParams(params));
export const getCardTransaction = id =>
  requestJson(`${domain}/card_transactions/${id}`);
export const getCardTransactionRelated = id =>
  requestJson(`${domain}/card_transactions/related/${id}`);
export const getCardTransactions = (params = {}) =>
  requestJson(`${domain}/card_transactions?${formatParams(params)}`);

export const patchCuencaBlockFraudUser = data =>
  requestJson(domain + "/cuenca/block_fraud_user", "PATCH", data);
export const patchCuencaUnBlockFraudUser = data =>
  requestJson(domain + "/cuenca/un_block_fraud_user", "PATCH", data);
export const postCuencaWhilelistAddUser = data =>
  requestJson(domain + "/cuenca/whilelist_add_user", "POST", data);
export const patchCuencaDeactivateBlockAccount = data =>
  requestJson(domain + "/cuenca/deactivate_block_account", "PATCH", data);
export const deleteCuencaDeactivateCard = data =>
  requestJson(domain + "/cuenca/deactivate_card", "DELETE", data);
export const patchCuencaReactivateCardTrustedAction = data =>
  requestJson(domain + "/cuenca/reactivate_card_trusted_action", "PATCH", data);
export const patchCuencaResetPassword = data =>
  requestJson(domain + "/cuenca/reset_password", "PATCH", data);
export const patchCuencaSendBlockSpeitTransaction = data =>
  requestJson(domain + "/cuenca/send_block_speit_transaction", "PATCH", data);
export const patchHoldTransaction = ({ id, ...data }) =>
  requestJson(`${domain}/cuenca/hold_transaction/${id}`, 'PATCH', data);
export const getHoldTransactions = (params = {}) =>
  requestJson(`${domain}/cuenca/hold_transaction?${formatParams(params)}`);

export const getCardEditions = (params = {}) =>
  requestJson(domain + "/gaita/card-editions", "GET");
export const editCardEdition = data =>
  requestJson(domain + "/gaita/card-editions", "PATCH", data);

export const getCustomers = (params = {}) =>
  requestJson(domain + "/customers?" + formatParams(params));
export const getCustomersUnVerified = (params = {}) =>
  requestJson(domain + "/mati_failures?" + formatParams(params));
export const getCustomer = id => requestJson(domain + "/customers/" + id);
export const getGlobalTransfer = id =>
  requestJson(domain + "/global/transfer/" + id);
export const getGlobalTransfersHeld = (params = {}) =>
  requestJson(`${domain}/global/transfers/held?${formatParams(params)}`);
export const getLocalTransfersHeld = (params = {}) =>
  requestJson(`${domain}/local/transfers/held?${formatParams(params)}`);
export const getTransfer = id =>
  requestJson(`${domain}/transfers/${id}`);
export const getTransfers = (params = {}) =>
  requestJson(`${domain}/transfers?${formatParams(params)}`);
export const getDeposits = (params = {}) =>
  requestJson(`${domain}/deposits?${formatParams(params)}`);
export const getDateLastCuenca = _type =>
  requestJson(`${domain}/date-last-cuenca/${_type}`);
export const getTransferStpSpeidInfo = (tracking_key) =>
  requestJson(`${domain}/stp-speid-info/${tracking_key}`);
export const getDeposit = id =>
  requestJson(`${domain}/deposits/${id}`);

export const getCardReplacement = id =>
  requestJson(`${domain}/cuenca/users/${id}/card_replacement`);
export const inactivateCardReplacement = data =>
  requestJson(`${domain}/cuenca/users/card_replacement/${data.user_id}`, 'PATCH', data);
export const createCardReplacement = ({ userId, ...data }) =>
  requestJson(`${domain}/cuenca/users/${userId}/card_replacement_creation`, 'POST', data);

export const postReconMissingTransfer = data =>
  requestJson(`${domain}/non_reflected/transfer`, "POST", data);

export const getMessagesList = (chat_id, params = {}) =>
  requestJson(
    domain + "/botmaker/messages/" + chat_id + "?" + formatParams(params)
  );
export const postMessagesList = (chat_id, data) =>
  requestJson(domain + "/botmaker/messages/" + chat_id, "POST", data);
export const postCustomerAssigne = chat_id =>
  requestJson(domain + "/customer_assigne/" + chat_id, "POST");
export const getMonitorTransactions = (params = {}) =>
  requestJson(domain + "/monitor/transactions?" + formatParams(params));
export const getServiceTransaction = id =>
  requestJson(`${domain}/bill_payments/${id}`);
export const getServiceTransactions = (params = {}) =>
  requestJson(`${domain}/bill_payments?${formatParams(params)}`);
export const getArcusInfo = id =>
  requestJson(`${domain}/arcus_info/${id}`);
export const getWhatsappTransfersList = (params = {}) =>
  requestJson(`${domain}/whatsapp_transfers?${formatParams(params)}`);
export const getWhatsappTransferDetail = swapId =>
  requestJson(`${domain}/whatsapp_transfers/${swapId}`);
export const getWalletTransactionDetail = TransactionId =>
  requestJson(`${domain}/wallet_transactions/${TransactionId}`);
export const getVerifiedStatus = (params = {}) =>
  requestJson(domain + "/verified/status?" + formatParams(params));
export const getVerifiedValidateIdentity = (params = {}) =>
  requestJson(domain + "/verified/validate/identity?" + formatParams(params));
export const postVerifiedChangeStatus = data =>
  requestJson(domain + "/verified/status", "POST", data);
export const postMatiRequestVerification = data =>
  requestJson(domain + "/mati/request_verification", "POST", data);
export const getCards = (params = {}) =>
  requestJson(domain + "/cards?" + formatParams(params));

export const getCommissionDetail = (commissionId) =>
  requestJson(`${domain}/commission/${commissionId}`);
export const refundComission = (commissionId, data) =>
  requestJson(`${domain}/commission/refund/${commissionId}`, 'PATCH', data);

export const getTicketList = (params = {}) =>
  requestJson(domain + "/tickets?" + formatParams(params));
export const getTicketDetail = ticket_id =>
  requestJson(domain + "/ticket/" + ticket_id);
export const getTicketComments = ticket_id =>
  requestJson(domain + "/ticket/" + ticket_id + "/comments");
export const getTicketGroups = (params = {}) =>
  requestJson(domain + "/tickets/groups?" + formatParams(params));
export const postCreateTicket = data =>
  requestJson(domain + "/tickets", "POST", data);
export const putCreateTicket = data =>
  requestJson(domain + "/ticket/" + data["id"], "PUT", data);
export const postUrgentNotification = data =>
  requestJson(domain + '/notificador/urgent_notification', 'POST', data);
export const getNotificadorExecutions = (params = {}) =>
  requestJson(domain + `/notificador/executions?${formatParams(params)}`);
export const getNotificadorTemplates = (params = {}) =>
  requestJson(domain + `/notificador/templates?${formatParams(params)}`);
export const postNotificadorTemplates = data =>
  requestJson(domain + '/notificador/templates', 'POST', data);
export const patchNotificadorTemplate = (template_id, data) =>
  requestJson(domain + `/notificador/template/${template_id}`, 'PATCH', data);

export const deactivateAccount = data =>
  requestJson(
    `${domain}/cuenca/deactivate_account/${data["user_id"]}`,
    "DELETE"
  );
export const reactivateAccount = data =>
  requestJson(
    `${domain}/cuenca/reactivate_account/${data["user_id"]}`,
    "PATCH"
  );
export const changePhoneNumber = data =>
  requestJson(
    `${domain}/cuenca/change_phone_number/${data["user_id"]}`,
    "PATCH",
    data
  );
export const assignVirtualCard = data =>
  requestJson(
    `${domain}/cuenca/assign_virtual_card/${data["user_id"]}`,
    "PATCH",
    data
  );
export const expireCardTransaction = data =>
  requestJson(
    `${domain}/cuenca/expire_card_transaction/${data["card_transaction_id"]}`,
    "PATCH"
  );

export const sendStoreReview = ({ userId, ...data }) =>
  requestJson(`${domain}/cuenca/users/store_review/${userId}`, 'POST', data);
export const getStoreReview = userId =>
  requestJson(`${domain}/cuenca/users/store_review_detail/${userId}`);

export const cashbackAtmFeeTransaction = ({ cardTransactionId }) =>
requestJson(
    `${domain}/cuenca/card_transaction/${cardTransactionId}/cashback`,
    "PATCH",
);

export const chargebackTransaction = ({ cardTransactionId,  ...data }) =>
  requestJson(
    `${domain}/cuenca/card_transaction/${cardTransactionId}/chargeback`,
    "PATCH",
    data
  );
export const updateChargebackTransaction = ({ cardTransactionId,  ...data }) =>
  requestJson(
    `${domain}/cuenca/card_transaction/chargeback/${cardTransactionId}`,
    "PATCH",
    data
  );

export const applyRefund = data =>
  requestJson(
    `${domain}/cuenca/card_transaction/${data["card_transaction_id"]}/refund`,
    "PATCH",
    data
  );
export const changeHumaData = data =>
  requestJson(`${domain}/cuenca/humans/${data["human_id"]}`, "PATCH", data);
export const changeName = data =>
  requestJson(
    `${domain}/cuenca/humans/${data["human_id"]}/names`,
    "PATCH",
    data
  );
export const changeRfc = ({humanId, rfc}) =>
  requestJson(
    `${domain}/cuenca/humans/${humanId}/rfc`,
    'PATCH',
    { rfc }
  );
export const changeHumanBirthData = ({humanId, ...data}) =>
  requestJson(
    `${domain}/cuenca/humans/${humanId}/birth`,
    'PATCH',
    {...data},
  );
export const returnTransaction = data =>
  requestJson(
    `${domain}/cuenca/humans/${data["human_id"]}/transactions`,
    "PATCH",
    data
  );
export const chargeCardReposition = data =>
  requestJson(
    `${domain}/cuenca/reposition`,
    "POST",
    data
  );

export const changePldLevelRisk = data =>
  requestJson(
    `${domain}/cuenca/users/${data["user_id"]}/pld_level_risk`,
    "PATCH",
    data
  );

export const hotSaleNotificationNotRead = data =>
  requestJson(
    `${domain}/cuenca/users/${data.user_id}/unread_hot_sale`,
    "PATCH",
    data
  );

export const changeDebitBalance = data =>
  requestJson(
    `${domain}/cuenca/users/${data["user_id"]}/debit_balance`,
    "PATCH",
    data
  );

export const generateNewClabe = data =>
 requestJson(
   `${domain}/cuenca/users/${data["user_id"]}/assign_new_clabe`,
   "PATCH",
   data
 );

export const reverseSwap = data =>
requestJson(
  `${domain}/cuenca/swap/${data["swap_id"]}/reverse`,
  "PATCH",
  data
);

export const changeCashWithdrawLimit = data =>
  requestJson(
    `${domain}/cuenca/users/${data["user_id"]}/cash_withdrawal_limit`,
    "PATCH",
    data
  );

export const changeNegativeLevel = data =>
  requestJson(
    `${domain}/cuenca/users/${data["user_id"]}/negative_level`,
    "PATCH",
    data
  );

export const getFraudFundsData = userId =>
  requestJson(`${domain}/cuenca/human/fraud_funds/${userId}`);
export const sendFraudFundsToStp = ({ userId, ...data }) =>
  requestJson(`${domain}/cuenca/human/fraud_funds/${userId}`, 'PATCH', data);

export const getPasswordResetList = (params = {}) =>
  requestJson(`${domain}/password_resets?${formatParams(params)}`);
export const getPasswordResetDetail = ({ passwordResetId }) =>
  requestJson(`${domain}/password_resets/${passwordResetId}`);
export const getProofsOfAddressList = (params = {}) =>
  requestJson(`${domain}/proofs_of_address?${formatParams(params)}`);
export const getProofOfAddressDetail = ({ userId }) =>
  requestJson(`${domain}/proofs_of_address/${userId}`);
export const patchPasswordReset = ({ passwordResetId, ...data }) =>
  requestJson(
    `${domain}/cuenca/password_resets/${passwordResetId}/update`,
    'PATCH',
    data,
  );

export const getArcusUserDeposits = (clabe) => requestJson(`${domain}/arcus_recon_accounts/${clabe}`, 'GET');

export const getPldAlertList = (params = {}) => requestJson(`${domain}/pld_alerts?${querystring.stringify(params)}`, 'GET');
export const getPldAlertDetail = (alertId) => requestJson(`${domain}/pld_alerts/${alertId}`, 'GET');
export const updatePldAlert = (alertId, data) => requestJson(`${domain}/pld_alerts/${alertId}`, 'PATCH', data);

export const getUserReportList = (params = {}) => requestJson(`${domain}/user_reports?${querystring.stringify(params)}`, 'GET');
export const createUserReport = (data) => requestJson(`${domain}/user_reports`, 'POST', data);
export const getUserReportDetail = (userReportId) => requestJson(`${domain}/user_reports/${userReportId}`, 'GET');
export const updateUserReport = (userReportId, data) => requestJson(`${domain}/user_reports/${userReportId}`, 'PATCH', data);

export const getArcusTxnStatus = (txnId) => requestJson(`${domain}/arcus_speid/${txnId}`, 'GET');

export const getUserDetail = (user_id) => requestJson(`${domain}/users/${user_id}`, 'GET');

export const createReport = (params = {}) => requestBlob(`${domain}/reports`, 'POST', params, {'Content-Type': 'application/json'});

