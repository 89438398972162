import React from 'react';
import ReactDOM from 'react-dom';
import { ReactTableDefaults } from 'react-table';

import PropTypes from 'prop-types';

import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import AuthLayout from 'layouts/Auth.jsx';
import AdminLayout from 'layouts/Admin.jsx';
import ChatLayout from 'layouts/Chat.jsx';

import 'assets/scss/omni.scss';

import Moment from 'react-moment';
import 'moment-timezone';

Moment.globalFormat = 'DD MMM YY h:mm:ss A';
Moment.globalTimezone = 'America/Mexico_City';

class ReactTableInput extends React.Component {
  render() {
    return (
      <input
        type='text'
        onKeyPress={evt => {
          if (evt.key === 'Enter') {
            this.props.onChange(evt.target.value);
          }
        }}
        style={{ width: '100%' }}
        placeholder='Buscar...'
      />
    );
  }
}

ReactTableInput.propTypes = {
  onChange: PropTypes.func.isRequired
};

Object.assign(ReactTableDefaults, {
  FilterComponent: ReactTableInput
});

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Redirect from='/admin/global/transfer/:id' to='/admin/transfer/:id'/>
      <Redirect from='/admin/local/transfer/:id' to='/admin/transfer/:id'/>
      <Route path='/auth' component={AuthLayout} />
      <Route path='/error' component={AuthLayout} />
      <PrivateRoute path='/chat' component={ChatLayout} />
      <PrivateRoute path='/admin' component={AdminLayout} />
      <Redirect from='/' to='/admin/customers' />
      
    </Switch>
  </Router>,
  document.getElementById('root')
);

function PrivateRoute(props) {
  const p = props;
  const { component: Component, ...rest } = p;
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('access_token') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: p.location }
            }}
          />
        )
      }
    />
  );
}
