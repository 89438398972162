import React, { Component } from "react";
import PropTypes from "prop-types";
import shave from "shave";

import "./ConversationListItem.css";

export default class ConversationListItem extends Component {
  constructor({ getMessagelist }) {
    super();
    this.state = {
      getMessagelist: getMessagelist
    };
  }

  componentDidMount() {
    shave(".conversation-snippet", 20);
  }

  render() {
    const {
      chatId,
      clientName,
      lastDigitsNumber,
      lastMessage,
      chatNumber
    } = this.props.data;
    return (
      <div
        className={`conversation-list-item ${
          chatId === this.props.chatSelect ? "select" : ""
        }`}
        onClick={() => {
          this.state.getMessagelist(chatId);
        }}
      >
        <div className="conversation-number">{lastDigitsNumber}</div>
        <div className="conversation-info">
          <h1 className="conversation-title">{clientName}</h1>
          <p className="conversation-phone">{chatNumber}</p>
          <p className="conversation-snippet">{lastMessage}</p>
        </div>
      </div>
    );
  }
}

ConversationListItem.propTypes = {
  getMessagelist: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  chatSelect: PropTypes.string
};
