import React, { useState } from 'react';

// components
import { withStyles } from '@material-ui/core';
import swal from '@sweetalert/with-react';

import { assignVirtualCard } from 'providers/api-rest';

const styles = {
    container: {
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        border: 'none',
        borderRadius: '3px',
        position: 'relative',
        width: '40%',
        padding: '12px',
        margin: '.3125rem 1px',
        fontSize: '12px',
        textTransform: 'uppercase',
        textAlign: 'center',
        backgroundColor: '#616C93',
        color: '#f2f2f2',
        cursor: 'pointer',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        '&:hover,&:focus': {
            backgroundColor: 'rgb(163, 169, 194, 0.15)',
            color: '#616C93',
        },
        '&:disabled': {
            backgroundColor: 'rgb(163, 169, 194, 0.15)',
            color: '#666666',
            cursor: 'default',
        }
    },
    input: {
        color: '#404040',
        fontSize: '14px',
        lineHeight: '1.42857',
        top: '10px',
        width: '60%',
        marginBottom: '20px',
        height: '30px',
        border: '0.5px solid #616C93',
        borderRadius: '3px',
    },
};

const AssignCardInput = ({
    classes,
    userId,
    createdAt
}) => {
    const [assignCardData, setData] = useState({
    	issuer: 'accendo',
    	funding_type: 'debit'
   	});

    const issuerList = {cuenca: 'Cuenca', accendo: 'Accendo'}
    const fundingTypeList = [];

    if (process.env.REACT_APP_MASTERCARD === 'fase_1'){
        const dateLimit = new Date(2021,5,11);
        const dateValid = new Date(createdAt) >= dateLimit;
        if (!dateValid) delete issuerList['cuenca'];
        if (assignCardData.issuer === 'cuenca' && dateValid){
            fundingTypeList.push({key: 'credit', value: 'Credito'})
        } else {
            fundingTypeList.push({key: 'debit', value: 'Debito'})
        }
    } else {
        assignCardData.issuer = 'cuenca'
        fundingTypeList.push({key: 'credit', value: 'Credito'})
        fundingTypeList.push({key: 'debit', value: 'Debito'})
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = { ...assignCardData };

        const response = await assignVirtualCard({
            user_id: userId,
            ...data,
        });

        if (response.status === 201) {
            swal(
                'Excelente 🎉',
                'Acción realizada exitosamente.',
                'success',
            );
        } else {
            swal(
                'Lo siento 😓',
                `Ocurrió un error: ${
                    response.data.msg || response.data.error
                }`,
                'error',
            );
        } 
    };

    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit}>
                <div className={classes.container}>
                    {process.env.REACT_APP_MASTERCARD === 'fase_1'
                        ?   <select
                                required
                                className={classes.input}
                                onChange={e => setData({
                                    ...assignCardData,
                                    issuer: e.target.value,
                                })}
                                value={assignCardData.issuer}
                            >
                                <option value="" disabled>
                                    Emisor
                                </option>
                                {Object.keys(issuerList).map((k, i) => (
                                    <option
                                        key={`${k}${i}`}
                                        value={k}
                                    >
                                        {issuerList[k]}
                                    </option>
                                ))}
                            </select>
                        : null
                    }
                    
                    <select
                        required
                        className={classes.input}
                        onChange={e => setData({
                            ...assignCardData,
                            funding_type: e.target.value,
                        })}
                        value={assignCardData.funding_type}
                    >
                        <option value="" disabled>
                            Tipo de Financiación
                        </option>
                        {fundingTypeList.map((s, i) => (
                            <option
                                key={`${s.key}${i}`}
                                value={s.key}
                            >
                                {s.value}
                            </option>
                        ))}
                    </select>
                    <input
                    	required
                    	type="text"
                    	className={classes.input}
                    	placeholder="Motivo"
                    	onChange={e => setData({
                            ...assignCardData,
                            reason: e.target.value,
                        })}
                    />
                </div>
                <button
                    className={classes.button}
                    type="submit"
                >
                    Asignar
                </button>
            </form>
        </div>
    );
};

export default withStyles(styles)(AssignCardInput);
