import React from "react";

import PropTypes from "prop-types";

import ReactPanZoom from "react-image-pan-zoom-rotate";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Card from "components/Card";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";

import ActionButtons from 'views/Validate/ActionButtons';

import { cardTitle } from "assets/jss/omni.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    top: "15px",
    position: 'relative',
    marginBottom: "0px",
    textAlign: "center"
  },
  verticalCenter: {
    margin: "0",
    top: "50%",
    transform: "translateY(-50%)",
    textAlign: "center"
  },
  reactPanZoom: {
    overflow: "hidden",
    position: "relative",
    height: "400px"
  },
};

class ValidateProofOfAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_info: props.user_info
    };
  }

  render() {
    const { classes, optionSelected } = this.props;
    const { user_info } = this.state;
    return (
      <Card>
        <CardHeader color="primary" icon>
          <h4 className={classes.cardIconTitle}>
            Validacion de <strong>Comprobante de Domicilio</strong>
          </h4>
        </CardHeader>
        <CardBody>
          <hr />
          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              <Card className={classes.verticalCenter}>
                <CardBody>
                  DIRECCIÓN: <strong>{user_info.full_address}</strong><br />
                  FECHA DE REGISTRO: <strong>
                    {user_info.register_at.split('T')[0]}
                  </strong>
                  <br />
                  <ActionButtons
                    validating='proof_of_address_status'
                    enableSucceded={true}
                    optionSelected={optionSelected}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={7}>
              <Card>
                <CardBody>
                  {user_info.proof_of_address ? (
                    <div className={classes.reactPanZoom}>
                      {user_info.proof_of_address.mimetype === "image/jpeg" ? (
                        <ReactPanZoom
                          image={user_info.proof_of_address.blob}
                          alt="document image"
                        />
                      ) : (
                        <embed
                          src={user_info.proof_of_address.blob}
                          width="100%"
                          height="550"
                          type={user_info.proof_of_address.mimetype}
                        />
                      )}
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

ValidateProofOfAddress.propTypes = {
  classes: PropTypes.object.isRequired,
  user_info: PropTypes.object.isRequired,
  optionSelected: PropTypes.func
};

export default withStyles(styles)(ValidateProofOfAddress);
