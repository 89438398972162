import React from "react";
import { withRouter } from 'react-router';

import ReactTable from "react-table";

import PropTypes from "prop-types";

import swal from "@sweetalert/with-react";

import Moment from "react-moment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Badge from "@material-ui/core/Badge";
import AutoRenew from "@material-ui/icons/Autorenew";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Tooltip from "@material-ui/core/Tooltip";
import Send from "@material-ui/icons/Send";

// core components
import Button from "components/CustomButtons";
import Card from "components/Card";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import { processError } from 'components/ErrorBoundary';

import { PaymentTypeIcon } from 'common/utils.jsx';

import regularFormsStyle from "assets/jss/omni/views/regularFormsStyle";

import { cardTitle, tooltip } from "assets/jss/omni.jsx";

import {
  getGaitaOrdersQuoted,
  patchGaitaOrdersQuoted,
  postGaitaOrdersQuoted
} from "providers/api-rest.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  regularFormsStyle,
  tooltip,
  icon: {
    marginLeft: "10%",
    fontSize: "25px"
  },
  search: {
    position: "absolute",
    right: 0,
    top: "10px",
    padding: 0,
    margin: 0,
    width: "30%"
  },
  updateTable: {
    position: "absolute",
    top: "10px",
    padding: 0,
    margin: 0,
    width: "30%"
  },
  updateReferrer: {
    position: "absolute",
    top: "10px",
    marginLeft: "60px",
    width: "120px"
  },
  updatedCursor: {
    cursor: "pointer"
  }
};

class GaitaOrders extends React.Component {
  constructor(props) {
    super(props);
    this.folios = {};
    this.state = {
      loading: true,
      folios: {},
      lastState: null,
      search: null,
      newOrderPlaced: props.newOrderPlaced
    };
  }

  componentWillReceiveProps = props => {
    if (props.newquote) {
      this.onFetchDataReload();
    }
  };

  async onFetchData(state, referrer = null) {
    this.setState({
      loading: true,
      lastState: state,
      order_status: 'ready_to_order,ordering'
    });
    const params = {
      page: state.page + 1,
      page_size: state.pageSize,
      search: state.search
    };
    if (referrer) {
      params["referrer"] = referrer;
    }
    const response = await getGaitaOrdersQuoted(params);
    this.setState({
      data:
        response.data && response.data.orders_groups
          ? response.data.orders_groups
          : [],
      loading: false,
      pages: response.data ? response.data.pages : 1,
      response: response
    });
  }

  onFetchDataReload() {
    this.setState({ loading: true });
    this.onFetchData(this.state.lastState);
  }

  onKeyPressSearch(search) {
    let lastState = this.state.lastState;
    lastState["search"] = search;
    this.setState({ lastState: lastState });
    this.onFetchDataReload();
  }

  createCustomInputs = customer_orders => {
    this.folios = {};
    let item = [];
    for (const customer_order in customer_orders) {
      const folio_number = parseInt(customer_order) + 1;
      item.push(
        <CustomInput
          labelText={"Ingrese el folio #" + folio_number + ""}
          id="client_name"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: event => {
              this.folios[folio_number] = event.target.value;
              if (this.folios[folio_number] === "") {
                delete this.folios[folio_number];
              }
            }
          }}
        />
      );
    }
    return item;
  };

  async handleExecuteCancelUserRequest(request_id) {
    const response = await patchGaitaOrdersQuoted(request_id);
    if (response.status === 200) {
      this.onFetchDataReload();
    } else {
      swal(
        "Lo siento!",
        "Error inesperado, intente de nuevo o contacta a Ingeniería",
        "error"
      );
      this.setState({ loading: false });
    }
  }

  async handleClickCancelUserRequest(request_id) {
    let message = "Estas seguro que quieres cancelar este pedido";
    await swal(message, {
      title: "Accion requerida",
      buttons: ["No", "Si"]
    }).then(willGroup => {
      if (willGroup) {
        this.handleExecuteCancelUserRequest(request_id);
      } else {
        return;
      }
    });
  }

  async handleClickConfirmQuote(order_id) {
    let message = "¿Estás seguro que quieres realizar esta orden?";

    await swal(message, {
      title: "Accion requerida",
      buttons: ["Cancelar", "Ordenar"]
    }).then(async willGroup => {
      if (willGroup) {
        const { data } = this.state;
        const dataFromOrder = data.filter(order => order.group_id === order_id)[0];
        const actualOrder = dataFromOrder.orders[0];
        const suspiciousAddresses = actualOrder['suspicious_addresses'];

        if (suspiciousAddresses.length) {
          message = `Se encontraron direcciones sospechosas para la orden ${order_id}: 🧐`;
          for (let i = 0; i < suspiciousAddresses.length; i++) {
            message = `${message}
              \t\t${i + 1} –– ${suspiciousAddresses[i]}
            `;
          }
          message = `${message}
            \n¿Desea continuar?
          `;

          const next = await swal(message, {
            title: 'Direcciones Sospechosas',
            buttons: ['Cancelar', 'Ordenar'],
          });
          if (!next) return;
        }

        this.handleExecuteConfirmQuote(order_id);
      } else {
        return;
      }
    });
  }

  async handleConfirmGrouping(state, parter) {
    let message = "Estas seguro que quieres agrupar estas ordenes";
    await swal(message, {
      title: "Accion requerida",
      buttons: ["Cancelar", "Agrupar"]
    }).then(willGroup => {
      if (willGroup) {
        this.onFetchData(state, parter);
      } else {
        return;
      }
    });
  }

  handleExecuteConfirmQuote = async order_id => {
    this.setState({ loading: true });

    const payload = {
      data: [
        {
          order_id,
          provider: "ivoy_90min"
        }
      ]
    };

    const response = await postGaitaOrdersQuoted(payload);
    if (response.status === 201) {
      const groups = this.state.data;
      for (let index in groups) {
        if (groups[index]["group_id"] === order_id) {
          groups.splice(index, 1);
          break;
        }
      }
      this.setState({ data: groups, loading: false });
      this.state.newOrderPlaced();
    } else {
      swal(
        "Lo siento!",
        "Error inesperado, intente de nuevo o contacta a Ingeniería",
        "error"
      );
      this.setState({ loading: false });
    }
  };

  getTrProps = (state, rowInfo) => {
    if (!rowInfo) return {};
    const { data } = this.state;
    const { original } = rowInfo;
    const orderData = data.find(order => order.group_id === original.group_id);

    const isReplacement = orderData.orders.reduce(
      (replacement, order) => replacement || order.is_replacement,
      false,
    );

    return isReplacement ? {
      style: {
        backgroundColor: '#b3dcff',
      },
    } : {};
  };

  getQuoteLocal(group) {
    return group["options"][0];
  }

  getSuspiciousNumber = orders => {
    return orders.reduce(
      (count, order) => count + order['suspicious_addresses'].length,
      0,
    );
  };

  render() {
    const { response } = this.state;
    const { classes, location } = this.props;
    const columns = [
      {
        Header: "Entregas",
        accessor: "customer_orders",
        minWidth: 30,
        Cell: row => {
          const { orders_count, payment_types } = row.original;
          return (
            <>
              <span>{orders_count} Entregas</span>
              {payment_types && payment_types.map(type => 
                <PaymentTypeIcon
                  id={`goq-${type}`}
                  type={type}
                  classes={classes}
                  size='small'
                />
              )}
            </>
          )
        }
      },
      {
        Header: "Tiempo",
        accessor: "eta",
        minWidth: 30,
        Cell: row => <span>{this.getQuoteLocal(row.original)["eta"]} min</span>
      },
      {
        Header: "Distancia",
        accessor: "distance",
        minWidth: 30,
        Cell: row => (
          <span>{this.getQuoteLocal(row.original)["distance"]} km</span>
        )
      },
      {
        Header: "Costo",
        accessor: "price",
        minWidth: 30,
        Cell: row => <span>${this.getQuoteLocal(row.original)["price"]}</span>
      },
      {
        Header: "Acciones",
        minWidth: 70,
        Cell: row => {
          const { orders, group_id } = row.original;
          return (
            <div className="actions-right">
              <Tooltip
                id="tooltip-top"
                title="Realizar Orden"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Badge
                  badgeContent={this.getSuspiciousNumber(orders)}
                  color="error"
                  overlap="circle"
                >
                  <Button
                    size="sm"
                    color="info"
                    round
                    onClick={() =>
                      this.handleClickConfirmQuote(group_id)
                    }
                  >
                    Realizar Orden
                    <CheckCircle color="primary" className={classes.icon} />
                  </Button>
                </Badge>
              </Tooltip>
            </div>
          );
        }
      }
    ];
    const subColumns = [
      {
        accesor: 'payment_type',
        minWidth: 30,
        Cell: row => (
          <PaymentTypeIcon
            type={row.original.payment_type}
            classes={classes}
          />
        )
      },
      {
        Header: "Cliente",
        accessor: "name"
      },
      {
        Header: "Telefono",
        accessor: "telephone"
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Fecha de Pedido",
        accessor: "created_at",
        minWidth: 130,
        Cell: row => {
          const created_at = row.original.created_at.split(".")[0];
          return <Moment utc>{created_at}</Moment>;
        }
      },
      {
        Header: "Direccion",
        accessor: "full_address",
        minWidth: 300,
        Cell: row => (
          <span>
            {row.original.full_address} INTERIOR: {row.original.int_number}{" "}
            CODIGO POSTAL: {row.original.zip_code}
          </span>
        )
      },
      {
        Header: "Comentario",
        accessor: "comment",
        minWidth: 100
      },
      {
        Header: "Acciones",
        minWidth: 70,
        filterable: false,
        Cell: row => (
          <div className="actions-right">
            <Tooltip
              id="tooltip-top"
              title="Cancelar Pedido"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <span
                onClick={() =>
                  this.handleClickCancelUserRequest(row.original.id)
                }
              >
                <Cancel color="secondary" className={classes.icon} />
              </span>
            </Tooltip>
          </div>
        )
      }
    ];

    if (response && response.status !== 200 && response.status !== 201) {
      processError(
        response.status,
        location.pathname,
        false,
        (
          (response.data && (response.data.error || response.data.message))
          || 'Listado órdenes cotizadas de Gaita, contacta a ingeniería'
        )
      );
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Send />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Cotizaciones realizadas
                <span
                  className={classes.updatedCursor}
                  onClick={() => this.onFetchDataReload()}
                >
                  <strong> |</strong> Actualizar
                  <span className={classes.updateTable}>
                    <AutoRenew />
                  </span>
                </span>
                <CustomInput
                  className={classes.search}
                  formControlProps={{
                    className: classes.top + " " + classes.search
                  }}
                  inputProps={{
                    placeholder: "Buscar (User Id) (Teléfono) (Email)",
                    inputProps: {
                      "aria-label": "Buscar",
                      className: classes.searchInput
                    },
                    onKeyPress: event => {
                      if (event.key === "Enter") {
                        this.onKeyPressSearch(event.target.value);
                      }
                    }
                  }}
                />
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                manual
                data={this.state.data}
                columns={columns}
                pages={this.state.pages}
                defaultPageSize={7}
                loading={this.state.loading}
                filterable={false}
                sortable={false}
                onFetchData={state => this.onFetchData(state)}
                getTrProps={this.getTrProps}
                className="-highlight"
                noDataText="No se han encontrado resultados"
                showPageSizeOptions={false}
                SubComponent={row => {
                  return (
                    <div
                      style={{
                        paddingLeft: "50px"
                      }}
                    >
                      <hr
                        style={{
                          marginBottom: "0px",
                          marginTop: "5px"
                        }}
                      />
                      <ReactTable
                        data={row.original.orders}
                        columns={subColumns}
                        defaultPageSize={row.original.orders.length}
                        showPagination={false}
                        sortable={false}
                      />
                    </div>
                  );
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

GaitaOrders.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  newOrderPlaced: PropTypes.func,
  newquote: PropTypes.bool
};

export default withRouter(withStyles(styles)(GaitaOrders));
