import React, { useCallback, useMemo, useState } from 'react';
import { withRouter } from 'react-router';

import swal from '@sweetalert/with-react';
import FileSaver from 'file-saver';
import JsZip from 'jszip';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import ReactTable from 'react-table';

// @material-ui/core components
import Checkbox from "@material-ui/core/Checkbox";
import withStyles from '@material-ui/core/styles/withStyles';

import Save from '@material-ui/icons/Save';
import Receipt from '@material-ui/icons/Receipt';

// core components
import Button from "components/CustomButtons";
import Card from 'components/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid';
import GridItem from 'components/Grid/GridItem';
import { processError } from 'components/ErrorBoundary';

import { PaymentTypeIcon, pueblaPostalCodes } from 'common/utils';

import poblano from 'assets/img/poblano.png';
import regularFormsStyle from 'assets/jss/omni/views/regularFormsStyle';
import { cardTitle, tooltip } from 'assets/jss/omni';

import {
  createGaitaCsvOrders,
  getGaitaOrdersQuoted,
} from 'providers/api-rest.js';

const styles = {
  regularFormsStyle,
  tooltip,
  allSelectedButton: {
    margin: 0,
    top: 0
  },
  buttonRealize: {
    margin: 0,
    marginLeft: '20px',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  icon: {
    marginLeft: '7%',
    fontSize: '20px'
  },
  search: {
    minWidth: '225px',
    margin: 0,
    padding: 0
  },
  topLeftContainer: {
    width: '60%',
    position: 'absolute',
    right: 0,
    top: '10px',
    padding: 0,
    margin: 0
  },
  updateTable: {
    position: 'absolute',
    top: '10px',
    padding: 0,
    margin: 0,
    width: '5%'
  },
  updateReferrer: {
    position: 'absolute',
    top: '10px',
    marginLeft: '60px',
    width: '120px'
  },
  updatedCursor: {
    cursor: 'pointer'
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    minHeight: '80px',
  },
};

const GaitaCsv = ({ classes, location }) => {
  const [orders, setOrders] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(0);

  const fetchOrders = useCallback(async (page) => {
    setLoading(true);

    const response = await getGaitaOrdersQuoted({
      page: page + 1,
      page_size: 100,
      order_type: 'domestic',
      order_status: 'created',
    });

    if (response.status !== 200) {
      processError(
        response.status,
        location.pathname,
        false,
        (
          (response.data && (response.data.error || response.data.message))
          || `Listado órdenes foráneas de Gaita, contacta a ingeniería`
        ),
      );
      return;
    }

    if (response.data && response.data.orders_groups) {
      const orders = response.data.orders_groups;
      setOrders(orders);
      setSelected(orders.map(({ group_id }) => group_id));
      setNumPages(response.pages);
    }

    setLoading(false);
  }, [location]);

  const toggleAll = useCallback(() => {
    const newSelected = [];

    if (selected.length !== orders.length) {
      orders.forEach((order) => {
        if (!selected.includes(order.group_id)) {
          newSelected.push(order.group_id);
        }
      });
    }

    setSelected(newSelected);
  }, [orders, selected]);

  const toggleOne = useCallback((groupId) => {
    let newSelected = selected;

    if (selected.includes(groupId)) {
      newSelected = newSelected.filter((id) => id !== groupId);
    } else {
      newSelected = [...newSelected, groupId];
    }

    setSelected(newSelected);
  }, [selected]);

  const downloadCsv = useCallback(async () => {
    setLoading(true);

    const { data, status } = await createGaitaCsvOrders(selected);

    if (status !== 201 || !Object.keys(data).length) {
      swal(
        'Lo siento 🥺',
        `
        Algo falló al crear o descargar los CSVs, intenta de nuez o contacta a ingeniería
        
        Si las órdenes que intentaste descargar no aparecen, es posible que sí se haya generado los archivos.
        Contacta a ingeniería para descargarlos.
        `,
        'error',
      );
      return;
    }

    const zip = JsZip();
    const now = new Date();
    const todayString = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;

    const byteStringToCsvBlob = (bString) => {
      const bChars = atob(bString);
      const bytes = new Array(bChars.length);

      for (let i = 0; i < bChars.length; i++) {
        bytes[i] = bChars.charCodeAt(i);
      }
      
      const bArray = new Uint8Array(bytes);
      return new Blob([bArray], { type: `text/csv` });
    };

    const csvBlobs = [];

    if (data['domestic_99']) {
      csvBlobs.push({
        blob: byteStringToCsvBlob(data['domestic_99']),
        name: `99-orders-${todayString}.csv`,
      });
    }

    if (data.estafeta) {
      csvBlobs.push({
        blob: byteStringToCsvBlob(data.estafeta),
        name: `estafeta-orders-${todayString}.csv`,
      });
    }

    csvBlobs.map(({ blob, name }) => zip.file(name, blob));

    zip.generateAsync({type: 'blob'}).then((zipFile) => {
      return FileSaver.saveAs(
        zipFile,
        `order-csvs-${todayString}.zip`,
      );
    });

    swal(
      'Wooooo! 🥳🎉',
      'Se ha descargado un zip con los documentos exitosamente',
      'success',
    );

    await fetchOrders(0);
  }, [fetchOrders, selected]);

  const columns = useMemo(() => [
    {
      Header: (
        <Checkbox
          checked={selected.length === orders.length}
          onChange={toggleAll}
          value="primary"
        />
      ),
      accessor: 'select_all',
      minWidth: 50,
      Cell: ({ original: { group_id } }) => (
        <Checkbox
          checked={selected.includes(group_id)}
          onChange={() => toggleOne(group_id)}
          value="secondary"
        />
      ),
    },
    {
      Header: 'Entregas',
      accessor: 'customer_orders',
      minWidth: 70,
      Cell: ({ original: { orders_count, payment_types } }) => (
        <>
          <span>{orders_count} Entrega{orders_count > 1 ? 's' : ''}</span>
          {payment_types && payment_types.map(type => (
            <PaymentTypeIcon
              id={`pti-${type}`}
              type={type}
              classes={classes}
              size='small'
            />
          ))}
        </>
      ),
    },
    {
      Header: 'Dirección',
      accessor: 'eta',
      minWidth: 300,
      Cell: ({ original: { orders } }) => {
        const [firstOrder] = orders;
        const {
          full_address,
          zip_code,
          int_number,
          comment,
        } = firstOrder;
        return (
          <span>
              {pueblaPostalCodes.has(zip_code) && (
                <>
                  <strong style={{color: '#92c155'}}>
                    {`CÓDIGO POSTAL DE PUEBLA (99 Minutos) `}
                  </strong>
                  <img src={poblano} width={20} />
                  <br />
                </>
              )}
              <strong>{`DIRECCION: ${full_address}`}</strong>
              <br />
              <strong>{`CODIGO POSTAL: ${zip_code} `}</strong>
              <strong>{`INTERIOR ${int_number}`}:</strong>
              <br />
              <strong>{`COMENTARIO: ${comment}`}</strong>
            </span>
        );
      },
    },
    {
      Header: 'Contacto',
      accessor: 'name',
      minWidth: 140,
      Cell: ({ original: { orders } }) => {
        const [firstOrder] = orders;
        return (
          <span>
            `${firstOrder.name} - ${firstOrder.telephone}`
          </span>
        );
      },
    },
    {
      Header: 'Fecha de Creación',
      accessor: 'created_at',
      minWidth: 130,
      Cell: ({ original: { created_at }}) => (
        <Moment utc>{created_at.split('.')[0]}</Moment>
      ),
    },
  ], [orders, selected, toggleAll]);

  const getTrProps = useCallback((state, rowInfo) => {
    if (!rowInfo) return {};

    const { original } = rowInfo;
    const orderData = orders.find(
      order => order.group_id === original.group_id,
    );

    const isReplacement = orderData.orders.reduce(
      (replacement, order) => replacement || order.is_replacement,
      false,
    );

    return isReplacement ? {
      style: {
        backgroundColor: '#b3dcff',
      },
    } : {};
  }, [orders]);

  return (
    <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='primary' icon>
                <CardIcon color='primary'>
                  <Receipt />
                </CardIcon>

                <h4 className={classes.cardIconTitle}>
                  Generar CSV para 99Minutos y Estafeta

                  <Button
                    round
                    size="sm"
                    color="info"
                    className={classes.buttonRealize}
                    onClick={downloadCsv}
                  >
                    Descargar CSV
                    <Save color="primary" className={classes.icon} />
                  </Button>
                </h4>
              </CardHeader>

              <CardBody>
                <ReactTable
                  manual
                  data={orders}
                  columns={columns}
                  pages={numPages}
                  defaultPageSize={100}
                  loading={isLoading}
                  filterable={false}
                  sortable={false}
                  onFetchData={({ page }) => fetchOrders(page)}
                  getTrProps={getTrProps}
                  className="-highlight"
                  noDataText="No se han encontrado resultados"
                  showPageSizeOptions={false}
                />
              </CardBody>
            </Card>
        </GridItem>
    </GridContainer>
  );
};

GaitaCsv.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(GaitaCsv));
