import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Badge from "components/Badge";
import Button from "components/CustomButtons";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";

import modalStyle from "assets/jss/omni/modalStyle.jsx";

const styles = theme => ({
  ...modalStyle(theme),
  loadAs: {
    padding: "0 !important",
    cursor: "pointer",
    textAlign: "center"
  }
});

class ModalOfDocument extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };

  handleSelect = data => {
    this.props.onSelect(data);
  };

  componentWillUpdate() {
    const { document } = this.props;
    if (document) {
      URL.revokeObjectURL(document);
    }
  }

  handleLoadAs(document_type) {
    const { document } = this.props;
    const data = {
      feedme_uri: document.feedme_uri,
      document_type: document_type
    };
    this.handleSelect(data);
    this.handleClose();
  }

  render() {
    const { id, classes, title, document, ...other } = this.props;
    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
        {...other}
      >
        <DialogTitle
          id="responsive-dialog-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => this.handleClose()}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{title}</h4>
          {document && id === "feedme_uri" ? (
            <div>
              <hr />
              <h6>Cargar como: </h6>
              <GridContainer>
                <GridItem xs={6} sm={6} md={3} className={classes.loadAs}>
                  <div
                    onClick={() => {
                      this.handleLoadAs("govt_id_front");
                    }}
                  >
                    <Badge color="info">ID (Front)</Badge>
                  </div>
                </GridItem>
                {document.document_select !== "passport" ? (
                  <GridItem xs={6} sm={6} md={3} className={classes.loadAs}>
                    <div
                      onClick={() => {
                        this.handleLoadAs("govt_id_back");
                      }}
                    >
                      <Badge color="info">ID (Back)</Badge>
                    </div>
                  </GridItem>
                ) : null}

                <GridItem xs={6} sm={6} md={3} className={classes.loadAs}>
                  <div
                    onClick={() => {
                      this.handleLoadAs("proofs_of_address");
                    }}
                  >
                    <Badge color="info">DOMICILIO</Badge>
                  </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={3} className={classes.loadAs}>
                  <div
                    onClick={() => {
                      this.handleLoadAs("proofs_of_life");
                    }}
                  >
                    <Badge color="info">PRUEBA DE VIDA</Badge>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          ) : null}
        </DialogTitle>
        <DialogContent className={classes.modalBody}>
          {document ? (
            <div>
              {document.mimetype === "image/jpeg" ? (
                <img src={document.blob} width="500" alt={title} />
              ) : (
                <div>
                  {document.mimetype === "video/mp4" ? (
                    <video controls width="500" height="750" autoPlay>
                      <source src={document.blob} type={document.mimetype} />
                    </video>
                  ) : (
                    <embed
                      src={document.blob}
                      width="500"
                      height="750"
                      type={document.mimetype}
                    />
                  )}
                </div>
              )}
            </div>
          ) : (
            <CircularProgress />
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

ModalOfDocument.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  document: PropTypes.object,
  onSelect: PropTypes.func,
  document_select: PropTypes.string
};

export default withStyles(styles)(ModalOfDocument);
