import React from "react";
import PropTypes from "prop-types";

import swal from "@sweetalert/with-react";

// @material-ui/core components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Badge from "components/Badge";
import Button from "components/CustomButtons";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import ModalOfDocument from "components/ModalOfDocument";

import { postDocuments, getTakemeDocument } from "providers/api-rest.js";

import modalStyle from "assets/jss/omni/modalStyle.jsx";

const styles = () => ({
  modalStyle,
  showFeedmeUri: {
    float: "right",
    cursor: "pointer",
    color: "#02acc1",
    fontWeight: "700",
    fontSize: "15px"
  },
  feedmeUriLink: {
    cursor: "pointer",
    color: "#02acc1",
    fontWeight: "700",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  alignCenter: {
    textAlign: "center"
  }
});

class ModalUploadDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentSelect: "ine",
      documents: {},
      feedme_urls: {},
      govtIdIsMx: false,
      modalFeedmeUriOpen: false,
      modalFeedmeUri: null
    };
  }
  handleClose = () => {
    this.setState({
      documentSelect: "ine",
      documents: {},
      govtIdIsMx: false,
      modalFeedmeUriOpen: false,
      modalFeedmeUri: null,
      feedme_urls: {}
    });
    this.props.onClose();
  };

  onChangeHandlerFile = event => {
    const fileType = event.target.name;
    const documents = this.state.documents;
    documents[fileType] = event.target.files[0];
    this.setState({
      documents: documents
    });
  };

  handleChangeTypeDocument(type) {
    const documents = this.state.documents;
    if (type === "passport") {
      delete documents["govt_id_back"];
    }
    this.setState({
      documentSelect: type,
      documents: documents
    });
  }

  showModalError(response) {
    if (response.status === 401) {
      swal(
        "Lo siento!",
        "Esta acción requiere verificación con yubikey",
        "error"
      );
    } else {
      swal("Lo siento!", "Error inesperado, contacta a Ingeniería", "error");
    }
  }

  async handleUploadDocuments() {
    const { customer, verified } = this.props;
    const documents = this.state.documents;
    const feedme_urls = this.state.feedme_urls;

    const formData = new FormData();
    formData.append("user_id", customer.user_id);

    const documents_keys = Object.keys(documents);
    const feedme_urls_keys = Object.keys(feedme_urls);
    if (
      documents_keys.includes("govt_id_front") ||
      documents_keys.includes("govt_id_back") ||
      feedme_urls_keys.includes("govt_id_front") ||
      feedme_urls_keys.includes("govt_id_back")
    ) {
      formData.append("govt_id_type", this.state.documentSelect);
    }
    if (this.state.govtIdIsMx) {
      formData.append("govt_id_is_mx", this.state.govtIdIsMx);
    }
    if (documents_keys.length !== 0) {
      for (let file in documents) {
        formData.append(file, documents[file]);
      }
    }
    if (feedme_urls_keys.length !== 0) {
      formData.append("feedme_urls", JSON.stringify(feedme_urls));
    }

    if (verified && verified.mati) {
      formData.append("mati_identity_id", verified.mati.id);
    }

    if (documents_keys.length !== 0 || feedme_urls_keys !== 0) {
      this.handleClose();
      const response = await postDocuments(formData);
      if (response.status === 201) {
        swal("Excelente!", "Acción realizada exitosamente.", "success");
      } else if (response.status === 400) {
        swal(
          "Notificación!",
          `Al cambiar de tipo de identificación de este cliente
debes subir frente y reverso al mismo tiempo`,
          "info"
        );
      } else {
        this.showModalError(response);
      }
    }
  }

  async openModalFeedmeUri(feedme_uri) {
    this.setState({ modalFeedmeUriOpen: true });
    const response = await getTakemeDocument({
      feedme_uri: feedme_uri
    });
    if (response.status === 200) {
      this.setState({
        modalFeedmeUri: {
          blob: response.data,
          mimetype: response.type,
          feedme_uri: feedme_uri,
          document_select: this.state.documentSelect
        }
      });
    } else {
      this.handleCloseFeedmeUri();
      this.showModalError(response);
    }
  }

  isValidURL(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    );
    if (
      res !== null &&
      res[0].includes("https://s3.amazonaws.com/feedme.cuenca.io/")
    )
      return true;
    return false;
  }

  async handleShowFeedmeUri() {
    const message = "Visualizar el documento detras de un feedme_uri";
    const feedme_uri = await swal(message, {
      title: "Visualizar feedme_uri",
      content: {
        element: "input",
        attributes: {
          placeholder: "Ingrese el enlace feedme_uri",
          type: "text"
        }
      },
      buttons: ["Cancelar", "Visualizar"]
    });
    if (feedme_uri && this.isValidURL(feedme_uri)) {
      this.openModalFeedmeUri(feedme_uri);
    } else if (feedme_uri && !this.isValidURL(feedme_uri)) {
      swal("Lo siento!", "El enlace debe ser una url valida", "error");
    } else if (feedme_uri === "") {
      swal("Lo siento!", "El enlace feedme_uri es requerido!", "error");
    }
  }

  handleCloseFeedmeUri = () => {
    this.setState({
      modalFeedmeUriOpen: false,
      modalFeedmeUri: null
    });
  };

  handleSelectFeedmeUri = data => {
    let documents = this.state.documents;
    let feedme_urls = this.state.feedme_urls;
    const values = Object.values(feedme_urls);
    if (values.includes(data.feedme_uri)) {
      Object.entries(feedme_urls).forEach(([type, feedme_uri]) => {
        if (feedme_uri === data.feedme_uri) {
          delete feedme_urls[type];
        }
      });
    }
    delete documents[data.document_type];
    feedme_urls[data.document_type] = data.feedme_uri;
    this.setState({
      feedme_urls: feedme_urls
    });
  };

  render() {
    const { classes, customer, ...other } = this.props;
    const documentSelect = this.state.documentSelect;
    let gotv_id = {};
    if (documentSelect === "ine") {
      gotv_id = {
        title_front: "Documento de Identidad (FRENTE): ",
        title_back: "Documento de Identidad (REVERSO):"
      };
    } else if (documentSelect === "residence") {
      gotv_id = {
        title_front: "Tarjeta de Residencia (FRENTE): ",
        title_back: "Tarjeta de Residencia (REVERSO):"
      };
    } else if (documentSelect === "matricula") {
      gotv_id = {
        title_front: "Matricula Consultar (FRENTE): ",
        title_back: "Matricula Consultar (REVERSO):"
      };
    } else if (documentSelect === "passport") {
      gotv_id = {
        title_front: "Pasaporte de uso Internacional: "
      };
    }
    const feedme_urls = this.state.feedme_urls;
    const files_govt_id = (
      <div>
        {!feedme_urls["govt_id_front"] ? (
          <div>
            <div>
              <strong>{gotv_id["title_front"]}</strong>
              <input
                type="file"
                name="govt_id_front"
                onChange={this.onChangeHandlerFile}
              />
            </div>
          </div>
        ) : (
          <div
            onClick={() =>
              this.openModalFeedmeUri(feedme_urls["govt_id_front"])
            }
          >
            <strong>
              {gotv_id["title_front"]}{" "}
              <span className={classes.feedmeUriLink}>
                {feedme_urls["govt_id_front"]}
              </span>
            </strong>
          </div>
        )}
        {documentSelect !== "passport" ? (
          <div>
            {!feedme_urls["govt_id_back"] ? (
              <div>
                <hr />
                <div>
                  <strong>{gotv_id["title_back"]}</strong>
                  <input
                    type="file"
                    name="govt_id_back"
                    onChange={this.onChangeHandlerFile}
                  />
                </div>
              </div>
            ) : (
              <div
                onClick={() =>
                  this.openModalFeedmeUri(feedme_urls["govt_id_back"])
                }
              >
                <hr />
                <strong>
                  {gotv_id["title_back"]}{" "}
                  <span className={classes.feedmeUriLink}>
                    {feedme_urls["govt_id_back"]}
                  </span>
                </strong>
              </div>
            )}
          </div>
        ) : null}
        {documentSelect === "passport" || documentSelect === "residence" ? (
          <div>
            <br />
            <div>
              Es un documento Mexicano?
              <input
                type="checkbox"
                checked={this.state.govtIdIsMx}
                onChange={() => {
                  this.setState({ govtIdIsMx: !this.state.govtIdIsMx });
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
    let modalFeedmeUriTitle = "";
    if (this.state.modalFeedmeUri) {
      modalFeedmeUriTitle = "Documento consultado";
    }
    return [
      <ModalOfDocument
        key={0}
        id="feedme_uri"
        open={this.state.modalFeedmeUriOpen}
        title={modalFeedmeUriTitle}
        document={this.state.modalFeedmeUri}
        onClose={this.handleCloseFeedmeUri}
        onSelect={this.handleSelectFeedmeUri}
        maxWidth="sm"
      />,
      <Dialog
        key={1}
        onClose={this.handleClose}
        disableEnforceFocus={true}
        aria-labelledby="responsive-dialog-title"
        {...other}
      >
        <DialogTitle
          id="responsive-dialog-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>
            Actualizar Documentos
            <div
              className={classes.showFeedmeUri}
              onClick={() => this.handleShowFeedmeUri()}
            >
              Ver Feedme Uri
            </div>
          </h4>
        </DialogTitle>
        <DialogContent className={classes.modalBody}>
          <div>
            <GridContainer>
              <GridItem xs={6} sm={6} md={3} className={classes.alignCenter}>
                <div
                  onClick={() => {
                    this.handleChangeTypeDocument("ine");
                  }}
                  className={classes.changeTypeDocument}
                >
                  <Badge
                    color={
                      this.state.documentSelect === "ine" ? "info" : "gray"
                    }
                  >
                    INE
                  </Badge>
                </div>
              </GridItem>
              <GridItem xs={6} sm={6} md={3} className={classes.alignCenter}>
                <div
                  onClick={() => {
                    this.handleChangeTypeDocument("residence");
                  }}
                  className={classes.changeTypeDocument}
                >
                  <Badge
                    color={
                      this.state.documentSelect === "residence"
                        ? "info"
                        : "gray"
                    }
                  >
                    RESIDENCIA
                  </Badge>
                </div>
              </GridItem>
              <GridItem xs={6} sm={6} md={3} className={classes.alignCenter}>
                <div
                  onClick={() => {
                    this.handleChangeTypeDocument("matricula");
                  }}
                  className={classes.changeTypeDocument}
                >
                  <Badge
                    color={
                      this.state.documentSelect === "matricula"
                        ? "info"
                        : "gray"
                    }
                  >
                    MATRICULA
                  </Badge>
                </div>
              </GridItem>
              <GridItem xs={6} sm={6} md={3} className={classes.alignCenter}>
                <div
                  onClick={() => {
                    this.handleChangeTypeDocument("passport");
                  }}
                  className={classes.changeTypeDocument}
                >
                  <Badge
                    color={
                      this.state.documentSelect === "passport" ? "info" : "gray"
                    }
                  >
                    PASAPORTE
                  </Badge>
                </div>
              </GridItem>
            </GridContainer>
            <hr />
            {files_govt_id}
            {!feedme_urls["proofs_of_address"] ? (
              <div>
                <hr />
                <strong>Comprobante de Domicilio: </strong>
                <input
                  type="file"
                  name="proofs_of_address"
                  onChange={this.onChangeHandlerFile}
                />
              </div>
            ) : (
              <div
                onClick={() =>
                  this.openModalFeedmeUri(feedme_urls["proofs_of_address"])
                }
              >
                <hr />
                <strong>
                  Comprobante de Domicilio:{" "}
                  <span className={classes.feedmeUriLink}>
                    {feedme_urls["proofs_of_address"]}
                  </span>
                </strong>
              </div>
            )}
            {customer.identity_id ? (
              <div>
                {!feedme_urls["proofs_of_life"] ? (
                  <div>
                    <hr />
                    <div>
                      <strong>Prueba de Vida: </strong>
                      <input
                        type="file"
                        name="proofs_of_life"
                        onChange={this.onChangeHandlerFile}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      this.openModalFeedmeUri(feedme_urls["proofs_of_life"])
                    }
                  >
                    <hr />
                    <strong>
                      Prueba de Vida:{" "}
                      <span className={classes.feedmeUriLink}>
                        {feedme_urls["proofs_of_life"]}
                      </span>
                    </strong>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => this.handleClose()}>Cancelar</Button>
          <Button color="info" onClick={() => this.handleUploadDocuments()}>
            Actualizar
          </Button>
        </DialogActions>
      </Dialog>
    ];
  }
}

ModalUploadDocument.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  customer: PropTypes.object,
  verified: PropTypes.object
};

export default withStyles(styles)(ModalUploadDocument);
