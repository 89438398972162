import React from "react";
import { withRouter } from 'react-router';

import ReactTable from "react-table";

import PropTypes from "prop-types";

import swal from "@sweetalert/with-react";

import Moment from "react-moment";

// @material-ui/core components
import Badge from "@material-ui/core/Badge";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import AutoRenew from "@material-ui/icons/Autorenew";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Send from "@material-ui/icons/Send";

// core components
import Button from "components/CustomButtons";
import Card from "components/Card";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";
import { processError } from 'components/ErrorBoundary';

import {
  PaymentTypeIcon,
  getReferredColor,
  getReferredText,
  pueblaPostalCodes,
} from 'common/utils';

import regularFormsStyle from "assets/jss/omni/views/regularFormsStyle";

import { cardTitle, tooltip } from "assets/jss/omni";
import poblano from 'assets/img/poblano.png';

import {
  getGaitaOrdersQuoted,
  patchGaitaOrdersQuoted,
  postGaitaOrdersQuoted,
  getMergedGuide,
  getOrderGuide,
  postGaitaOrderManual,
} from "providers/api-rest.js";

const styles = {
  regularFormsStyle,
  tooltip,
  allSelectedButton: {
    margin: 0,
    top: 0
  },
  buttonRealize: {
    margin: 0
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  icon: {
    marginLeft: "7%",
    fontSize: "20px"
  },
  search: {
    minWidth: "225px",
    margin: 0,
    padding: 0
  },
  topLeftContainer: {
    width: "60%",
    position: "absolute",
    right: 0,
    top: "10px",
    padding: 0,
    margin: 0
  },
  updateTable: {
    position: "absolute",
    top: "10px",
    padding: 0,
    margin: 0,
    width: "5%"
  },
  updateReferrer: {
    position: "absolute",
    top: "10px",
    marginLeft: "60px",
    width: "120px"
  },
  updatedCursor: {
    cursor: "pointer"
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    minHeight: '80px',
  },
};

class GaitaOrdersForeignReferred extends React.Component {
  constructor(props) {
    super(props);

    this.provider = props.provider;
    this.state = {
      loading: true,
      search: null,
      lastState: null,
      selectedOrders: [],
      checkedAll: false,
      data: [],
      page: 1,
      pageSize: 7,
      response: null
    };
  }

  async onFetchData(state) {
    this.setState({
      loading: true,
      lastState: state
    });

    let orderType;
    if (this.provider === "ivoy_nextday") {
      orderType = "local_next_day";
    } else {
      orderType = "domestic";
    }

    const params = {
      page: state.page + 1,
      page_size: state.pageSize,
      order_referred: "ss,special_edition,hiking",
      order_type: orderType,
      order_status: "created,ordering",
      search: state.search
    };

    const response = await getGaitaOrdersQuoted(params);
    this.setState({
      data:
        response.data && response.data.orders_groups
          ? response.data.orders_groups
          : [],
      loading: false,
      pages: response.data ? response.data.pages : 1,
      response: response
    });
  }

  onFetchDataReload() {
    this.setState({ loading: true });
    this.onFetchData(this.state.lastState);
  }

  onKeyPressSearch(search) {
    let lastState = this.state.lastState;
    lastState["search"] = search;
    this.setState({ lastState: lastState });
    this.onFetchData(lastState);
  }

  async handleExecuteCancelUserRequest(request_id) {
    const response = await patchGaitaOrdersQuoted(request_id);
    if (response.status === 200) {
      this.onFetchData(this.state.lastState);
    } else {
      swal(
        "Lo siento!",
        "Error inesperado, intente de nuevo o contacta a Ingeniería",
        "error"
      );
    }
  }

  async handleClickCancelUserRequest(request_id) {
    let message = "Estas seguro que quieres cancelar este pedido";
    await swal(message, {
      title: "Accion requerida",
      buttons: ["No", "Si"]
    }).then(willGroup => {
      if (willGroup) {
        this.handleExecuteCancelUserRequest(request_id);
      } else {
        return;
      }
    });
  }

  async handleClickConfirmQuote(orders_list) {
    const oneOrMany = orders_list.length > 1 ? "estas órdenes" : "esta orden";
    let message = `¿Estás seguro que quieres realizar ${oneOrMany}?`;

    await swal(message, {
      title: "Accion requerida",
      buttons: ["Cancelar", "Ordenar"]
    }).then(async willContinue => {
      if (willContinue) {
        // Get data from selected orders to check for suspicious addresses
        const { data } = this.state;
        const dataFromOrders = data.filter(order =>
          orders_list.includes(order.group_id)
        );

        // Get individual orders
        const allOrdersFromOrders = [];
        dataFromOrders.forEach(order =>
          order.orders.forEach(miniOrder =>
            allOrdersFromOrders.push({ ...miniOrder, group_id: order.group_id })
          )
        );

        // Check if any suspicious address exists
        const anySuspicious = allOrdersFromOrders.reduce(
          (isThereAny, order) =>
            isThereAny || order["suspicious_addresses"].length > 0,
          false
        );

        // If any address is found:
        // Show all suspicious addresses and ask for confirmation
        if (anySuspicious) {
          message = `Se encontraron direcciones sospechosas en ${oneOrMany}:`;

          // Build message showing all addresses for each order
          allOrdersFromOrders.forEach(order => {
            const suspicious = order["suspicious_addresses"];
            if (suspicious.length > 0) {
              message = `${message}
                \tPara la orden ${order.group_id} se encontraron:
              `;

              // Show addresses as a list
              for (let i = 0; i < suspicious.length; i++) {
                message = `${message}
                  \t\t${i + 1} –– ${suspicious[i]}
                `;
              }
            }
          });
          message = `${message}
            \n¿Desea continuar?
          `;

          const next = await swal(message, {
            title: "Direcciones Sospechosas",
            buttons: ["Cancelar", "Ordenar"]
          });

          if (!next) return;
        }

        this.handleExecuteConfirmQuote(orders_list);
      } else {
        return;
      }
    });
  }

  async handleExecuteConfirmQuote(orders_list) {
    this.setState({
      loading: true,
      checkedAll: false,
      selectedOrders: []
    });

    const payload = {
      data: orders_list.map(id => ({
        order_id: id,
        provider: this.provider
      }))
    };

    const response = await postGaitaOrdersQuoted(payload);

    if (response.status === 201) {
      let data = this.state.data;

      // Get info about the order to show in case of error
      let sentOrdersOrderInfo = data.filter(order =>
        orders_list.includes(order.group_id)
      );
      sentOrdersOrderInfo = sentOrdersOrderInfo.map(order => {
        const emails = order.orders.reduce((emails, individual) => {
          emails.push(individual.email);
          return emails;
        }, []);
        return { group_id: order.group_id, emails };
      });

      data = data.filter(order => !orders_list.includes(order.group_id));
      const notSent = response.data["not_sent"];
      const sent = response.data.sent;

      // If any file was not send show a message with the info
      if (notSent.length > 0) {
        let message = `Las siguientes ${
          response.data["not_sent"].length
        } órdenes no fueron enviadas:`;

        notSent.forEach(order => {
          const orderErrorInfo = sentOrdersOrderInfo.filter(
            sentOrder => sentOrder.group_id === order.group_id
          );
          message = `${message}
            ${orderErrorInfo[0].emails} – Error: ${
            order.error
          }. ${order.provider_status || order.error_code}`;
        });

        await swal(message, {
          title: "Error en órdenes",
          icon: "warning"
        });

        return;
      }

      // Gather necessary data for s3 file for each sent order
      if (sent.length > 0) {
        const s3_data = sent.map(order_sent => ({
          group_id: order_sent.group_id,
          guide_link: order_sent.guide_link,
          provider_id: order_sent.provider_id
        }));

        await this.handleClickDownloadGuide(s3_data, sentOrdersOrderInfo);
      }

      this.setState({ data, loading: false });
    } else {
      swal(
        "Lo siento!",
        "Error inesperado, intente de nuevo o contacta a Ingeniería",
        "error"
      );
      this.setState({ loading: false });
    }
  }

  async handleClickDownloadGuide(s3_data, sentOrdersOrderInfo) {
    // Base case: if only one file exists do not merge PDFs
    if (s3_data.length === 1) {
      const fileSplit = s3_data[0].guide_link.split("/");
      const filename = fileSplit.pop();
      const bucket = fileSplit.pop();

      const params = {
        filename,
        bucket,
        provider_id: s3_data[0].provider_id
      };

      const response = await getOrderGuide(params);

      if (response.status === 200) {
        let link = document.createElement("a");
        document.body.appendChild(link);
        link.setAttribute("download", params.filename);
        link.href = response.data;
        link.click();
      } else {
        const errorInfo = sentOrdersOrderInfo.filter(
          orderInfo => orderInfo.group_id === s3_data[0].group_id
        );

        alert(
          `La orden para ${
            errorInfo[0].emails
          } fue creada pero la guia no esta lista. Intenta descargarla en unos minutos.`
        );
      }
    } else {
      // Merge all files into single PDF, then download
      let allFiles = [];

      allFiles = s3_data.map(individual => {
        const fileSplit = individual.guide_link.split("/");
        const filename = fileSplit.pop();
        const bucket = fileSplit.pop();

        return {
          filename,
          bucket,
          provider_id: individual.provider_id
        };
      });

      const response = await getMergedGuide(
        { timestamp: Date.now() },
        { files: allFiles }
      );
      if (response.status === 200) {
        let link = document.createElement("a");
        document.body.appendChild(link);
        link.setAttribute("download", "Guia_Ordenes");
        link.href = response.data;
        link.click();
      } else {
        let errorInfo = sentOrdersOrderInfo.filter(
          orderInfo => orderInfo.group_id === s3_data[0].group_id
        );
        errorInfo = errorInfo.reduce((emails, info) => {
          emails.push(info.emails);
          return emails;
        }, []);

        alert(
          `Las órdenes para ${errorInfo} fueron creadas pero una o varias guías no están listas. Intenta descargarla en unos minutos individualmente.`
        );
      }
    }
  }

  handleManualOrder = async orderId => {
    const message = '¿Estás segurx que mandaste esta orden manual? 🧐';
    if (await swal(message, {
      title: 'Confirma',
      buttons: ['No, Cancelar', '¡Sí!'],
    })) {
      this.setState({ loading: true });
      const payload = { order_id: orderId, provider: this.provider };
      const response = await postGaitaOrderManual(payload);

      if (response.status === 201) {
        await swal(
          'Éxito 🥳',
          'La orden se completó correctamente',
          'success',
        );
      } else {
        await swal(
          'No se pudo mandar la orden 😩',
          'Algo salió mal al completar la orden, intenta de nuevo o contact a Ingeniería pls',
          'error',
        );
      }
      this.setState({ loading: false });
    }
  };

  handleCheckAll() {
    const { data, checkedAll } = this.state;
    let newSelected = this.state.selectedOrders;

    if (!checkedAll) {
      data.forEach(order => {
        if (!newSelected.includes(order.group_id)) {
          newSelected.push(order.group_id);
        }
      });
    } else {
      newSelected = [];
    }

    this.setState({
      selectedOrders: newSelected,
      checkedAll: !checkedAll
    });
  }

  handleCheck(group_id) {
    let newSelected = this.state.selectedOrders;
    const checkedGroup = newSelected.includes(group_id);

    if (checkedGroup) {
      newSelected = newSelected.filter(group => group !== group_id);
    } else {
      newSelected.push(group_id);
    }

    this.setState({ selectedOrders: newSelected });
  }

  getSuspiciousNumber(orders) {
    return orders.reduce(
      (count, order) => count + order["suspicious_addresses"].length,
      0
    );
  }

  getTrProps(state, rowInfo) {
    if (rowInfo) {
      const color = getReferredColor(rowInfo.original.referred);

      return {
        style: {
          backgroundColor: color
        }
      };
    }
    return {};
  }

  renderReferred(referred) {
    return getReferredText(referred);
  }

  render() {
    const { classes, location } = this.props;
    const {
      checkedAll,
      data,
      loading,
      pages,
      response,
      selectedOrders
    } = this.state;

    const columns = [
      {
        Header: (
          <Checkbox
            checked={checkedAll}
            onChange={this.handleCheckAll}
            value="primary"
          />
        ),
        accessor: "select_all",
        minWidth: 50,
        Cell: row => (
          <Checkbox
            checked={selectedOrders.includes(row.original.group_id)}
            onChange={() => this.handleCheck(row.original.group_id)}
            value="secondary"
          />
        )
      },
      {
        Header: "Entregas",
        accessor: "customer_orders",
        minWidth: 70,
        Cell: row => {
          const { orders_count, payment_types } = row.original;
          return (
            <>
              <span>{orders_count} Entregas</span>
              {payment_types && payment_types.map(type =>
                <PaymentTypeIcon
                  id={`gofr-${type}`}
                  type={type}
                  classes={classes}
                  size='small'
                />
              )}
            </>
          )
        }
      },
      {
        Header: "Direccion",
        accessor: "eta",
        minWidth: 250,
        Cell: row => {
          const {
            full_address,
            zip_code,
            int_number,
            comment,
          } = row.original.orders[0];
          return (
            <span>
              {pueblaPostalCodes.has(zip_code) && (
                <>
                  <strong style={{color: '#92c155'}}>
                    {`CÓDIGO POSTAL DE PUEBLA (99 Minutos) `}
                  </strong>
                  <img src={poblano} width={20} />
                  <br />
                </>
              )}
              <strong>{`DIRECCION: ${full_address}`}</strong>
              <br />
              <strong>{`CODIGO POSTAL: ${zip_code} `}</strong>
              <strong>{`INTERIOR ${int_number}`}:</strong>
              <br />
              <strong>{`COMENTARIO: ${comment}`}</strong>
            </span>
          );
        },
      },
      {
        Header: "Contacto",
        accessor: "name",
        minWidth: 160,
        Cell: row => {
          const primary = row.original.orders[0];
          return (
            <span>
              {primary.name} - {primary.telephone}
            </span>
          );
        }
      },
      {
        Header: "Fecha de Creacion",
        accessor: "created_at",
        minWidth: 130,
        Cell: row => {
          const created_at = row.original.created_at.split(".")[0];
          return <Moment utc>{created_at}</Moment>;
        }
      },
      {
        Header: "Referido",
        accessor: "referred",
        minWidth: 70,
        Cell: row => {
          const referred_id = row.original.referred;
          return (
            <span>
              <strong>{this.renderReferred(referred_id)}</strong>
            </span>
          );
        }
      },
      {
        Header: "Acciones",
        minWidth: 100,
        Cell: row => {
          const { orders, group_id } = row.original;
          const { zip_code } = orders[0];
          return (
            <div className={`actions-right ${classes.actions}`}>
              <div>
                <Tooltip
                  id="tooltip-top"
                  title="Realizar Orden"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Badge
                    badgeContent={this.getSuspiciousNumber(orders)}
                    color="error"
                    overlap="circle"
                  >
                    <Button
                      size="sm"
                      color="info"
                      className={classes.buttonRealize}
                      round
                      onClick={() =>
                        this.handleClickConfirmQuote([group_id])
                      }
                    >
                      Realizar
                      <CheckCircle color="primary" className={classes.icon} />
                    </Button>
                  </Badge>
                </Tooltip>
              </div>
              {pueblaPostalCodes.has(zip_code) && (
                <div>
                <Tooltip
                  id="tooltip-top"
                  title="Realizada manualmente con 99 Minutos"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                    <Button
                      size="sm"
                      color="linkedin"
                      className={classes.buttonRealize}
                      round
                      onClick={() => this.handleManualOrder(group_id)}
                    >
                      99 Minutos
                    </Button>
                </Tooltip>
                </div>
              )}
            </div>
          )
        },
      }
    ];

    const subColumns = [
      {
        accessor: 'payment_type',
        minWidth: 30,
        Cell: row => (
          <PaymentTypeIcon
            type={row.original.payment_type}
            classes={classes}
          />
        )
      },
      {
        Header: "Cliente",
        accessor: "name"
      },
      {
        Header: "Telefono",
        accessor: "telephone"
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Fecha de Pedido",
        accessor: "created_at",
        minWidth: 130,
        Cell: row => {
          const created_at = row.original.created_at.split(".")[0];
          return <Moment utc>{created_at}</Moment>;
        }
      },
      {
        Header: "Direccion",
        accessor: "full_address",
        minWidth: 300,
        Cell: row => {
          const primary = row.original;
          return (
            <span>
              <strong>DIRRECCION:</strong> {primary.full_address}
              <br />
              <strong>CODIGO POSTAL:</strong> {primary.zip_code}{" "}
              <strong>INTERIOR:</strong> {primary.int_number}
              <br />
              <strong>COMENTARIO:</strong> {primary.comment}
            </span>
          );
        }
      },
      {
        Header: "Comentario",
        accessor: "comment",
        minWidth: 100,
        Cell: row => {
          const size = row.original["suspicious_addresses"].length;
          if (size > 0) {
            return (
              <Typography color="error" variant="body1">
                {`Direcciones sospechosas: ${size}`}
              </Typography>
            );
          }
          return null;
        }
      },
      {
        Header: "Acciones",
        minWidth: 70,
        filterable: false,
        Cell: row => {
          return (
            <div className={`actions-right ${classes.actions}`}>
              <Tooltip
                id="tooltip-top"
                title="Cancelar Pedido"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <span
                  onClick={() =>
                    this.handleClickCancelUserRequest(row.original.id)
                  }
                >
                  <Cancel color="secondary" className={classes.icon} />
                </span>
              </Tooltip>
            </div>
          );
        }
      }
    ];

    if (response && response.status !== 200 && response.status !== 201) {
      processError(
        response.status,
        location.pathname,
        false,
        (
          (response.data && (response.data.error || response.data.message))
          || `Listado órdenes foráneas referidas ${this.provider} de Gaita, contacta a ingeniería`
        )
      );
    }

    const provider = this.provider;
    let title;
    if (provider === "ivoy_nextday") {
      title = "Ordenes NextDay Referidos";
    } else {
      title = "Ordenes Foraneas Referidos";
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Send />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {title}
                <span
                  className={classes.updatedCursor}
                  onClick={() => this.onFetchDataReload()}
                >
                  <strong> |</strong> Actualizar
                  <span className={classes.updateTable}>
                    <AutoRenew />
                  </span>
                </span>
                <GridContainer
                  alignItems="flex-start"
                  className={classes.topLeftContainer}
                  justify="flex-end"
                >
                  <GridItem>
                    <CustomInput
                      className={classes.search}
                      formControlProps={{
                        className: classes.search
                      }}
                      inputProps={{
                        placeholder: "Buscar (User Id) (Teléfono) (Email)",
                        inputProps: {
                          "aria-label": "Buscar",
                          className: classes.searchInput
                        },
                        onKeyPress: event => {
                          if (event.key === "Enter") {
                            this.onKeyPressSearch(event.target.value);
                          }
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <Tooltip
                      id="tooltip-top"
                      title="Realizar Varias"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        className={classes.allSelectedButton}
                        color="info"
                        disabled={selectedOrders.length === 0}
                        onClick={() =>
                          this.handleClickConfirmQuote(selectedOrders)
                        }
                        round
                        size="sm"
                      >
                        Realizar ({selectedOrders.length})
                      </Button>
                    </Tooltip>
                  </GridItem>
                </GridContainer>
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                manual
                data={data}
                columns={columns}
                pages={pages}
                defaultPageSize={7}
                loading={loading}
                filterable={false}
                sortable={false}
                onFetchData={state => this.onFetchData(state)}
                getTrProps={this.getTrProps}
                className="-highlight"
                noDataText="No se han encontrado resultados"
                showPageSizeOptions={false}
                SubComponent={row => {
                  return (
                    <div
                      style={{
                        paddingLeft: "50px"
                      }}
                    >
                      <hr
                        style={{
                          marginBottom: "0px",
                          marginTop: "5px"
                        }}
                      />
                      <ReactTable
                        data={row.original.orders}
                        columns={subColumns}
                        defaultPageSize={row.original.orders.length}
                        showPagination={false}
                        sortable={false}
                      />
                    </div>
                  );
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

GaitaOrdersForeignReferred.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  provider: PropTypes.string.isRequired
};

export default withRouter(withStyles(styles)(GaitaOrdersForeignReferred));
