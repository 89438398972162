import React from "react";

import Messenger from "messenger/Messenger";

class Messages extends React.Component {
  render() {
    const props = this.props;
    let phone_number = "";
    if (props.phone_number) {
      phone_number = props.phone_number.replace("+521", "").replace("+52", "");
    }
    return (
      <div className="App">
        <Messenger search={phone_number} />
      </div>
    );
  }
}

export default Messages;
