import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/es";
import "./Message.css";

export default class Message extends Component {
  render() {
    const {
      data,
      isMine,
      startsSequence,
      endsSequence,
      showTimestamp
    } = this.props;

    const friendlyTimestamp = moment(data.timestamp)
      .locale("es")
      .format("LLLL");
    return (
      <div
        className={[
          "message",
          `${isMine ? "mine" : ""}`,
          `${startsSequence ? "start" : ""}`,
          `${endsSequence ? "end" : ""}`
        ].join(" ")}
      >
        {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

        <div className="bubble-container">
          <div className="bubble" title={friendlyTimestamp}>
            <strong>
              <small>{data.fromName}</small>
            </strong>
            <br />
            {data.message}
            {data.image ? (
              <img width="80%" src={data.image} alt={data.fromName} />
            ) : null}
            {data.audio ? <audio src={data.audio} controls /> : null}
            {data.video ? <video src={data.video} controls /> : null}
            {data.author === "apple" ? (
              <div>
                <span className="status">
                  <small>{data.status || "sent"}</small>
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

Message.propTypes = {
  data: PropTypes.object.isRequired,
  isMine: PropTypes.bool.isRequired,
  startsSequence: PropTypes.bool.isRequired,
  endsSequence: PropTypes.bool.isRequired,
  showTimestamp: PropTypes.bool.isRequired
};
