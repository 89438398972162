import React, { Component } from "react";
import PropTypes from "prop-types";

import ConversationSearch from "../ConversationSearch/index.jsx";
import ConversationListItem from "../ConversationListItem/index.jsx";

import { getConversationList } from "providers/api-rest.js";

import "./ConversationList.css";

export default class ConversationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: null,
      conversations: [],
      getMessagelist: props.getMessagelist,
      chatSelect: null
    };
  }

  componentDidMount() {
    this.getConversations();
  }

  getConversations = async (search = "") => {
    if (this.props.search) {
      search = this.props.search;
    }
    const response = await getConversationList({ search: search });
    this.setState(prevState => {
      let conversations = response.data.map(result => {
        const chatNumber = result.phone_number;
        let name = chatNumber + " - " + result.fromName;
        if ("clientName" in result) {
          name = result["clientName"];
        }
        const lastDigitsNumber = chatNumber.slice(-2);
        return {
          chatId: result._id,
          clientName: name,
          lastDigitsNumber: lastDigitsNumber,
          lastMessage: result.lastMsg,
          chatNumber: chatNumber,
          clientId: result.clientId,
          botmakerId: result.botmakerId
        };
      });
      return { ...prevState, conversations };
    });
    if (response.data.length) this.getMessagelist(response.data[0]._id);
  };

  getMessagelist = chatId => {
    this.setState({
      chatSelect: chatId
    });
    const conversations = this.state.conversations;

    let chat = null;
    for (const index in conversations) {
      if (conversations[index] && conversations[index]["chatId"] === chatId) {
        chat = conversations[index];
        break;
      }
    }
    this.state.getMessagelist(chat);
  };

  getConversationList = chatSearch => {
    this.getConversations(chatSearch);
  };

  render() {
    return (
      <div className="conversation-list">
        <ConversationSearch getConversationList={this.getConversationList} />
        {this.state.conversations.map(conversation => (
          <ConversationListItem
            key={conversation.clientName}
            data={conversation}
            getMessagelist={this.getMessagelist}
            chatSelect={this.state.chatSelect}
          />
        ))}
      </div>
    );
  }
}

ConversationList.propTypes = {
  getMessagelist: PropTypes.func.isRequired,
  search: PropTypes.string
};
