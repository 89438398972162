import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import Swal from 'sweetalert2';
import Moment from "react-moment";
import ReactPanZoom from "react-image-pan-zoom-rotate";

import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons";
import GridContainer from "components/Grid";
import GridItem from "components/Grid/GridItem.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import Close from "@material-ui/icons/Close";
import Done from "@material-ui/icons/Done";

import {
    getPasswordResetDetail,
    getTakemeDocument,
    getDocuments,
    patchPasswordReset,
} from 'providers/api-rest';

const styles = {
    headerName: {
		background: '#616C93',
		height: '70px',
		fontSize: '20px',
		padding: '14px',
		color: 'white',
		fontWeight: '600',
		'&:hover': {
			cursor: 'pointer',
			background: 'rgb(163, 169, 194, 0.15)',
			color: '#616C93',
		}
	},
    cardStyle: {
		borderRadius: 0,
		marginTop: '10px',
		marginBottom: '5px',
		boxShadow: '0 0 0 0',
		'& td': {
			fontFamily: "Helvetica Neue",
			color: '#8D8D8D',
			borderTop: '1px solid rgba(141, 141, 141, 0.25)',
			fontWeight: '400',
		},
		'& tr:first-child': {
			'& td':{
				borderTop: 0
			}
		},
		'& > div':{
		  	padding: '0 20px'
		}
	},
    reactPanZoom: {
        overflow: "hidden",
        position: "relative"
    },
    verticalCenter: {
        margin: "0",
        top: "50%",
        transform: "translateY(-50%)",
        textAlign: "center",
        position: "relative"
    },
};

function PasswordResetDetal({ classes, match }) {
    const { userId, passwordResetId } = match.params;
    const [[loadingGov, govIdBloc], setGovId] = useState([true, null]);
    const [[loadingVideo, selfieVideo], setVideo] = useState([true, null]);
    const [resetData, setData] = useState({
        humanId: '',
        name: '',
        feedmeUri: '',
        createdAt: null,
    });

    const getId = async () => {
        const response = await getDocuments({
            document_type: 'govt_ids',
            user_id: userId,
        });
        if (response.status === 200) setGovId([false, response.data]);
    };

    const getVideo = async () => {
        const { feedme_uri } = resetData;
        if (!feedme_uri) return;
        const response = await getTakemeDocument({ feedme_uri });
        if (response.status === 200) setVideo([false, response.data]);
    };

    const getDetail = async () => {
        const response = await getPasswordResetDetail({ passwordResetId });
        if (response.status === 200) {
            const { human_id, name, created_at, feedme_uri } = response.data;
            setData({
                humanId: human_id,
                createdAt: created_at,
                feedme_uri,
                name,
            });
        }
    };

    const fetchData = async () => {
        await getDetail();
        getId();
    };

    const changeVideoStatus = async status => {
        const { isDismissed, value } = await Swal.fire({
            title: '¿Estás segure?',
            text: '¿Segure que deseas realizar esta acción? 🤔',
            input: 'text',
            inputAttributes: {
                placeholder: 'Ingrese el motivo de acción',
            },
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: 'Confirmar',
        });

        if (isDismissed) return;
        if (!value) {
            Swal.fire({
                title: 'Lo siento 😥',
                text: 'Motivo requerido',
                icon: 'error',
                confirmButtonText: 'Upsi',
            });
            return;
        }

        const response = await patchPasswordReset({
            reason: value,
            passwordResetId,
            status,
        });

        if (response.status === 201) {
            setTimeout(() => window.close(), 5500);
            await Swal.fire({
                title: '¡Éxito! 🥳',
                text:
                    `Se ${status === 'failed' ? 'rechazó' : 'aprobó'} el video correctamente.

                    Esta ventana se cerrará en 5 segundos
                    `,
                icon: 'success',
                confirmButtonText: 'Cerrar de una',
            });
            window.close();
        } else {
            Swal.fire({
                title: 'Lo siento 😥',
                text: 
                    `El sistema no quizo actualizar el estatus del video.
                    
                    Intenta en un momento, si no jala, avísale a los inges. 🥲
                    `,
                icon: 'error',
                confirmButtonText: 'Upsi',
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        getVideo();
    }, [resetData]);

    return (
        <div>

                <div
                    className={classes.headerName}
                    onClick={() => window.open(`/admin/customer/${encodeURIComponent(resetData.humanId)}`)}
                >
                    {resetData.name}
                    <br/>
                    {resetData.createdAt && (
                        <Moment utc>{resetData.createdAt}</Moment>
                    )}
                </div>

                <Card className={classes.cardStyle}>
                    <CardBody>
                        <GridContainer spacing={8}>

                            <GridItem xs={12}>
                                <GridContainer spacing={16}>

                                    <GridItem
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        className={classes.reactPanZoom}
                                    >
                                        <div className={classes.verticalCenter}>
                                            {loadingVideo ? <CircularProgress /> : (
                                                selfieVideo && (
                                                    <video
                                                        autoPlay
                                                        controls
                                                        loop
                                                        height="800"
                                                     >
                                                        <source src={selfieVideo} />
                                                    </video>
                                                )
                                            )}
                                        </div>
                                    </GridItem>

                                    <GridItem
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        className={classes.reactPanZoom}
                                    >
                                        <div className={classes.verticalCenter}>
                                            {loadingGov ? <CircularProgress /> : (
                                                govIdBloc && (
                                                    <ReactPanZoom
                                                        key={`panzoom${resetData.humanId}1`}
                                                        image={govIdBloc}
                                                        alt='Document ID'
                                                    />
                                                )
                                            )}
                                        </div>
                                    </GridItem>

                                </GridContainer>
                            </GridItem>

                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={2}>
                                        <Button
                                            round
                                            color="danger"
                                            onClick={() => changeVideoStatus('failed')}
                                        >
                                            <span>
                                                Rechazar video
                                            </span>
                                            <Close />
                                        </Button>
                                    </GridItem>

                                    <GridItem xs={2}>
                                        <Button
                                            round
                                            color="success"
                                            onClick={() => changeVideoStatus('succeeded')}
                                        >
                                            <span>
                                                Aceptar video
                                            </span>
                                            <Done />
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                        </GridContainer>
                    </CardBody>
                </Card>

        </div>
    );
}

export default withRouter(withStyles(styles)(PasswordResetDetal));
